import { QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';
import { useApiContext } from '../../hooks/useApiContext';

export const VALUATION_WORKFLOW_LOGS_KEY = 'ValuationWorkflowLogs';

export const BuildValuationWorkflowLogsRequestBody = (workflowTaskId: string): QueryExecuteRequest => ({
    domainId: 'VALU_ValuationWorkflowLog',
    primaryViewId: '-1',
    adhocListViewMembers: [
        {
            scalarDisplayName: 'Id',
            scalarExpression: '[Id]',
        },
        {
            scalarDisplayName: 'Description',
            scalarExpression: '[Description]',
        },
        {
            scalarDisplayName: 'Timestamp',
            scalarExpression: '[Timestamp]',
        },
        {
            scalarDisplayName: 'UserId',
            scalarExpression: '[UserId]',
        },
        {
            scalarDisplayName: 'WorkflowTaskId',
            scalarExpression: '[WorkflowTaskId]',
        },
        {
            scalarDisplayName: 'IsAutoComplete',
            scalarExpression: '[IsAutoComplete]',
        },
    ],
    sorting: {
        orderBy: {
            scalarExpression: '[Timestamp]',
        },
        order: 'desc',
    },
    paging: { start: 0, stop: 50 },
    summaryMode: false,
    adhocFilter: {
        filterName: 'adhoc',
        advancedInd: true,
        expressionLang: 'Centric',
        advancedExpression: `[WorkflowTaskId] = '${workflowTaskId}'`,
    },
});

export const useValuationWorkflowLogs = (workflowTaskId: string): UseQueryResult<QueryExecuteResponse> => {
    const api = useApiContext();

    const requestBody: QueryExecuteRequest = BuildValuationWorkflowLogsRequestBody(workflowTaskId);

    const queryResult = useQuery<QueryExecuteResponse>(
        [VALUATION_WORKFLOW_LOGS_KEY, workflowTaskId],
        () => executeLiftSiftQuery(api.LiftSiftApi, requestBody, api.requestInit, api.ShowMessage),
        { retry: 2, refetchOnWindowFocus: false },
    );

    return queryResult;
};

export default useValuationWorkflowLogs;
