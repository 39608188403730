import { mergeHeaders } from '@samc/react-ui-request';
import { QueryExecuteRequest, QueryExecuteResponse, TaskRequest, TaskResponse } from '@samc/screen-config-api';
import { EsInputMenuOption } from '../models/EsInputMenu';
import { WorkflowStatus } from '../models/WorkflowStatus';
import { GetHeaders } from './GetHeader';
import { HcrVcrRequestModel } from '../models/HcrVcrRequestModel';

export const fetchApi = async (
    input: RequestInfo,
    init?: RequestInit | undefined,
    ignoreStatus: number[] = [],
): Promise<Response> => {
    const response = await fetch(input, init);

    if (response.status >= 400 && response.status < 600 && ignoreStatus.indexOf(response.status) === -1) {
        const body = await response.json();

        if (body.developerMessage) {
            throw new Error(`${response.statusText}: ${body.developerMessage.message}`);
        }
        if (body.messages && body.messages.length > 0) {
            throw new Error(`${body.messages[0]}`);
        }
    }
    return response;
};

export const submitTask = async (
    baseUrl: string | undefined,
    domainId: string,
    fieldConfigurationId: string,
    requestIdentifier: string,
    payload: TaskRequest['payload'],
    requestInit: RequestInit,
    trialPost = true,
    fetch = fetchApi,
    overrideCodes: string[] = [],
): Promise<[TaskResponse, TaskRequest]> => {
    const request: TaskRequest & { postFlag: number } = {
        domainId,
        fieldConfigurationId,
        requestIdentifier,
        postFlag: trialPost ? 2 : 0,
        payload,
    };

    if (overrideCodes.length) request.overrideCodes = overrideCodes;

    const response = await fetch(`${baseUrl}/api/v2/task`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit?.headers, {
            'Content-Type': 'application/json; charset=utf-8',
        }),
        body: JSON.stringify(request),
    });

    return [await response.json(), request];
};

export const postData = async <T = Record<string, unknown>>(
    baseUrl: string | undefined,
    url: string,
    body: unknown,
    requestInit: RequestInit,
): Promise<T> => {
    const response = await fetchApi(`${baseUrl}${url}`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), requestInit?.headers, {
            'Content-Type': 'application/json; charset=utf-8',
        }),
        body: JSON.stringify(body),
    });
    return response.json();
};

export const getData = async <T = Record<string, unknown>>(
    baseUrl: string | undefined,
    url: string,
    requestInit: RequestInit,
): Promise<T> => {
    const response = await fetchApi(`${baseUrl}/${url}`, {
        method: 'GET',
        headers: mergeHeaders(GetHeaders(), requestInit?.headers),
    });
    return response.json();
};

export const apiProvider = {
    getData,
    postData,
    submitTask,
};

export const getEsInputMenu = async (
    api: string,
    esInputDataId?: string,
    requestInit?: RequestInit,
): Promise<EsInputMenuOption[]> => {
    if (!esInputDataId) return [];

    const url = `${api}/api/ESInputData/ValuationDataMenu?esInputDataId=${esInputDataId}`;

    const response = await fetchApi(url, {
        method: 'GET',
        headers: mergeHeaders(GetHeaders(), requestInit?.headers),
    });

    return response.json();
};

export const getValuationWorkflowStatus = async (
    api: string,
    valuationId?: string,
    requestInit?: RequestInit,
): Promise<WorkflowStatus | undefined> => {
    if (!valuationId) return undefined;

    const url = `${api}/api/v1/ValuationWorkflow/status?valuationId=${valuationId}`;

    const response = await fetchApi(url, {
        method: 'GET',
        headers: mergeHeaders(GetHeaders(), requestInit?.headers),
    });

    return response.json();
};

export const resendNotification = async (api: string, taskId?: string, requestInit?: RequestInit): Promise<void> => {
    if (!taskId) return;

    const url = `${api}/api/v1/ValuationWorkflow/resend?taskId=${taskId}`;

    await fetchApi(url, {
        method: 'GET',
        headers: mergeHeaders(GetHeaders(), requestInit?.headers),
    });
};

/**
 * Posts a v2/Query/Execute request to Lift and Sift.
 * The logic here is taken from screen-config but without the cacheId stuff we do over there because it was causing us
 * to occasionally get stale results back when we wouldn't expect it. This function won't attach a cacheId of its own volition.
 *
 * @param liftSiftApi The url of the Lift and Sift endpoint.
 * @param requestBody The body to attach to the POST request.
 * @param requestInit
 * @param showError Error handling function that will be called on error.
 * @returns The Lift and Sift Execute response.
 */
export const executeLiftSiftQuery = async (
    liftSiftApi: string,
    requestBody: QueryExecuteRequest,
    requestInit?: RequestInit,
    showError?: (error: string) => void,
): Promise<QueryExecuteResponse> => {
    try {
        const response = await fetchApi(`${liftSiftApi}/api/v2/Query/Execute`, {
            method: 'POST',
            headers: mergeHeaders(GetHeaders(), requestInit?.headers, {
                'Content-Type': 'application/json; charset=utf-8',
            }),
            body: JSON.stringify(requestBody),
        });

        return response.json();
    } catch (e) {
        if (e instanceof Error) {
            showError?.(e.message);
        }
        throw e;
    }
};

export const downloadAsFile = async (response: Response, fileName: string): Promise<void> => {
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    // creates an anchor to inject a filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const getHcrVcrGenerator =
    (apiUrl: string, requestInit?: RequestInit) =>
    async (request: HcrVcrRequestModel): Promise<void> => {
        const targetUrl = `${apiUrl}/api/v1/generate/hcrvcr`;

        const response = await fetchApi(targetUrl, {
            method: 'POST',
            headers: mergeHeaders(GetHeaders(), requestInit?.headers, {
                'Content-Type': 'application/json; charset=utf-8',
            }),
            body: JSON.stringify(request),
        });

        // get file name from content-disposition header
        const contentDisposition = response.headers.get('content-disposition');
        const fileNameRegex = /filename=(.+);/;
        const fileNameMatch = contentDisposition?.match(fileNameRegex);

        await downloadAsFile(response, fileNameMatch?.[1] ?? 'report.xlsx');
    };
