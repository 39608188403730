import React from 'react';
import {
    Field,
    FieldLabel,
    FieldRoot,
    FormSkeleton,
    InputType,
    SectionHeader,
    useFormManagementContext as _useFormManagementContext,
} from '@samc/react-ui-form';
import { styled } from 'styletron-react';
import { MessageBar } from '@samc/react-ui-core';
import { WorkflowEditContactGridAssignTo } from '../WorkflowEditContactGridAssignTo/WorkflowEditContactGridAssignTo';
import { WorkflowEditContactGridEnabledFor } from '../WorkflowEditContactGridEnabledFor/WorkflowEditContactGridEnabledFor';
import { WorkflowEditContactGridViewableBy } from '../WorkflowEditContactGridViewableBy/WorkflowEditContactGridViewableBy';
import { ValuationContact } from '../../../valuationApi';

const formConfig: FormSkeleton = {
    title: 'Workflow Ad-Hoc Task Form',
    fields: {
        name: {
            field: 'Name',
            inputType: InputType.Text,
            editableRule: 'true',
            displayNameRule: "'Task Name'",
            requiredFieldRule: 'true',
        },
        description: {
            field: 'Description',
            inputType: InputType.MultilineText,
            editableRule: 'true',
            displayNameRule: "'Description'",
            requiredFieldRule: 'true',
        },
        dueDate: {
            field: 'DueDate',
            inputType: InputType.Date,
            editableRule: 'true',
            displayNameRule: "'Due Date'",
        },
    },
    gridFields: [],
    tabs: [],
};

/* istanbul ignore next */
const StyledFlexWrapper = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
}));

/* istanbul ignore next */
const StyledFieldLabelWrapper = styled('div', () => ({
    minWidth: '30ch',
    maxWidth: '30ch',
    display: 'flex',
    overflow: 'hidden',
    paddingRight: '4px',
}));

/* istanbul ignore next */
const StyledGridWrapper = styled('div', () => ({
    flex: '1 0 auto',
}));

/* istanbul ignore next */
const StyledFieldRoot = styled(FieldRoot, () => ({
    flex: '1',
    maxWidth: '100%',
    minWidth: '0px',
    width: '100%',
}));

/* istanbul ignore next */
const StyledFieldLabel = styled(FieldLabel, () => ({
    marginTop: 'unset',
}));

/* istanbul ignore next */
const StyledSectionWrapper = styled('div', () => ({
    marginTop: '5px',
    paddingTop: '10px',
}));

/* istanbul ignore next */
const StyledFieldWrapper = styled('div', () => ({
    borderTop: '1px solid #d1d1d1',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    paddingTop: '10px',
}));

interface Props {
    /**
     * The Id of the Valuation for which ValuationContacts will be fetched.
     */
    valuationId: string;
    /**
     * The id of the ValuationWorkflowTask for which to fetch ValuationWorkflowTaskOwners.
     */
    workflowTaskId: string;

    /**
     * Callback on contact deselected.
     */
    onContactAssignedToDeselected?: (contact?: ValuationContact) => void;
    /**
     * Callback on contact selected.
     */
    onContactAssignedToSelected?: (contact?: ValuationContact) => void;
    /**
     * Callback on contact deselected. This callback will not trigger when the current user is deselected.
     */
    onContactEnabledForDeselected?: (contact?: ValuationContact) => void;
    /**
     * Callback on contact selected. This callback will not trigger when the current user is selected.
     */
    onContactEnabledForSelected?: (contact?: ValuationContact) => void;
    /**
     * Callback on contact deselected.
     */
    onContactViewableByDeselected?: (contact?: ValuationContact) => void;
    /**
     * Callback on contact selected.
     */
    onContactViewableBySelected?: (contact?: ValuationContact) => void;
    /**
     * DI override of the hook to get the FormApi.
     */
    useFormManagementContext?: typeof _useFormManagementContext;
}

export const WorkflowEditAdHocTaskForm = ({
    onContactAssignedToDeselected,
    onContactAssignedToSelected,
    onContactEnabledForDeselected,
    onContactEnabledForSelected,
    onContactViewableByDeselected,
    onContactViewableBySelected,
    useFormManagementContext = _useFormManagementContext,
    valuationId,
    workflowTaskId,
}: Props): React.ReactElement => {
    const { setConfig } = useFormManagementContext();

    React.useEffect(() => {
        setConfig(formConfig);
    }, [setConfig]);

    return (
        <StyledFlexWrapper>
            <StyledSectionWrapper>
                <SectionHeader title="Task Information" />
                <StyledFieldWrapper>
                    <Field Id="name" />
                    <Field Id="description" />
                    <Field Id="dueDate" />
                </StyledFieldWrapper>
            </StyledSectionWrapper>
            <StyledSectionWrapper>
                <SectionHeader title="Responsible Valuation Contact" />
                <StyledFieldWrapper>
                    <MessageBar text="The Valuation Admin is always enabled for ad-hoc tasks." />
                    <StyledFieldRoot>
                        <StyledFieldLabelWrapper>
                            <StyledFieldLabel labelText="Assign To" />
                        </StyledFieldLabelWrapper>
                        <StyledGridWrapper>
                            <WorkflowEditContactGridAssignTo
                                onContactDeselected={onContactAssignedToDeselected}
                                onContactSelected={onContactAssignedToSelected}
                                valuationId={valuationId}
                                workflowTaskId={workflowTaskId}
                            />
                        </StyledGridWrapper>
                    </StyledFieldRoot>
                    <StyledFieldRoot>
                        <StyledFieldLabelWrapper>
                            <StyledFieldLabel labelText="Enabled For" />
                        </StyledFieldLabelWrapper>
                        <StyledGridWrapper>
                            <WorkflowEditContactGridEnabledFor
                                onContactDeselected={onContactEnabledForDeselected}
                                onContactSelected={onContactEnabledForSelected}
                                valuationId={valuationId}
                                workflowTaskId={workflowTaskId}
                            />
                        </StyledGridWrapper>
                    </StyledFieldRoot>
                    <StyledFieldRoot>
                        <StyledFieldLabelWrapper>
                            <StyledFieldLabel labelText="Viewable By" />
                        </StyledFieldLabelWrapper>
                        <StyledGridWrapper>
                            <WorkflowEditContactGridViewableBy
                                onContactDeselected={onContactViewableByDeselected}
                                onContactSelected={onContactViewableBySelected}
                                valuationId={valuationId}
                                workflowTaskId={workflowTaskId}
                            />
                        </StyledGridWrapper>
                    </StyledFieldRoot>
                </StyledFieldWrapper>
            </StyledSectionWrapper>
        </StyledFlexWrapper>
    );
};

export default WorkflowEditAdHocTaskForm;
