import React from 'react';
import { GridFieldConfiguration } from '@samc/react-ui-grid';
import { Picklist, PicklistDisplaySetting, PicklistSetting } from '@samc/picklist-api';
import {
    GridFieldConfigurationWithDynamicPicklistField,
    transformPicklistGridFieldConfigurations,
} from '@samc/picklist-core/lib/hooks/usePicklistGridFields/usePicklistGridFields';
import GridPicklistManager from '@samc/picklist-core/lib/utilities/GridPicklistManager/GridPicklistManager';
import { Evaluator } from '@samc/expressions-core';
import { ApiContextType } from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';
import RIGHT from '@samc/expressions-core/build/functions/RIGHT/RIGHT';
import { apiProvider } from '../../../../valuationApi';
import { createGuid } from '../../../static/ValuationWizard.utils';
import {
    AddTask,
    CreatedBy,
    month,
    PeriodTypes,
    ValuationPicklist,
    ReportType,
    Status,
    TemplateType,
    Valuation,
    ValuationAssetMethodologySetupVM,
    ValuationAssetVM,
} from '../../../static/ValuationConstants';

interface UpdateColumnParamTypes {
    template: Record<string, unknown>[];
    years: number;
    setPeriodData: (params: Record<string, unknown>[]) => void;
    setPeriodColumns: (params: GridFieldConfiguration[]) => void;
    setFrameworkComponents: React.Dispatch<React.SetStateAction<Record<string, unknown> | undefined>>;
    api: ApiContextType;
    valuationQuarterData: Picklist;
    valuationMonthData: Picklist;
    reportTypeData: Picklist;
    scopeData: Picklist;
    frequencyData: Picklist;
    sourceTypeData: Picklist;
    evaluate: Evaluator['evaluate'];
}

export const updateColumns = ({
    template,
    years,
    setPeriodData,
    setPeriodColumns,
    setFrameworkComponents,
    api,
    valuationQuarterData,
    valuationMonthData,
    reportTypeData,
    scopeData,
    frequencyData,
    sourceTypeData,
    evaluate,
}: UpdateColumnParamTypes): void => {
    const valuationQuarterPicklist = new GridPicklistManager({
        picklistGetter: async () => valuationQuarterData,
        itemGetter: async ({ picklistItems }) => ({
            items: picklistItems ?? [],
            totalCount: picklistItems?.length ?? 0,
        }),
    });

    const valuationMonthPicklist = new GridPicklistManager({
        picklistGetter: async () => valuationMonthData,
        itemGetter: async ({ picklistItems }) => ({
            items: picklistItems ?? [],
            totalCount: picklistItems?.length ?? 0,
        }),
    });

    const frequencyPicklist = new GridPicklistManager({
        picklistGetter: async () => frequencyData,
        itemGetter: async ({ picklistItems }) => ({
            items: picklistItems ?? [],
            totalCount: picklistItems?.length ?? 0,
        }),
    });

    const reportTypePicklist = new GridPicklistManager({
        picklistGetter: async () => reportTypeData,
        itemGetter: async ({ picklistItems }) => ({
            items: picklistItems ?? [],
            totalCount: picklistItems?.length ?? 0,
        }),
    });

    const scopePicklist = new GridPicklistManager({
        picklistGetter: async () => scopeData,
        itemGetter: async ({ picklistItems }) => ({
            items: picklistItems ?? [],
            totalCount: picklistItems?.length ?? 0,
        }),
    });

    const sourcePicklist = new GridPicklistManager({
        picklistGetter: async () => sourceTypeData,
        itemGetter: async ({ picklistItems }) => ({
            items: picklistItems ?? [],
            totalCount: picklistItems?.length ?? 0,
        }),
    });

    apiProvider
        .getData(
            api.ValuationApi as string,
            `/api/ValuationWizard/GetReportCycleTemplateDetails?TemplateId=${template[0].Id}&NumberOfYears=${years}`,
            api.requestInit ?? {},
        )
        .then((r): void => {
            if (r.years && Array.isArray(r.years)) {
                const { wrappedFields: picklistGfcs, frameworkComponents } = transformPicklistGridFieldConfigurations(
                    [
                        {
                            field: 'sourceType',
                            width: 120,
                            displayNameRule: 'Source Type',
                            picklistField: {
                                id: createGuid(),
                                picklistId: ValuationPicklist.Source,
                                displaySetting: PicklistDisplaySetting.LongName,
                                setting: PicklistSetting.Single,
                            },
                        },
                        {
                            field: 'reportType',
                            width: 150,
                            displayNameRule: 'Report Type',
                            picklistField: {
                                id: createGuid(),
                                picklistId: ValuationPicklist.ReportType,
                                displaySetting: PicklistDisplaySetting.LongName,
                                setting: PicklistSetting.Single,
                            },
                        },
                        {
                            field: 'scope',
                            width: 100,
                            displayNameRule: 'Scope',
                            picklistField: {
                                id: createGuid(),
                                picklistId: ValuationPicklist.Scope,
                                displaySetting: PicklistDisplaySetting.LongName,
                                setting: PicklistSetting.Single,
                            },
                        },
                        {
                            field: 'frequency',
                            width: 125,
                            displayNameRule: 'Frequency',
                            picklistField: {
                                id: createGuid(),
                                picklistId: ValuationPicklist.Frequency,
                                displaySetting: PicklistDisplaySetting.LongName,
                                setting: PicklistSetting.Single,
                            },
                        },
                        ...r.years.map(
                            (element): GridFieldConfigurationWithDynamicPicklistField => ({
                                field: element.toString(),
                                displayNameRule: element.toString(),
                                editableRule: 'true',
                                width: 150,
                                picklistField: ({ data }) => ({
                                    id: createGuid(),
                                    picklistId:
                                        data.schedulingPeriodType === PeriodTypes.MONTH
                                            ? ValuationPicklist.ValuationMonth
                                            : ValuationPicklist.ValuationQuarter,
                                    displaySetting: PicklistDisplaySetting.LongName,
                                    setting: PicklistSetting.MultiSelect,
                                }),
                            }),
                        ),
                    ],
                    ({ picklistId }) => {
                        switch (picklistId) {
                            case ValuationPicklist.Source:
                                return sourcePicklist;
                            case ValuationPicklist.Scope:
                                return scopePicklist;
                            case ValuationPicklist.ReportType:
                                return reportTypePicklist;
                            case ValuationPicklist.ValuationMonth:
                                return valuationMonthPicklist;
                            case ValuationPicklist.ValuationQuarter:
                                return valuationQuarterPicklist;
                            case ValuationPicklist.Frequency:
                            default:
                                return frequencyPicklist;
                        }
                    },
                    evaluate,
                );

                setFrameworkComponents(frameworkComponents);
                setPeriodColumns(picklistGfcs);
            }
            if (
                r.cycleTemplateReportDetails &&
                Array.isArray(r.cycleTemplateReportDetails) &&
                r.years &&
                Array.isArray(r.years)
            ) {
                setPeriodData(
                    r.cycleTemplateReportDetails.map((element) => ({
                        sourceType: element.defaultSourceShortName,
                        reportType: element.reportTypeShortName,
                        scope: element.scopeShortName,
                        frequency: element.frequencyLongName,
                        schedulingPeriodType: element.schedulingPeriodType,
                        specifiedPeriods: element.specifiedPeriods,
                        valutionPeriodType: element.valutionPeriodType,
                        ...(r.years as string[]).reduce((acc, objYear: string) => {
                            return {
                                ...acc,
                                [`${objYear}`]: element.specifiedPeriods ?? '',
                            };
                        }, {}),
                    })),
                );
            }
        })
        .catch((e) => {
            console.error(e); // eslint-disable-line no-console
        });
};

const formattedDate = (date: string): string => {
    const dt = new Date(date);
    return `${dt.getDate()} ${month[dt.getMonth()]} ${dt.getFullYear()}`;
};

const getValuationPeriod = (
    ValuationPeriodTypeId = '',
    ValuationYear = '',
    ValuationQuarter = '',
    ValuationMonth = '',
): string => {
    let valuationPeriod = '';
    if (ValuationPeriodTypeId === 'ANNUAL') {
        valuationPeriod = `Annual-${ValuationYear}`;
    } else if (ValuationPeriodTypeId === 'SEMIANNUAL') {
        valuationPeriod = `Semi Annual-${RIGHT(ValuationQuarter, 1)}Q${ValuationYear}`;
    } else if (ValuationPeriodTypeId === 'QUARTER') {
        valuationPeriod = `Quarterly -${RIGHT(ValuationQuarter, 1)}Q${ValuationYear}`;
    } else if (ValuationPeriodTypeId === 'MONTH') {
        valuationPeriod = `Monthly-${ValuationMonth}/${ValuationYear}`;
    }

    return valuationPeriod;
};

const getDateString = (date: unknown): string => (date ? new Date(date as string).toLocaleDateString('en-us') : '');

export const updateValuationCycleData = (
    params: Record<string, unknown>[],
    valuation: Valuation,
    setValuation: React.Dispatch<React.SetStateAction<Valuation>>,
    setGridData: React.Dispatch<React.SetStateAction<Record<string, unknown>[]>>,
    enableValGroup: boolean,
    portfolioTypeId: string,
    assetOrGroup: Record<string, unknown> | undefined,
): void => {
    const tasks = params.map((o): Record<string, unknown> => {
        const task: AddTask = {
            AssetNameGroupName: String(
                assetOrGroup && assetOrGroup.ValuationGroupName ? assetOrGroup.ValuationGroupName : assetOrGroup?.Name,
            ),
            ValuationUniqId: createGuid(),
            EnableValGroup: Boolean(enableValGroup),
            PortfoliosId: String(o.portfoliosId),
            PortfolioId: String(o.portfoliosId),
            Portfolio_Id: String(o.portfoliosId),
            StatusId: Status.Scheduled,
            ValuationIsReadonly: 0,
            PortfolioTypeId: portfolioTypeId,
            HideOneOff: null,
            ValuationRegion: assetOrGroup?.RegionalArea ? String(assetOrGroup.RegionalArea) : null,
            ValuationAssetsId: assetOrGroup && assetOrGroup.Id ? String(assetOrGroup.Id) : null,
            AssetId: String(assetOrGroup && assetOrGroup.Id ? assetOrGroup.Id : ''),
            AssetsName: String(assetOrGroup && assetOrGroup.Name ? assetOrGroup.Name : ''),
            ValuationType: String(assetOrGroup ? assetOrGroup.type : ''),
            ValuationGroupId:
                assetOrGroup && assetOrGroup.ValuationGroupId ? String(assetOrGroup.ValuationGroupId) : null,
            ValuationGroupName: String(
                assetOrGroup && assetOrGroup.ValuationGroupName ? assetOrGroup.ValuationGroupName : '',
            ),
            ValuationMethod: '',
            COVValuationMethod: '',
            CreatedBy,
            ValuationReportTypeId: String(o.valuationReportTypeId ? o.valuationReportTypeId : ''),
            ValuationScopeId: String(o.valuationScopeId ? o.valuationScopeId : ''),
            ValuationSourceTypeId: String(o.valuationSourceTypeId ? o.valuationSourceTypeId : ''),
            ValuationWorkflowTemplateId: String(o.ValuationWorkflowTemplateId ? o.ValuationWorkflowTemplateId : ''),
            ValuationPeriodTypeId: String(o.valuationPeriodTypeId ? o.valuationPeriodTypeId : ''),
            ValuationPeriod: String(o.ValuationPeriod ? o.ValuationPeriod : ''),
            ValuationMonth: String(o.valuationMonth ? o.valuationMonth : ''),
            ValuationQuarter: String(o.valuationQuarter ? o.valuationQuarter : ''),
            ValuationYear: Number(o.valuationYear ?? o.valuationYear),
            ValuationDateofValue: getDateString(o.valuationDateofValue ? o.valuationDateofValue : null),
            ValuationEngagementDate: getDateString(o.valuationEngagementDate ? o.valuationEngagementDate : null),
            ValuationDraftDueDate: getDateString(o.valuationDraftDueDate ? o.valuationDraftDueDate : null),
            ValuationFinalDuedate: getDateString(o.valuationFinalDuedate ? o.valuationFinalDuedate : null),
            ExceptionMade: o.exceptionMade !== undefined ? Boolean(o.exceptionMade) : null,
            ExceptionReason: String(o.exceptionReason ? o.exceptionReason : ''),
        };

        let finalDate = '';

        if (task.ValuationDateofValue) {
            task.ValuationYear = new Date(task.ValuationDateofValue).getFullYear();
            finalDate = formattedDate(task.ValuationDateofValue);
        }

        if (task.ValuationEngagementDate) {
            task.ValuationYear = new Date(task.ValuationEngagementDate).getFullYear();
            finalDate = formattedDate(task.ValuationEngagementDate);
        }

        const valuationName = `${task.ValuationGroupName || task.AssetsName || ''} (${task.ValuationReportTypeId}-${
            task.ValuationScopeId
        })`;

        task.ValuationName = `${valuationName} ${finalDate}`;

        const vaAssetVaGroupList: ValuationAssetVM[] = [];
        const valuationAssetMethodologySetupVMList: ValuationAssetMethodologySetupVM[] = [];

        if (o.valuationAssetVM instanceof Array)
            o.valuationAssetVM.forEach((va: Record<string, unknown>) => {
                vaAssetVaGroupList.push({
                    AssetId: String(va.assetsId),
                    AssetName: String(assetOrGroup && assetOrGroup.Name ? assetOrGroup.Name : ''),
                    PortfoliosId: String(va.portfoliosId),
                    ValuationGroupId: String(va.valuationGroupId) || null,
                    ExcludeAssetFromReporting: Boolean(va.excludeAssetFromReporting) ?? false,
                    ExcludeAssetFromReportingExplanation: String(va.excludeAssetFromReportingExplanation) || '',
                });

                valuationAssetMethodologySetupVMList.push({
                    RegionalArea: String(assetOrGroup && assetOrGroup.RegionalArea ? assetOrGroup.RegionalArea : ''),
                    AssetId: String(va.assetsId),
                    AssetName: String(assetOrGroup && assetOrGroup.Name ? assetOrGroup.Name : ''),
                    PortfoliosId: String(va.portfoliosId),
                    PortfolioTypeId: portfolioTypeId,
                    ValuationName: `${valuationName} ${finalDate}`,
                    MethodologyTemplateId: null,
                    ValuationGroupId: String(va.valuationGroupId) || null,
                    AllAsset_CopyFromPriorValuationId: null,
                });
            });

        task.NoOfAsset = Number(o.totalAssetsInValuations);
        task.TotalAssetsInValuations = Number(o.totalAssetsInValuations);
        task.ValuationAssetMethodologySetup = [...valuationAssetMethodologySetupVMList];
        task.ValuationId = task.ValuationUniqId;

        return task;
    }) as AddTask[];

    setGridData(tasks);
    setValuation({
        ...valuation,
        addTasks: [...tasks],
    });
};

export const getValuationFromCycleData = (
    params: Record<string, unknown>[],
    enableValGroup: boolean,
    portfolioTypeId: string,
    assetOrGroup: Record<string, unknown> | undefined,
): AddTask[] =>
    params.map((o): Record<string, unknown> => {
        const task: AddTask = {
            AssetNameGroupName: String(
                assetOrGroup && assetOrGroup.ValuationGroupName ? assetOrGroup.ValuationGroupName : assetOrGroup?.Name,
            ),
            ValuationUniqId: createGuid(),
            EnableValGroup: Boolean(enableValGroup),
            PortfoliosId: String(o.portfoliosId),
            PortfolioId: String(o.portfoliosId),
            Portfolio_Id: String(o.portfoliosId),
            StatusId: Status.Scheduled,
            ValuationIsReadonly: 0,
            HideOneOff: null,
            PortfolioTypeId: portfolioTypeId,
            ValuationRegion: assetOrGroup?.RegionalArea ? String(assetOrGroup.RegionalArea) : null,
            ValuationAssetsId: assetOrGroup && assetOrGroup.Id ? String(assetOrGroup.Id) : null,
            AssetId: String(assetOrGroup && assetOrGroup.Id ? assetOrGroup.Id : ''),
            AssetsName: String(assetOrGroup && assetOrGroup.Name ? assetOrGroup.Name : ''),
            ValuationType: String(assetOrGroup ? assetOrGroup.type : ''),
            ValuationGroupId:
                assetOrGroup && assetOrGroup.ValuationGroupId ? String(assetOrGroup.ValuationGroupId) : null,
            ValuationGroupName: String(
                assetOrGroup && assetOrGroup.ValuationGroupName ? assetOrGroup.ValuationGroupName : '',
            ),
            ValuationMethod: '',
            COVValuationMethod: '',
            CreatedBy,
            ValuationReportTypeId: String(o.valuationReportTypeId ? o.valuationReportTypeId : ''),
            ValuationScopeId: String(o.valuationScopeId ? o.valuationScopeId : ''),
            ValuationSourceTypeId: String(o.valuationSourceTypeId ? o.valuationSourceTypeId : ''),
            ValuationWorkflowTemplateId: String(o.ValuationWorkflowTemplateId ? o.ValuationWorkflowTemplateId : ''),
            ValuationPeriodTypeId: String(o.valuationPeriodTypeId ? o.valuationPeriodTypeId : ''),
            ValuationPeriod: getValuationPeriod(
                String(o.valuationPeriodTypeId),
                String(o.valuationYear),
                String(o.valuationQuarter),
                String(o.valuationMonth),
            ),
            ValuationMonth: String(o.valuationMonth ? o.valuationMonth : ''),
            ValuationQuarter: String(o.valuationQuarter ? o.valuationQuarter : ''),
            ValuationYear: Number(o.valuationYear ?? o.valuationYear),
            ValuationDateofValue: getDateString(o.valuationDateofValue ? o.valuationDateofValue : null),
            ValuationEngagementDate: getDateString(o.valuationEngagementDate ? o.valuationEngagementDate : null),
            ValuationDraftDueDate: getDateString(o.valuationDraftDueDate ? o.valuationDraftDueDate : null),
            ValuationFinalDuedate: getDateString(o.valuationFinalDuedate ? o.valuationFinalDuedate : null),
            ExceptionMade: o.exceptionMade ? Boolean(o.exceptionMade) : undefined,
            ExceptionReason: String(o.exceptionReason ? o.exceptionReason : ''),
        };

        if (task.ValuationDateofValue) {
            task.ValuationYear = new Date(task.ValuationDateofValue).getFullYear();
        }

        if (task.ValuationEngagementDate) {
            task.ValuationYear = new Date(task.ValuationEngagementDate).getFullYear();
        }

        task.NoOfAsset = Number(o.totalAssetsInValuations);
        task.TotalAssetsInValuations = Number(o.totalAssetsInValuations);
        task.ValuationId = task.ValuationUniqId;
        task.Id = task.ValuationUniqId;

        return task;
    }) as AddTask[];

export const getReportData = (data: Record<string, Record<string, unknown>>): ReportType[] =>
    Object.values(data).map(
        ({ reportType, scope, schedulingPeriodType, valutionPeriodType, sourceType, ...years }) => ({
            ReportTypeId: String(reportType),
            ScopeId: String(scope),
            PeriodTypeId: valutionPeriodType,
            SourceTypeId: sourceType,
            ReportPeriod: Object.keys(years).reduce(
                (acc: TemplateType[], year): TemplateType[] =>
                    !Number.isNaN(Number(year)) // This checks that the key is a valid year
                        ? [
                              ...acc,
                              {
                                  year,
                                  quarterValue:
                                      schedulingPeriodType !== PeriodTypes.MONTH ? String(years[year]).split(',') : [],
                                  monthValue:
                                      schedulingPeriodType === PeriodTypes.MONTH ? String(years[year]).split(',') : [],
                              },
                          ]
                        : [...acc],
                [],
            ),
        }),
    );

export const getTaskFromCyclePeriod = (
    requestParams: Record<string, unknown>,
    api: ApiContextType,
    lastChanges: unknown,
): Promise<Record<string, unknown>[]> =>
    apiProvider.postData(
        api.ValuationApi as string,
        `/api/ValuationWizard/ReportCyclePeriods`,
        {
            ...requestParams,
            ReportTypeScopeRequests: getReportData(lastChanges as Record<string, Record<string, unknown>>),
        },
        api.requestInit ?? {},
    );
