import { QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import {
    AddTask,
    CreatedBy,
    Status,
    Valuation,
    ValuationAssetMethodologySetupVM,
    ValuationAssetVM,
    ValuationGroupAsset,
} from '../../static/ValuationConstants';
import { createGuid } from '../../static/ValuationWizard.utils';
import { fetchAssetsForValuationGroup, getQBData } from './AddValuationWizard.service';
import { GridDataToValuationsParams, ValuationAssetMethodologySetup } from './BulkValuationWizard.interfaces';

export const getAssetDetailsById = async (
    qbQuery: (params: QueryExecuteRequest) => Promise<QueryExecuteResponse>,
    assetId: string,
): Promise<Record<string, unknown>> => {
    if (assetId) {
        const response = await getQBData({
            qbQuery,
            domainId: 'POMA_Asset',
            advancedExpression: `[Id] = '${assetId}'`,
            adhocListViewMembers: [
                { scalarExpression: `[Name]`, scalarDisplayName: 'Name' },
                { scalarExpression: `[RegionalArea]`, scalarDisplayName: 'RegionalArea' },
                { scalarExpression: `[Investment_Id]`, scalarDisplayName: 'Investment_Id' },
                { scalarExpression: `[Fund_Id]`, scalarDisplayName: 'Fund_Id' },
            ],
        });

        if (response && response.Data && response.Data.length === 1) {
            return response.Data[0];
        }
    }

    return {};
};

export const getValuationGroupNameById = async (
    qbQuery: (params: QueryExecuteRequest) => Promise<QueryExecuteResponse>,
    valuationGroupId: string,
): Promise<Record<string, unknown>> => {
    if (valuationGroupId) {
        const response = await getQBData({
            qbQuery,
            domainId: 'ValuationGroup',
            advancedExpression: `[ValuationGroupId] = '${valuationGroupId}'`,
            adhocListViewMembers: [
                { scalarExpression: `[ValuationGroupName]`, scalarDisplayName: 'ValuationGroupName' },
                { scalarExpression: `[RegionalArea]`, scalarDisplayName: 'RegionalArea' },
            ],
        });

        if (response && response.Data && response.Data.length === 1) {
            return response.Data[0];
        }
    }
    return {};
};

export const getValuationFromGridData = async (
    data: Record<string, unknown>[],
    { qbQuery, assetOrGroupBulk, enableValGroup, portfolioId, portfolioTypeId }: GridDataToValuationsParams,
): Promise<Valuation> => {
    let addTasks: AddTask[] = [];

    if (data.length) {
        addTasks = await Promise.all(
            data.map(async (row): Promise<AddTask> => {
                let fundIds = '';
                let investmentIds = '';

                const task: Record<string, unknown> = Object.keys(row).reduce(
                    (acc, elementKey) => (row[elementKey] ? { ...acc, [elementKey]: row[elementKey] } : acc),
                    {},
                );

                task.EnableValGroup = enableValGroup;
                task.CreatedBy = CreatedBy;

                let assetDetails;

                if (row.AssetId) {
                    assetDetails = await getAssetDetailsById(qbQuery, String(row.AssetId));
                    task.AssetsName = (assetDetails.Name as string) || '';
                    task.ValuationRegion = String(assetDetails.RegionalArea);
                    task.Asset_Group = task.AssetsName;
                    task.AssetNameGroupName = task.AssetsName;
                    task.ValuationAssetsId = row.AssetId;
                }

                let valuationGroupAssets: ValuationGroupAsset[] = [];

                if (row.ValuationGroupId) {
                    valuationGroupAssets = await fetchAssetsForValuationGroup(String(row.ValuationGroupId), qbQuery);
                }

                let valuationGroupDetails;

                if (task.ValuationGroupId && task.ValuationGroupId !== '') {
                    valuationGroupDetails = await getValuationGroupNameById(qbQuery, String(task.ValuationGroupId));
                    task.ValuationGroupName = valuationGroupDetails.ValuationGroupName as string;
                    task.ValuationRegion = String(valuationGroupDetails.RegionalArea);
                    task.Asset_Group = task.ValuationGroupName;
                    task.AssetNameGroupName = task.ValuationGroupName;
                }

                const valuationAssetVMList: ValuationAssetVM[] = [];
                const valuationAssetMethodologySetupVMList: ValuationAssetMethodologySetupVM[] = [];

                if (row.AssetId) {
                    valuationAssetVMList.push({
                        AssetId: String(row.AssetId),
                        AssetName: String(task.AssetsName),
                        PortfoliosId: String(portfolioId),
                        ValuationGroupId: null,
                        ExcludeAssetFromReporting: false,
                        ExcludeAssetFromReportingExplanation: '',
                    });

                    valuationAssetMethodologySetupVMList.push({
                        RegionalArea: assetDetails ? (assetDetails.RegionalArea as string) : '',
                        VAMethodologySetupId: createGuid(),
                        AssetId: String(row.AssetId),
                        AssetName: String(task.AssetsName),
                        PortfoliosId: String(portfolioId),
                        PortfolioTypeId: String(portfolioTypeId),
                        ValuationName: task.ValuationName as string,
                        ValuationId: String(row.ValuationUniqId),
                        MethodologyTemplateId: null,
                        ValuationGroupId: null,
                        AllAsset_CopyFromPriorValuationId: null,
                    });
                    fundIds = row.FundIds as string;
                    investmentIds = row.InvestmentIds as string;
                } else if (row.ValuationGroupId) {
                    if (valuationGroupAssets && valuationGroupAssets.length) {
                        fundIds = [...new Set(valuationGroupAssets.map((item) => item.fundId))].join(',');
                        investmentIds = [...new Set(valuationGroupAssets.map((item) => item.investmentId))].join(',');
                        valuationGroupAssets.forEach((asset) => {
                            valuationAssetVMList.push({
                                AssetId: asset.assetsId,
                                AssetName: asset.assetName,
                                PortfoliosId: asset.portfoliosId,
                                ValuationGroupId: asset.valuationGroupId,
                                ExcludeAssetFromReporting: false,
                                ExcludeAssetFromReportingExplanation: '',
                            });

                            valuationAssetMethodologySetupVMList.push({
                                RegionalArea: asset.RegionalArea,
                                VAMethodologySetupId: createGuid(),
                                AssetId: asset.assetsId,
                                AssetName: asset.assetName,
                                PortfoliosId: asset.portfoliosId,
                                PortfolioTypeId: String(portfolioTypeId),
                                ValuationName: task.ValuationName as string,
                                ValuationId: String(row.ValuationUniqId),
                                MethodologyTemplateId: null,
                                ValuationGroupId: asset.valuationGroupId,
                                AllAsset_CopyFromPriorValuationId: null,
                            });
                        });
                    }
                }
                task.FundIds = fundIds;
                task.InvestmentIds = investmentIds;

                task.TotalAssetsInValuations = valuationAssetVMList.length;

                task.ValuationAsset = [...valuationAssetVMList];
                task.ValuationAssetMethodologySetup = [...valuationAssetMethodologySetupVMList];
                task.ForBulkValuation = assetOrGroupBulk;
                task.ValuationId = task.ValuationUniqId;
                task.Id = task.ValuationUniqId;
                task.ValuationIsReadonly = 0;

                return task;
            }),
        );
    }

    return {
        addTasks: addTasks.length
            ? addTasks.filter((tasks) => !tasks.__DELETED__)
            : [
                  {
                      ForBulkValuation: assetOrGroupBulk,
                      PortfolioId: portfolioId as string,
                      Portfolio_Id: portfolioId as string,
                      PortfoliosId: portfolioId as string,
                      PortfolioTypeId: portfolioTypeId as string,
                      StatusId: Status.Scheduled,
                      ValuationIsReadonly: 0,
                  },
              ],
    };
};

export const getGridDataFromValuations = (assets: AddTask[]): Record<string, ValuationAssetMethodologySetup> => {
    const gridData: Record<string, ValuationAssetMethodologySetup> = {};

    if (assets.length)
        assets.forEach((asset) => {
            if (asset.ValuationAssetMethodologySetup?.length) {
                asset.ValuationAssetMethodologySetup.forEach((vams) => {
                    if (vams.VAMethodologySetupId)
                        gridData[vams.VAMethodologySetupId] = {
                            VAMethodologySetupId: vams.VAMethodologySetupId,
                            AssetId: vams.AssetId ?? '',
                            ValuationName: vams.ValuationName ?? '',
                            ValuationId: vams.ValuationId ?? '',
                            AllAsset_CopyFromPriorValuationId: vams.AllAsset_CopyFromPriorValuationId || null,
                            MethodologyTemplateId: vams.MethodologyTemplateId || null,
                            VAIsReadonly: 0,
                        };
                });
            }
        });

    return gridData;
};

export const updateKeysForNewRows = (data: Record<string, unknown>, primaryKey: string): Record<string, unknown> => {
    const updatedData: Record<string, Record<string, unknown>> = Object.keys(data).reduce(
        (o, k) => ({ ...o, [k]: data[k] as Record<string, unknown> }),
        {},
    );

    Object.keys(data).forEach((key) => {
        if (key.length < 6) {
            const newKey = createGuid();
            updatedData[newKey] = { ...updatedData[key], [primaryKey]: newKey };
            delete updatedData[key];
        }
    });

    return updatedData;
};

export const getUpdatedValuationFromSetupData = (
    valuation: Valuation,
    data: ValuationAssetMethodologySetup[],
): Valuation =>
    valuation.addTasks?.length
        ? {
              addTasks: valuation.addTasks.map(
                  (task: AddTask): AddTask => ({
                      ...task,
                      ValuationAssetMethodologySetup: task.ValuationAssetMethodologySetup?.map((vams) => {
                          const selectedMethodology = data.find(
                              ({ VAMethodologySetupId }) => vams.VAMethodologySetupId === VAMethodologySetupId,
                          );

                          return {
                              ...vams,
                              VAIsReadonly: true,
                              MethodologyTemplateId: selectedMethodology?.MethodologyTemplateId,
                              AllAsset_CopyFromPriorValuationId: selectedMethodology?.AllAsset_CopyFromPriorValuationId,
                          };
                      }),
                  }),
              ),
          }
        : valuation;

export const getSetupDataFromValuations = (tasks: AddTask[]): Record<string, unknown> => {
    const gridData: Record<string, unknown> = {};

    if (tasks.length)
        tasks.forEach((task) => {
            if (task.ValuationAssetMethodologySetup?.length) {
                task.ValuationAssetMethodologySetup.forEach((asset) => {
                    if (asset.VAMethodologySetupId) gridData[asset.VAMethodologySetupId] = asset;
                });
            }
        });

    return gridData;
};
