/* istanbul ignore file */
import React from 'react';
import { TaskRequest, TaskResponse } from '@samc/screen-config-api';

export interface WorkflowTaskValidation {
    validationRequest?: TaskRequest;
    setValidationRequest: React.Dispatch<React.SetStateAction<TaskRequest | undefined>>;
    validationResponse?: TaskResponse;
    setValidationResponse: React.Dispatch<React.SetStateAction<TaskResponse | undefined>>;
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WorkflowTaskValidationPopupContext = React.createContext<WorkflowTaskValidation>({
    validationRequest: undefined,
    setValidationRequest: () => undefined,
    validationResponse: undefined,
    setValidationResponse: () => undefined,
    visible: false,
    setVisible: () => undefined,
});

export const WorkflowTaskValidationPopupContextProvider = ({
    children,
}: {
    children: React.ReactElement;
}): React.ReactElement => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [validationResponse, setValidationResponse] = React.useState<TaskResponse | undefined>(undefined);
    const [validationRequest, setValidationRequest] = React.useState<TaskRequest | undefined>(undefined);

    const workflowTaskValidation = React.useMemo(
        (): WorkflowTaskValidation => ({
            validationRequest,
            setValidationRequest,
            validationResponse,
            setValidationResponse,
            visible,
            setVisible,
        }),
        [validationRequest, validationResponse, visible],
    );

    return (
        <WorkflowTaskValidationPopupContext.Provider value={workflowTaskValidation}>
            {children}
        </WorkflowTaskValidationPopupContext.Provider>
    );
};

export const useWorkflowTaskValidationPopupContext = (): WorkflowTaskValidation => {
    return React.useContext(WorkflowTaskValidationPopupContext);
};
