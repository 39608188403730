/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { SearchBox } from '@fluentui/react';
import { Grid, GridFieldConfiguration } from '@samc/react-ui-grid';
import { useDebouncedCallback } from '@samc/react-ui-core';
import { QueryExecuteResponse, useQBQuery } from '@samc/screen-config-api';
import { IRowNode, SelectionChangedEvent } from 'ag-grid-community';
import { createGuid } from '../../static/ValuationWizard.utils';

interface GridSearchProps {
    rightGridRows: Record<string, unknown>[];
    setRightGridRows: React.Dispatch<React.SetStateAction<Record<string, unknown>[]>>;
    portfolioFilter?: string;
    valuationGroupId?: string;
    valuationId?: string;
    viewSetTabId?: string;
}

export const GridSearch = ({
    rightGridRows,
    setRightGridRows,
    portfolioFilter,
    valuationGroupId,
    valuationId,
    viewSetTabId,
}: GridSearchProps): React.ReactElement => {
    const portfolioExpression = portfolioFilter?.replace('=', ' CONTAINS ');
    const [assetIds, setAssetIds] = React.useState<Record<string, unknown>[]>([]);
    const [leftGridRows, setLeftGridRows] = React.useState<Record<string, unknown>[]>([]);
    const qbQuery = useQBQuery();

    const onSelectionChanged = (e: SelectionChangedEvent): void => {
        const selectedLeftRowsGrid = e.api.getSelectedRows() as Record<string, unknown>[];
        const rows = selectedLeftRowsGrid.filter(
            (row): boolean => !rightGridRows.some((r): boolean => r.Id === row.Id),
        );
        const leftdata = leftGridRows.filter((row): boolean => !rows.some((r): boolean => r.Id === row.Id));
        setRightGridRows([...rows, ...rightGridRows]);
        setLeftGridRows(leftdata);
    };

    const columns: GridFieldConfiguration[] = [
        { field: 'ReferenceId', headerName: 'Asset Id' },
        { field: 'Name', displayNameRule: 'Asset Name' },
        { field: 'InvestmentId', displayNameRule: 'InvestmentId', visibleRule: 'false' },
        { field: 'Investment_Name', displayNameRule: 'Investment Name' },
        { field: 'Investment_FundId', displayNameRule: 'Investment_FundId', visibleRule: 'false' },
        { field: 'Fund_Name', displayNameRule: 'Fund Name' },
        { field: 'Fund_PortfolioId', displayNameRule: 'Fund_PortfolioId', visibleRule: 'false' },
        { field: 'Portfolio_Name', displayNameRule: 'Portfolio' },
        { field: 'Id', displayNameRule: 'Id', visibleRule: 'false' },
    ];

    // to remove selected ids from search
    const ids = React.useMemo(
        () => rightGridRows.map((x) => ` && ([Id] NOT CONTAINS '${x.Id}')`).join(''),
        [rightGridRows],
    );

    const adhocListViewMembers = React.useMemo(
        () => [
            {
                scalarExpression: `[ReferenceId]`,
                scalarDisplayName: 'ReferenceId',
            },
            {
                scalarExpression: '[Name]',
                scalarDisplayName: 'Name',
            },
            {
                scalarExpression: '[InvestmentId]',
                scalarDisplayName: 'InvestmentId',
            },
            { scalarExpression: '[Investment_Name]', scalarDisplayName: 'Investment_Name' },
            {
                scalarExpression: `[Investment_FundId]`,
                scalarDisplayName: 'Investment_FundId',
            },
            {
                scalarExpression: `[Fund_Name]`,
                scalarDisplayName: 'Fund_Name',
            },
            {
                scalarExpression: `[Fund_PortfolioId]`,
                scalarDisplayName: 'Fund_PortfolioId',
            },
            {
                scalarExpression: `[Portfolio_Name]`,
                scalarDisplayName: 'Portfolio_Name',
            },
            {
                scalarExpression: `[Id]`,
                scalarDisplayName: 'Id',
            },
        ],
        [],
    );

    // to get data of selected contacts from company catalog
    const adhocfilterData = (expression?: string): void => {
        async function fetchQbData(): Promise<void> {
            const loadViewSetData = async (): Promise<QueryExecuteResponse> => {
                return qbQuery({
                    domainId: 'POMA_Asset',
                    primaryViewId: '-1',
                    filterIds: [],
                    paging: { start: 0, stop: 1000 },
                    adhocListViewMembers,
                    sorting: {
                        order: 'asc',
                        orderBy: { scalarExpression: '[Name]' },
                    },
                    summaryMode: false,
                    adhocFilter: {
                        filterName: 'adhoc',
                        advancedInd: true,
                        expressionLang: 'Centric',
                        advancedExpression: expression,
                    },
                });
            };
            const result = await loadViewSetData();
            setLeftGridRows([...result.Data]);
        }
        fetchQbData();
        // qbQuery not included in this since it is a function
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleSearch = useDebouncedCallback(
        (_event?: React.ChangeEvent<HTMLInputElement>, v?: string) => {
            const data = `(([ReferenceId] CONTAINS '${v}') OR ([Name] CONTAINS '${v}') OR ([Investment_Name] CONTAINS '${v}') ${ids} && (${portfolioExpression}))`;
            adhocfilterData(data);
        },
        [],
        300,
    );

    const adhocValuationAssetsListViewMembers = React.useMemo(
        () => [
            {
                scalarExpression: '[AssetId]',
                scalarDisplayName: 'AssetsId',
            },
        ],
        [],
    );

    const adhocGroupAssetsListViewMembers = React.useMemo(
        () => [
            {
                scalarExpression: '[AssetsId]',
                scalarDisplayName: 'AssetsId',
            },
        ],
        [],
    );
    const qbQueryAssets = useQBQuery();
    const [domainId, adhocFilter, advancedExpression] =
        viewSetTabId === 'AddAssetToValuation'
            ? ['ValuationAsset', valuationId, `([ValuationId] = '${valuationId}')`]
            : ['ValuationGroupAsset', valuationGroupId, `([ValuationGroupId] = '${valuationGroupId}')`];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect((): void => {
        qbQueryAssets({
            domainId,
            primaryViewId: '-1',
            filterIds: [],
            paging: { start: 0, stop: 1000 },
            adhocListViewMembers:
                viewSetTabId === 'AddAssetToValuation'
                    ? adhocValuationAssetsListViewMembers
                    : adhocGroupAssetsListViewMembers,
            sorting: {},
            summaryMode: false,
            adhocFilter: adhocFilter
                ? {
                      filterName: 'adhoc',
                      advancedInd: true,
                      expressionLang: 'Centric',
                      advancedExpression,
                  }
                : undefined,
        }).then((r) => {
            setAssetIds(r.Data);
        });
    }, [adhocValuationAssetsListViewMembers, qbQueryAssets, adhocFilter]);
    return (
        <>
            <div className="search">
                <div>
                    <label className="ValuationLable">
                        FIND ASSETS TO ADD TO THE
                        {viewSetTabId === 'AddAssetToValuation' ? ' VALUATION' : ' VALUATION GROUP'}
                    </label>
                </div>
                <div className="searcBoxWithLabel">
                    <label className="searchLabel">Search</label>
                    <SearchBox onChange={handleSearch} className="customSearchBox" />
                </div>
            </div>
            <div className="gridscreen">
                <label className="info">
                    * Search by Asset ID, Asset Name or Investment Name. Assets already added to the group will not be
                    enabled for selection.
                </label>
                <Grid
                    onSubmit={(): void => undefined} // noop, not editable
                    key={createGuid()}
                    isEditingDefault={false}
                    suppressEditToggle
                    suppressAddRowButton
                    suppressDeleteButton
                    suppressClearFilter={false}
                    suppressExcelExport={false}
                    suppressRowSpacing
                    suppressDeleteConfirmationModal
                    suppressColumnFilters={false}
                    suppressFullScreen={false}
                    data={leftGridRows}
                    fields={columns}
                    onSelectionChanged={onSelectionChanged}
                    gridOptions={{
                        isRowSelectable: (node: IRowNode): boolean =>
                            !assetIds.some(({ AssetsId }): boolean => node.data.Id === AssetsId),
                    }}
                />
            </div>
        </>
    );
};

export default GridSearch;
