import { QueryExecuteRequest, QueryExecuteResponse, useApiContext } from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';

const QUERY_KEY = 'useValuationAssetMethodologySetupData';

export const useValuationAssetMethodologySetupData = (
    valuationAssetId?: string | null,
    enabled?: boolean,
): UseQueryResult<QueryExecuteResponse> => {
    const api = useApiContext();

    const { ValuationAssetMethodologySetupDataDomainId } = useApplicationContext();

    const requestBody: QueryExecuteRequest = {
        domainId: ValuationAssetMethodologySetupDataDomainId,
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
        ],
        sorting: {},
        paging: { start: 0, stop: 1 },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[ValuationAssetId] = '${valuationAssetId}'`,
        },
    };

    const queryResult = useQuery<QueryExecuteResponse>(
        [QUERY_KEY, valuationAssetId],
        () =>
            executeLiftSiftQuery(
                api.LiftSiftApi,
                requestBody,
                api.requestInit,
                api.ShowMessage,
            ) as unknown as QueryExecuteResponse,
        { retry: 1, refetchOnWindowFocus: false, enabled: !!valuationAssetId && !!enabled },
    );

    return queryResult;
};

export default useValuationAssetMethodologySetupData;
