/* eslint-disable no-param-reassign */
import React from 'react';
import { TaskRequest, TaskResponse, TaskStatus } from '@samc/screen-config-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { submitTask } from '../api/Requests';
import { useApiContext } from '../../hooks/useApiContext';
import { VALUATION_WORKFLOW_TASKS_KEY } from '../useValuationWorkflowTasks/useValuationWorkflowTasks';
import { ValuationWorkflowTaskOwner } from '../models/ValuationWorkflowTaskOwner';

interface Props {
    contactsToAssign: { UserId: string }[];
    contactsToRemove: { UserId: string }[];
    workflowTaskId: string;
    existingOwners: ValuationWorkflowTaskOwner[];
}

export const useValuationWorkflowTaskOwnersSaver = (): [
    UseMutationResult<[TaskResponse, TaskRequest], unknown, Props, unknown>,
] => {
    const api = useApiContext();
    const queryClient = useQueryClient();

    const onSave = React.useCallback(
        async ({
            contactsToAssign,
            contactsToRemove,
            workflowTaskId,
            existingOwners,
        }: Props): Promise<[TaskResponse, TaskRequest]> => {
            let masterDecrementingIndex = -1;

            const payloadWithRowsToAdd = contactsToAssign?.reduce(
                (payload, contact): { [id: string]: Record<string, unknown> } => {
                    const simpleIndexAsString = String(masterDecrementingIndex);

                    payload[simpleIndexAsString] = {
                        Id: simpleIndexAsString,
                        OwnerUserId: contact.UserId,
                        WorkflowTaskId: workflowTaskId,
                        __ADDED__: true,
                    };

                    masterDecrementingIndex--;
                    return payload;
                },
                {} as { [id: string]: Record<string, unknown> },
            );

            const payloadWithRowsToDelete = contactsToRemove?.reduce(
                (payload, rowToDelete): { [id: string]: Record<string, unknown> } => {
                    const targetOwner = existingOwners.find((x) => x.UserId === rowToDelete.UserId);
                    if (!targetOwner) {
                        // Owner wasn't found - nothing to delete, so silently leave it out of the payload
                        return payload;
                    }
                    payload[targetOwner.Id] = {
                        Id: targetOwner.Id,
                        __DELETED__: true,
                    };

                    return payload;
                },
                payloadWithRowsToAdd as { [id: string]: Record<string, unknown> },
            );

            return submitTask(
                api.TaskApi,
                'VALU_ValuationWorkflowTaskOwner',
                'VALU_ValuationWorkflowTaskOwner',
                'WorkflowTaskReassignForm - VALU_ValuationWorkflowTaskOwner',
                payloadWithRowsToDelete,
                api.requestInit ?? {},
                false,
                api.FetchApi,
            );
        },
        [api.FetchApi, api.TaskApi, api.requestInit],
    );

    const saveMutation = useMutation<[TaskResponse, TaskRequest], unknown, Props>(onSave, {
        onSuccess: (data: [TaskResponse, TaskRequest<Record<string, unknown>>]) => {
            const [result] = data;
            if (result.statusCode === TaskStatus.Completed && result.messageCount === 0) {
                api.ShowMessage(`ValuationWorkflowTaskOwners Updated Successfully`, 'success');
                queryClient.invalidateQueries([VALUATION_WORKFLOW_TASKS_KEY]);
            } else {
                api.ShowMessage(`Failed to Update ValuationWorkflowTaskOwners`, 'error');
            }
        },
        onError: (e) => {
            throw e;
        },
    });

    return [saveMutation];
};

export default useValuationWorkflowTaskOwnersSaver;
