import React from 'react';
import {
    ApiContextType,
    ApiContext,
    QueryExecuteRequest,
    QueryExecuteResponse,
    DomainViewMember,
} from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';

export const ES_INPUT_DATA_KEY = 'ESInputData';

export const useEsInputData = (
    esInputDataIds: string,
    adhocListViewMemberList: DomainViewMember[],
    keyChange: number,
): UseQueryResult<QueryExecuteResponse> => {
    const api = React.useContext<ApiContextType>(ApiContext);

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_ESInputData',
        primaryViewId: '-1',
        adhocListViewMembers: [
            ...adhocListViewMemberList,
            {
                scalarDisplayName: 'AssetId',
                scalarExpression: '[AssetId]',
            },
        ],
        sorting: {},
        paging: { start: 0, stop: 1 },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `DoesContain([Id], '${esInputDataIds}')`,
        },
    };

    const queryResult = useQuery<QueryExecuteResponse>(
        [
            ES_INPUT_DATA_KEY,
            esInputDataIds,
            keyChange,
            adhocListViewMemberList.map((e) => e.scalarDisplayName).join('_'),
        ],
        () =>
            executeLiftSiftQuery(
                api.LiftSiftApi,
                requestBody,
                api.requestInit,
                api.ShowMessage,
            ) as unknown as QueryExecuteResponse,
        { retry: 2, refetchOnWindowFocus: false },
    );

    return queryResult;
};

export default useEsInputData;
