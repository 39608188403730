import React from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { ErrorBoundary, SidePanelWrapper, useDirtinessPrompt } from '@samc/react-ui-core';
import { useStyletron } from 'styletron-react';
import { SelectAndShow } from '@samc/react-ui-form';
import { HeaderContextProvider } from '@samc/react-ui-history';
import type { FormScreenState } from '@samc/screen-config-core/lib/model/FormScreenState/FormScreenState';
import { FormTabChangedEvent } from '@samc/react-ui-form/lib/forms/FormRenderer/FormRenderer';
import { ScreenConfigFormTab } from '@samc/screen-config-core/lib/model/ScreenConfigFormSkeleton/ScreenConfigFormSkeleton';
import { createPath, generatePath, NavigateOptions, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApplicationContext } from '../../../hooks/useApplicationContext/useApplicationContext';

interface Props {
    params: CustomScreenParams;

    valuationAssetId?: string | null;
}

export const DEA_SIDE_PANEL_FORMVIEW_ID = 'VALU_DEAPanelForm';
export const FROM_ID = 'VALU_DEAForm';

interface DEATabUrlParams extends Record<string, string | undefined> {
    id: string;
    tabId: string;
    DEAMenuItem: string | undefined;
    subtabId: string | undefined;
}

export const DEATab = (props: Props): JSX.Element => {
    const { params, valuationAssetId } = props;
    const { primaryKeyValue, filters } = params;

    const { DeaDataDomainId, DeaPathFormat } = useApplicationContext();

    const urlParams = useParams() as DEATabUrlParams;
    const _navigate = useNavigate();
    const location = useLocation();

    const [css] = useStyletron();

    const dirtinessScope = React.useRef<HTMLDivElement>(null);
    const resetInProgress = React.useRef(false);
    const mainFormState = React.useRef<FormScreenState>();
    const sidebarFormState = React.useRef<FormScreenState>();

    const { promptAndContinue } = useDirtinessPrompt({ scope: dirtinessScope });

    const onSSSelectionChange = React.useCallback(
        async (id: string | number, ss: SelectAndShow) => {
            if (!id) return false;

            if (ss.id !== DeaDataDomainId) return true;

            if (valuationAssetId === String(id)) return true; // same value

            try {
                await promptAndContinue();
                return true;
            } catch (e) {
                return false;
            }
        },
        [promptAndContinue, valuationAssetId],
    );

    const beforeSidebarReset = React.useCallback(({ requestIdentifiers }: { requestIdentifiers?: string[] }): void => {
        const mainForm = mainFormState.current;
        if (requestIdentifiers || resetInProgress.current || !mainForm) return; // only when entire form is being reset

        // use resetInProgress to avoid infinite reset loops
        resetInProgress.current = true;
        mainForm.formApi.resetForm().finally(() => {
            resetInProgress.current = false;
        });
    }, []);

    const sidebarComponent = primaryKeyValue ? (
        <div className={css({ display: 'flex', flexDirection: 'column', overflow: 'auto' })}>
            <HeaderContextProvider>
                <FormScreenWrapper
                    onFormStateChange={(fs): void => {
                        sidebarFormState.current = fs;
                    }}
                    onSubmit={Promise.resolve}
                    formViewId={DEA_SIDE_PANEL_FORMVIEW_ID}
                    filters={filters}
                    primaryKeyValue={primaryKeyValue}
                    onSelectAndShowSelectionChange={onSSSelectionChange}
                    beforeReset={beforeSidebarReset}
                    ServiceDrivenViewSet={ServiceDrivenViewSet}
                    GridScreenWrapper={GridScreenWrapper}
                />
            </HeaderContextProvider>
        </div>
    ) : (
        <MessageBar messageBarType={MessageBarType.info}>Select a valuation</MessageBar>
    );

    const navigate = React.useCallback(
        (paramUpdates: Pick<Partial<DEATabUrlParams>, 'subtabId' | 'DEAMenuItem'>, options?: NavigateOptions): void => {
            const { subtabId, DEAMenuItem, ...rest } = urlParams;
            const pathParams = {
                ...rest,
                subtabId: subtabId ?? null,
                DEAMenuItem: DEAMenuItem ?? null,
                ...(paramUpdates.DEAMenuItem && {
                    // if menu item changes, should clear the subtab as the form view will change
                    subtabId: undefined,
                }),
                ...paramUpdates,
            };

            const newPath = generatePath(
                DeaPathFormat,
                // must uri-encode all
                Object.entries(pathParams).reduce(
                    (all, [attr, val]) => (val ? { ...all, [attr]: encodeURIComponent(val) } : all),
                    pathParams,
                ),
            );

            _navigate(
                createPath({
                    pathname: newPath,
                    search: location.search,
                    hash: location.hash,
                }),
                options,
            );
        },
        [_navigate, location.hash, location.search, urlParams],
    );

    const onTabChanged = React.useCallback(
        (newTabName: string, ev: FormTabChangedEvent<ScreenConfigFormTab>): void => {
            const { userRequested } = ev;

            navigate(
                {
                    subtabId: newTabName,
                },
                {
                    replace: !userRequested,
                },
            );
        },
        [navigate],
    );

    const beforeMainFormReset = React.useCallback(({ requestIdentifiers }: { requestIdentifiers?: string[] }): void => {
        const sideForm = sidebarFormState.current;
        if (requestIdentifiers || resetInProgress.current || !sideForm) return; // only when entire form is being reset

        // use resetInProgress to avoid infinite reset loops
        resetInProgress.current = true;
        sideForm.formApi.resetForm().finally(() => {
            resetInProgress.current = false;
        });
    }, []);

    return (
        <ErrorBoundary>
            <SidePanelWrapper
                sidePanelProps={{
                    className: css({ overflow: 'auto' }),
                    children: sidebarComponent,
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        right: '4%',
                        marginTop: '15px',
                        color: '#62a7d9',
                        cursor: 'pointer',
                        fontWeight: '600',
                        textDecoration: 'underline',
                    }}
                />

                {!valuationAssetId && <MessageBar messageBarType={MessageBarType.info}>Select an asset</MessageBar>}
                {valuationAssetId && (
                    <div ref={dirtinessScope} className={css({ height: '100%', overflow: 'auto' })}>
                        <div className={css({ clear: 'both' })}>
                            <FormScreenWrapper
                                key={`${FROM_ID}_${valuationAssetId}`}
                                primaryKeyValue={valuationAssetId ?? undefined}
                                formViewId={FROM_ID}
                                filters={filters}
                                initialTabIndexOrName={urlParams.subtabId}
                                onTabChanged={onTabChanged}
                                onFormStateChange={(fs): void => {
                                    mainFormState.current = fs;
                                }}
                                beforeReset={beforeMainFormReset}
                                ServiceDrivenViewSet={ServiceDrivenViewSet}
                                GridScreenWrapper={GridScreenWrapper}
                            />
                        </div>
                    </div>
                )}
            </SidePanelWrapper>
        </ErrorBoundary>
    );
};

export default DEATab;
