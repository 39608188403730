import React, { useCallback } from 'react';
import { ChangeDataArgs, useGridApis } from '@samc/react-ui-grid';
import { useApiContext, useQBQuery } from '@samc/screen-config-api';
import { FormViews, Status, Valuation, ValuationDomains, ValuationGroupAsset } from '../../static/ValuationConstants';
import { HybridGrid } from './HybridGrid';
import { createGuid, getFinalGridData } from '../../static/ValuationWizard.utils';
import { NavButtons } from './NavButtons';
import { getDefaultViewFromPortfolio, getValuationsFromGridData } from './AddValuationWizard.service';
import { CycleMethod, ValuationMethod, ValuationType } from './ValuationWizard.enums';
import { GridStateType, SetupMethodType } from './AddValuations.interfaces';
import { DefaultValidationResponse, ValidationResponse, validateSetupData, addRowIndex } from './ValidationUtils';
import { ValuationSteps, WorkflowSteps } from '../WizardProgress/ProgressConstants';
import {
    getValuationWorkflowFromGridData,
    validateSetupData as validateWorkflowSetupData,
} from '../ChangeWorkflow/ChangeWorkflowWizard.service';
import NavLoader from '../../atoms/NavLoader/NavLoader';

interface PropTypes {
    dataForGrid: Record<string, unknown>[] | undefined;
    setupMethod: SetupMethodType;
    setCurrentStep: (params: number) => void;
    requestParams: {
        valuationAsset: Record<string, unknown>;
        valuationGroupAssets: ValuationGroupAsset[];
        enableValGroup: boolean;
        portfolioId: string | null;
        portfolioTypeId: string;
        valuationMethod: ValuationMethod;
        covValuationMethod: CycleMethod;
        CreatedBy: string;
    };
    setValuation: (params: Valuation) => void;
    filters?: string[];
}

// constants should be declared outside functional component, esp non-primatives
const ESSENTIAL_FIELDS = ['AssetNameGroupName', 'ValuationName', 'StatusId'];
const NAV_DOMAIN_NAME_MAP = { VALU_Valuation: 'ValuationName' };

export const SetupValuationsGrid = ({
    dataForGrid,
    setupMethod,
    setCurrentStep,
    setValuation,
    requestParams,
    filters,
}: PropTypes): React.ReactElement => {
    const { apis, onGridApiChanged } = useGridApis<Record<string, unknown>>();

    const api = useApiContext();
    const qbQuery = useQBQuery();
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const { valuationAsset, valuationGroupAssets, portfolioTypeId, portfolioId } = requestParams;
    const assetCount = valuationGroupAssets.length ? valuationGroupAssets.length : 1;

    const [setupData, updateSetupData] = React.useState<GridStateType>({
        apis, // yes, it's silly, but it's safe
        data: {},
        ...(dataForGrid &&
            dataForGrid.length > 0 && {
                data: dataForGrid.reduce((acc, row) => ({ ...acc, [`${row.ValuationUniqId}`]: row }), {}),
            }),
    });

    const [validationResponse, updateValidationResponse] = React.useState<ValidationResponse>({
        ...DefaultValidationResponse,
    });

    const viewId = getDefaultViewFromPortfolio(
        valuationAsset.type === ValuationType.SingleAsset || valuationAsset.type === ValuationType.ChangeWorkflow
            ? FormViews.MultiValuationSetupByAssetScreen
            : FormViews.MultiValuationSetupByValGroupScreen,
        valuationAsset.type === ValuationType.SingleAsset || valuationAsset.type === ValuationType.ChangeWorkflow
            ? 'ValuationSetupAssetDefaultView'
            : 'ValuationSetupGroupDefaultView',
    );

    const gridData = React.useMemo(() => Object.values(setupData.data) as Record<string, unknown>[], [setupData.data]);

    const onChangeData = useCallback(
        ({ cellValueChangedEvent, changedData, record }: ChangeDataArgs<Record<string, unknown>>): void => {
            if (record) {
                const newRecord = { ...record };

                if (!newRecord.ValuationUniqId) {
                    newRecord.ValuationUniqId = createGuid();

                    if (cellValueChangedEvent) {
                        cellValueChangedEvent.node.setDataValue('ValuationUniqId', newRecord.ValuationUniqId);
                    }
                }
            }

            updateSetupData((c) => ({ ...c, data: getFinalGridData(apis, changedData, 'Id') }));
        },

        [apis],
    );

    const onBack = (): void => {
        if (setupMethod.valuationMethod === ValuationMethod.CycleofValuations) {
            if (setupMethod.cycleMethod === CycleMethod.CreateCycleManually) {
                setCurrentStep(ValuationSteps.SelectCycleMethod);
            } else {
                setCurrentStep(ValuationSteps.SetupReportPeriods);
            }
        } else if (setupMethod.valuationMethod === ValuationMethod.MultipleValuations) {
            setCurrentStep(ValuationSteps.SelectSetupMethod);
        } else if (setupMethod.valuationMethod === ValuationMethod.ChangeValuationWorkflow) {
            setCurrentStep(WorkflowSteps.SelectWorkflow);
        }
    };

    const onContinue = async (): Promise<void> => {
        const tempValuation = setupData.data
            ? getValuationsFromGridData({
                  ...requestParams,
                  assetOrGroup: valuationAsset,
                  gridData: Object.values(
                      addRowIndex(apis, setupData.data as Record<string, Record<string, unknown>>, 'ValuationUniqId'),
                  ),
              })
            : { addTasks: dataForGrid };

        setLoading(true);
        validateSetupData({
            TaskApi: api.TaskApi,
            requestInit: api.requestInit || {},
            addTasks: tempValuation.addTasks?.length
                ? tempValuation.addTasks
                : [
                      {
                          PortfolioId: portfolioId as string,
                          PortfoliosId: portfolioId as string,
                          Portfolio_Id: portfolioId as string,
                          PortfolioTypeId: portfolioTypeId as string,
                          StatusId: Status.Scheduled,
                          ValuationIsReadonly: 0,
                          ValuationAssetsId: valuationAsset.Id as string,
                          ValuationGroupId: valuationAsset.ValuationGroupId as string,
                      },
                  ],
            updateErrors: updateValidationResponse,
            onSuccess: () => {
                setValuation(tempValuation);
                setCurrentStep(ValuationSteps.AddContacts);
            },
            setLoading,
        });
    };

    const executeWorkflow = async (overrideCodes: string[]): Promise<void> => {
        const tempValuation = await getValuationWorkflowFromGridData(
            Object.values(dataForGrid as Record<string, unknown>[]) as Record<string, unknown>[],
            {
                qbQuery,
                assetOrGroupBulk: '',
                enableValGroup: true,
                portfolioId,
                portfolioTypeId,
            },
        );
        validateWorkflowSetupData({
            TaskApi: api.TaskApi,
            requestInit: api.requestInit || {},
            addTasks: tempValuation.addTasks?.length
                ? tempValuation.addTasks
                : [
                      {
                          PortfolioId: portfolioId as string,
                          PortfoliosId: portfolioId as string,
                          Portfolio_Id: portfolioId as string,
                          PortfolioTypeId: portfolioTypeId as string,
                          StatusId: Status.Scheduled,
                          ValuationIsReadonly: 0,
                          ValuationAssetsId: valuationAsset.Id as string,
                          ValuationGroupId: valuationAsset.ValuationGroupId as string,
                      },
                  ],
            updateErrors: updateValidationResponse,
            onSuccess: () => {
                setValuation(tempValuation);
                setCurrentStep(WorkflowSteps.UpdateValuationContact);
                setLoading(false);
            },
            overrideCodes,
        });
    };

    const onWorkflowContinue = async (): Promise<void> => {
        setLoading(true);
        await executeWorkflow([]);
    };

    const defaultData = React.useMemo((): Record<string, unknown> => {
        const { type: valuationType } = valuationAsset;

        if (valuationType === ValuationType.SingleAsset) {
            return {
                AssetId: valuationAsset.Id,
                AssetNameGroupName: valuationAsset.Name,
                PortfolioTypeId: portfolioTypeId,
                PortfolioId: portfolioId,
                NoOfAsset: assetCount,
                ValuationIsReadonly: 0,
                ValuationRegion: valuationAsset?.RegionalArea ? valuationAsset.RegionalArea : null,
                HideOneOff: null,
            };
        }
        if (valuationType === ValuationType.ChangeWorkflow) {
            return {
                PortfolioTypeId: portfolioTypeId,
                PortfolioId: portfolioId,
                PortfoliosId: portfolioId,
                Portfolio_Id: portfolioId,
                ValuationIsReadonly: 1,
                HideOneOff: null,
            };
        }

        return {
            ValuationGroupId: valuationAsset.ValuationGroupId,
            PortfolioTypeId: portfolioTypeId,
            AssetNameGroupName: valuationAsset.ValuationGroupName,
            PortfolioId: portfolioId,
            NoOfAsset: assetCount,
            ValuationIsReadonly: 0,
            ValuationRegion: valuationAsset?.RegionalArea ? valuationAsset.RegionalArea : null,
            HideOneOff: null,
        };
    }, [assetCount, portfolioId, portfolioTypeId, valuationAsset]);

    const submitWithOverrides = async (overrideCodes: string[]): Promise<void> => {
        await executeWorkflow(overrideCodes);
    };

    return (
        <>
            <NavButtons
                isDisabled={isLoading}
                onBack={onBack}
                onContinue={
                    setupMethod.valuationMethod === ValuationMethod.ChangeValuationWorkflow
                        ? onWorkflowContinue
                        : onContinue
                }
                validationResponse={validationResponse}
                updateValidationResponse={updateValidationResponse}
                submitWithOverrides={submitWithOverrides}
                domainNameMap={NAV_DOMAIN_NAME_MAP}
            >
                <NavLoader isLoading={isLoading} label="Validating Valuations..." />
            </NavButtons>

            <div className="setup-valuations-grid">
                <HybridGrid
                    // NOTE: using the key prop may cause unexpected behavior, so it is not advised.
                    gridData={gridData}
                    domainId={ValuationDomains.Valuation}
                    listViewID={viewId}
                    onGridApiChanged={onGridApiChanged}
                    filters={filters}
                    gridOptions={{
                        defaultRowSpacing: 'normal',
                        isEditingDefault: true,
                        rowSelection: 'multiple',
                        rowsPerPage: 500,
                        suppressAddRowButton: valuationAsset.type === ValuationType.ChangeWorkflow,
                        suppressColumnFilters: true,
                        suppressDeleteButton: valuationAsset.type === ValuationType.ChangeWorkflow,
                        suppressDeleteConfirmationModal: true,
                        suppressEditToggle: true,
                        suppressExcelExport: false,
                        suppressFullScreen: false,
                        suppressRowSpacing: true,
                        suppressViewSelector: false,
                        suppressRefresh: true,
                    }}
                    errorMessages={validationResponse.list}
                    onChangeData={onChangeData}
                    defaultData={defaultData}
                    scope={`SetupValuations_${portfolioId}`}
                    essentialFields={ESSENTIAL_FIELDS}
                />
            </div>
        </>
    );
};

export default SetupValuationsGrid;
