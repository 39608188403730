import React, { useEffect } from 'react';
import { SearchableDropdown } from '@samc/react-ui-core';
import { FieldLabel, FieldRoot } from '@samc/react-ui-form';
import { useStyletron } from 'styletron-react';
import { Labels } from './ConfirmMethodologiesContants';
import { getAssetPicklistData, getValuationPicklistData } from './ConfirmMethodologies.service';
import { CustomPicklistProps, ListType, SelectAssetOrValuationProps } from './ConfirmMethodologies.interfaces';

const CustomPicklist = ({
    disabled = false,
    label,
    options,
    updateOptions,
}: CustomPicklistProps): React.ReactElement => {
    const { list = [], selected = { id: '', displayText: '' } } = options ?? {};

    const onChange = (option: ListType): void => {
        if (option && selected.id !== option.id) updateOptions({ list, selected: option });
    };

    const [css] = useStyletron();

    return (
        <FieldRoot
            className={css({
                width: '100%',
                maxWidth: '500px',
                marginLeft: '6px',
            })}
        >
            <div
                className={css({
                    width: '30ch',
                    minWidth: '65px',
                    display: 'flex',
                    overflow: 'hidden',
                    paddingRight: '4px',
                })}
            >
                <FieldLabel labelText={label} />
            </div>
            <SearchableDropdown
                options={list}
                selectedKeys={selected ? [selected.id] : undefined}
                disabled={disabled}
                onChange={onChange}
            />
        </FieldRoot>
    );
};

const SelectAssetOrValuation = ({
    valuations,
    updateValuations,
    assets,
    updateAssets,
    tasks,
}: SelectAssetOrValuationProps): React.ReactElement => {
    useEffect(() => {
        if (tasks) {
            if (valuations?.selected?.id) {
                const picklistData = getAssetPicklistData(tasks, valuations.selected, assets.selected);

                if (picklistData.selected.id !== assets?.selected.id) updateAssets(picklistData);
            } else {
                const picklistData = getValuationPicklistData(tasks);

                updateValuations(picklistData);
            }
        }
    }, [assets.selected, valuations.selected, tasks, updateAssets, updateValuations]);

    const [css] = useStyletron();

    return (
        <div
            className={css({
                marginTop: '3px',
                paddingTop: '10px',
                paddingBottom: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'stretch',
                borderTop: '1px solid #d1d1d1',
            })}
        >
            <CustomPicklist
                label={Labels.SELECT_VALUATION}
                options={valuations}
                updateOptions={updateValuations}
                disabled={valuations?.list.length === 1}
            />

            <CustomPicklist label={Labels.SELECT_ASSET} options={assets} updateOptions={updateAssets} />
        </div>
    );
};

export default SelectAssetOrValuation;
