/* istanbul ignore file */
import React from 'react';

export interface IWorkflowModalContext {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WorkflowModalContext = React.createContext<IWorkflowModalContext>({
    isOpen: false,
    setIsOpen: () => undefined,
});

export const WorkflowModalContextProvider = ({ children }: { children: React.ReactElement }): React.ReactElement => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const workflowModalContext = React.useMemo(
        (): IWorkflowModalContext => ({
            isOpen,
            setIsOpen,
        }),
        [isOpen],
    );

    return <WorkflowModalContext.Provider value={workflowModalContext}>{children}</WorkflowModalContext.Provider>;
};
