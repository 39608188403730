import * as React from 'react';
import {
    GridFieldConfiguration,
    ConvertGridFilterToFilterExpression,
    GridDropdownOption,
    GridDropdownSelectionChangedEvent,
    GridDropdownOptionFilter,
    ColumnFilterCondition,
    ColumnFilter,
    GridDropdown,
} from '@samc/react-ui-grid';
import {
    useDomain,
    useListView,
    QueryExecuteResponse,
    QueryExecuteRequest,
    AdHocFilter,
    DomainViewMember,
} from '@samc/screen-config-api';
import QueryExecuteManager, {
    ManagerRenderProps,
    PagingData,
} from '@samc/screen-config-core/lib/molecules/QueryExecuteManager/QueryExecuteManager';
import {
    BaseDropdownOptionGetterParams,
    BaseDropdownOptionGetterResponse,
} from '@samc/react-ui-core/lib/atoms/SearchableDropdown/BaseDropdown/Types';
import { AdHocFilterBuilder } from '../../../builders/AdHocFilterBuilder/AdHocFilterBuilder';

export interface RecordDropdownProps {
    domainId: string;
    onValueChanged: (record: Record<string, unknown>) => void;
    filters?: string[];
    selectedKeys?: string[];
}

export const ValGroupSearchDropDown = (props: RecordDropdownProps): React.ReactElement => {
    const { domainId, onValueChanged, filters, selectedKeys } = props;
    const domain = useDomain(domainId);
    const primaryKey = React.useMemo(() => domain.data?.primaryKey ?? '', [domain.data]);
    const listview = useListView('WizardValuationGroup');

    const adhocLVMembArray: DomainViewMember[] = [];

    // eslint-disable-next-line no-unused-expressions
    listview.data?.listViewMembers.forEach((data) => {
        const adhocLVMembArrayObj = {} as DomainViewMember;
        adhocLVMembArrayObj.scalarExpression = data.scalarExpression === undefined ? 'null' : data.scalarExpression;
        adhocLVMembArrayObj.scalarDisplayName = data.viewFieldName === undefined ? 'null' : data.viewFieldName;
        adhocLVMembArray.push(adhocLVMembArrayObj);
    });
    const adhocListViewMembers = adhocLVMembArray;

    // for field configuration retrive

    const gridFieldsArray: GridFieldConfiguration[] = [];

    // eslint-disable-next-line no-unused-expressions
    listview.data?.listViewMembers.forEach((data) => {
        const gridFieldsArrayObj = {} as GridFieldConfiguration;
        gridFieldsArrayObj.field = data.viewFieldName;
        gridFieldsArrayObj.headerName = data.scalarDisplayName;
        gridFieldsArray.push(gridFieldsArrayObj);
    });

    const gridFields = gridFieldsArray;

    const onChange = (ev: GridDropdownSelectionChangedEvent<string, Record<string, unknown>>): void => {
        const { selectedOptions } = ev;

        if (selectedOptions && selectedOptions.length === 1) {
            onValueChanged(selectedOptions[0].data as Record<string, unknown>);
        }
    };

    const resolveSelection =
        (
            doFetch: (
                summaryMode: boolean,
                pagingData: PagingData,
                adHocFilter?: AdHocFilter,
                filterIds?: string[],
            ) => Promise<[QueryExecuteResponse, QueryExecuteRequest]>,
        ) =>
        async (ids: string[]): Promise<GridDropdownOption<string, Record<string, unknown>>[]> => {
            // Since our GridDropdown is singleSelect, we expect to have a single id at most.
            if (ids.length !== 1) return [];

            const filterById = new AdHocFilterBuilder().withExpression(`[Id] = '${ids[0]}'`).build();

            const doFetchWrapper = async (summaryMode: boolean): Promise<QueryExecuteResponse> => {
                const [response] = await doFetch(summaryMode, { start: 0, stop: 1 }, filterById);
                return response;
            };

            const listViewResponse = await doFetchWrapper(false);

            return listViewResponse.Data.map(
                (item): GridDropdownOption<string, Record<string, unknown>> => ({
                    id: item.primaryKey as string,
                    displayText: item.ValuationGroupName as string,
                    data: item,
                }),
            );
        };

    const getOptions =
        (
            doFetch: (
                summaryMode: boolean,
                pagingData: PagingData,
                adHocFilter?: AdHocFilter,
                filterIds?: string[],
            ) => Promise<[QueryExecuteResponse, QueryExecuteRequest]>,
        ) =>
        async (
            params: BaseDropdownOptionGetterParams<string, GridDropdownOptionFilter<string>>,
        ): Promise<BaseDropdownOptionGetterResponse<GridDropdownOption<string, Record<string, unknown>>>> => {
            const { filter } = params;

            const filterModel = filter?.filterModel as {
                [column: string]: ColumnFilterCondition | ColumnFilter;
            };

            const doFetchWrapper = async (summaryMode: boolean): Promise<QueryExecuteResponse> => {
                const [response] = await doFetch(
                    summaryMode,
                    {
                        start: 0,
                        stop: filter?.limit || 100,
                        order: (filter?.sortModel.length || 0) > 0 ? filter?.sortModel[0].sort : 'asc',
                        orderBy:
                            (filter?.sortModel.length || 0) > 0 ? filter?.sortModel[0].colId : 'ValuationGroupName',
                    },
                    filter && Object.keys(filterModel).length > 0
                        ? {
                              advancedExpression: ConvertGridFilterToFilterExpression(filterModel, gridFields),
                              expressionLang: 'Centric',
                              advancedInd: true,
                          }
                        : undefined,
                    undefined,
                );
                return response;
            };

            const listViewResponse = await doFetchWrapper(false);

            return {
                totalCount: listViewResponse.TotalRecords,
                options: listViewResponse.Data.map(
                    (item): GridDropdownOption<string, Record<string, unknown>> => ({
                        id: item.primaryKey as string,
                        displayText: item.ValuationGroupName as string,
                        data: item,
                    }),
                ),
            };
        };

    if (primaryKey === '' || adhocListViewMembers.length === 0) {
        return <></>;
    }

    return (
        <QueryExecuteManager domainId={domainId} adhocListViewMembers={adhocListViewMembers} filters={filters}>
            {(queryRenderProps: ManagerRenderProps): React.ReactElement => (
                <div className="screen-config-record-dropdown">
                    <GridDropdown<string, Record<string, unknown>>
                        style={{ width: '800px', height: '300px' }}
                        autoHeight={false}
                        fields={gridFields}
                        multiSelect={false}
                        selectRowOnClick
                        resolveSelection={resolveSelection(queryRenderProps.doFetch)}
                        getOptions={getOptions(queryRenderProps.doFetch)}
                        onChange={onChange}
                        selectedKeys={selectedKeys}
                    />
                </div>
            )}
        </QueryExecuteManager>
    );
};

export default ValGroupSearchDropDown;
