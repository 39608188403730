import { TooltipHost } from '@fluentui/react';
import { useThemeContext } from '@samc/react-ui-theme';
import React from 'react';
import { useStyletron } from 'styletron-react';
import { MenuItem } from './MenuItem';

interface NestedMenuItemProps<TData> {
    className?: string;
    rootClassName?: string;
    expansionClassName?: string;
    item: MenuItem<TData>;
    selectedId?: string;
    nestingLevel: number;
    indentationSize: number;
    forceExpanded?: boolean;
}

const isSelected = <TData,>(item: MenuItem<TData>, selectedId?: string): boolean => {
    if (selectedId === undefined) return false;
    const { children, id } = item;

    if (id === selectedId) return true;

    if (!children) return false;
    return children.some((c) => isSelected(c, selectedId));
};

export const NestedMenuItem = <TData,>(props: NestedMenuItemProps<TData>): JSX.Element => {
    const {
        item,
        selectedId,
        indentationSize,
        className,
        nestingLevel,
        rootClassName,
        expansionClassName,
        forceExpanded,
    } = props;
    const { displayText, onClick, hoverText, children, isDisabled } = item;

    const selected = React.useMemo(() => isSelected(item, selectedId), [item, selectedId]);
    const [expanded, setExpanded] = React.useState(selected);

    const childItems = React.useMemo(() => {
        if (!children) return [];
        return children.map((c) => (
            <NestedMenuItem
                key={c.id}
                selectedId={selectedId}
                item={c}
                indentationSize={indentationSize}
                nestingLevel={nestingLevel + 1}
            />
        ));
    }, [children, indentationSize, nestingLevel, selectedId]);

    const leftPadding = nestingLevel * indentationSize;

    const onClickItem = React.useCallback(() => {
        if (onClick) onClick();
        if (children?.length) setExpanded((e) => !e);
    }, [children?.length, onClick]);

    const theme = useThemeContext();
    const [css] = useStyletron();

    return (
        <div className={className}>
            <div className={rootClassName}>
                <TooltipHost content={hoverText ?? displayText}>
                    <button
                        className={css({
                            boxSizing: 'border-box',
                            width: '100%',
                            padding: `4px 4px 4px ${leftPadding + 4}px`,
                            border: 'unset',
                            cursor: 'pointer',
                            color: selected ? theme.TextColors.light.toString() : theme.TextColors.dark.toString(),
                            fontWeight: selected ? 'bold' : 'normal',
                            fontSize: '12px',
                            textAlign: 'left',
                            backgroundColor: selected
                                ? theme.ButtonColors.primary.default.background.toString()
                                : 'unset',
                            ...(isDisabled && {
                                opacity: 0.5,
                                cursor: 'not-allowed',
                            }),
                        })}
                        disabled={isDisabled}
                        onClick={onClickItem}
                        type="button"
                    >
                        {displayText}
                    </button>
                </TooltipHost>
            </div>
            {(expanded || forceExpanded) && !!childItems.length && (
                <div className={expansionClassName}>{childItems}</div>
            )}
        </div>
    );
};

export interface NestedMenuProps<TData = unknown> {
    items: MenuItem<TData>[];
    /**
     * Indentation size per nesting level in px.
     * @default 15
     */
    indentationSize?: number;
    className?: string;
    selectedId?: string;
    forceExpanded?: boolean;
}

export const NestedMenu = <TData,>(props: NestedMenuProps<TData>): JSX.Element => {
    const { items, className, selectedId, indentationSize, forceExpanded } = props;

    const theme = useThemeContext();
    const [css] = useStyletron();

    return (
        <div className={className}>
            {items.map((i) => (
                <NestedMenuItem
                    selectedId={selectedId}
                    key={i.id}
                    item={i}
                    forceExpanded={forceExpanded}
                    expansionClassName={css({ borderBottom: `1px solid ${theme.BorderColor.toString()}` })}
                    rootClassName={css({ borderBottom: `1px solid ${theme.BorderColor.toString()}` })}
                    nestingLevel={0}
                    indentationSize={indentationSize ?? 15}
                />
            ))}
        </div>
    );
};

export default NestedMenu;
