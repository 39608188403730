import React from 'react';
import { ApiContextType, ApiContext, QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';
import { ValuationContactVM } from '../../components/static/ValuationConstants';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';

export const VALUATION_CONTACTS_KEY = 'ValuationContacts';

export const useValuationContactsValuationIds = (
    valuationIds: string,
): UseQueryResult<QueryExecuteResponse<ValuationContactVM>> => {
    const api = React.useContext<ApiContextType>(ApiContext);
    const { SystemExecuteMaxRecordsToReturn } = useApplicationContext();

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_ValuationContact',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
            {
                scalarDisplayName: 'RoleId',
                scalarExpression: '[RoleId]',
            },
            {
                scalarDisplayName: 'UserId',
                scalarExpression: '[UserId]',
            },
            {
                scalarDisplayName: 'ValuationId',
                scalarExpression: '[ValuationId]',
            },
            {
                scalarDisplayName: 'CompanyId',
                scalarExpression: '[CompanyId]',
            },
            {
                scalarDisplayName: 'ContactId',
                scalarExpression: '[ContactId]',
            },
            {
                scalarDisplayName: 'ValuationWorkflowParticipantId',
                scalarExpression: '[ValuationWorkflowParticipantId]',
            },
        ],
        sorting: {},
        paging: { start: 0, stop: SystemExecuteMaxRecordsToReturn },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `DoesContain([ValuationId], '${valuationIds}')`,
        },
    };

    const queryResult = useQuery<QueryExecuteResponse<ValuationContactVM>>(
        [VALUATION_CONTACTS_KEY, valuationIds],
        () =>
            executeLiftSiftQuery(
                api.LiftSiftApi,
                requestBody,
                api.requestInit,
                api.ShowMessage,
            ) as unknown as QueryExecuteResponse<ValuationContactVM>,
        { retry: 2, refetchOnWindowFocus: false },
    );

    return queryResult;
};

export default useValuationContactsValuationIds;
