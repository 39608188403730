import React from 'react';
import { QueryClient as QueryClientClass, QueryClientProvider } from 'react-query';
import { fetchApi as _fetchApi } from '../../valuationApi';
import { ShowMessage as _ShowMessage } from '../../functions/ShowMessage/ShowMessage';
import { useTaskSubmitter as _useTaskSubmitter } from '../../valuationApi/useTaskSubmitter/useTaskSubmitter';
import { useValuation as _useValuation } from '../../valuationApi/useValuation/useValuation';
import { useValuationContacts as _useValuationContacts } from '../../valuationApi/useValuationContacts/useValuationContacts';
import { useValuationWorkflowLogs as _useValuationWorkflowLogs } from '../../valuationApi/useValuationWorkflowLogs/useValuationWorkflowLogs';
import { useValuationWorkflowStatus as _useValuationWorkflowStatus } from '../../valuationApi/useValuationWorkflowStatus/useValuationWorkflowStatus';
import { useValuationWorkflowTask as _useValuationWorkflowTask } from '../../valuationApi/useValuationWorkflowTask/useValuationWorkflowTask';
import { useValuationWorkflowTaskEnabledUsers as _useValuationWorkflowTaskEnabledUsers } from '../../valuationApi/useValuationWorkflowTaskEnabledUsers/useValuationWorkflowTaskEnabledUsers';
import { useValuationHub as _useValuationHub } from '../../hooks/useValuationHub/useValuationHub';
import { useValuationWorkflowTaskOwners as _useValuationWorkflowTaskOwners } from '../../valuationApi/useValuationWorkflowTaskOwners/useValuationWorkflowTaskOwners';
import { useValuationWorkflowTasks as _useValuationWorkflowTasks } from '../../valuationApi/useValuationWorkflowTasks/useValuationWorkflowTasks';
import { useValuationWorkflowTaskStatuses as _useValuationWorkflowTaskStatuses } from '../../valuationApi/useValuationWorkflowTaskStatuses/useValuationWorkflowTaskStatuses';
import { useValuationWorkflowTaskViewableUsers as _useValuationWorkflowTaskViewableUsers } from '../../valuationApi/useValuationWorkflowTaskViewableUsers/useValuationWorkflowTaskViewableUsers';
import { useValuationWorkflowTemplateStages as _useValuationWorkflowTemplateStages } from '../../valuationApi/useValuationWorkflowTemplateStages/useValuationWorkflowTemplateStages';
import { useValuationWorkflowTemplateTasks as _useValuationWorkflowTemplateTasks } from '../../valuationApi/useValuationWorkflowTemplateTasks/useValuationWorkflowTemplateTasks';
import { useValuationWorkflowTemplateTaskStatuses as _useValuationWorkflowTemplateTaskStatuses } from '../../valuationApi/useValuationWorkflowTemplateTaskStatuses/useValuationWorkflowTemplateTaskStatuses';
import { ApiProvider } from './ApiContext';
import { Api } from './ApiType';

const _queryClient = new QueryClientClass();

const _aggregatorApi = '/_apis/aggregator';
const _folderApi = '/_apis/aggregator/folderapi';
const _liftSiftApi = '/_apis/querybuilder';
const _queryAuthApi = '/_apis/query-authorization';
const _reportingApi = '/_apis/reporting';
const _strataApi = '/_apis/strata';
const _taskApi = '/taskgateway';
const _valuationApi = '/_apis/valuation';

export const ApiContextProvider = ({
    AggregatorApi = _aggregatorApi,
    children,
    FetchApi = _fetchApi,
    FolderApi = _folderApi,
    LiftSiftApi = _liftSiftApi,
    QueryAuthorizationApi = _queryAuthApi,
    QueryClient = _queryClient,
    ReportingApi = _reportingApi,
    requestInit,
    ShowMessage = _ShowMessage,
    StrataApi = _strataApi,
    TaskApi = _taskApi,
    tenantName,
    useTaskSubmitter = _useTaskSubmitter,
    useValuation = _useValuation,
    useValuationContacts = _useValuationContacts,
    useValuationWorkflowLogs = _useValuationWorkflowLogs,
    useValuationWorkflowStatus = _useValuationWorkflowStatus,
    useValuationWorkflowTask = _useValuationWorkflowTask,
    useValuationWorkflowTaskEnabledUsers = _useValuationWorkflowTaskEnabledUsers,
    useValuationHub = _useValuationHub,
    useValuationWorkflowTaskOwners = _useValuationWorkflowTaskOwners,
    useValuationWorkflowTasks = _useValuationWorkflowTasks,
    useValuationWorkflowTaskStatuses = _useValuationWorkflowTaskStatuses,
    useValuationWorkflowTaskViewableUsers = _useValuationWorkflowTaskViewableUsers,
    useValuationWorkflowTemplateStages = _useValuationWorkflowTemplateStages,
    useValuationWorkflowTemplateTasks = _useValuationWorkflowTemplateTasks,
    useValuationWorkflowTemplateTaskStatuses = _useValuationWorkflowTemplateTaskStatuses,
    ValuationApi = _valuationApi,
}: Partial<Api> & {
    children?: React.ReactNode;
}): React.ReactElement => {
    const value: Api = React.useMemo(
        () => ({
            AggregatorApi,
            FetchApi,
            FolderApi,
            LiftSiftApi,
            QueryAuthorizationApi,
            QueryClient,
            ReportingApi,
            requestInit,
            ShowMessage,
            StrataApi,
            TaskApi,
            tenantName,
            useTaskSubmitter,
            useValuation,
            useValuationContacts,
            useValuationWorkflowLogs,
            useValuationWorkflowStatus,
            useValuationWorkflowTask,
            useValuationWorkflowTaskEnabledUsers,
            useValuationHub,
            useValuationWorkflowTaskOwners,
            useValuationWorkflowTasks,
            useValuationWorkflowTaskStatuses,
            useValuationWorkflowTaskViewableUsers,
            useValuationWorkflowTemplateStages,
            useValuationWorkflowTemplateTasks,
            useValuationWorkflowTemplateTaskStatuses,
            ValuationApi,
        }),
        [
            AggregatorApi,
            FetchApi,
            FolderApi,
            ReportingApi,
            LiftSiftApi,
            QueryAuthorizationApi,
            QueryClient,
            requestInit,
            ShowMessage,
            StrataApi,
            TaskApi,
            tenantName,
            useTaskSubmitter,
            useValuation,
            useValuationContacts,
            useValuationWorkflowLogs,
            useValuationWorkflowStatus,
            useValuationWorkflowTask,
            useValuationWorkflowTaskEnabledUsers,
            useValuationHub,
            useValuationWorkflowTaskOwners,
            useValuationWorkflowTasks,
            useValuationWorkflowTaskStatuses,
            useValuationWorkflowTaskViewableUsers,
            useValuationWorkflowTemplateStages,
            useValuationWorkflowTemplateTasks,
            useValuationWorkflowTemplateTaskStatuses,
            ValuationApi,
        ],
    );

    return (
        <QueryClientProvider client={QueryClient}>
            <ApiProvider value={value}>{children}</ApiProvider>
        </QueryClientProvider>
    );
};

export default ApiContextProvider;
