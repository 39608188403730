import React from 'react';
import { ApiContextType, ApiContext, QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';
import { ESInputDataDefault } from '../../components/static/ValuationConstants';

export const ES_INPUT_DATA_DEFAULT_KEY = 'ESInputDataDefaults';

export const useEsInputDataDefault = (): UseQueryResult<QueryExecuteResponse<ESInputDataDefault>> => {
    const api = React.useContext<ApiContextType>(ApiContext);

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_ESInputDataDefaults',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
            {
                scalarDisplayName: 'SharedFieldId',
                scalarExpression: '[SharedFieldId]',
            },
        ],
        sorting: {},
        paging: { start: 0, stop: 200 },
        summaryMode: false,
    };

    const queryResult = useQuery<QueryExecuteResponse<ESInputDataDefault>>(
        [ES_INPUT_DATA_DEFAULT_KEY],
        () =>
            executeLiftSiftQuery(
                api.LiftSiftApi,
                requestBody,
                api.requestInit,
                api.ShowMessage,
            ) as unknown as QueryExecuteResponse<ESInputDataDefault>,
        { retry: 2, refetchOnWindowFocus: false },
    );

    return queryResult;
};

export default useEsInputDataDefault;
