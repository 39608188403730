import { ToastMessage, toastSuccess } from '@samc/react-ui-core';
import * as React from 'react';
import { useApiContext } from '../../hooks/useApiContext';
import { resendNotification } from '../api/Requests';

export const VALUATION_WORKFLOW_STATUS_KEY = 'ValuationWorkflowStatus';

export const useResendNotification = (): ((taskId: string) => Promise<void>) => {
    const { ValuationApi, requestInit } = useApiContext();

    return React.useCallback(
        async (taskId: string) => {
            await resendNotification(ValuationApi, taskId, requestInit);
            toastSuccess(<ToastMessage title="Success" message="Notification Resent Successfully" />);
        },
        [ValuationApi, requestInit],
    );
};

export default useResendNotification;
