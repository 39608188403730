/* eslint-disable @typescript-eslint/no-unused-vars */
import { TaskRequest, TaskResponse } from '@samc/screen-config-api';
import { apiProvider } from '../../../valuationApi';
import { AddTask, GridIdentifiers, ValuationDomains, ValuationSubDomains } from '../../static/ValuationConstants';
import { createGuid } from '../../static/ValuationWizard.utils';
// import { getValidationResponse, ValidationResponse } from '../AddValutionWizard/ValidationUtils';
import { optionType } from './ConfirmMethodologies.interfaces';

export const removePrimary = (record: ValuationSubDomains): ValuationSubDomains => {
    const newRecord = { ...record };
    delete newRecord.VAMethodologiesId;
    delete newRecord.VAOtherDataScreensId;
    delete newRecord.VAOtherValuesId;

    return newRecord;
};

export const getChildRequestsForDomainData = (
    domainData: ValuationSubDomains[],
    domainId: ValuationDomains,
    payloadSequence: number,
    AssetId: string,
    ValuationId: string,
    ValuationName: string,
    AssetsName: string,
    sequence = 1,
): Record<string, unknown> => ({
    domainId,
    fieldConfigurationId: domainId,
    requestIdentifier: `${domainId}`, // -${AssetId}-${ValuationId} identifer string = Domain + AssetId + ValuationId
    payload: domainData.reduce(
        (acc, record, index) => ({
            ...acc,
            [`${payloadSequence}${sequence}${index}`]: removePrimary({
                ...record,
                AssetId,
                VAMethodologySetupId: `${payloadSequence}`,
                ValuationAssetId: `${payloadSequence}`,
                __ADDED__: true,
                ValuationId,
                ValuationName: `${ValuationName} - ${AssetsName}`,
            }),
        }),
        {},
    ),
});

export const getPayloadForConfirmation = (addTasks: AddTask[], { list }: optionType): AddTask => {
    return addTasks.reduce(
        (
            payload,
            { ValuationAssetMethodologySetup, ValuationUniqId = createGuid(), ValuationName, AssetsName },
            index,
        ) => {
            let assets = {};

            if (ValuationAssetMethodologySetup && ValuationAssetMethodologySetup.length) {
                assets = ValuationAssetMethodologySetup.reduce(
                    (
                        children,
                        {
                            ValuationGroupId,
                            VAMethodologySetupId,
                            AssetId,
                            ValuationAssetMethodologies,
                            ValuationAssetOtherDataScreens,
                            ValuationAssetOtherValues,
                            ...vam
                        },
                        i,
                    ) => {
                        const payloadSequence = `${-index - 1}${i}`;

                        return {
                            ...children,

                            [`${payloadSequence}`]: {
                                ...vam,
                                AssetId,
                                PortfolioId: vam.PortfoliosId,
                                ValuationAssetId: createGuid(),
                                ValuationName,
                                __CHILD_REQUESTS__: [
                                    { domainData: ValuationAssetMethodologies, domain: ValuationDomains.VAM },
                                    { domainData: ValuationAssetOtherDataScreens, domain: ValuationDomains.VAODS },
                                    { domainData: ValuationAssetOtherValues, domain: ValuationDomains.VAOV },
                                ].map(({ domainData, domain }) => {
                                    const childRequestData = domainData
                                        ? getChildRequestsForDomainData(
                                              domainData,
                                              domain,
                                              parseInt(payloadSequence, 10),
                                              AssetId ?? '',
                                              ValuationUniqId,
                                              ValuationName ?? '',
                                              AssetsName || AssetId
                                                  ? list.find(({ id }) => AssetId === id)?.displayText || ''
                                                  : '' || '',
                                              domainData.length,
                                          )
                                        : {};

                                    return childRequestData;
                                }),
                                __ADDED__: true,
                            },
                        };
                    },
                    {},
                );
            }

            return {
                ...payload,
                ...assets,
            };
        },
        {},
    );
};

export const customizePayloadForErrors = (payload: TaskRequest['payload']): TaskRequest['payload'] => {
    const dataByDomain: TaskRequest['payload'] = {};

    Object.keys(payload).forEach((payloadKey) => {
        payload[payloadKey].__CHILD_REQUESTS__?.forEach(
            ({
                domainId,
                requestIdentifier,
                payload: childPayload,
            }: {
                domainId: string;
                payload: TaskRequest['payload'];
                requestIdentifier: string;
            }) => {
                dataByDomain[domainId] = {
                    ...dataByDomain[domainId],
                    ...Object.keys(childPayload).reduce(
                        (p, pKey) => ({ ...p, [requestIdentifier + pKey]: childPayload[pKey] }),
                        {},
                    ),
                };
            },
        );
    });

    return Object.keys(payload).reduce(
        (p, payloadKey) => ({
            ...p,
            [payloadKey]: {
                ...payload[payloadKey],
                __CHILD_REQUESTS__: payload[payloadKey].__CHILD_REQUESTS__?.map((request) => ({
                    ...request,
                    payload: dataByDomain[request.domainId] as TaskRequest['payload'],
                })),
            },
        }),
        {},
    );
};

export const validateMethodologies = (
    addTasks: AddTask[],
    assets: optionType,
    TaskApi: string | undefined,
    requestInit: RequestInit,
): Promise<[TaskResponse, TaskRequest<Record<string, unknown>>]> =>
    apiProvider.submitTask(
        TaskApi,
        ValuationDomains.VAM_SETUP,
        ValuationDomains.VAM_SETUP,
        GridIdentifiers.VA_METHODOLOGYSETUP_GRID,
        getPayloadForConfirmation(addTasks, assets) as TaskRequest['payload'],
        requestInit,
    );
