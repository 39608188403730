import { ValueFormatterParams } from 'ag-grid-community';
import { FieldConfigurationMember, InputType } from '@samc/screen-config-api';
import { SsfValueFormatter, localeDateFormat } from '@samc/react-ui-formatting';
import { formatCurrency } from '@samc/react-ui-core';
import { getYesNoDisplayValue } from '@samc/react-ui-grid/lib/molecules/value-getters/YesNoValueGetter/YesNoValueGetter';
import {
    DEFAULT_MONEY_FORMAT,
    DEFAULT_PERCENT_FORMAT,
} from '@samc/react-ui-grid/lib/organisms/BaseGrid/ValueFormatter/ValueFormatter';
import { ComparisonColId } from './ComparisonGrid.functions';

const getDefaultRenderer = (value: string): string => value;

const getDisplayFormat = (
    inputType: InputType,
    formatId: string,
): {
    format: string;
    type: 'number' | 'date' | 'string' | 'datetime' | 'boolean' | 'time' | undefined;
} => {
    switch (inputType) {
        case InputType.Text:
            return { format: 'string', type: 'string' };
        case InputType.Number:
            return { format: formatId || 'number', type: 'number' };
        case InputType.MeasurementField:
            return { format: formatId || 'number', type: 'number' };
        case InputType.CurrencyField:
            return { format: formatId || DEFAULT_MONEY_FORMAT, type: 'number' };
        case InputType.UnitOfCurrency:
            return { format: 'string', type: 'string' };
        case InputType.Percent:
            return { format: formatId || DEFAULT_PERCENT_FORMAT, type: 'string' };
        case InputType.DateTime:
            return { format: 'string', type: 'datetime' };
        case InputType.Time:
            return { format: 'string', type: 'time' };
        case InputType.CurrencyPerArea:
            return { format: 'string', type: 'string' };
        default:
            return { format: 'string', type: 'string' };
    }
};

const getFieldRenderer = (
    value: string,
    inputType: InputType,
    formatId: string,
    currency: string | undefined,
): string => {
    if (inputType === InputType.CurrencyField) {
        const val = Number.isNaN(parseInt(value, 10)) ? undefined : parseFloat(value);
        const formattedCurrency = formatCurrency(currency ?? 'USD', val, formatId);

        return getDefaultRenderer(formattedCurrency);
    }

    if (inputType === InputType.Date && value)
        return getDefaultRenderer(localeDateFormat(new Date(value), undefined, false));

    if (inputType === InputType.YesNo) return getDefaultRenderer(getYesNoDisplayValue(value));

    const { format, type } = getDisplayFormat(inputType, formatId);
    return format && InputType.Text !== inputType
        ? (SsfValueFormatter(value, format, type) as string)
        : getDefaultRenderer(typeof value === 'boolean' ? `${value}` : value);
};

const getUnitOfMeasureValue = (filteredValue: string, config: FieldConfigurationMember): string => {
    if (config.unitOfMeasureProperties?.units.length) {
        const unit = config.unitOfMeasureProperties?.units.find(({ id }) => id === filteredValue);

        return unit ? (unit.unitOfMeasure as string) : filteredValue;
    }

    return filteredValue;
};

export const PivotCellRenderer = (params: ValueFormatterParams): string => {
    const { value, column, data } = params;
    const colId = column?.getColId();
    const filteredValue = typeof value === 'string' && value.replace(/,/g, '').trim() === '' ? '' : value;

    const config = data?.fieldConfig;
    const inputType = config?.inputType ?? InputType.Text;

    // For calcType "Current - Prior" use default field configurations else none
    if ((ComparisonColId.PP === colId || ComparisonColId.YY === colId) && data?.calcType !== 'Current - Prior') {
        return getDefaultRenderer(filteredValue);
    }

    if (inputType === InputType.UnitOfMeasure) {
        return getDefaultRenderer(getUnitOfMeasureValue(filteredValue, config));
    }

    return getFieldRenderer(
        filteredValue === null ? undefined : filteredValue,
        inputType,
        config?.formatId,
        data.currency,
    );
};

export default PivotCellRenderer;
