import React from 'react';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { useSelectAndShowURLSearchParams } from '@samc/screen-config-core/lib/hooks/useSelectAndShowURLSearchParams/useSelectAndShowURLSearchParams';
import { useDirtiness } from '@samc/react-ui-core';
import { Spinner } from '@fluentui/react';
import { EsInputTab as EsInputTabBase } from './ESInputTab.SearchParams';

interface Props {
    params: CustomScreenParams;
}

export const EsInputTab = (props: Props): JSX.Element => {
    const { params } = props;

    const [isFormReset, setIsFormReset] = React.useState<boolean>(false);

    const { isDirty } = useDirtiness({ scope: document.body });

    const [_, __, deleteAllSelectAndShowURLSearchParams] = useSelectAndShowURLSearchParams();

    // We cannot alter the URLSearchParams while the form is dirty. This is clunky but until we have a better solution, this will have to do.
    React.useEffect(() => {
        if (!isDirty && isFormReset) {
            deleteAllSelectAndShowURLSearchParams();
            setIsFormReset(false);
        }
    }, [deleteAllSelectAndShowURLSearchParams, isDirty, isFormReset, setIsFormReset]);

    if (isFormReset) {
        return <Spinner />;
    }

    return <EsInputTabBase params={params} onFormReset={() => setIsFormReset(true)} />;
};

export const renderEsInputTab = (params: CustomScreenParams, esInputTabId: string): JSX.Element | undefined => {
    const { tabId } = params;

    if (tabId !== esInputTabId) return undefined;

    return <EsInputTab key={params.primaryKeyValue} params={params} />;
};

export default EsInputTab;
