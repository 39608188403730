import React, { ReactElement, useMemo } from 'react';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { BulkAddValuationSteps, getWizardProgress, Steps, ValuationWizardSteps } from './ProgressConstants';

const WizardProgress = ({ isBulk, currentStep }: { currentStep: Steps; isBulk: boolean }): ReactElement => {
    const progress = useMemo(() => getWizardProgress(currentStep), [currentStep]);

    return (
        <ProgressBar
            steps={isBulk ? BulkAddValuationSteps : ValuationWizardSteps}
            currentStep={progress[0]}
            partialProgress={progress[1]}
        />
    );
};

export default WizardProgress;
