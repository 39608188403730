import {
    ViewSet,
    useApplicationContext,
    useEntityEntitlementContext,
    useViewSetsByDomain,
} from '@samc/screen-config-api';
import React from 'react';
import { EsInputMenuOption, useEsInputMenu } from '../../../../valuationApi';
import { getFormViewIdFromViewSet } from './Utils';

export interface EsInputMenuEntitledOptionsParams {
    esInputDataId: string;
}

export const ESINPUTDATA_DOMAIN_ID = 'VALU_ESInputData';
export const ENTITLED_ESINPUT_MENU_KEY = 'entitled_es_input_menu_options';

interface EntitledMenuOptionsResponse {
    isLoading: boolean;
    entitledMenuOptions: EsInputMenuOption[] | undefined;
}

export const useEntitledEsInputMenuOptions = (
    params: EsInputMenuEntitledOptionsParams,
): EntitledMenuOptionsResponse => {
    const { esInputDataId } = params;

    const { data: _menuItems, isLoading: areMenuItemsLoading } = useEsInputMenu(esInputDataId);
    const { data: esInputDataViewsets, isLoading: areEsInputDataViewsetsLoading } =
        useViewSetsByDomain(ESINPUTDATA_DOMAIN_ID);

    const isLoading = areEsInputDataViewsetsLoading || areMenuItemsLoading;

    // we can use the context as we are scoped to the Valuation which is as narrow as it gets (no need to further scope to EsInputData...)
    const { currentEntityEntitlements } = useEntityEntitlementContext();
    const { Entitlements: entitlementNames } = useApplicationContext();

    const esInputDataViewsetMap = React.useMemo(() => {
        if (!esInputDataViewsets) return undefined;

        return esInputDataViewsets.reduce((all, cur) => ({ ...all, [cur.id]: cur }), {} as Record<string, ViewSet>);
    }, [esInputDataViewsets]);

    const entitledMenuOptions = React.useMemo(() => {
        if (!_menuItems || !esInputDataViewsetMap) return undefined;

        const parseOptions = <TIn extends { childItems: EsInputMenuOption[] | null }>(root: TIn): TIn => {
            const outputItems: EsInputMenuOption[] = [];

            // splice in viewSet
            root.childItems?.forEach((item): number | undefined => {
                const itemOut: EsInputMenuOption = parseOptions({ ...item });

                if (itemOut.viewSetId) {
                    const viewSet = esInputDataViewsetMap[itemOut.viewSetId];
                    if (!viewSet) return undefined; // don't add, missing due to not being entitled, likely

                    // stitch in form view id
                    const formViewId = getFormViewIdFromViewSet(viewSet);
                    if (!itemOut.formViewId && formViewId) itemOut.formViewId = formViewId;

                    if (currentEntityEntitlements) {
                        const { tabs } = viewSet;
                        const tabEntitlementNames = tabs.map((tab) =>
                            entitlementNames.GenerateViewSetTabEntitlementName(viewSet.domainId, viewSet.id, tab.id),
                        );

                        if (!tabEntitlementNames.some((ent) => !!currentEntityEntitlements[ent])) return undefined;
                    }
                } else if (!itemOut.formViewId && (!itemOut.childItems || itemOut.childItems.length === 0)) {
                    // if there is no view and no children, the item shouldn't even be persisted
                    return undefined;
                }

                return outputItems.push(itemOut);
            });

            return {
                ...root,
                childItems: outputItems,
            };
        };

        // calling things this way allows recursion
        return parseOptions({ childItems: _menuItems }).childItems;
    }, [_menuItems, currentEntityEntitlements, entitlementNames, esInputDataViewsetMap]);

    return {
        entitledMenuOptions,
        isLoading,
    };
};
