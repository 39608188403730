import React from 'react';
import { TaskRequest, TaskResponse, TaskStatus } from '@samc/screen-config-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { submitTask } from '../api/Requests';
import { useApiContext } from '../../hooks/useApiContext';
import { ValuationWorkflowTask } from '../models/ValuationWorkflowTask';
import { VALUATION_WORKFLOW_TASKS_KEY } from '../useValuationWorkflowTasks/useValuationWorkflowTasks';

interface Props {
    newWorkflowTaskStatusId: string;
    workflowTask: ValuationWorkflowTask;
    name?: string | null;
}

export const useValuationWorkflowTaskStatusIdSaver = (): [
    UseMutationResult<[TaskResponse, TaskRequest], unknown, Props, unknown>,
] => {
    const api = useApiContext();
    const queryClient = useQueryClient();

    const onSave = React.useCallback(
        async ({ name, newWorkflowTaskStatusId, workflowTask }: Props): Promise<[TaskResponse, TaskRequest]> => {
            return submitTask(
                api.TaskApi,
                'VALU_ValuationWorkflowTask',
                'VALU_ValuationWorkflowTask',
                'WorkflowTaskGrid',
                {
                    [String(workflowTask.Id)]: {
                        Id: String(workflowTask.Id),
                        TaskStatusId: String(newWorkflowTaskStatusId),
                        Name: name,
                    },
                },
                api.requestInit ?? {},
                false,
                api.FetchApi,
            );
        },
        [api.FetchApi, api.TaskApi, api.requestInit],
    );

    const saveMutation = useMutation<[TaskResponse, TaskRequest], unknown, Props>(onSave, {
        onSuccess: (data: [TaskResponse, TaskRequest<Record<string, unknown>>]) => {
            const [result] = data;

            switch (result.statusCode) {
                case TaskStatus.Completed:
                    api.ShowMessage(`Task Updated Successfully`, 'success');
                    queryClient.invalidateQueries([VALUATION_WORKFLOW_TASKS_KEY]);
                    break;
                case TaskStatus.Deferred:
                    break;
                case TaskStatus.Failed:
                default:
                    api.ShowMessage(`Failed to Update Task`, 'error');
                    break;
            }
        },
        onError: (e) => {
            throw e;
        },
    });

    return [saveMutation];
};

export default useValuationWorkflowTaskStatusIdSaver;
