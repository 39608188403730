import React, { useEffect, useMemo, useState } from 'react';
import { DomainViewMember, useQBQuery } from '@samc/screen-config-api';
import { ErrorBoundary } from '@samc/react-ui-core';
import { CreateValuations } from './CreateValuations/CreateValuations';
import './AddValuationWizard.scss';
import { Valuation } from '../../static/ValuationConstants';
import { customColumns } from '../../static/ValuationWizard.utils';
import { SetupBulkValuations } from './SetupBulkValuations';
import BulkWizardHead from '../WizardHead/BulkWizardHead';
import { ConfirmMethodologies } from '../ConfirmMethodologies/ConfirmMethodologies';
import { WizardProps } from './BulkValuationWizard.interfaces';
import { getQBData } from './AddValuationWizard.service';
import { MethodologyAndApproachSetup } from './MethodologyAndApproachSetup';
import { SetupType } from './BulkValuationWizard.enums';
import AddContacts from '../AddContacts/AddContacts';
import { BulkValuationSteps, Steps } from '../WizardProgress/ProgressConstants';
import WizardProgress from '../WizardProgress/WizardProgress';

export const AddValuationWizardBulk = ({
    portfolioId,
    assetIdBulk,
    valuationGroupIdBulk,
    filters,
    setWizardisDirty,
}: WizardProps): React.ReactElement => {
    const [currentStep, setCurrentStep] = React.useState<Steps>(BulkValuationSteps.SetupValuations);
    const [assetOrGroupBulk, setAssetOrGroup] = React.useState<SetupType>(SetupType.undefined);
    const [valuation, setValuation] = React.useState<Valuation>({});
    const [enableValGroup, setEnableValGroup] = React.useState<boolean>(false);
    const [portfolioTypeId, setPortfolioTypeId] = React.useState<unknown>();
    const [showModal, toggleModal] = useState(false);
    const [requestSetupDetails, setRequestSetupDetails] = useState<boolean>(false);

    const qbQuery = useQBQuery();

    const adhocListViewMembers: DomainViewMember[] = useMemo(() => [...customColumns], []);

    useEffect((): void => {
        if (portfolioId) {
            getQBData({
                qbQuery,
                domainId: 'POMA_Portfolio',
                advancedExpression: `[Id] = '${portfolioId}'`,
                adhocListViewMembers,
            }).then((response) => {
                if (response && response.Data && response.Data.length === 1) {
                    const isShowValGroup = Boolean(response.Data[0].EnableValuationGroup);
                    const typeCode = response.Data[0].TypeCode;
                    setEnableValGroup(isShowValGroup);
                    setPortfolioTypeId(typeCode);
                }
            });
        }
    }, [adhocListViewMembers, portfolioId, qbQuery]);

    useEffect(() => {
        if (assetIdBulk) {
            setAssetOrGroup(SetupType.ASSETS);
        } else if (valuationGroupIdBulk) {
            setAssetOrGroup(SetupType.VALUATION_GROUP);
        }
    }, [assetIdBulk, valuationGroupIdBulk]);

    const updateAssetOrGroup = (params: SetupType): void => {
        setValuation({});
        setWizardisDirty(true);
        setAssetOrGroup(params);
    };

    return (
        <div className="bulk-add-valuations">
            <ErrorBoundary>
                <BulkWizardHead showModal={showModal} toggleModal={toggleModal} setWizardisDirty={setWizardisDirty} />

                <WizardProgress currentStep={currentStep} isBulk />

                {/* Step 1 */}
                {currentStep === BulkValuationSteps.SetupValuations && (
                    <SetupBulkValuations
                        portfolioTypeId={portfolioTypeId}
                        portfolioId={portfolioId}
                        assetId={assetIdBulk}
                        valuationGroupId={valuationGroupIdBulk}
                        assetOrGroupBulk={assetOrGroupBulk}
                        enableValGroup={enableValGroup}
                        setAssetOrGroup={updateAssetOrGroup}
                        setCurrentStep={setCurrentStep}
                        valuation={valuation}
                        setValuation={setValuation}
                    />
                )}

                {/* Step 2 */}
                {currentStep === BulkValuationSteps.AddContacts && (
                    <AddContacts
                        setCurrentStep={setCurrentStep}
                        isBulk
                        valuation={valuation}
                        setValuation={setValuation}
                    />
                )}

                {/* Step 3 */}
                {currentStep === BulkValuationSteps.SetupMethodologyAndApproach && (
                    <MethodologyAndApproachSetup
                        valuation={valuation}
                        setValuation={setValuation}
                        setCurrentStep={setCurrentStep}
                        setRequestSetupDetails={setRequestSetupDetails}
                        portfolioId={portfolioId}
                        isBulk
                    />
                )}

                {/* Step 3.1 */}
                {currentStep === BulkValuationSteps.ConfirmMethodologies && (
                    <ConfirmMethodologies
                        valuation={valuation}
                        setValuation={setValuation}
                        setCurrentStep={setCurrentStep}
                        requestSetupDetails={requestSetupDetails}
                        setRequestSetupDetails={setRequestSetupDetails}
                        filters={filters}
                        portfolioId={portfolioId ?? ''}
                        isBulk
                    />
                )}

                {/* Step 4 */}
                {currentStep === BulkValuationSteps.CreateValuations && (
                    <CreateValuations
                        valuation={valuation}
                        setCurrentStep={setCurrentStep}
                        setWizardisDirty={setWizardisDirty}
                        isBulk
                    />
                )}
            </ErrorBoundary>
        </div>
    );
};

export default AddValuationWizardBulk;
