/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { SectionHeader } from '@samc/react-ui-form';
import { AssetSearchDropDown } from '../AssetSearchDropDown/AssetSearchDropDown';
import { ValGroupSearchDropDown } from '../ValGroupSearchDropDown/ValGroupSearchDropDown';
import { NavButtons } from './NavButtons';
import { formattedDate } from './AddValuationWizard.service';
import { ValuationType } from './ValuationWizard.enums';
import { ValuationSteps } from '../WizardProgress/ProgressConstants';

interface SelectAssetTypes {
    setValuationAsset: (record?: Record<string, unknown>) => void;
    enableValGroup: unknown;
    valuationAsset?: Record<string | number, unknown>;
    portfolioId: string | null;
    setCurrentStep: (param: ValuationSteps) => void;
}

export const SelectValuationType = ({
    setValuationAsset,
    enableValGroup,
    valuationAsset,
    portfolioId,
    setCurrentStep,
}: SelectAssetTypes): React.ReactElement => {
    const handleValuationType = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked && valuationAsset?.type !== event.target.value) {
            setValuationAsset({ type: event.target.value });
        }
    };

    const handleSetPrimaryKey = (record: Record<string, unknown>): void => {
        setValuationAsset({
            ...record,
            type: valuationAsset?.type,
            // eslint-disable-next-line no-nested-ternary
            RegionalArea: record.RegionalArea ? record.RegionalArea : null,
        });
    };
    const onContinue = (): void => setCurrentStep(ValuationSteps.SelectSetupMethod);

    const selectedAsset = valuationAsset?.Id ? [valuationAsset.Id as string] : undefined;

    const selectedValuationGroup = valuationAsset?.ValuationGroupId
        ? [valuationAsset.ValuationGroupId as string]
        : undefined;

    return (
        <>
            <NavButtons
                isDisabled={valuationAsset === undefined || valuationAsset.primaryKey === undefined}
                onContinue={onContinue}
            />

            <div className="center-box assetorgroup">
                <SectionHeader title="INDICATE WHICH ASSETS WILL BE VALUED" />

                <div className="radio-label-dropdown-box">
                    <input
                        className="mt-5"
                        type="radio"
                        name="Step1"
                        checked={valuationAsset?.type === ValuationType.SingleAsset}
                        value={ValuationType.SingleAsset}
                        id={ValuationType.SingleAsset}
                        onChange={handleValuationType}
                    />
                    <label htmlFor={ValuationType.SingleAsset} className="label">
                        Select a Single Asset
                    </label>
                    <div
                        className="record-dropdown"
                        style={
                            valuationAsset?.type === ValuationType.SingleAsset
                                ? {}
                                : { pointerEvents: 'none', opacity: '0.4' }
                        }
                    >
                        <AssetSearchDropDown
                            domainId="POMA_Asset"
                            onValueChanged={handleSetPrimaryKey}
                            key={
                                valuationAsset?.type === ValuationType.ValuationGroup
                                    ? new Date().getTime()
                                    : 'SINGLE_ASSET'
                            }
                            filters={[`([PortfolioId] = '${portfolioId}')`]}
                            selectedKeys={selectedAsset}
                        />
                    </div>
                </div>
                <div
                    className="radio-label-dropdown-box"
                    style={!enableValGroup ? { pointerEvents: 'none', opacity: '0.4' } : {}}
                >
                    <input
                        className="mt-5"
                        disabled={!enableValGroup}
                        type="radio"
                        name="Step1"
                        value={ValuationType.ValuationGroup}
                        id={ValuationType.ValuationGroup}
                        checked={valuationAsset?.type === ValuationType.ValuationGroup}
                        onChange={handleValuationType}
                    />
                    <label htmlFor={ValuationType.ValuationGroup} className="label">
                        Select a Valuation Group
                    </label>
                    <div
                        className="record-dropdown"
                        style={
                            valuationAsset?.type === ValuationType.ValuationGroup
                                ? {}
                                : { pointerEvents: 'none', opacity: '0.4' }
                        }
                    >
                        <ValGroupSearchDropDown
                            domainId="ValuationGroup"
                            onValueChanged={handleSetPrimaryKey}
                            key={
                                valuationAsset?.type === ValuationType.SingleAsset
                                    ? new Date().getTime()
                                    : 'VALUATION_GROUP'
                            }
                            filters={[
                                `([PortfolioId] = '${portfolioId}') and ([ValuationGroupAssetCount] > 0) and ((ISNULLOREMPTY([ValuationGroupDisabledDate])) or ([ValuationGroupDisabledDate]>'${formattedDate}'))`,
                            ]}
                            selectedKeys={selectedValuationGroup}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelectValuationType;
