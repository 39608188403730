import React from 'react';
import { ApiContextType, ApiContext, QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';
import { DEInvestmentValueAckStatus } from '../models/DEAInvestmentValueAckStatus';

export const DE_INVESTMENT_VALUE_ACK_STATUS_KEY = 'DEAInvestmentValueAck';

export const useDEValueAckStatus = (id: string): UseQueryResult<QueryExecuteResponse<DEInvestmentValueAckStatus>> => {
    const api = React.useContext<ApiContextType>(ApiContext);

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_Valuation',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'DEInvestmentValueAckStatus',
                scalarExpression: '[DEInvestmentValueAckStatus]',
            },
            {
                scalarDisplayName: 'Portfolio_IncludeDebtEquityAnalysis',
                scalarExpression: '[Portfolio_IncludeDebtEquityAnalysis]',
            },
        ],
        sorting: {},
        paging: { start: 0, stop: 50 },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[Id] = '${id}'`,
        },
    };

    const queryResult = useQuery<QueryExecuteResponse<DEInvestmentValueAckStatus>>(
        [DE_INVESTMENT_VALUE_ACK_STATUS_KEY],
        () =>
            executeLiftSiftQuery(
                api.LiftSiftApi,
                requestBody,
                api.requestInit,
                api.ShowMessage,
            ) as unknown as QueryExecuteResponse<DEInvestmentValueAckStatus>,
        { retry: 2, refetchOnWindowFocus: false },
    );
    return queryResult;
};

export default useDEValueAckStatus;
