/* istanbul ignore file */
/* eslint-disable no-console */
import React from 'react';
import { HttpTransportType, HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { useToken } from '@samc/single-spa-authentication';
import { useApiContext } from '../useApiContext';

export const useValuationHub = (): [boolean, HubConnection] => {
    const { requestInit, tenantName, ValuationApi } = useApiContext();
    const token = useToken();

    const [isConnected, setIsConnected] = React.useState(false);
    const [connection] = React.useState(
        new HubConnectionBuilder()
            .withUrl(`${ValuationApi}/hubs/v1/ValuationTask?tenant=${tenantName}`, {
                accessTokenFactory: () => token,
                headers: requestInit?.headers,
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            } as IHttpConnectionOptions)
            .withAutomaticReconnect()
            .build(),
    );

    React.useEffect(() => {
        connection.start().then(
            () => {
                setIsConnected(true);
                console.info('Connected to ValuationHub');
            },
            () => console.error('Failed to connect to ValuationHub'),
        );

        return () => {
            connection.stop().then(
                () => {
                    setIsConnected(false);
                    console.info('Disconnected from ValuationHub');
                },
                () => console.error('Failed to stop connection to ValuationHub'),
            );
        };
    }, [connection]);

    return [isConnected, connection];
};

export default useValuationHub;
