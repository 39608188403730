import React from 'react';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { useSelectAndShowURLSearchParams } from '@samc/screen-config-core/lib/hooks/useSelectAndShowURLSearchParams/useSelectAndShowURLSearchParams';
import { Spinner } from '@fluentui/react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { DEATab as DEATabBase } from './DEATab.FormScreen';
import { useApplicationContext } from '../../../hooks/useApplicationContext/useApplicationContext';
import { useEsInputDataByValuationAsset } from '../../../valuationApi/useEsInputDataByValuationAsset/useEsInputDataByValuationAsset';
import { useValuationAssetMethodologySetupData } from '../../../valuationApi/useValuationAssetMethodologySetupData/useValuationAssetMethodologySetupData';

interface Props {
    params: CustomScreenParams;
}

export const DEATab = (props: Props): JSX.Element => {
    const { params } = props;

    const { AssetDataDomainId, DeaDataDomainId, EsInputDataDomainId, ValuationAssetMethodologySetupDataDomainId } =
        useApplicationContext();

    const [getSelectAndShowValue, setSelectAndShowValue] = useSelectAndShowURLSearchParams();

    const assetDataId = getSelectAndShowValue(AssetDataDomainId);
    const deaDataId = getSelectAndShowValue(DeaDataDomainId);
    const esInputDataId = getSelectAndShowValue(EsInputDataDomainId);
    const valuationAssetMethodologyDataId = getSelectAndShowValue(ValuationAssetMethodologySetupDataDomainId);

    const esInputDataQueryResult = useEsInputDataByValuationAsset(deaDataId, true);
    const valuationAssetMethodologySetupDataQueryResult = useValuationAssetMethodologySetupData(deaDataId, true);

    if (esInputDataQueryResult.isLoading || valuationAssetMethodologySetupDataQueryResult.isLoading) {
        return <Spinner />;
    }

    if (esInputDataQueryResult.isError) {
        return <MessageBar messageBarType={MessageBarType.error} text="Failed to fetch ESInput." />;
    }

    if (valuationAssetMethodologySetupDataQueryResult.isError) {
        return <MessageBar messageBarType={MessageBarType.error} text="Failed to fetch ValuationAssetMethodology." />;
    }

    const assetDataIdFromQuery = esInputDataQueryResult.data?.Data[0]?.AssetId as string | undefined;
    const esInputDataIdFromQuery = esInputDataQueryResult.data?.Data[0]?.Id as string | undefined;
    const valuationAssetMethodologySetupDataIdFromQuery = valuationAssetMethodologySetupDataQueryResult.data?.Data[0]
        ?.Id as string | undefined;

    if (esInputDataIdFromQuery && esInputDataId !== esInputDataIdFromQuery) {
        setSelectAndShowValue(EsInputDataDomainId, esInputDataIdFromQuery);
    }

    if (assetDataIdFromQuery && assetDataId !== assetDataIdFromQuery) {
        setSelectAndShowValue(AssetDataDomainId, assetDataIdFromQuery);
    }

    if (
        valuationAssetMethodologySetupDataIdFromQuery &&
        valuationAssetMethodologyDataId !== valuationAssetMethodologySetupDataIdFromQuery
    ) {
        setSelectAndShowValue(
            ValuationAssetMethodologySetupDataDomainId,
            valuationAssetMethodologySetupDataIdFromQuery,
        );
    }

    return <DEATabBase params={params} valuationAssetId={deaDataId} />;
};

export const renderDEATab = (params: CustomScreenParams, deaTabId: string): JSX.Element | undefined => {
    const { tabId } = params;

    if (tabId !== deaTabId) return undefined;

    return <DEATab key={`VALU_DEATab_${params.primaryKeyValue}`} params={params} />;
};

export default DEATab;
