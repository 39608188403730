/* istanbul ignore file */
import React from 'react';
import {
    Field,
    FieldLabel,
    FieldRoot,
    FormSkeleton,
    InputType,
    SectionHeader,
    useFormManagementContext as _useFormManagementContext,
} from '@samc/react-ui-form';
import { styled } from 'styletron-react';
import { WorkflowEditContactGridAssignTo } from '../WorkflowEditContactGridAssignTo/WorkflowEditContactGridAssignTo';
import { ValuationContact } from '../../../valuationApi';

const formConfig: FormSkeleton = {
    title: 'Workflow Task Reassign Form',
    fields: {
        name: {
            field: 'Name',
            inputType: InputType.Text,
            editableRule: 'false',
            displayNameRule: "'Task Name'",
        },
        description: {
            field: 'Description',
            inputType: InputType.MultilineText,
            editableRule: 'false',
            displayNameRule: "'Description'",
        },
    },
    gridFields: [],
    tabs: [],
};

/* istanbul ignore next */
const StyledFlexWrapper = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
}));

/* istanbul ignore next */
const StyledFieldWrapper = styled('div', () => ({
    borderTop: '1px solid #d1d1d1',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    paddingTop: '10px',
}));

/* istanbul ignore next */
const StyledFieldLabelWrapper = styled('div', () => ({
    minWidth: '30ch',
    maxWidth: '30ch',
    display: 'flex',
    overflow: 'hidden',
    paddingRight: '4px',
}));

/* istanbul ignore next */
const StyledGridWrapper = styled('div', () => ({
    flex: '1 0 auto',
}));

/* istanbul ignore next */
const StyledFieldRoot = styled(FieldRoot, () => ({
    borderTop: '1px solid #d1d1d1',
    flex: '1',
    marginTop: '5px',
    maxWidth: '100%',
    minWidth: '0px',
    paddingTop: '10px',
    width: '100%',
}));

/* istanbul ignore next */
const StyledFieldLabel = styled(FieldLabel, () => ({
    marginTop: 'unset',
}));

/* istanbul ignore next */
const StyledSectionWrapper = styled('div', () => ({
    marginTop: '5px',
    paddingTop: '10px',
}));

interface Props {
    /**
     * The Id of the Valuation for which ValuationContacts will be fetched.
     */
    valuationId: string;
    /**
     * The id of the ValuationWorkflowTask for which to fetch ValuationWorkflowTaskOwners.
     */
    workflowTaskId: string;

    /**
     * Callback on contact deselected.
     */
    onContactAssignedToDeselected?: (contact?: ValuationContact) => void;
    /**
     * Callback on contact selected.
     */
    onContactAssignedToSelected?: (contact?: ValuationContact) => void;
    /**
     * DI override of the hook to get the FormApi.
     */
    useFormManagementContext?: typeof _useFormManagementContext;
}

export const WorkflowTaskReassignForm = ({
    useFormManagementContext = _useFormManagementContext,
    onContactAssignedToDeselected,
    onContactAssignedToSelected,
    valuationId,
    workflowTaskId,
}: Props): React.ReactElement => {
    const { setConfig } = useFormManagementContext();

    React.useEffect(() => {
        setConfig(formConfig);
    }, [setConfig]);

    return (
        <StyledFlexWrapper>
            <StyledSectionWrapper>
                <SectionHeader title="Task Information" />
                <StyledFieldWrapper>
                    <Field Id="name" />
                    <Field Id="description" />
                </StyledFieldWrapper>
            </StyledSectionWrapper>
            <StyledSectionWrapper>
                <SectionHeader title="Responsible Valuation Contact" />
                <StyledFieldRoot>
                    <StyledFieldLabelWrapper>
                        <StyledFieldLabel labelText="Assign To" />
                    </StyledFieldLabelWrapper>
                    <StyledGridWrapper>
                        <WorkflowEditContactGridAssignTo
                            onContactDeselected={onContactAssignedToDeselected}
                            onContactSelected={onContactAssignedToSelected}
                            valuationId={valuationId}
                            workflowTaskId={workflowTaskId}
                        />
                    </StyledGridWrapper>
                </StyledFieldRoot>
            </StyledSectionWrapper>
        </StyledFlexWrapper>
    );
};

export default WorkflowTaskReassignForm;
