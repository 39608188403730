import React from 'react';
import { useRequestBase, RequestResult } from '@samc/react-ui-request';
import { QueryExecuteRequest, QueryExecuteResponse, useQBQuery } from '@samc/screen-config-api';
import { ApiContext } from '../../contexts/ApiContext/ApiContext';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';

export const VALUATION_WORKFLOW_TEMPLATE_TASK_STATUSES_KEY = 'ValuationWorkflowTemplateTaskStatuses';

export const useValuationWorkflowTemplateTaskStatuses = (
    workflowTemplateTaskIds?: string[],
): RequestResult<QueryExecuteResponse> => {
    const qbQuery = useQBQuery();
    const { SystemExecuteMaxRecordsToReturn } = useApplicationContext();

    const QueryExecuteRequestBuilder = React.useCallback((templateTaskIds?: string[]): QueryExecuteRequest => {
        if (!templateTaskIds) return {} as QueryExecuteRequest;

        return {
            domainId: 'VALU_ValuationWorkflowTemplateTaskStatus',
            primaryViewId: '-1',
            adhocListViewMembers: [
                {
                    scalarDisplayName: 'Id',
                    scalarExpression: '[Id]',
                },
                {
                    scalarDisplayName: 'IsActionable',
                    scalarExpression: '[IsActionable]',
                },
                {
                    scalarDisplayName: 'WorkflowTemplateTaskId',
                    scalarExpression: '[WorkflowTemplateTaskId]',
                },
                {
                    scalarDisplayName: 'WorkflowTaskStatusId',
                    scalarExpression: '[WorkflowTaskStatusId]',
                },
            ],
            sorting: {
                orderBy: {
                    scalarExpression: '[Id]',
                },
                order: 'asc',
            },
            paging: { start: 0, stop: SystemExecuteMaxRecordsToReturn },
            summaryMode: false,
            adhocFilter: {
                filterName: 'adhoc',
                advancedInd: true,
                expressionLang: 'Centric',
                advancedExpression: `[WorkflowTemplateTaskId] = '${templateTaskIds.join(
                    `' OR [WorkflowTemplateTaskId] = '`,
                )}'`,
            },
        };
    }, []);

    const result = useRequestBase({
        key: VALUATION_WORKFLOW_TEMPLATE_TASK_STATUSES_KEY,
        query: qbQuery,
        params: [QueryExecuteRequestBuilder(workflowTemplateTaskIds)],
        requestContext: ApiContext,
        options: {
            enabled: !!workflowTemplateTaskIds,
        },
    });

    return result;
};

export default useValuationWorkflowTemplateTaskStatuses;
