import { QueryExecuteRequest, QueryExecuteResponse, useQBQuery } from '@samc/screen-config-api';
import React, { useEffect, useState, useCallback } from 'react';
import { Valuation } from '../../static/ValuationConstants';

export interface Props {
    domainId: string;
    primaryViewId: string | undefined;
    valuationsIds: string | null;
}

interface GetQBDataParamTypes {
    qbQuery: (params: QueryExecuteRequest) => Promise<QueryExecuteResponse>;
    ids: string | undefined;
    valuationsIds: string | null;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useQBQueryToGetDomain = (props: Props) => {
    const { domainId, primaryViewId, valuationsIds } = props;
    const qbQuery = useQBQuery();
    const valuationIdsArray = valuationsIds?.split(',');

    const ids = React.useMemo(() => valuationIdsArray?.map((x) => `'${x}' `).join(','), [valuationIdsArray]);
    const adhocValuationViewMembersConfig = React.useMemo(
        () => [
            {
                scalarExpression: '[ValuationName]',
                scalarDisplayName: 'ValuationName',
            },
            {
                scalarExpression: '[ValuationAssetsId]',
                scalarDisplayName: 'ValuationAssetsId',
            },
            {
                scalarExpression: '[ValuationGroupId]',
                scalarDisplayName: 'ValuationGroupId',
            },
            {
                scalarExpression: '[ValuationMonth]',
                scalarDisplayName: 'ValuationMonth',
            },
            {
                scalarExpression: '[ValuationYear]',
                scalarDisplayName: 'ValuationYear',
            },
            {
                scalarExpression: '[ValuationQuarter]',
                scalarDisplayName: 'ValuationQuarter',
            },
            {
                scalarExpression: '[AssetId]',
                scalarDisplayName: 'AssetId',
            },
            {
                scalarExpression: '[ValuationScopeId]',
                scalarDisplayName: 'ValuationScopeId',
            },
            {
                scalarExpression: '[ValuationWorkflowTemplateId]',
                scalarDisplayName: 'ValuationWorkflowTemplateId',
            },
            {
                scalarExpression: '[ValuationReportTypeId]',
                scalarDisplayName: 'ValuationReportTypeId',
            },
            {
                scalarExpression: '[ValuationSourceTypeId]',
                scalarDisplayName: 'ValuationSourceTypeId',
            },
            {
                scalarExpression: '[ValuationPeriodTypeId]',
                scalarDisplayName: 'ValuationPeriodTypeId',
            },
            {
                scalarExpression: '[ValuationPeriod]',
                scalarDisplayName: 'ValuationPeriod',
            },
            {
                scalarExpression: '[ValuationScopeId]',
                scalarDisplayName: 'ValuationScopeId',
            },
            {
                scalarExpression: '[ValuationDraftDueDate]',
                scalarDisplayName: 'ValuationDraftDueDate',
            },
            {
                scalarExpression: '[ValuationDateofValue]',
                scalarDisplayName: 'ValuationDateofValue',
            },
            {
                scalarExpression: '[ValuationEngagementDate]',
                scalarDisplayName: 'ValuationEngagementDate',
            },
            {
                scalarExpression: '[ExceptionMade]',
                scalarDisplayName: 'ExceptionMade',
            },
            {
                scalarExpression: '[ExceptionReason]',
                scalarDisplayName: 'ExceptionReason',
            },
            {
                scalarExpression: '[NoOfAsset]',
                scalarDisplayName: 'NoOfAsset',
            },
            {
                scalarExpression: '[ExceptionReason]',
                scalarDisplayName: 'ExceptionReason',
            },
            {
                scalarExpression: '[ExceptionReason]',
                scalarDisplayName: 'ExceptionReason',
            },
            {
                scalarExpression: '[Asset_Group]',
                scalarDisplayName: 'Asset_Group',
            },
            {
                scalarExpression: '[ValuationFinalDuedate]',
                scalarDisplayName: 'ValuationFinalDuedate',
            },
            {
                scalarExpression: '[AppraisalSelectionRationale]',
                scalarDisplayName: 'AppraisalSelectionRationale',
            },
            {
                scalarExpression: '[ExcludeValuationFromReporting]',
                scalarDisplayName: 'ExcludeValuationFromReporting',
            },
            {
                scalarExpression: '[PortfolioId]',
                scalarDisplayName: 'PortfolioId',
            },
            {
                scalarExpression: '[PortfolioTypeId]',
                scalarDisplayName: 'PortfolioTypeId',
            },
            {
                scalarExpression: '[StatusId]',
                scalarDisplayName: 'StatusId',
            },
            {
                scalarExpression: '[ValuationRegion]',
                scalarDisplayName: 'ValuationRegion',
            },
            {
                scalarExpression: '[InvestmentIds]',
                scalarDisplayName: 'InvestmentIds',
            },
            {
                scalarExpression: '[FundIds]',
                scalarDisplayName: 'FundIds',
            },
            {
                scalarExpression: '[Id]',
                scalarDisplayName: 'Id',
            },
        ],
        [],
    );

    const [portfolioTypeId, setportfolioTypeId] = useState('');
    const [qbQueryData, setQbQueryData] = useState<Valuation>();

    const getQBData = useCallback(
        ({
            qbQuery: _qbQuery,
            ids: _ids,
            valuationsIds: _valuationsIds,
        }: GetQBDataParamTypes): Promise<QueryExecuteResponse> => {
            return _qbQuery({
                domainId,
                primaryViewId,
                filterIds: [],
                paging: { start: 0, stop: 1000 },
                adhocListViewMembers: adhocValuationViewMembersConfig,
                sorting: {},
                summaryMode: false,
                adhocFilter: _valuationsIds
                    ? {
                          filterName: 'adhoc',
                          advancedInd: true,
                          expressionLang: 'Centric',
                          advancedExpression: `([Id] IN (${_ids}))`,
                      }
                    : undefined,
            });
        },
        [adhocValuationViewMembersConfig, domainId, primaryViewId],
    );

    useEffect(() => {
        getQBData({
            qbQuery,
            ids,
            valuationsIds,
        }).then((response) => {
            const _portfolioTypeId = response.Data[0].PortfolioTypeId;
            setportfolioTypeId(_portfolioTypeId as string);
            setQbQueryData({
                addTasks: [...response.Data],
            });
        });
    }, [getQBData, ids, qbQuery, valuationsIds]);

    return { qbQueryData, portfolioTypeId };
};

export default useQBQueryToGetDomain;
