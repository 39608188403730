import * as React from 'react';
import {
    GridFieldConfiguration,
    ConvertGridFilterToFilterExpression,
    GridDropdown,
    GridDropdownOptionGetter,
    GridDropdownOption,
    GridDropdownSelectionChangedEvent,
} from '@samc/react-ui-grid';
import { useDomain, AdHocFilter } from '@samc/screen-config-api';
import QueryExecuteManager, {
    ManagerRenderProps,
    QueryExecuteFetchHandler,
} from '@samc/screen-config-core/lib/molecules/QueryExecuteManager/QueryExecuteManager';
import { BaseDropdownOption } from '@samc/react-ui-core';

export interface RecordDropdownProps {
    domainId: string;
    onValueChanged: (record: Record<string, unknown>) => void;
    filters?: string[];
    initialSelectedKeys?: string[];
}

export const WorkflowDropDown = (props: RecordDropdownProps): React.ReactElement => {
    const { domainId, onValueChanged, filters, initialSelectedKeys } = props;

    const [selectedKeys, setSelectedKeys] = React.useState(initialSelectedKeys);

    const domain = useDomain(domainId);
    const primaryKey = React.useMemo(() => domain.data?.primaryKey ?? '', [domain.data]);
    const adhocListViewMembers = React.useMemo(
        () => [
            {
                scalarExpression: `[Id]`,
                scalarDisplayName: 'Id',
            },
            {
                scalarExpression: `[Name]`,
                scalarDisplayName: 'Name',
            },
            {
                scalarExpression: `[Description]`,
                scalarDisplayName: 'Description',
            },
        ],
        [],
    );
    const columns: GridFieldConfiguration[] = [
        { field: 'Name', headerName: 'Template Name' },
        { field: 'Description', headerName: 'Description' },
    ];
    const gridFields = columns;

    const handleChange = (ev: GridDropdownSelectionChangedEvent<string, unknown>): void => {
        const { selectedOptions } = ev;
        const selectedOption = selectedOptions.at(0); // single select

        const { data } = selectedOption ?? {};
        if (!data) return;

        onValueChanged(data as Record<string, unknown>);
    };

    const getSelectionResolver =
        (onFetch: QueryExecuteFetchHandler) =>
        async (ids: string[]): Promise<BaseDropdownOption<string, unknown>[]> => {
            const [{ Data }] = await onFetch(
                false,
                {
                    start: 0,
                    stop: ids.length,
                },
                {
                    filterName: 'Adhoc',
                    expressionLang: 'Centric',
                    advancedInd: true,
                    advancedExpression: `LOWER([Id]) IN (${ids
                        .map((id) =>
                            typeof id !== 'string' ? `'${id}'` : `'${id.replace("'", "'+ CHAR(39)+'").toLowerCase()}'`,
                        )
                        .join(',')})`,
                },
            );

            return Data.map(
                (d): BaseDropdownOption<string, unknown> => ({
                    id: String(d.Id),
                    displayText: String(d.Name),
                    data: d,
                }),
            );
        };

    const getItemFetcher =
        (onFetch: QueryExecuteFetchHandler): GridDropdownOptionGetter<string, unknown> =>
        async (params) => {
            const { filter, offset } = params;
            const { filterModel, limit, sortModel } = filter ?? {};
            const { sort: order, colId: orderBy } = sortModel?.at(0) ?? {};

            const start = offset ?? 0;
            const stop = start + (limit ?? 100);

            let adhocFilter: AdHocFilter | undefined;
            if (filterModel != null && Object.keys(filterModel).length > 0) {
                adhocFilter = {
                    filterName: 'Adhoc',
                    expressionLang: 'Centric',
                    advancedExpression: ConvertGridFilterToFilterExpression(filterModel as never, gridFields),
                    advancedInd: true,
                };
            }

            const [{ Data, TotalRecords }] = await onFetch(
                false,
                {
                    start,
                    stop,
                    order,
                    orderBy,
                },
                adhocFilter,
            );

            return {
                totalCount: TotalRecords,
                options: Data.map(
                    (d): GridDropdownOption<string, unknown> => ({
                        id: String(d.Id),
                        displayText: String(d.Name),
                        data: d,
                    }),
                ),
            };
        };

    // keep selected keys up-to-date
    React.useEffect(() => {
        setSelectedKeys(initialSelectedKeys);
    }, [initialSelectedKeys]);

    if (primaryKey === '' || adhocListViewMembers.length === 0) {
        return <></>;
    }

    return (
        <QueryExecuteManager domainId={domainId} adhocListViewMembers={adhocListViewMembers} filters={filters}>
            {(queryRenderProps: ManagerRenderProps): React.ReactElement => (
                <div className="screen-config-record-dropdown">
                    <GridDropdown
                        selectedKeys={selectedKeys}
                        fields={gridFields}
                        getOptions={getItemFetcher(queryRenderProps.doFetch)}
                        onChange={handleChange}
                        placeholder=""
                        style={{ width: '800px', height: '300px' }}
                        autoHeight={false}
                        multiSelect={false}
                        selectRowOnClick
                        resolveSelection={getSelectionResolver(queryRenderProps.doFetch)}
                    />
                </div>
            )}
        </QueryExecuteManager>
    );
};

export default WorkflowDropDown;
