/* eslint-disable camelcase */
import { ChangeDataArgs } from '@samc/react-ui-grid';
import { DomainViewMember, QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import {
    AddTask,
    AssetMethodology,
    Valuation,
    ValuationAssetMethodologiesVM,
    ValuationAssetMethodologySetupVM,
    ValuationAssetOtherDataScreensVM,
    ValuationAssetOtherValuesVM,
    ValuationAssetVM,
    ValuationGroupAsset,
} from '../../static/ValuationConstants';
import { apiProvider } from '../../../valuationApi';
import { CreateCombinationName, createGuid, GetValGroupAssetColumns } from '../../static/ValuationWizard.utils';
import { ValuationType } from './ValuationWizard.enums';

export const getDateString = (date: unknown): string | null => (date ? new Date(date as string).toISOString() : null);

export const getGridObjectFromList = <
    T extends ValuationAssetMethodologiesVM | ValuationAssetOtherDataScreensVM | ValuationAssetOtherValuesVM,
>(
    list: T[],
    idField: string,
): Record<string, unknown> =>
    list?.length ? list.reduce((acc, item) => ({ ...acc, [`${item[idField]}`]: item }), {}) : {};

/**
 * deprecated
 */
export const removeDeleted = <
    T extends ValuationAssetMethodologiesVM | ValuationAssetOtherDataScreensVM | ValuationAssetOtherValuesVM,
>(
    data: T[],
): T[] => data.filter(({ __DELETED__ = false }) => !__DELETED__);

export const fetchMethodologies = async (
    valuationData: Valuation,
    ValuationApi: string,
    requestInit: RequestInit,
): Promise<Valuation> => {
    const valuation: Valuation = { ...valuationData };

    if (valuation.addTasks && valuation.addTasks.length) {
        const requestData: Record<string, unknown>[] = [];

        valuation.addTasks.forEach(({ ValuationAssetMethodologySetup }) => {
            if (ValuationAssetMethodologySetup) {
                ValuationAssetMethodologySetup.forEach(
                    ({
                        AssetId = null,
                        AllAsset_CopyFromPriorValuationId = null,
                        MethodologyTemplateId = null,
                        ValuationName = null,
                        ValuationId = null,
                    }) => {
                        requestData.push({
                            AssetId,
                            VCAComparison_ValuationAssetId: AllAsset_CopyFromPriorValuationId,
                            MethodologyTemplateId,
                            ValuationName,
                            ValuationId,
                        });
                    },
                );
            }
        });

        valuation.addTasks.forEach((task) => {
            task.ValuationAssetMethodologySetup?.forEach((vams) => {
                /* eslint-disable no-param-reassign */
                vams.ValuationAssetMethodologies = [];
                vams.ValuationAssetOtherDataScreens = [];
                vams.ValuationAssetOtherValues = [];
                /* eslint-enable no-param-reassign */
            });
        });

        const methodologyAsset = await apiProvider.postData<AssetMethodology[]>(
            ValuationApi,
            `/api/ValuationWizard/GetMethodologySetupDetails`,
            requestData,
            requestInit ?? {},
        );

        if (methodologyAsset && methodologyAsset.length > 0) {
            for (let index1 = 0; index1 < methodologyAsset.length; index1++) {
                const objData1: AssetMethodology = methodologyAsset[index1];

                for (let index2 = 0; index2 < valuation.addTasks.length; index2++) {
                    const objTask1 = valuation.addTasks[index2];

                    if (objTask1.ValuationAssetMethodologySetup) {
                        const foundIndex = objTask1.ValuationAssetMethodologySetup.findIndex(
                            (obj) =>
                                obj.AssetId?.toLowerCase() === objData1.assetId?.toLowerCase() &&
                                obj.ValuationName === objData1.valuationName &&
                                obj.ValuationId?.toLowerCase() === objData1.valuationId?.toLowerCase(),
                        );

                        if (foundIndex > -1) {
                            const valuationAssetMethodologiesVMList: ValuationAssetMethodologiesVM[] = [];
                            const valuationAssetOtherDataScreensVMList: ValuationAssetOtherDataScreensVM[] = [];
                            const valuationAssetOtherValuesVMList: ValuationAssetOtherValuesVM[] = [];

                            for (let index3 = 0; index3 < objData1.listValuationAssetMethodologiesVM.length; index3++) {
                                const valuationAssetMethodologiesVM =
                                    objData1.listValuationAssetMethodologiesVM[index3];

                                let isEnableforVCA = false;
                                let approach = 'Cost';

                                if (valuationAssetMethodologiesVM.methodologyTypeId) {
                                    if (
                                        valuationAssetMethodologiesVM.methodologyTypeId === 'DCF' ||
                                        valuationAssetMethodologiesVM.methodologyTypeId === 'DirectCap' ||
                                        valuationAssetMethodologiesVM.methodologyTypeId === 'TradVal'
                                    ) {
                                        isEnableforVCA = true;
                                    }

                                    if (
                                        valuationAssetMethodologiesVM.methodologyTypeId === 'DCF' ||
                                        valuationAssetMethodologiesVM.methodologyTypeId === 'DirectCap' ||
                                        valuationAssetMethodologiesVM.methodologyTypeId === 'CapMethod' ||
                                        valuationAssetMethodologiesVM.methodologyTypeId === 'LandResidual' ||
                                        valuationAssetMethodologiesVM.methodologyTypeId === 'StudentHousing' ||
                                        valuationAssetMethodologiesVM.methodologyTypeId === 'TradVal'
                                    ) {
                                        approach = 'Income';
                                    }

                                    if (
                                        valuationAssetMethodologiesVM.methodologyTypeId === 'CompMethod' ||
                                        valuationAssetMethodologiesVM.methodologyTypeId === 'SalesComp'
                                    ) {
                                        approach = 'Sales';
                                    }
                                }

                                valuationAssetMethodologiesVMList.push({
                                    RegionalArea: objTask1.ValuationAssetMethodologySetup[foundIndex].RegionalArea,
                                    IsEditable: true,
                                    Approach: approach,
                                    AssetId: valuationAssetMethodologiesVM.assetId,
                                    Primary: valuationAssetMethodologiesVM.primary,
                                    MethodologyTypeId: valuationAssetMethodologiesVM.methodologyTypeId,
                                    MethodologyScenarioTypeId: valuationAssetMethodologiesVM.methodologyScenarioTypeId,
                                    MethodologyValueTypeId: valuationAssetMethodologiesVM.methodologyValueTypeId,
                                    ValueTypeDescription: valuationAssetMethodologiesVM.valueTypeDescription,
                                    InterestId: valuationAssetMethodologiesVM.interestId,
                                    CombinationName: CreateCombinationName(
                                        valuationAssetMethodologiesVM.methodologyTypeId
                                            ? valuationAssetMethodologiesVM.methodologyTypeId
                                            : '',
                                        valuationAssetMethodologiesVM.methodologyScenarioTypeId
                                            ? valuationAssetMethodologiesVM.methodologyScenarioTypeId
                                            : '',
                                        valuationAssetMethodologiesVM.methodologyValueTypeId
                                            ? valuationAssetMethodologiesVM.methodologyValueTypeId
                                            : '',
                                        valuationAssetMethodologiesVM.interestId
                                            ? valuationAssetMethodologiesVM.interestId
                                            : '',
                                        valuationAssetMethodologiesVM.valueTypeDescription
                                            ? valuationAssetMethodologiesVM.valueTypeDescription
                                            : '',
                                    ),
                                    VAMethodologiesId: createGuid(),
                                    IsEnableforVCA: isEnableforVCA,
                                    UseforReporting: valuationAssetMethodologiesVM.useforReporting,
                                    UseforAsCompleteValue: valuationAssetMethodologiesVM.useforAsCompleteValue,
                                    UseForStabilizedValue: valuationAssetMethodologiesVM.useForStabilizedValue,
                                });
                            }

                            if (valuationAssetMethodologiesVMList && valuationAssetMethodologiesVMList.length > 0) {
                                objTask1.ValuationAssetMethodologySetup[foundIndex].ValuationAssetMethodologies = [
                                    ...valuationAssetMethodologiesVMList,
                                ];
                            } else {
                                objTask1.ValuationAssetMethodologySetup[foundIndex].ValuationAssetMethodologies = [];
                            }

                            for (
                                let index4 = 0;
                                index4 < objData1.listValuationAssetOtherDataScreensVM.length;
                                index4++
                            ) {
                                const valuationAssetOtherDataScreensVM =
                                    objData1.listValuationAssetOtherDataScreensVM[index4];
                                valuationAssetOtherDataScreensVMList.push({
                                    RegionalArea: objTask1.ValuationAssetMethodologySetup[foundIndex].RegionalArea,
                                    IsEditable: true,
                                    AssetId: valuationAssetOtherDataScreensVM.assetId,
                                    OtherDataInputScreensTypeId:
                                        valuationAssetOtherDataScreensVM.otherDataInputScreensTypeId,
                                    VAOtherDataScreensId: createGuid(),
                                });
                            }

                            if (
                                valuationAssetOtherDataScreensVMList &&
                                valuationAssetOtherDataScreensVMList.length > 0
                            ) {
                                objTask1.ValuationAssetMethodologySetup[foundIndex].ValuationAssetOtherDataScreens = [
                                    ...valuationAssetOtherDataScreensVMList,
                                ];
                            } else {
                                objTask1.ValuationAssetMethodologySetup[foundIndex].ValuationAssetOtherDataScreens = [];
                            }

                            for (let index5 = 0; index5 < objData1.listValuationAssetOtherValuesVM.length; index5++) {
                                const valuationAssetOtherValuesVM = objData1.listValuationAssetOtherValuesVM[index5];
                                valuationAssetOtherValuesVMList.push({
                                    RegionalArea: objTask1.ValuationAssetMethodologySetup[foundIndex].RegionalArea,
                                    IsEditable: true,
                                    AssetId: valuationAssetOtherValuesVM.assetId,
                                    DateOfValueEntry: valuationAssetOtherValuesVM.dateOfValueEntry,
                                    MethodologyValueTypeId: valuationAssetOtherValuesVM.methodologyValueTypeId,
                                    Description: valuationAssetOtherValuesVM.description,
                                    VAOtherValuesId: createGuid(),
                                });
                            }

                            if (valuationAssetOtherValuesVMList && valuationAssetOtherValuesVMList.length > 0) {
                                objTask1.ValuationAssetMethodologySetup[foundIndex].ValuationAssetOtherValues = [
                                    ...valuationAssetOtherValuesVMList,
                                ];
                            } else {
                                objTask1.ValuationAssetMethodologySetup[foundIndex].ValuationAssetOtherValues = [];
                            }
                        }
                    }
                }
            }
        }
    }

    return valuation;
};

export const updateGridRow = (
    { cellValueChangedEvent }: ChangeDataArgs<Record<string, unknown>>,
    id: string,
    gridData: Record<string, unknown>[],
    idField: string,
): void => {
    if (cellValueChangedEvent) {
        const { node } = cellValueChangedEvent;
        node.setData(gridData.find((m): boolean => m[idField] === id));
    }
};

export const formattedDate = new Date()
    .toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    })
    .replace(/ /g, '-');

interface GetQBDataParamTypes {
    qbQuery: (params: QueryExecuteRequest, includeLimitedAccessRecords?: boolean) => Promise<QueryExecuteResponse>;
    domainId: string;
    advancedExpression: string;
    adhocListViewMembers?: DomainViewMember[];
    stop?: number;
    includeLimitedAccessRecords?: boolean;
}

export const getQBData = ({
    qbQuery,
    domainId,
    advancedExpression,
    adhocListViewMembers,
    stop = 1,
    includeLimitedAccessRecords,
}: GetQBDataParamTypes): Promise<QueryExecuteResponse> =>
    qbQuery(
        {
            domainId,
            primaryViewId: '-1',
            filterIds: [],
            adhocListViewMembers,
            paging: {
                start: 0,
                stop,
            },
            sorting: {},
            summaryMode: false,
            adhocFilter: {
                filterName: 'adhoc',
                advancedInd: true,
                expressionLang: 'Centric',
                advancedExpression,
            },
        },
        includeLimitedAccessRecords,
    );

export const getValuationsFromGridData = ({
    gridData,
    portfolioId,
    enableValGroup,
    assetOrGroup,
    portfolioTypeId,
    valuationMethod,
    covValuationMethod,
    valuationGroupAssets,
}: {
    gridData: Record<string, unknown>[];
    enableValGroup: boolean;
    portfolioId: string | null;
    assetOrGroup: Record<string, unknown> | undefined;
    portfolioTypeId: string;
    valuationMethod: string;
    covValuationMethod: string;
    CreatedBy: string;
    valuationGroupAssets: ValuationGroupAsset[];
}): Valuation => {
    const addTasks: AddTask[] = [];

    gridData.forEach((task): void => {
        let fundIds = '';
        let investmentIds = '';

        const newTask: Record<string, unknown> = {
            ...task,
            ...assetOrGroup,
            EnableValGroup: enableValGroup,
            ValuationAssetsId: assetOrGroup?.Id ? String(assetOrGroup?.Id) : null,
            AssetsName: (assetOrGroup?.Name as string) || '',
            ValuationGroupName: (assetOrGroup?.ValuationGroupName as string) || '',
            AssetId: assetOrGroup?.Id ? String(assetOrGroup?.Id) : null,
            ValuationGroupId: assetOrGroup?.ValuationGroupId ? String(assetOrGroup.ValuationGroupId) : null,
            ValuationRegion: assetOrGroup?.RegionalArea ? assetOrGroup?.RegionalArea : null,
            PortfoliosId: String(portfolioId),
            PortfolioId: String(portfolioId),
            Portfolio_Id: String(portfolioId),
            COVValuationMethod: covValuationMethod,
            ValuationMethod: valuationMethod,
        };

        const valuationAssetValuationGroupVMList: ValuationAssetVM[] = [];
        const valuationAssetMethodologySetupVMList: ValuationAssetMethodologySetupVM[] = [];

        if (assetOrGroup && assetOrGroup.type === ValuationType.SingleAsset) {
            valuationAssetValuationGroupVMList.push({
                AssetId: String(assetOrGroup.Id),
                AssetName: String(assetOrGroup?.Name),
                PortfoliosId: String(portfolioId),
                ValuationGroupId: null,
                ExcludeAssetFromReporting: false,
                ExcludeAssetFromReportingExplanation: '',
            });

            valuationAssetMethodologySetupVMList.push({
                RegionalArea: String(assetOrGroup.RegionalArea),
                VAMethodologySetupId: createGuid(),
                AssetId: String(assetOrGroup.Id),
                AssetName: String(assetOrGroup?.Name),
                PortfoliosId: String(portfolioId),
                PortfolioTypeId: String(portfolioTypeId),
                ValuationName: String(task.ValuationName ? task.ValuationName : ''),
                ValuationId: String(task.ValuationUniqId ? task.ValuationUniqId : ''),
                MethodologyTemplateId: null,
                ValuationGroupId: null,
                AllAsset_CopyFromPriorValuationId: null,
            });
            fundIds = assetOrGroup.Fund_Id as string;
            investmentIds = assetOrGroup.Investment_Id as string;
        } else if (assetOrGroup && assetOrGroup.type === ValuationType.ValuationGroup && valuationGroupAssets.length) {
            fundIds = [...new Set(valuationGroupAssets.map((item) => item.fundId))].join(',');
            investmentIds = [...new Set(valuationGroupAssets.map((item) => item.investmentId))].join(',');
            valuationGroupAssets.forEach((vga) => {
                valuationAssetValuationGroupVMList.push({
                    AssetId: vga.assetsId,
                    AssetName: vga.assetName,
                    PortfoliosId: vga.portfoliosId,
                    ValuationGroupId: vga.valuationGroupId,
                    ExcludeAssetFromReporting: false,
                    ExcludeAssetFromReportingExplanation: '',
                });

                valuationAssetMethodologySetupVMList.push({
                    RegionalArea: vga.RegionalArea,
                    VAMethodologySetupId: createGuid(),
                    AssetId: vga.assetsId,
                    AssetName: vga.assetName,
                    PortfoliosId: vga.portfoliosId,
                    PortfolioTypeId: String(portfolioTypeId),
                    ValuationName: String(task.ValuationName ? task.ValuationName : ''),
                    ValuationId: task.ValuationUniqId ? (task.ValuationUniqId as string) : createGuid(),
                    MethodologyTemplateId: null,
                    ValuationGroupId: vga.valuationGroupId,
                    AllAsset_CopyFromPriorValuationId: null,
                });
            });
        }
        newTask.FundIds = fundIds;
        newTask.InvestmentIds = investmentIds;
        newTask.ValuationYear = task.ValuationYear ? Number(task.ValuationYear) : undefined;

        newTask.TotalAssetsInValuations = valuationAssetValuationGroupVMList.length;
        newTask.NoOfAsset = task.NoOfAsset || undefined;

        if (task.ValuationName) {
            newTask.ValuationName = String(task.ValuationName);
        }

        newTask.ValuationAsset = [...valuationAssetValuationGroupVMList];
        newTask.ValuationAssetMethodologySetup = [...valuationAssetMethodologySetupVMList];
        newTask.ValuationId = task.ValuationUniqId;
        newTask.Id = task.ValuationUniqId;

        addTasks.push(newTask);
    });

    return { addTasks };
};
const getQBValuationGroupAssetData = ({
    qbQuery,
    domainId,
    advancedExpression,
    adhocListViewMembers,
}: GetQBDataParamTypes): Promise<QueryExecuteResponse> =>
    qbQuery({
        domainId,
        primaryViewId: '-1',
        filterIds: [],
        adhocListViewMembers,
        paging: {
            start: 0,
            stop: 1000,
        },
        sorting: {},
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression,
        },
    });

export const fetchAssetsForValuationGroup = (
    valuationGroupID: string,
    qbQuery: (params: QueryExecuteRequest) => Promise<QueryExecuteResponse>,
): Promise<ValuationGroupAsset[]> =>
    getQBValuationGroupAssetData({
        qbQuery,
        domainId: 'ValuationGroupAsset',
        adhocListViewMembers: GetValGroupAssetColumns(),
        advancedExpression: `[ValuationGroupId] = '${valuationGroupID}'`,
    }).then((r) => {
        const valuationGroupAsset: ValuationGroupAsset[] = [];
        if (r && r.Data && r.Data.length > 0) {
            for (let index = 0; index < r.Data.length; index++) {
                const obj = r.Data[index];
                valuationGroupAsset.push({
                    valuationGroupAssetId: String(obj.ValuationGroupAssetId),
                    valuationGroupId: String(obj.ValuationGroupId),
                    assetsId: String(obj.AssetsId),
                    portfoliosId: String(obj.PortfolioId),
                    assetName: String(obj.AssetsName),
                    fundId: String(obj.FundsId),
                    investmentId: String(obj.InvestmentsId),
                    RegionalArea: obj.RegionalArea as string,
                });
            }
        }
        return valuationGroupAsset;
    });

export const getDefaultViewFromPortfolio = (defaultView: string, viewKey: string): string => {
    let viewId: string = defaultView;
    const porfolioDefaults = JSON.parse(localStorage.getItem('Portfolio_Id') ?? '');

    if (porfolioDefaults[viewKey]) viewId = porfolioDefaults[viewKey];

    return viewId;
};
