import React from 'react';
import { SectionHeader } from '@samc/react-ui-form';
import { SelectionBox } from '../../atoms/SelectionBox/SelectionBox';
import { LABELS } from '../../static/content';
import { SetupMethodType } from './AddValuations.interfaces';
import { NavButtons } from './NavButtons';
import { CycleMethod, ValuationMethod } from './ValuationWizard.enums';
import { ValuationSteps } from '../WizardProgress/ProgressConstants';

const Types = [
    { value: ValuationMethod.SingleValuation, label: LABELS.SETUP_TYPE.SINGLE },
    { value: ValuationMethod.CycleofValuations, label: LABELS.SETUP_TYPE.CYCLE },
    { value: ValuationMethod.MultipleValuations, label: LABELS.SETUP_TYPE.MULTIPLE },
];

interface SelectSetupMethodTypes {
    setupMethod: SetupMethodType;
    updateSetupMethod: (params: SetupMethodType) => void;
    portfolioTypeId: string;
    setCurrentStep: (param: ValuationSteps) => void;
}

export const SelectSetupMethod = ({
    setupMethod,
    updateSetupMethod,
    portfolioTypeId,
    setCurrentStep,
}: SelectSetupMethodTypes): React.ReactElement => {
    const onBack = (): void => setCurrentStep(ValuationSteps.SelectAssetOrValuationGroup);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.checked) {
            updateSetupMethod({
                valuationMethod: e.target.value as ValuationMethod,
                cycleMethod: CycleMethod.undefined,
            });
        }
    };

    const onContinue = (): void => {
        if (setupMethod.valuationMethod === ValuationMethod.SingleValuation)
            setCurrentStep(ValuationSteps.SingleValutionForm);
        else if (setupMethod.valuationMethod === ValuationMethod.CycleofValuations)
            setCurrentStep(ValuationSteps.SelectCycleMethod);
        else if (setupMethod.valuationMethod === ValuationMethod.MultipleValuations)
            setCurrentStep(ValuationSteps.SetupValuations);
    };

    return (
        <>
            <NavButtons
                isDisabled={setupMethod.valuationMethod === ValuationMethod.undefined}
                onBack={onBack}
                onContinue={onContinue}
            />

            <div className="center-box">
                <SectionHeader title={LABELS.SELECT_SETUP_LABEL} />

                {Types.map(
                    ({ value, label }): React.ReactElement =>
                        value === ValuationMethod.CycleofValuations &&
                        portfolioTypeId.toLowerCase() === 'BANKLEND'.toLowerCase() ? (
                            <></>
                        ) : (
                            <SelectionBox
                                key={value}
                                name="choose-valuation-type"
                                value={value}
                                checked={value === setupMethod.valuationMethod}
                                label={label}
                                onChange={onChange}
                            />
                        ),
                )}
            </div>
        </>
    );
};

export default SelectSetupMethod;
