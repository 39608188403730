/* istanbul ignore file */
import React from 'react';
import { RowSelectedEvent } from 'ag-grid-community';
import { useFormManagementContext as _useFormManagementContext } from '@samc/react-ui-form';
import { Spinner } from '@fluentui/react';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import {
    PROPERTY_NAME_CONTACTS_ASSIGNED_TO,
    ValuationContact,
    ValuationWorkflowTaskOwner,
    useValuationWorkflowTaskOwners,
} from '../../../valuationApi';
import { WorkflowEditContactGrid } from '../../atoms/WorkflowEditContactGrid/WorkflowEditContactGrid';

interface Props {
    /**
     * The id of the Valuation for which to fetch Valuation Contacts.
     */
    valuationId: string;
    /**
     * The id of the ValuationWorkflowTask for which to fetch ValuationWorkflowTaskOwners.
     */
    workflowTaskId: string;

    /**
     * Callback on contact deselected.
     */
    onContactDeselected?: (contact?: ValuationContact) => void;
    /**
     * Callback on contact selected.
     */
    onContactSelected?: (contact?: ValuationContact) => void;
    /**
     * DI override of the hook to get the FormApi.
     */
    useFormManagementContext?: typeof _useFormManagementContext;
}

export const WorkflowEditContactGridAssignTo = ({
    onContactDeselected,
    onContactSelected,
    useFormManagementContext = _useFormManagementContext,
    valuationId,
    workflowTaskId,
}: Props): React.ReactElement => {
    const { api } = useFormManagementContext();

    /**
     * Handles RowSelectedEvents. This will intentionally ignore api based selectionChangedEvents because they would be from
     * WorkflowContactGrid.selectInitialRows() and we don't want to dirty the form as a result.
     */
    const onRowSelected = (event: RowSelectedEvent<ValuationContact>): void => {
        if (event.source === 'api') return;

        // We are calling onChange to update the FormState properly, but this value won't actually be used.
        api?.onChange(
            PROPERTY_NAME_CONTACTS_ASSIGNED_TO,
            event.api.getSelectedNodes().map((node) => node.data),
        );

        if (event.node.isSelected()) {
            onContactSelected?.(event.node.data);
        }

        if (!event.node.isSelected()) {
            onContactDeselected?.(event.node.data);
        }
    };

    const onSave = async (): Promise<void> => api?.onSubmit();

    const valuationWorkflowTaskOwnersQuery = useValuationWorkflowTaskOwners(workflowTaskId);

    if (valuationWorkflowTaskOwnersQuery.isLoading) {
        return <Spinner data-testid="spinner-WorkflowEditContactGridAssignTo" />;
    }

    if (valuationWorkflowTaskOwnersQuery.isError) {
        return <MessageBar text="Error fetching owner users." messageBarType={MessageBarType.error} />;
    }

    const initialContactsAssignedTo = valuationWorkflowTaskOwnersQuery.data
        ?.Data as unknown as ValuationWorkflowTaskOwner[];

    return (
        <WorkflowEditContactGrid
            initialGridSelection={initialContactsAssignedTo}
            onRowSelected={onRowSelected}
            valuationId={valuationId}
            onSave={onSave}
        />
    );
};

export default WorkflowEditContactGridAssignTo;
