/* istanbul ignore file */
import React from 'react';
import { GFCCellRendererParams } from '@samc/react-ui-grid';
import { styled } from 'styletron-react';
import { IconButton } from '@fluentui/react';
import { ValuationWorkflowTask } from '../../../valuationApi';
import { useWorkflowModalContext } from '../../../hooks/useWorkflowModalContext/useWorkflowModalContext';
import { WorkflowEditAdHocTaskPanel } from '../../organisms/WorkflowEditAdHocTaskPanel/WorkflowEditAdHocTaskPanel';
import { AgGridExpandAndCollapse } from '../../atoms/AgGridExpandAndCollapse/AgGridExpandAndCollapse';
import { useApplicationContext } from '../../../hooks/useApplicationContext/useApplicationContext';

const BASE_LINK_PATH = '#';

/* istanbul ignore next */
const StyledFlexRow = styled('div', () => ({
    height: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));

/* istanbul ignore next */
const StyledEditButton = styled(IconButton, ({ show }: { show: boolean }) => ({
    height: '100%',
    display: show ? 'inline-block' : 'none',
}));

export const WorkflowTaskNameRenderer = (props: GFCCellRendererParams): React.ReactElement => {
    const { api, node, valueFormatted } = props;
    const { CanUserUpdateAdHocTask } = useApplicationContext();

    const workflowTask: ValuationWorkflowTask = node.data;

    const { setIsOpen: setIsModalOpen } = useWorkflowModalContext();

    const closeModal = (): void => {
        setIsModalOpen(false);
    };

    const shouldShowAdHocTaskEdit = (): boolean => {
        return (
            !!workflowTask.AdHocTaskFlag &&
            (workflowTask.CurrentUserIsEnabledForTask || workflowTask.CurrentUserIsTaskOwner) &&
            CanUserUpdateAdHocTask
        );
    };

    const [isAdHocTaskPanelOpen, setAdHocTaskPanelOpen] = React.useState<boolean>(false);

    const openAdHocTaskPanel = (): void => {
        setAdHocTaskPanelOpen(true);
    };

    const closeAdHocTaskPanel = (): void => {
        setAdHocTaskPanelOpen(false);
    };

    if (!workflowTask.HyperlinkPath) {
        return (
            <>
                <StyledFlexRow>
                    <AgGridExpandAndCollapse api={api} node={node}>
                        <span>{workflowTask.Name}</span>
                    </AgGridExpandAndCollapse>
                    <StyledEditButton
                        ariaLabel="Edit"
                        iconProps={{ iconName: 'Edit' }}
                        title="Edit"
                        show={shouldShowAdHocTaskEdit()}
                        onClick={openAdHocTaskPanel}
                    />
                </StyledFlexRow>
                {isAdHocTaskPanelOpen && (
                    <WorkflowEditAdHocTaskPanel
                        initialFormData={workflowTask}
                        isOpen={isAdHocTaskPanelOpen}
                        onCancel={closeAdHocTaskPanel}
                        onSubmitSuccess={closeAdHocTaskPanel}
                        valuationId={workflowTask.ValuationId}
                    />
                )}
            </>
        );
    }

    const hrefPathSegments = [
        BASE_LINK_PATH,
        workflowTask.HyperlinkPath,
        `?primaryKeyValue=${workflowTask.ValuationId}`,
    ];

    return (
        <>
            <StyledFlexRow>
                <AgGridExpandAndCollapse api={api} node={node}>
                    <a href={hrefPathSegments.join('/')} onClick={closeModal}>
                        {valueFormatted}
                    </a>
                </AgGridExpandAndCollapse>
                <StyledEditButton
                    ariaLabel="Edit"
                    iconProps={{ iconName: 'Edit' }}
                    title="Edit"
                    show={shouldShowAdHocTaskEdit()}
                    onClick={openAdHocTaskPanel}
                />
            </StyledFlexRow>
            {isAdHocTaskPanelOpen && (
                <WorkflowEditAdHocTaskPanel
                    initialFormData={workflowTask}
                    isOpen={isAdHocTaskPanelOpen}
                    onCancel={closeAdHocTaskPanel}
                    onSubmitSuccess={closeAdHocTaskPanel}
                    valuationId={workflowTask.ValuationId}
                />
            )}
        </>
    );
};

export default WorkflowTaskNameRenderer;
