/**
 * Compares two strings for equality ignoring case and returns whether or not
 * they are equal.
 * For the purposes of this function, if either a or b is undefined, then the result is false.
 *
 * ToDo Move this to react-ui eventually...
 *
 * @param a First string.
 * @param b Second string.
 * @returns True if a and b are equal ignoring case, false otherwise.
 */
export const CaseInsensitiveStringEquals = (a?: string | null, b?: string | null): boolean => {
    if (a === undefined || a === null || b === undefined || b === null) return false;

    return !a.localeCompare(b, undefined, { sensitivity: 'accent' });
};

export default CaseInsensitiveStringEquals;
