export enum BulkValuationSteps {
    SetupValuations = 1,
    AddContacts = 2,
    SetupMethodologyAndApproach = 3,
    ConfirmMethodologies = 3.1,
    CreateValuations = 4,
}

export enum ValuationSteps {
    SelectAssetOrValuationGroup = 1,
    SelectSetupMethod = 2,
    SelectCycleMethod = 2.1,
    SetupCycleTemplate = 2.2,
    SetupReportPeriods = 2.3,
    SingleValutionForm = 3,
    SetupValuations = 3.1,
    AddContacts = 4,
    SetupMethodologyAndApproach = 5,
    ConfirmMethodologies = 5.1,
    CreateValuations = 6,
}

export const enum WorkflowSteps {
    SelectWorkflow = 1,
    UpdateValuation = 2,
    UpdateValuationContact = 3,
}

export const ChangeWorkflowSteps = ['Select Workflow', 'Update Valuation Data', 'Update Valuation Contacts'];

export const BulkAddValuationSteps = [
    'Set Up Valuations',
    'Add Contacts',
    'Confirm Methodologies',
    'Create Valuations',
];

export const ValuationWizardSteps = [
    'Select Asset(s)',
    'Select Setup Method',
    'Set Up Valuation',
    'Add Contacts',
    'Confirm Methodologies',
    'Create Valuations',
];

export type Steps = BulkValuationSteps | ValuationSteps;

export const getWizardProgress = (step: Steps): [number, number] => {
    switch (step) {
        case ValuationSteps.SelectSetupMethod:
            return [2, 0];
        case ValuationSteps.SelectCycleMethod:
            return [2, 25];
        case ValuationSteps.SetupCycleTemplate:
            return [2, 50];
        case ValuationSteps.SetupReportPeriods:
            return [2, 75];
        case ValuationSteps.SingleValutionForm:
            return [3, 0];
        case ValuationSteps.SetupValuations:
            return [3, 0];
        case ValuationSteps.AddContacts:
            return [4, 0];
        case ValuationSteps.SetupMethodologyAndApproach:
            return [5, 0];
        case ValuationSteps.ConfirmMethodologies:
            return [5, 50];
        case ValuationSteps.CreateValuations:
            return [6, 0];
        default:
            return [1, 0];
    }
};

export const getBulkWizardProgress = (step: Steps): [number, number] => {
    switch (step) {
        case BulkValuationSteps.AddContacts:
            return [2, 0];
        case BulkValuationSteps.SetupMethodologyAndApproach:
            return [3, 0];
        case BulkValuationSteps.ConfirmMethodologies:
            return [3, 50];
        case BulkValuationSteps.CreateValuations:
            return [4, 0];
        default:
            return [1, 0];
    }
};
