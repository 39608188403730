import React from 'react';
import Styles from './ProgressBar.styles';

interface ProgressBarProps {
    steps: string[];
    currentStep?: number;
    partialProgress?: number | undefined; // percentage - must be between 0 - 100
}

const getStep = (
    step: string,
    currentStep: number,
    index: number,
    steps: string[],
    partialProgress: number | undefined,
): JSX.Element => {
    const stepState = Styles.getStepState(currentStep, index);

    return (
        <div key={step} style={{ flex: 1, position: 'relative' }} className="progress-step">
            <span style={Styles.getMarkerStyle(stepState)} className="progress-step-number">
                {index}
            </span>
            <span style={Styles.getLabelStyle(stepState)} className="progress-step-label">
                {step}
            </span>
            {steps.length > index && <span style={Styles.getBarStyle(stepState, partialProgress)} />}
        </div>
    );
};

export const ProgressBar = ({ steps, currentStep = 1, partialProgress }: ProgressBarProps): React.ReactElement => (
    <div style={Styles.ComponentWrapper}>
        <div style={Styles.StepStyle}>
            {steps.map((step, index) => getStep(step, currentStep, index + 1, steps, partialProgress))}
        </div>
    </div>
);

export default ProgressBar;
