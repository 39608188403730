import React from 'react';
import { SelectionChangedEvent } from 'ag-grid-community';
import { useFormManagementContext as _useFormManagementContext } from '@samc/react-ui-form';
import { PROPERTY_NAME_CONTACTS_VIEWABLE_BY, ValuationContact } from '../../../valuationApi';
import { WorkflowContactGrid } from '../../atoms/WorkflowContactGrid/WorkflowContactGrid';

interface Props {
    /**
     * The id of the Valuation for which to fetch Valuation Contacts.
     */
    valuationId: string;

    /**
     * DI override of the hook to get the FormApi.
     */
    useFormManagementContext?: typeof _useFormManagementContext;
}

export const WorkflowContactGridViewableBy = ({
    useFormManagementContext = _useFormManagementContext,
    valuationId,
}: Props): React.ReactElement => {
    const { api } = useFormManagementContext();

    const handleSelectionChanged = (event: SelectionChangedEvent<ValuationContact>): void => {
        const selectedContacts = event.api.getSelectedNodes().map((node) => node.data);

        api?.onChange(PROPERTY_NAME_CONTACTS_VIEWABLE_BY, selectedContacts);
    };

    return <WorkflowContactGrid onSelectionChanged={handleSelectionChanged} valuationId={valuationId} />;
};

export default WorkflowContactGridViewableBy;
