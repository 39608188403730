/* istanbul ignore file */
import React from 'react';
import { IRowNode } from 'ag-grid-community';
import {
    fetchApi as _fetchApi,
    useValuationWorkflowTask as _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners as _useValuationWorkflowTaskOwners,
    useValuationWorkflowTaskOwnersSaver as _useValuationWorkflowTaskOwnersSaver,
    ValuationApiOverrides,
    ValuationWorkflowTask,
} from '../../../valuationApi';
import { WorkflowResponsibleParticipantRenderer as BaseWorkflowResponsibleParticipantRenderer } from './WorkflowResponsibleParticipantRenderer.Panel';

interface Props
    extends Pick<
        ValuationApiOverrides,
        | 'fetchApi'
        | 'useValuationWorkflowTask'
        | 'useValuationWorkflowTaskOwners'
        | 'useValuationWorkflowTaskOwnersSaver'
    > {
    node: IRowNode;
    displayValue?: string;
}

export const WorkflowResponsibleParticipantRenderer = ({
    displayValue,
    fetchApi = _fetchApi,
    node,
    useValuationWorkflowTask = _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners = _useValuationWorkflowTaskOwners,
    useValuationWorkflowTaskOwnersSaver = _useValuationWorkflowTaskOwnersSaver,
}: Props): React.ReactElement => {
    const workflowTask: ValuationWorkflowTask = node.data;

    return (
        <BaseWorkflowResponsibleParticipantRenderer
            displayValue={displayValue}
            fetchApi={fetchApi}
            useValuationWorkflowTask={useValuationWorkflowTask}
            useValuationWorkflowTaskOwners={useValuationWorkflowTaskOwners}
            useValuationWorkflowTaskOwnersSaver={useValuationWorkflowTaskOwnersSaver}
            workflowTask={workflowTask}
        />
    );
};

export default WorkflowResponsibleParticipantRenderer;
