import React, { useState } from 'react';
import { FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { useApiContext } from '@samc/screen-config-api';
import { FormData, useFormState } from '@samc/react-ui-form';
import { FormViews, Valuation } from '../../static/ValuationConstants';
import { NavButtons } from './NavButtons';
import { DefaultValidationResponse, validateSetupData, ValidationResponse } from './ValidationUtils';
import { ValuationSteps } from '../WizardProgress/ProgressConstants';
import NavLoader from '../../atoms/NavLoader/NavLoader';

interface PropTypes {
    setValuationFormData: (fd: FormData) => void;
    formData: FormData;
    setCurrentStep: (param: ValuationSteps) => void;
    filters?: string[];
    valuation: Valuation;
}

export const Step3 = ({
    setValuationFormData,
    formData,
    setCurrentStep,
    filters,
    valuation,
}: PropTypes): React.ReactElement => {
    const [validationResponse, updateValidationResponse] = useState<ValidationResponse>({
        ...DefaultValidationResponse,
    });

    const api = useApiContext();
    const [isLoading, setLoading] = useState<boolean>(false);

    const [formState, onFormStateChanged] = useFormState();
    const [initialData] = React.useState({ ...formData, HideOneOff: null });

    const onChange = (data: FormData): void => setValuationFormData(data);

    const onBack = (): void => setCurrentStep(ValuationSteps.SelectSetupMethod);

    const onContinue = (): void => {
        setLoading(true);
        validateSetupData({
            TaskApi: api.TaskApi,
            requestInit: api.requestInit || {},
            addTasks: valuation.addTasks ? valuation.addTasks : [{}],
            updateErrors: updateValidationResponse,
            onSuccess: () => {
                setLoading(false);
                setCurrentStep(ValuationSteps.AddContacts);
            },
            setLoading,
        });
    };

    const retrieveDefaults = (): void => undefined;

    return (
        <>
            <NavButtons
                isDisabled={!formState.isValid || isLoading}
                onBack={onBack}
                onContinue={onContinue}
                retrieveDefaults={retrieveDefaults}
                validationResponse={validationResponse}
                updateValidationResponse={updateValidationResponse}
            >
                <NavLoader isLoading={isLoading} label="Validating Valuation..." />
            </NavButtons>

            <div className="val-form">
                <FormScreenWrapper
                    defaultData={initialData}
                    formViewId={FormViews.ValuationWizardSetup}
                    primaryKeyValue=""
                    onChange={onChange}
                    onFormStateChange={onFormStateChanged}
                    filters={filters}
                    ServiceDrivenViewSet={ServiceDrivenViewSet}
                    GridScreenWrapper={GridScreenWrapper}
                />
            </div>
        </>
    );
};

export default Step3;
