import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Entitlements, useCurrentUser } from '@samc/single-spa-authentication';
import { MessageBar, MessageBarType, UnsavedChangesDialog } from '@samc/react-ui-core';
import { useHistoryContext } from '@samc/react-ui-history';
import { Transition } from 'history';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import { ChangeWorkflowWizard as Wizard } from '../../components/organisms/ChangeWorkflow';
import { decodeBase64 } from '../../util';

const ChangeWorkflowWizardViewSet = (): React.ReactElement => {
    const [params] = useSearchParams();
    const [portfolioId, setPortfolioId] = useState<string | null>(null);
    const [valuationIds, setValuationIds] = useState<string | null>(null);
    const [showModal, toggleModal] = useState(false);
    const [wizardIsDirty, setWizardisDirty] = useState(false);
    const [transition, setTransition] = useState<Transition>();
    const navigate = useNavigate();
    const [primaryKeyValue] = useSearchParams();
    const valuationId: string | null = primaryKeyValue.get('primaryKeyValue');
    const { browserHistory, hashHistory } = useHistoryContext();

    useEffect(() => {
        if (!wizardIsDirty) return (): void => undefined;

        const unblock = browserHistory.block((tx: Transition) => {
            toggleModal(true);
            setTransition({ ...tx });
        });

        return (): void => {
            unblock();
        };
    }, [browserHistory, hashHistory, wizardIsDirty]);

    const closeModal = useCallback(
        (discard: boolean) => {
            if (discard && transition) {
                if (valuationId !== null) {
                    navigate(`/screens/ValuationDetail?primaryKeyValue=${valuationId}&PortfolioId=${portfolioId}`);
                } else {
                    navigate(`/screens/AllValuation?PortfolioId=${portfolioId}`);
                }
            }

            toggleModal(false);
        },
        [navigate, portfolioId, transition, valuationId],
    );

    const URL_PARAMNAME_FILTER = 'filter';

    const getBase64FilterExpressionFromUrl = (): string | null => {
        return params.has(URL_PARAMNAME_FILTER) ? params.get(URL_PARAMNAME_FILTER) : null;
    };

    const encodedFilterExpression = getBase64FilterExpressionFromUrl();

    const filters = React.useMemo((): string[] => {
        if (!encodedFilterExpression || !encodedFilterExpression.length) return [];

        const decodedExpression = decodeBase64(encodedFilterExpression);
        try {
            const filterArray = JSON.parse(decodedExpression);
            if (filterArray && Array.isArray(filterArray)) {
                return filterArray;
            }
        } catch (e) {
            //
        }

        return [decodedExpression];
    }, [encodedFilterExpression]);

    React.useEffect(() => {
        if (params.has('portfolioId')) {
            if (portfolioId && portfolioId !== params.get('portfolioId')) {
                setPortfolioId(params.get('portfolioId'));
            } else {
                setPortfolioId(params.get('portfolioId'));
            }
        }
        if (params.has('PortfolioId')) {
            if (portfolioId && portfolioId !== params.get('PortfolioId')) {
                setPortfolioId(params.get('PortfolioId'));
            } else {
                setPortfolioId(params.get('PortfolioId'));
            }
        }
        if (params.has('valuationIds')) {
            if (valuationIds && valuationIds !== params.get('valuationIds')) {
                setValuationIds(params.get('valuationIds'));
            } else {
                setValuationIds(params.get('valuationIds'));
            }
        }
        if (params.has('ValuationIds')) {
            if (valuationIds && valuationIds !== params.get('ValuationIds')) {
                setValuationIds(params.get('ValuationIds'));
            } else {
                setValuationIds(params.get('ValuationIds'));
            }
        }
    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

    const user = useCurrentUser();

    const validateUser = React.useMemo(() => user.hasEntitlement(Entitlements.DataAccess.ChangeWorkflow), [user]);

    if (!user.isLoaded) return <div />;

    if (!validateUser)
        return (
            <div>
                <MessageBar
                    messageBarType={MessageBarType.error}
                    title="Error loading the Change Workflow Wizard"
                    text="You are not authorized to view this page. Please contact your administrator if you believe this is an error."
                />
            </div>
        );

    return (
        <EvaluatorConfigurationProvider value={{}}>
            <Wizard
                key={`key_${portfolioId}`}
                portfolioId={portfolioId as string}
                valuationsIds={valuationIds as string}
                setWizardisDirty={setWizardisDirty}
                filters={filters}
            />

            <UnsavedChangesDialog show={showModal} close={closeModal} />
        </EvaluatorConfigurationProvider>
    );
};

export default ChangeWorkflowWizardViewSet;
