/* istanbul ignore file */
import React from 'react';
import { WorkflowTaskReassignPanel as BaseWorkflowTaskReassignPanel } from './WorkflowTaskReassignPanel.FormContext';
import {
    useValuationWorkflowTask as _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners as _useValuationWorkflowTaskOwners,
    ValuationApiOverrides,
    ValuationWorkflowTaskOwner,
} from '../../../valuationApi';

interface Props
    extends Pick<
        ValuationApiOverrides,
        | 'fetchApi'
        | 'useValuationWorkflowTask'
        | 'useValuationWorkflowTaskOwners'
        | 'useValuationWorkflowTaskOwnersSaver'
    > {
    /**
     * If true, the Panel is displayed, if false the Panel is hidden.
     */
    isOpen: boolean;
    /**
     * The Id of the Valuation for which ValuationContacts will be fetched.
     */
    valuationId: string;
    /**
     * The Id of the ValuationWorkflowTask to be fetched.
     */
    valuationWorkflowTaskId: string;

    /**
     * Callback on click of Cancel button.
     */
    onCancel?: () => void | Promise<void>;
    /**
     * Callback on click of Submit and Close button.
     */
    onSubmitAndClose?: () => Promise<void> | void;
    /**
     * Callback on successful submission of the Form.
     */
    onSubmitSuccess?: () => void;
}

export const WorkflowTaskReassignPanel = ({
    isOpen,
    onCancel,
    onSubmitAndClose,
    onSubmitSuccess,
    useValuationWorkflowTask = _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners = _useValuationWorkflowTaskOwners,
    valuationId,
    valuationWorkflowTaskId,
}: Props): React.ReactElement => {
    const valuationWorkflowTaskRequest = useValuationWorkflowTask(valuationWorkflowTaskId);
    const valuationWorkflowTaskOwnersRequest = useValuationWorkflowTaskOwners(valuationWorkflowTaskId);

    const initialData = valuationWorkflowTaskRequest.data?.Data[0];
    const initiallySelectedContacts = valuationWorkflowTaskOwnersRequest.data
        ?.Data as unknown as ValuationWorkflowTaskOwner[];

    return (
        <BaseWorkflowTaskReassignPanel
            initialContactsAssignedTo={initiallySelectedContacts}
            initialFormData={initialData}
            isOpen={isOpen}
            onCancel={onCancel}
            onSubmitAndClose={onSubmitAndClose}
            onSubmitSuccess={onSubmitSuccess}
            valuationId={valuationId}
            valuationWorkflowTaskId={valuationWorkflowTaskId}
        />
    );
};

export default WorkflowTaskReassignPanel;
