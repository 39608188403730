/* istanbul ignore file */
import React from 'react';
import { IRowNode, RowSelectedEvent } from 'ag-grid-community';
import { useFormManagementContext as _useFormManagementContext } from '@samc/react-ui-form';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { Spinner } from '@fluentui/react';
import {
    PROPERTY_NAME_CONTACTS_ENABLED_FOR,
    ValuationContact,
    ValuationWorkflowTaskEnabledUser,
    useValuationWorkflowTaskEnabledUsers,
} from '../../../valuationApi';
import { useApplicationContext } from '../../../hooks/useApplicationContext/useApplicationContext';
import { WorkflowEditContactGrid } from '../../atoms/WorkflowEditContactGrid/WorkflowEditContactGrid';

interface Props {
    /**
     * The id of the Valuation for which to fetch Valuation Contacts.
     */
    valuationId: string;
    /**
     * The id of the ValuationWorkflowTask for which to fetch ValuationWorkflowTaskOwners.
     */
    workflowTaskId: string;

    /**
     * Callback on contact deselected. This callback will not trigger when the current user is deselected.
     */
    onContactDeselected?: (contact?: ValuationContact) => void;
    /**
     * Callback on contact selected. This callback will not trigger when the current user is selected.
     */
    onContactSelected?: (contact?: ValuationContact) => void;
    /**
     * DI override of the hook to get the FormApi.
     */
    useFormManagementContext?: typeof _useFormManagementContext;
}

export const WorkflowEditContactGridEnabledFor = ({
    onContactDeselected,
    onContactSelected,
    useFormManagementContext = _useFormManagementContext,
    valuationId,
    workflowTaskId,
}: Props): React.ReactElement => {
    const { api } = useFormManagementContext();

    const { CurrentUser } = useApplicationContext();

    /**
     * Handles RowSelectedEvents. This will intentionally ignore api based selectionChangedEvents because they would be from
     * WorkflowContactGrid.selectInitialRows() and we don't want to dirty the form as a result.
     *
     * In order to prevent duplicate attempts to add the current user as an enabled user, we will intentionally ignore RowSelectedEvents
     * for the current user meaning they will never be included in the Task payload. The ValuationWorkflowTaskAddedEventHandler will add them for us.
     */
    const onRowSelected = (event: RowSelectedEvent<ValuationContact>): void => {
        if (event.source === 'api') return;

        // We are calling onChange to update the FormState properly, but this value won't actually be used.
        api?.onChange(
            PROPERTY_NAME_CONTACTS_ENABLED_FOR,
            event.api.getSelectedNodes().map((node) => node.data),
        );

        if (CurrentUser.id === event.node.data?.UserId) return;

        if (event.node.isSelected()) {
            onContactSelected?.(event.node.data);
        }

        if (!event.node.isSelected()) {
            onContactDeselected?.(event.node.data);
        }
    };

    const isRowSelectable = ({ node }: { node: IRowNode<ValuationContact> }): boolean => {
        return !(node.data?.IsValuationAdmin || node.data?.UserId === CurrentUser.id);
    };

    const onSave = async (): Promise<void> => api?.onSubmit();

    const valuationWorkflowTaskEnabledUsersQuery = useValuationWorkflowTaskEnabledUsers(workflowTaskId);

    if (valuationWorkflowTaskEnabledUsersQuery.isLoading) {
        return <Spinner data-testid="spinner-WorkflowEditContactGridEnabledFor" />;
    }

    if (valuationWorkflowTaskEnabledUsersQuery.isError) {
        return <MessageBar text="Error fetching enabled users." messageBarType={MessageBarType.error} />;
    }

    const initialContactsEnabledFor = valuationWorkflowTaskEnabledUsersQuery.data
        ?.Data as unknown as ValuationWorkflowTaskEnabledUser[];

    return (
        <WorkflowEditContactGrid
            initialGridSelection={initialContactsEnabledFor}
            isRowSelectable={isRowSelectable}
            onRowSelected={onRowSelected}
            showDisabledCheckboxes
            valuationId={valuationId}
            onSave={onSave}
        />
    );
};

export default WorkflowEditContactGridEnabledFor;
