/* istanbul ignore file */
import React from 'react';
import { TaskResponsePopup } from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { TaskRequest } from '@samc/screen-config-api';
import { useWorkflowTaskValidationPopupContext as _useWorkflowTaskValidationPopupContext } from '../../contexts/WorkflowTaskValidationPopupContext';
import { useApiContext } from '../../../hooks/useApiContext';
import { IsTaskSuccessful } from '../../../functions/IsTaskSuccessful/IsTaskSuccessful';
import { VALUATION_WORKFLOW_TASKS_KEY } from '../../../valuationApi';

interface Props {
    useWorkflowTaskValidationPopupContext?: typeof _useWorkflowTaskValidationPopupContext;
}

export const WorkflowTaskValidationPopup = ({
    useWorkflowTaskValidationPopupContext = _useWorkflowTaskValidationPopupContext,
}: Props): React.ReactElement => {
    const { QueryClient, useTaskSubmitter } = useApiContext();
    const [submitTask] = useTaskSubmitter();

    const { validationResponse, validationRequest, visible, setVisible } = useWorkflowTaskValidationPopupContext();

    const requests = validationRequest ? [validationRequest] : [];
    const responses = validationResponse ? [validationResponse] : [];

    const domainNameMap = {
        VALU_ValuationWorkflowTask: 'Name',
    };

    const submitWithUpdates = async (updatedRequest: TaskRequest<Record<string, unknown>>[]): Promise<void> => {
        const _responses = await Promise.all(
            updatedRequest.map(async (taskRequest) => {
                return submitTask.mutateAsync(taskRequest);
            }),
        );

        if (_responses.every(([response]) => IsTaskSuccessful(response))) {
            setVisible(false);
            QueryClient.invalidateQueries([VALUATION_WORKFLOW_TASKS_KEY]);
        }
    };

    return (
        <TaskResponsePopup
            responses={responses}
            requests={requests}
            visible={visible}
            setVisible={setVisible}
            domainNameMap={domainNameMap}
            submitWithUpdates={submitWithUpdates}
        />
    );
};

export default WorkflowTaskValidationPopup;
