import { TaskRequest } from '@samc/screen-config-api';

export class TaskRequestBuilder {
    private taskRequest: TaskRequest;

    private lastRequest: TaskRequest;

    private payloadPointers: string[];

    public constructor(domainId: string, fieldConfigurationId: string, requestIdentifier: string) {
        this.taskRequest = {
            domainId,
            requestIdentifier,
            fieldConfigurationId,
            payload: {},
        };

        this.lastRequest = this.taskRequest;

        this.payloadPointers = [];
    }

    public asAddTask(isAdd?: boolean): TaskRequestBuilder;

    public asAddTask(isAdd = true): TaskRequestBuilder {
        if (!this.payloadPointers) return this;
        if (!isAdd) return this;

        const targetRequest = this.lastRequest;
        const endOfArray = this.payloadPointers.length - 1;
        const payloadKey = this.payloadPointers[endOfArray];

        targetRequest.payload[payloadKey] = {
            ...targetRequest.payload[payloadKey],
            __ADDED__: true,
        };

        return this;
    }

    public asDeleteTask(isDelete?: boolean): TaskRequestBuilder;

    public asDeleteTask(isDelete = true): TaskRequestBuilder {
        if (!this.payloadPointers) return this;
        if (!isDelete) return this;

        const targetRequest = this.lastRequest;
        const endOfArray = this.payloadPointers.length - 1;
        const payloadKey = this.payloadPointers[endOfArray];

        targetRequest.payload[payloadKey] = {
            ...targetRequest.payload[payloadKey],
            __DELETED__: true,
        };

        return this;
    }

    public withChildRequest(
        domainId: string,
        fieldConfigurationId: string,
        requestIdentifier: string,
    ): TaskRequestBuilder {
        const targetRequest = this.taskRequest;
        const endOfArray = Object.keys(targetRequest.payload).length - 1;
        const payloadKey = Object.keys(targetRequest.payload)[endOfArray];

        if (!targetRequest.payload[payloadKey].__CHILD_REQUESTS__)
            targetRequest.payload[payloadKey].__CHILD_REQUESTS__ = [];

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const lengthOfArray = targetRequest.payload[payloadKey].__CHILD_REQUESTS__!.push({
            domainId,
            fieldConfigurationId,
            requestIdentifier,
            payload: {},
        });

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.lastRequest = targetRequest.payload[payloadKey].__CHILD_REQUESTS__![lengthOfArray - 1];

        return this;
    }

    public withPayload(key: string, payload: Record<string, unknown>): TaskRequestBuilder {
        const targetRequest = this.lastRequest;

        targetRequest.payload = {
            ...targetRequest.payload,
            [key]: {
                ...payload,
            },
        };

        this.payloadPointers?.push(key);

        return this;
    }

    public build(): TaskRequest {
        return this.taskRequest;
    }

    /* istanbul ignore next */
    public debug(): TaskRequestBuilder {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(this.taskRequest, undefined, 2));

        return this;
    }
}

export default TaskRequestBuilder;
