import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.min.css';
import { Button, Currencies } from '@samc/react-ui-core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { useFieldConfigurationByDomain } from '@samc/screen-config-api';
import TaskResponsePopup from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { SectionHeader } from '@samc/react-ui-form';
import { Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import { useStyletron } from 'styletron-react';
import { ComparisonDataType, LoanType, SectionType } from './ComparisonGrid.utils';
import { useApiContext } from '../../../hooks/useApiContext';
import CustomComparisonHeader from './ComparisonHeader';
import { PivotCellRenderer } from './PivotCellRenderer';
import {
    ComparisonColId,
    ComparisonErrorType,
    ComparisonResponseType,
    getCellStyleByFontRules,
    getComparisonErrors,
    getComparisonRowClassRules,
} from './ComparisonGrid.functions';
import { apiProvider } from '../../../valuationApi';
import { HcrVcrRequestModel } from '../../../valuationApi/models/HcrVcrRequestModel';
import { useHcrVcrReportGenerator } from '../../../hooks/useHcrVcrReportGenerator/useHcrVcrReportGenerator';

interface VCRFormDataType {
    ValuationAssetId?: string;
    VCRComparisonValuationsSelected?: string;
    VCRCashFlowScenario?: string;
    VCRComparisonValuationsPP?: string;
    VCRComparisonValuationsYY?: string;
    yyComparisonId?: string;
}

interface HCRFormDataType {
    ValuationAssetId?: string;
    HCRComparisonValuationsSelected?: string;
    HCRCashFlowScenario?: string;
}

export const VALU_HCR_VIEWSET_ID = 'VALU_HCR' as const;
export const VALU_VCR_VIEWSET_ID = 'VALU_VCR' as const;

export interface ComparisonGrid2Props {
    viewSetId: typeof VALU_HCR_VIEWSET_ID | typeof VALU_VCR_VIEWSET_ID;
    formData: VCRFormDataType | HCRFormDataType;
}

export const isDataHCROrVCR = (input: {
    viewSetId: string | null | undefined;
    formData: object | null | undefined;
}): input is {
    viewSetId: typeof VALU_HCR_VIEWSET_ID | typeof VALU_VCR_VIEWSET_ID;
    formData: VCRFormDataType | HCRFormDataType;
} => {
    const { viewSetId, formData } = input;

    if (!formData) return false;
    return viewSetId === VALU_HCR_VIEWSET_ID || viewSetId === VALU_VCR_VIEWSET_ID;
};

const _isVCR = (
    viewSetId: typeof VALU_HCR_VIEWSET_ID | typeof VALU_VCR_VIEWSET_ID,
    formData: VCRFormDataType | HCRFormDataType,
): formData is VCRFormDataType => formData && viewSetId === VALU_VCR_VIEWSET_ID;

const ComparisonGrid2 = ({ viewSetId, formData }: ComparisonGrid2Props): ReactElement => {
    const isVCR = _isVCR(viewSetId, formData);

    const [gridApi, setGridApi] = useState<GridApi>();

    const [comparisonResponse, setComparisonResponse] = useState<ComparisonDataType>({
        dataResponse: { data: [] },
        configSection: { section: [] },
        ppValuationFields: null,
        yyValuationFields: null,
    });
    const [errorResponse, setErrorResponse] = useState<ComparisonErrorType>();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const [css] = useStyletron();

    const { ValuationApi, requestInit = {} } = useApiContext();
    const hcrVcrApi = useHcrVcrReportGenerator();

    const { data: fieldConfigData, isLoading } = useFieldConfigurationByDomain('VALU_ValuationAssetReporting');
    const { data: fieldConfigEntityLoans, isLoading: isLoadingEntityLoans } =
        useFieldConfigurationByDomain('VALU_DEAEntityLoans');
    const { data: fieldConfigPropertyLoans, isLoading: isLoadingPropertyLoans } =
        useFieldConfigurationByDomain('VALU_DEAPropertyLoans');
    const { data: fieldConfigVA, isLoading: isLoadingVA } = useFieldConfigurationByDomain('ValuationAsset');

    const { dataResponse, configSection, ppValuationFields, yyValuationFields } = comparisonResponse;

    const columnDefs = useMemo(() => {
        return [
            {
                field: 'sectionLabel',
                rowGroup: true,
                hide: true,
                filter: 'agTextColumnFilter',
            },
            { field: 'loan', rowGroup: true, hide: true, filter: 'agTextColumnFilter' },
            {
                field: 'name',
                headerName: 'Date of Value',
                minWidth: 300,
            },
            ...dataResponse.data.map((col) => ({
                field: `${col.ValuationAssetId}`,
                headerName: 'date',
                width: 150,
                valueFormatter: PivotCellRenderer,
                cellStyle: getCellStyleByFontRules,
                headerComponentParams: { date: col.Valuation_ValuationDateofValue },
            })),
            ...(ppValuationFields
                ? [
                      {
                          field: ComparisonColId.PP,
                          headerName: ComparisonColId.PP,
                          width: 150,
                          valueFormatter: PivotCellRenderer,
                          cellStyle: getCellStyleByFontRules,
                      },
                  ]
                : []),
            ...(yyValuationFields
                ? [
                      {
                          field: ComparisonColId.YY,
                          headerName: ComparisonColId.YY,
                          width: 150,
                          valueFormatter: PivotCellRenderer,
                          cellStyle: getCellStyleByFontRules,
                      },
                  ]
                : []),
        ] as ColDef[];
    }, [dataResponse.data, ppValuationFields, yyValuationFields]);

    const rowData = useMemo(() => {
        const rows: Record<string, unknown>[] = [];
        const { data: responseData } = dataResponse;

        const data = [
            ...responseData,
            ...(ppValuationFields ? [{ ...ppValuationFields, ValuationAssetId: ComparisonColId.PP }] : []),
            ...(yyValuationFields ? [{ ...yyValuationFields, ValuationAssetId: ComparisonColId.YY }] : []),
        ];

        const getRowDataValues = (
            loanLabel: string,
            propertyId: string,
            loanKey: string,
            propertyName: string,
        ): Record<string, unknown> | undefined => {
            // Find if a similar loan exists with same property to avoid duplicates
            const similarLoan = rows.find(({ loan, id }) => loan === loanKey && id === propertyId);
            if (similarLoan) return undefined;

            const dataValues = data.reduce((acc, col: Record<string, unknown>) => {
                if (col[loanLabel]) {
                    const loanObject = (col[loanLabel] as Record<string, unknown>[]).find(
                        ({ LoanName }) => LoanName === loanKey,
                    );

                    if (loanObject) return { ...acc, [`${col.ValuationAssetId}`]: loanObject[propertyName] };
                }

                return { ...acc };
            }, {});

            return dataValues;
        };

        const expenseGrowthSection = 'Operating Expenses';

        if (!isLoading && !isLoadingEntityLoans && !isLoadingPropertyLoans && fieldConfigData && !isLoadingVA) {
            const configFields: string[] = [];

            /* find properties for field configurations */
            data.forEach((row) => {
                Object.keys(row).forEach((fieldKey) => {
                    if (!configFields.includes(fieldKey)) configFields.push(fieldKey);
                });
            });

            const fieldConfigMember = fieldConfigData.at(0)?.fieldConfigurationMembers;
            const configs = fieldConfigMember?.filter(({ viewFieldName }) => configFields.includes(viewFieldName));

            const fcmEntityLoan = fieldConfigEntityLoans?.at(0)?.fieldConfigurationMembers;
            const fcmPropertyLoan = fieldConfigPropertyLoans?.at(0)?.fieldConfigurationMembers;
            const fcmValuationAsset = fieldConfigVA?.at(0)?.fieldConfigurationMembers;

            if (configs?.length && configSection.section.length && data.length) {
                configSection.section.forEach((row: SectionType): void => {
                    row.child.forEach((rowChild: SectionType['child'][0]): void => {
                        if (rowChild.id.includes(LoanType.PROPERTY) || rowChild.id.includes(LoanType.ENTITY)) {
                            if (!rowChild.id.includes('LoanOrder')) {
                                const loanLabel = rowChild.id.includes(LoanType.PROPERTY)
                                    ? LoanType.PROPERTY
                                    : LoanType.ENTITY;

                                data.forEach((val: Record<string, unknown>) => {
                                    if (val[loanLabel]) {
                                        (val[loanLabel] as Record<string, unknown>[]).forEach(
                                            (loan: Record<string, unknown>) => {
                                                const propertyName = rowChild.id.split('.')[1] ?? rowChild.id; // remove loan label from id

                                                const rowDataValues = getRowDataValues(
                                                    loanLabel,
                                                    rowChild.id,
                                                    `${loan.LoanName}`,
                                                    propertyName,
                                                );

                                                const fcm = LoanType.PROPERTY ? fcmPropertyLoan : fcmEntityLoan;

                                                if (rowDataValues)
                                                    rows.push({
                                                        ...rowChild, // name is required as field label & fontRule for styling the values
                                                        sectionLabel:
                                                            loanLabel === LoanType.PROPERTY
                                                                ? 'Property Level Loans'
                                                                : 'Entity Loans',
                                                        currency: Currencies[data[0].Currency as string],
                                                        fieldConfig: fcm?.find(
                                                            ({ viewFieldName }) => propertyName === viewFieldName,
                                                        ),
                                                        loan: `${loan.LoanName}`,
                                                        ...rowDataValues,
                                                    });
                                            },
                                        );
                                    }
                                });
                            }
                        } else if (rowChild.id.includes('ValuationAsset')) {
                            rows.push({
                                ...rowChild,
                                sectionLabel: row.name,
                                currency: Currencies[data[0].Currency as string],
                                fieldConfig:
                                    fcmValuationAsset?.find(
                                        ({ viewFieldName }) => viewFieldName === rowChild.id.split('.')[1],
                                    ) ??
                                    fieldConfigMember?.find(
                                        ({ viewFieldName }) => viewFieldName === rowChild.id.split('.')[1],
                                    ),
                                ...data.reduce(
                                    (acc, col: Record<string, unknown>) => ({
                                        ...acc,
                                        [`${col.ValuationAssetId}`]: col[rowChild.id.split('.')[1]],
                                    }),
                                    {},
                                ),
                            });
                        } else {
                            /* non loan related properties */
                            rows.push({
                                ...rowChild,
                                sectionLabel:
                                    row.name.indexOf(expenseGrowthSection) > -1 ? 'Expense Growth Rates' : row.name,
                                currency: Currencies[data[0].Currency as string],
                                fieldConfig: configs.find((fcm) => fcm.viewFieldName === rowChild.id),
                                loan: row.name.indexOf(expenseGrowthSection) > -1 ? row.name : undefined,
                                ...data.reduce(
                                    (acc, col: Record<string, unknown>) => ({
                                        ...acc,
                                        [`${col.ValuationAssetId}`]: col[rowChild.id],
                                    }),
                                    {},
                                ),
                            });
                            /* */
                        }
                    });
                });
            }
        }

        return rows;
    }, [
        configSection.section,
        dataResponse,
        fieldConfigData,
        fieldConfigEntityLoans,
        fieldConfigPropertyLoans,
        fieldConfigVA,
        isLoading,
        isLoadingEntityLoans,
        isLoadingPropertyLoans,
        isLoadingVA,
        ppValuationFields,
        yyValuationFields,
    ]);

    const VCRComparisonParams = useMemo(() => {
        return {
            currentValuationAssetId: formData.ValuationAssetId,
            isVCR,
            valuationAssetList: isVCR
                ? formData.VCRComparisonValuationsSelected?.toString().split(',') ?? []
                : formData.HCRComparisonValuationsSelected?.toString().split(',') ?? [],

            scenarioType: isVCR ? formData.VCRCashFlowScenario : formData.HCRCashFlowScenario,
            ...(isVCR
                ? {
                      ppComparisonId: formData.VCRComparisonValuationsPP,
                      yyComparisonId: formData.VCRComparisonValuationsYY,
                  }
                : {}),
        } as HcrVcrRequestModel;
    }, [formData, isVCR]);

    const defaultColDef = useMemo(
        () => ({
            flex: 1,
            minWidth: 100,
        }),
        [],
    );

    const exportToExcel = useCallback(async (): Promise<void> => {
        setIsProcessing(true);

        await hcrVcrApi(VCRComparisonParams);
        setIsProcessing(false);
    }, [VCRComparisonParams, hcrVcrApi]);

    /**
     * requests comparison data based on selected options
     */
    const onCompare = useCallback(async (): Promise<void> => {
        setIsProcessing(true);
        const response: ComparisonResponseType & ComparisonErrorType = await apiProvider.postData(
            ValuationApi,
            `/api/VCR`,
            VCRComparisonParams,
            requestInit,
        );

        if (response.dataResponse) setComparisonResponse(response);
        if (response.status === 'FAIL') setErrorResponse(response);
        if (response) setIsProcessing(false);
    }, [VCRComparisonParams, ValuationApi, requestInit]);

    const onGridReady = useCallback(
        ({ api }: GridReadyEvent<Record<string, unknown>, unknown>): void => {
            if (!gridApi) setGridApi(api);
        },
        [gridApi],
    );

    useEffect(() => {
        if (VCRComparisonParams.currentValuationAssetId && VCRComparisonParams.scenarioType) {
            onCompare();
        }
    }, [VCRComparisonParams.currentValuationAssetId, VCRComparisonParams.scenarioType, viewSetId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="ag-theme-balham" style={{ width: '100%', height: '100%', paddingLeft: 19, paddingRight: 19 }}>
            {isProcessing && <Spinner size={SpinnerSize.large} />}

            {VCRComparisonParams.currentValuationAssetId && (
                <div
                    className={css({
                        display: 'flex',
                        marginBottom: '6px',
                    })}
                >
                    <SectionHeader title={isVCR ? 'VCR' : 'HCR'} className={css({ marginTop: 'auto' })} />

                    <span className={css({ marginLeft: 'auto' })}>
                        {rowData.length > 0 && (
                            <TooltipHost content="Report cannot be exported if Scenario is blank. Please select a Scenario to export the report.">
                                <Button
                                    type="button"
                                    text="Export"
                                    className={css({ marginRight: '12px' })}
                                    buttonType="secondary"
                                    onClick={exportToExcel}
                                    disabled={isProcessing || VCRComparisonParams.scenarioType === null}
                                />
                            </TooltipHost>
                        )}

                        <Button
                            type="button"
                            text="Compare"
                            buttonType="primary"
                            onClick={onCompare}
                            disabled={isProcessing}
                        />
                    </span>
                </div>
            )}

            <div
                className={`ag-theme-balham ${css({
                    width: '100%',
                    height: '100%',
                    borderTop: '1px solid #d9d9d9',
                    paddingTop: '10px',
                    marginBottom: '10px',
                })}`}
            >
                {rowData.length > 0 && (
                    <AgGridReact
                        groupAllowUnbalanced
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        groupDisplayType="groupRows"
                        onGridReady={onGridReady}
                        groupDefaultExpanded={1}
                        components={{
                            agColumnHeader: CustomComparisonHeader,
                        }}
                        rowClassRules={getComparisonRowClassRules(css)}
                        groupRowRendererParams={{ suppressCount: true }}
                    />
                )}
            </div>

            <TaskResponsePopup
                responses={getComparisonErrors(errorResponse)}
                requests={[]}
                visible={!!errorResponse}
                setVisible={(): void => setErrorResponse(undefined)}
            />
        </div>
    );
};

export default ComparisonGrid2;
