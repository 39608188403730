/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { ChangeDataArgs, Grid, GridFieldConfiguration } from '@samc/react-ui-grid';
import { GridSearch } from './GridSearch';

interface TwoGridProps {
    previousData: Record<string, unknown>[];
    setData: React.Dispatch<React.SetStateAction<Record<string, unknown>[]>>;
    portfolioFilter?: string;
    valuationGroupId?: string;
    valuationId?: string;
    viewSetTabId?: string;
}

export const TwoGrids = (prop: TwoGridProps): React.ReactElement => {
    const { previousData, setData, portfolioFilter, valuationGroupId, valuationId, viewSetTabId } = prop;
    const columns: GridFieldConfiguration[] = [
        { field: 'ReferenceId', headerName: 'Asset Id' },
        { field: 'Name', displayNameRule: 'Asset Name' },
        { field: 'Id', displayNameRule: 'Id', visibleRule: 'false' },
        {
            field: 'InvestmentId',
            displayNameRule: 'InvestmentId',
            visibleRule: 'false',
        },
        { field: 'Investment_Name', displayNameRule: 'Investment Name' },
        {
            field: 'Investment_FundId',
            displayNameRule: 'Investment_FundId',
            visibleRule: 'false',
        },
        { field: 'Fund_Name', displayNameRule: 'Fund Name' },
        {
            field: 'Fund_PortfolioId',
            displayNameRule: 'Fund_PortfolioId',
            visibleRule: 'false',
        },
        { field: 'Portfolio_Name', displayNameRule: 'Portfolio' },
    ];

    const handleDataChanged = React.useCallback(
        (args: ChangeDataArgs<Record<string, unknown>>) => {
            const changedData = Object.values(args.changedData);
            const rows = previousData.filter((row): boolean => !changedData.some((r): boolean => r.Id === row.Id));
            setData([...changedData, ...rows]);
        },
        [setData, previousData],
    );

    return (
        <div className="twogrid">
            <div>
                <GridSearch
                    rightGridRows={previousData}
                    setRightGridRows={setData}
                    portfolioFilter={portfolioFilter}
                    valuationGroupId={valuationGroupId}
                    valuationId={valuationId}
                    viewSetTabId={viewSetTabId}
                />
            </div>
            <div className="basegrid" style={{ overflow: 'none', display: 'flex' }}>
                <Grid
                    onSubmit={(): void => undefined} // noop, not editable
                    isEditingDefault={false}
                    suppressEditToggle
                    suppressAddRowButton
                    suppressDeleteButton={false}
                    suppressClearFilter={false}
                    suppressExcelExport={false}
                    suppressRowSpacing
                    suppressDeleteConfirmationModal
                    suppressFullScreen={false}
                    suppressColumnFilters={false}
                    data={previousData}
                    fields={columns}
                    onChangeData={handleDataChanged}
                />
            </div>
        </div>
    );
};

export default TwoGrids;
