/* istanbul ignore file */
import React from 'react';
import { ThemedComponent, useThemeContext } from '@samc/react-ui-theme';
import { styled } from 'styletron-react';
import { Shimmer } from '@fluentui/react';
import { QueryExecuteResponse, ViewType } from '@samc/screen-config-api';
import { UseQueryResult } from 'react-query';
import { FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet, ViewModal } from '@samc/screen-config-core';
import { ControllerBarBox } from '../ControllerBarBox/ControllerBarBox';
import { DEATrackingHelper } from '../../../valuationApi/models/DEATrackingHelper';
import { DEInvestmentValueAckStatus } from '../../../valuationApi/models/DEAInvestmentValueAckStatus';
import { useApiContext } from '../../../hooks/useApiContext';
import { CaseInsensitiveStringEquals } from '../../functions/CaseInsensitiveStringEquals';
import { ValuationHub } from '../../../enums/ValuationHub';
import { ValuationEvent } from '../../../models/ValuationEvent';

interface Props {
    valuationId: string;
    deaHelperResponse?: UseQueryResult<QueryExecuteResponse<DEATrackingHelper>>;
    deaInvestmentValueAckResponse?: UseQueryResult<QueryExecuteResponse<DEInvestmentValueAckStatus>>;
    refreshViewSet: () => void;
}
/* istanbul ignore next */
const StyledClickableBox = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    cursor: 'pointer',
}));

/* istanbul ignore next */
const StyledFlexRow = styled('span', () => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5em',
    justifyContent: 'space-between',
}));

/* istanbul ignore next */
const StyledWorkflowTemplateStatusName = styled('span', ({ theme }: ThemedComponent) => ({
    fontWeight: 'bold',
    color: `${theme.TextColors.secondary}`,
}));

/* istanbul ignore next */
const StyledOpenTasksCount = styled('span', ({ theme }: ThemedComponent) => ({
    color: `${theme.FormColors.fieldColors.error.fontColor}`,
}));

export const DEA_TAB_ID = 'DEBTEQUITYANALYSIS';
export const DEADATA_DOMAIN_ID = 'ValuationAsset';
export const DEA_SIDE_PANEL_FORMVIEW_ID = 'VALU_DEAPanelForm';
export const FORM_ID = 'VALU_DEATracking_screen';

export const DEATrackingHelperBox = ({
    deaHelperResponse,
    valuationId,
    deaInvestmentValueAckResponse,
    refreshViewSet,
}: Props): React.ReactElement => {
    const theme = useThemeContext();

    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const helperData = deaHelperResponse?.data?.Data?.[0];

    const { useValuationHub } = useApiContext();

    const [_isConnected, connection] = useValuationHub();

    React.useEffect(() => {
        connection.on(ValuationHub.ValuationEventFired, (eventModel: ValuationEvent) => {
            if (CaseInsensitiveStringEquals(valuationId, eventModel.valuationId)) {
                deaInvestmentValueAckResponse?.refetch();
                deaHelperResponse?.refetch();
            }
        });

        return () => {
            connection.off(ValuationHub.ValuationEventFired);
        };
    }, [connection, deaInvestmentValueAckResponse, valuationId, deaHelperResponse]);

    const openModal = (): void => {
        setIsModalOpen(true);
    };

    const closeModal = (): void => {
        setIsModalOpen(false);
    };

    const onSaved = (): void => {
        refreshViewSet();
        deaHelperResponse?.refetch();
    };

    const deaData = deaInvestmentValueAckResponse?.data?.Data[0];

    const showHelper =
        !deaInvestmentValueAckResponse?.isLoading &&
        deaData?.Portfolio_IncludeDebtEquityAnalysis === true &&
        deaData?.DEInvestmentValueAckStatus !== 'Scheduled';

    return showHelper ? (
        <ControllerBarBox
            isLoading={deaHelperResponse?.isLoading === true}
            isError={deaHelperResponse?.isError === true}
        >
            <>
                <StyledClickableBox role="button" aria-hidden onClick={openModal}>
                    <div>
                        <Shimmer isDataLoaded={!deaInvestmentValueAckResponse?.isLoading}>
                            <StyledWorkflowTemplateStatusName theme={theme}>
                                Debt Equity Inv Value Ack:{' '}
                                {deaData.DEInvestmentValueAckStatus === 'Yes' ? 'Complete' : 'Incomplete'}
                            </StyledWorkflowTemplateStatusName>
                        </Shimmer>
                    </div>
                    <Shimmer isDataLoaded={helperData !== undefined}>
                        <StyledFlexRow>
                            <div>
                                <span>Open Items: </span>
                                <StyledOpenTasksCount theme={theme}>
                                    {helperData?.OpenDEChecklistItemsCount}
                                </StyledOpenTasksCount>
                            </div>
                            <div>
                                <span>Remaining Asset Acks: </span>
                                <StyledOpenTasksCount theme={theme}>
                                    {helperData?.DebtEquitValueAcknowledgementPending}
                                </StyledOpenTasksCount>
                            </div>
                        </StyledFlexRow>
                    </Shimmer>
                </StyledClickableBox>
                <ViewModal
                    onSaved={onSaved}
                    isOpen={isModalOpen}
                    onDismiss={closeModal}
                    key={`${FORM_ID}_${valuationId}`}
                    primaryKeyValue={valuationId}
                    viewId={FORM_ID}
                    viewType={ViewType.Form}
                    headerText="Valuation Debt Equity Status"
                    FormScreenWrapper={FormScreenWrapper}
                    ServiceDrivenViewSet={ServiceDrivenViewSet}
                    GridScreenWrapper={GridScreenWrapper}
                />
            </>
        </ControllerBarBox>
    ) : (
        <></>
    );
};

export default DEATrackingHelperBox;
