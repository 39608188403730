import { DomainViewMember } from '@samc/screen-config-api';
import { AllGridApis } from '@samc/react-ui-grid';
import { v4 as uuid } from 'uuid';

export const customColumns = [
    {
        scalarExpression: '[Id]',
        scalarDisplayName: 'Id',
    },
    {
        scalarExpression: '[EnableValuationGroup]',
        scalarDisplayName: 'EnableValuationGroup',
    },
    {
        scalarExpression: '[Name]',
        scalarDisplayName: 'Name',
    },
    {
        scalarExpression: '[StatusCode]',
        scalarDisplayName: 'StatusCode',
    },
    {
        scalarExpression: '[TypeCode]',
        scalarDisplayName: 'TypeCode',
    },
];

export const assetColumns = [
    {
        scalarExpression: '[Id]',
        scalarDisplayName: 'Id',
    },
    {
        scalarExpression: '[Investment_ClientInvestmentId]',
        scalarDisplayName: 'Investment Client Investment Id',
    },
    {
        scalarExpression: '[Investment_Name]',
        scalarDisplayName: 'Investment',
    },
    {
        scalarExpression: '[Investment_ReferenceId]',
        scalarDisplayName: 'VMS Investment ID',
    },
    {
        scalarExpression: '[Name]',
        scalarDisplayName: 'Asset Names',
    },
    {
        scalarExpression: '[ReferenceId]',
        scalarDisplayName: 'VMS Asset ID',
    },
    {
        scalarExpression: '[RegionalArea]',
        scalarDisplayName: 'RegionalArea',
    },
    {
        scalarExpression: '[Fund_Id]',
        scalarDisplayName: 'Fund_Id',
    },
    {
        scalarExpression: '[InvestmentId]',
        scalarDisplayName: 'Investment_Id',
    },
];

export const valGroupColumns = [
    {
        scalarExpression: '[ValuationGroupId]',
        scalarDisplayName: 'ValuationGroupId',
    },
    {
        scalarExpression: '[ClientValuationGroupId]',
        scalarDisplayName: 'Client Valuation Group Id',
    },
    {
        scalarExpression: '[VMSGroupId]',
        scalarDisplayName: 'VMS Group Id',
    },
    {
        scalarExpression: '[ValuationGroupName]',
        scalarDisplayName: 'Valuation Group Name',
    },
    {
        scalarExpression: '[ValuationGroupTypeId]',
        scalarDisplayName: 'Valuation Group Type Id',
    },
];

export const GetAssetColumns = (): DomainViewMember[] => {
    const adhocListViewMembers: DomainViewMember[] = [];
    for (let i = 0; i < assetColumns.length; i++) {
        const adhocLVMembArrayObj = {} as DomainViewMember;
        adhocLVMembArrayObj.scalarExpression = assetColumns[i].scalarExpression;
        adhocLVMembArrayObj.scalarDisplayName = assetColumns[i].scalarDisplayName;
        adhocListViewMembers.push(adhocLVMembArrayObj);
    }

    return adhocListViewMembers;
    // code here
};

export const GetValGroupColumns = (): DomainViewMember[] => {
    const adhocListViewMembers: DomainViewMember[] = [];
    for (let i = 0; i < valGroupColumns.length; i++) {
        const adhocLVMembArrayObj = {} as DomainViewMember;
        adhocLVMembArrayObj.scalarExpression = valGroupColumns[i].scalarExpression;
        adhocLVMembArrayObj.scalarDisplayName = valGroupColumns[i].scalarDisplayName;
        adhocListViewMembers.push(adhocLVMembArrayObj);
    }

    return adhocListViewMembers;
};

export function createGuid(): string {
    return uuid();
}

export const CreateCombinationName = (
    MethodologyTypeId = '',
    MethodologyScenarioTypeId = '',
    MethodologyValueTypeId = '',
    InterestId = '',
    ValueTypeDescription = '',
): string => {
    let combinationName = '';
    if (ValueTypeDescription && ValueTypeDescription !== '') {
        combinationName = `${MethodologyTypeId}/${MethodologyScenarioTypeId}/${MethodologyValueTypeId}-${ValueTypeDescription}/${InterestId}`;
    } else {
        combinationName = `${MethodologyTypeId}/${MethodologyScenarioTypeId}/${MethodologyValueTypeId}/${InterestId}`;
    }
    return combinationName;
};

/**
 * Gets latest/updated data from the grid using grid api
 * @param gridApi
 * @param changedData
 * @param primaryKey
 * @param filterDeleted
 * @returns
 */
export const getFinalGridData = (
    apis: AllGridApis,
    changedData: Record<string, unknown>,
    primaryKey: string,
    filterDeleted = true,
): Record<string, unknown> => {
    const updatedData: Record<string, Record<string, unknown>> = Object.keys(changedData).reduce(
        (o, k) => ({ ...o, [k]: changedData[k] as Record<string, unknown> }),
        {},
    );

    if (Object.keys(updatedData).length) {
        apis.forEach((_, { gridApi }) => {
            gridApi.forEachNode(({ data }) => {
                if (data) updatedData[data[primaryKey]] = data as Record<string, unknown>; // Override ok
            });
        });

        if (filterDeleted) {
            Object.keys(updatedData).forEach((key: string) => {
                if (updatedData[key].__DELETED__) delete updatedData[key];
            });
        }
    }

    return updatedData;
};

export const valGroupAssetColumns = [
    {
        scalarExpression: '[ValuationGroupAssetId]',
        scalarDisplayName: 'ValuationGroupAssetId',
    },
    {
        scalarExpression: '[RegionalArea]',
        scalarDisplayName: 'RegionalArea',
    },
    {
        scalarExpression: '[ValuationGroupId]',
        scalarDisplayName: 'ValuationGroupId',
    },
    {
        scalarExpression: '[AssetsId]',
        scalarDisplayName: 'AssetsId',
    },
    {
        scalarExpression: '[PortfolioId]',
        scalarDisplayName: 'PortfolioId',
    },
    {
        scalarExpression: '[AssetsName]',
        scalarDisplayName: 'AssetsName',
    },
    {
        scalarExpression: '[FundsId]',
        scalarDisplayName: 'FundsId',
    },
    {
        scalarExpression: '[InvestmentsId]',
        scalarDisplayName: 'InvestmentsId',
    },
];

export const GetValGroupAssetColumns = (): DomainViewMember[] => {
    const adhocListViewMembers: DomainViewMember[] = [];
    for (let i = 0; i < valGroupAssetColumns.length; i++) {
        const adhocLVMembArrayObj = {} as DomainViewMember;
        adhocLVMembArrayObj.scalarExpression = valGroupAssetColumns[i].scalarExpression;
        adhocLVMembArrayObj.scalarDisplayName = valGroupAssetColumns[i].scalarDisplayName;
        adhocListViewMembers.push(adhocLVMembArrayObj);
    }
    return adhocListViewMembers;
};
