import React from 'react';
import { ToastMessage, toastSuccess } from '@samc/react-ui-core';
import { TaskStatus, ViewSetAction, TaskResponse, TaskRequest } from '@samc/screen-config-api';
import TaskResponsePopup from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { apiProvider } from '../../../valuationApi';
import { useApiContext } from '../../../hooks/useApiContext';
import {
    DefaultValidationResponse,
    getValidationResponse,
    ValidationResponse,
} from '../AddValutionWizard/ValidationUtils';

interface Props {
    reviewCommentId: string | undefined;
    refreshViewSet: () => void;
    updateAction: React.Dispatch<ViewSetAction | undefined>;
}

const DeleteReviewComment = ({ reviewCommentId, refreshViewSet, updateAction }: Props): React.ReactElement => {
    const [validationResponse, setValidationResponse] = React.useState<ValidationResponse>({
        ...DefaultValidationResponse,
    });
    const onErrorPopupClose = (): void => {
        updateAction(undefined);
        setValidationResponse({ ...validationResponse, messages: [], childResponses: {} });
    };
    const api = useApiContext();
    React.useEffect((): void => {
        apiProvider
            .submitTask(
                api.ValuationApi,
                'VALU_ReviewComment',
                'VALU_ReviewComment',
                'VALU_ReviewComment Grid',
                {
                    [String(reviewCommentId)]: {
                        Id: String(reviewCommentId),
                        __DELETED__: true,
                    },
                },
                api.requestInit ?? {},
            )
            .then(([response, request]) => {
                if (
                    response.statusCode !== TaskStatus.Completed ||
                    response.messageCount > 0 ||
                    validationResponse.prompts.length > 0
                ) {
                    setValidationResponse(getValidationResponse(request, response));
                } else {
                    toastSuccess(<ToastMessage title="Success" message="Review Comment deleted successfully." />);
                    updateAction(undefined);
                    refreshViewSet();
                }
            });
    }, []);

    const submitUpdate = (updatedRequest: TaskRequest[]): void => {
        apiProvider
            .submitTask(
                api.ValuationApi,
                'VALU_ReviewComment',
                'VALU_ReviewComment',
                'VALU_ReviewComment Grid',
                updatedRequest[0].payload,
                api.requestInit ?? {},
            )
            .then(() => {
                toastSuccess(<ToastMessage title="Success" message="Review Comment deleted successfully." />);
                updateAction(undefined);
                refreshViewSet();
            });
    };

    const { request } = validationResponse;
    const requests = React.useMemo(() => [request], [request]);
    const responses = React.useMemo(() => [validationResponse as TaskResponse], [validationResponse]);

    return (
        <div>
            <TaskResponsePopup
                requests={requests}
                responses={responses}
                visible={
                    validationResponse.messages.length > 0 ||
                    validationResponse.prompts.length > 0 ||
                    Object.values(validationResponse.childResponses).some(({ messages }) => messages.length > 0)
                }
                setVisible={onErrorPopupClose}
                submitWithUpdates={validationResponse.prompts.length > 0 ? submitUpdate : undefined}
            />
        </div>
    );
};

export default DeleteReviewComment;
