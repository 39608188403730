import React from 'react';
import {
    ApiContextType,
    ApiContext,
    QueryExecuteRequest,
    QueryExecuteResponse,
    DomainViewMember,
} from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';

export const POMA_ASSET_KEY = 'POMAAsset';

export const usePomaAsset = (
    assetId: string,
    adhocListViewMemberList: DomainViewMember[],
    keyChange: number,
): UseQueryResult<QueryExecuteResponse<Record<string, unknown>[]>> => {
    const api = React.useContext<ApiContextType>(ApiContext);

    const requestBody: QueryExecuteRequest = {
        domainId: 'POMA_Asset',
        primaryViewId: '-1',
        adhocListViewMembers: adhocListViewMemberList,
        sorting: {},
        paging: { start: 0, stop: 1 },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `DoesContain([Id], '${assetId}')`,
        },
    };

    const queryResult = useQuery<QueryExecuteResponse<Record<string, unknown>[]>>(
        [POMA_ASSET_KEY, assetId, keyChange],
        () =>
            executeLiftSiftQuery(
                api.LiftSiftApi,
                requestBody,
                api.requestInit,
                api.ShowMessage,
            ) as unknown as QueryExecuteResponse<Record<string, unknown>[]>,
        { retry: 2, refetchOnWindowFocus: false },
    );

    return queryResult;
};

export default usePomaAsset;
