import {
    FieldConfigurationMember,
    ListViewMember,
    useFieldConfigurationByDomain,
    useListView,
} from '@samc/screen-config-api';
import { ITaskMessage } from '@samc/screen-config-api/lib/model/Tasks/Interfaces/ITaskMessage';

const convertToListViewMember = (fcMember: FieldConfigurationMember): ListViewMember => ({
    defaultValueExpression: fcMember?.defaultValueExpression,
    displayFormat: fcMember?.formatId,
    editableExpression: fcMember?.editableExpression,
    scalarDisplayName: fcMember.viewFieldName,
    sequence: 1000,
    viewFieldName: fcMember.viewFieldName,
    requiredExpression: fcMember?.requiredExpression,
    visibleExpression: fcMember?.visibleExpression,
    hexFillRule: fcMember?.hexFillRule,
    formatId: fcMember?.formatId,
    inputType: fcMember?.inputType,
    lookupProperties: fcMember?.lookupProperties,
    tooltip: fcMember?.tooltip,
    horizontalAlignment: fcMember?.horizontalAlignment,
    calculationCondition: fcMember?.calculationCondition,
    calculationFrequency: fcMember?.calculationFrequency,
    calculationRule: fcMember?.calculationRule,
    displayNameExpression: fcMember?.displayNameExpression,
});

interface Props {
    /* domain to get the grid field configuration */
    domainId: string;
    /* viewId for the loaded view  */
    viewId: string;
    /* error message list from the validation response */
    errors: ITaskMessage[];
    /* fields explicitly always made available in the grid */
    essentialFields?: string[];
    mappedFields?: Record<string, unknown>;
}

const useMissingGridFields = ({
    domainId,
    viewId,
    errors,
    essentialFields = [],
    mappedFields,
}: Props): ListViewMember[] | undefined => {
    /* field configurations by domain */
    const { data: fieldConfigData } = useFieldConfigurationByDomain(domainId);
    /* list view data by viewId */
    const { data: listViewData } = useListView(viewId);

    const fieldConfigMembers = fieldConfigData?.at(0)?.fieldConfigurationMembers;
    const { listViewMembers } = listViewData ?? { listViewMembers: [] };

    /* list of fields from errors */
    const errFields = Object.keys(
        errors.reduce(
            (acc, { fields }) => ({
                ...acc,
                ...fields.reduce((ac, field) => ({ ...ac, [field]: undefined }), {}),
            }),
            /* adds the seed fields to the list */
            essentialFields.length ? essentialFields.reduce((acc, field) => ({ ...acc, [field]: undefined }), {}) : {},
        ),
    );

    /* list of fields from list-view */
    const fieldsInListView = listViewMembers.map(({ viewFieldName }) => viewFieldName);

    /* list of field-configurations based on fields available in errors but not in list-view */
    const missingFieldsInView = fieldConfigMembers?.filter(
        ({ viewFieldName }) => errFields.includes(viewFieldName) && !fieldsInListView.includes(viewFieldName),
    );

    if (mappedFields && Object.keys(mappedFields).length) {
        Object.keys(mappedFields).forEach((fieldName) => {
            const missingField = missingFieldsInView?.filter((field) => field.viewFieldName === fieldName);
            const missingMappedField = missingFieldsInView?.filter(
                (field) => field.viewFieldName === mappedFields[fieldName],
            );

            if (missingField?.length && missingMappedField?.length === 0) {
                const config = fieldConfigMembers?.filter(
                    ({ viewFieldName }) => viewFieldName === mappedFields[fieldName],
                );

                if (config?.length) missingFieldsInView?.push(config[0]);
            }
        });
    }

    return missingFieldsInView && missingFieldsInView.length
        ? missingFieldsInView.map((field) => convertToListViewMember(field))
        : undefined;
};

export default useMissingGridFields;
