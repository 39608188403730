/* istanbul ignore file */
import React from 'react';
import { WorkflowTemplatePanel } from '../WorkflowTemplatePanel/WorkflowTemplatePanel';
import { ValuationWorkflowTemplateStage, ValuationWorkflowTemplateTask } from '../../../valuationApi';

interface Props {
    children?: ({ openPanel }: { openPanel: () => void }) => React.ReactNode;
    /**
     * A collection of ValuationWorkflowTemplateStages which will separate ValuationWorkflowTasks in the grid
     * by ValuationWorkflowTaskStatus.Name, using Full Width Rows.
     */
    workflowTemplateStages: ValuationWorkflowTemplateStage[];
    /**
     * A collection of ValuationWorkflowTemplateTasks which will comprise the contents of the WorkflowTemplatePanel.
     */
    workflowTemplateTasks: ValuationWorkflowTemplateTask[];
    /**
     * Boolean indicator of if the WorkflowTemplateTasks request errored.
     */
    workflowTemplateStagesRequestIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks are still loading.
     */
    workflowTemplateStagesRequestIsLoading?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks request errored.
     */
    workflowTemplateTasksRequestIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks are still loading.
     */
    workflowTemplateTasksRequestIsLoading?: boolean;
}

export const WorkflowModal = ({
    children,
    workflowTemplateStages,
    workflowTemplateStagesRequestIsErrored,
    workflowTemplateStagesRequestIsLoading,
    workflowTemplateTasks,
    workflowTemplateTasksRequestIsErrored,
    workflowTemplateTasksRequestIsLoading,
}: Props): React.ReactElement => {
    const [isPanelOpen, setPanelOpen] = React.useState<boolean>(false);

    const openPanel = (): void => {
        setPanelOpen(true);
    };

    const closePanel = (): void => {
        setPanelOpen(false);
    };

    return (
        <>
            {children?.({ openPanel })}
            <WorkflowTemplatePanel
                isOpen={isPanelOpen}
                onClickClose={closePanel}
                workflowTemplateStages={workflowTemplateStages}
                workflowTemplateTasks={workflowTemplateTasks}
                workflowTemplateTasksRequestIsErrored={workflowTemplateTasksRequestIsErrored}
                workflowTemplateTasksRequestIsLoading={workflowTemplateTasksRequestIsLoading}
                workflowTemplateStagesRequestIsErrored={workflowTemplateStagesRequestIsErrored}
                workflowTemplateStagesRequestIsLoading={workflowTemplateStagesRequestIsLoading}
            />
        </>
    );
};

export default WorkflowModal;
