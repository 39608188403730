import { isNullOrUndefined } from '@samc/react-ui-core';
import { ValuationWorkflowTaskStatus } from '../../../valuationApi';

export const ByWorkflowTaskStatusSortOrderAsc = (
    a: ValuationWorkflowTaskStatus,
    b: ValuationWorkflowTaskStatus,
): number => {
    // This is just a standard sort by SortOrder with some safeguards around possible undefined values.
    const aSortOrder = a.SortOrder;
    const bSortOrder = b.SortOrder;

    if (isNullOrUndefined(aSortOrder) || isNullOrUndefined(bSortOrder)) {
        return 0;
    }

    return aSortOrder - bSortOrder;
};

export default ByWorkflowTaskStatusSortOrderAsc;
