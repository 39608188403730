import React from 'react';
import { IRowNode, SelectionChangedEvent } from 'ag-grid-community';
import { useFormManagementContext as _useFormManagementContext } from '@samc/react-ui-form';
import { PROPERTY_NAME_CONTACTS_ENABLED_FOR, ValuationContact } from '../../../valuationApi';
import { WorkflowContactGrid } from '../../atoms/WorkflowContactGrid/WorkflowContactGrid';
import { useApplicationContext } from '../../../hooks/useApplicationContext/useApplicationContext';

interface Props {
    /**
     * The id of the Valuation for which to fetch Valuation Contacts.
     */
    valuationId: string;

    /**
     * DI override of the hook to get the FormApi.
     */
    useFormManagementContext?: typeof _useFormManagementContext;
}

export const WorkflowContactGridEnabledFor = ({
    useFormManagementContext = _useFormManagementContext,
    valuationId,
}: Props): React.ReactElement => {
    const { api } = useFormManagementContext();

    const { CurrentUser } = useApplicationContext();

    const handleSelectionChanged = (event: SelectionChangedEvent<ValuationContact>): void => {
        const selectedContacts = event.api.getSelectedNodes().map((node) => node.data);

        api?.onChange(
            PROPERTY_NAME_CONTACTS_ENABLED_FOR,
            /*
             * In order to prevent duplicate attempts to add the current user as an enabled user, we
             * will not include them as part of the Task payload. The ValuationWorkflowTaskAddedEventHandler
             * will add them for us.
             */
            selectedContacts.filter((contact) => !(CurrentUser.id === contact?.UserId)),
        );
    };

    const isRowSelectable = ({ node }: { node: IRowNode<ValuationContact> }): boolean => {
        return !(node.data?.IsValuationAdmin || node.data?.UserId === CurrentUser.id);
    };

    const isRowSelected = (node: IRowNode<ValuationContact>): boolean => {
        return (node.data?.IsValuationAdmin || node.data?.UserId === CurrentUser.id) ?? false;
    };

    return (
        <WorkflowContactGrid
            isRowSelectable={isRowSelectable}
            isRowSelected={isRowSelected}
            onSelectionChanged={handleSelectionChanged}
            showDisabledCheckboxes
            valuationId={valuationId}
        />
    );
};

export default WorkflowContactGridEnabledFor;
