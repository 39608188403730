import React from 'react';
import { IHeaderParams } from 'ag-grid-community';
import { styled } from 'styletron-react';
import { defaultTheme } from '@samc/react-ui-theme';
import { localeDateFormat } from '@samc/react-ui-formatting';
import { ComparisonColId } from './ComparisonGrid.functions';

const StyledHeader = styled('span', ({ align }: { align?: string }) => ({
    margin: align === 'left' ? '0' : '0 auto',
    fontWeight: 'bold',
    color: defaultTheme.TextColors.dark.toString(),
}));

const CustomComparisonHeader = ({ column }: IHeaderParams): JSX.Element => {
    const { headerName, headerComponentParams = {}, field } = column.getColDef();
    const { date = new Date().toString() } = headerComponentParams;

    if (headerName === ComparisonColId.DATE) {
        return <StyledHeader>{localeDateFormat(new Date(date), undefined, false)}</StyledHeader>;
    }

    if (headerName === ComparisonColId.PP || headerName === ComparisonColId.YY) {
        return <StyledHeader>{field}&#160;&#916;</StyledHeader>;
    }

    if (headerName) {
        return <StyledHeader align="left">&#8722;&#160;{headerName}</StyledHeader>;
    }

    return <></>;
};

export default CustomComparisonHeader;
