import React from 'react';
import {
    ApplicationContextType,
    ApplicationContext,
} from '../../contexts/ApplicationContext/ApplicationContext.Context';

export const useApplicationContext = (): ApplicationContextType => {
    return React.useContext(ApplicationContext);
};

export default useApplicationContext;
