import React from 'react';
import { ApiContextType, ApiContext, QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';
import { ValuationWorkflowTask } from '../models/ValuationWorkflowTask';
import { ValuationWorkflowTaskMapper } from '../mappers/ValuationWorkflowTaskMapper';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';

export const VALUATION_WORKFLOW_TASKS_KEY = 'ValuationWorkflowTasks';

export const useValuationWorkflowTasks = (
    valuationId: string,
): UseQueryResult<QueryExecuteResponse<ValuationWorkflowTask>> => {
    const api = React.useContext<ApiContextType>(ApiContext);
    const { SystemExecuteMaxRecordsToReturn } = useApplicationContext();

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_ValuationWorkflowTask',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
            {
                scalarDisplayName: 'Name',
                scalarExpression: '[Name]',
            },
            {
                scalarDisplayName: 'Description',
                scalarExpression: '[Description]',
            },
            {
                scalarDisplayName: 'DueDate',
                scalarExpression: '[DueDateCalculated]',
            },
            {
                scalarDisplayName: 'DueDateOverride',
                scalarExpression: '[DueDateOverride]',
            },
            {
                scalarDisplayName: 'ResponsibleParticipant',
                scalarExpression: '[TaskOwners]',
            },
            {
                scalarDisplayName: 'ActionsLog',
                scalarExpression:
                    "IIF(ISNULLOREMPTY([ActionsLogDescription]), '', CONCAT([ActionsLogDescription],' by ',GetPicklistShortName([ActionsLogUser], 'User'),' ',CDate([ActionsLogTimestamp]),' ',IIF([ActionsLogIsAutoComplete], 'via autocomplete', '')))",
            },
            {
                scalarDisplayName: 'TaskStageId',
                scalarExpression: '[TaskStageId]',
            },
            {
                scalarDisplayName: 'TaskGroup',
                scalarExpression: '[TaskGroup]',
            },
            {
                scalarDisplayName: 'AdHocTaskFlag',
                scalarExpression: '[AdHocTaskFlag]',
            },
            {
                scalarDisplayName: 'TaskStatusId',
                scalarExpression: '[TaskStatusId]',
            },
            {
                scalarDisplayName: 'WorkflowTemplateTaskId',
                scalarExpression: '[WorkflowTemplateTaskId]',
            },
            {
                scalarDisplayName: 'CompletedByDate',
                scalarExpression: '[CompletedByDate]',
            },
            {
                scalarDisplayName: 'ValuationId',
                scalarExpression: '[ValuationId]',
            },
            {
                scalarDisplayName: 'HyperlinkPath',
                scalarExpression: '[HyperlinkPath]',
            },
            {
                scalarDisplayName: 'CurrentUserIsEnabledForTask',
                scalarExpression: '[CurrentUserIsEnabledForTask]',
            },
            {
                scalarDisplayName: 'CurrentUserIsTaskOwner',
                scalarExpression: '[CurrentUserIsTaskOwner]',
            },
        ],
        sorting: {
            order: 'asc',
            orderBy: {
                scalarExpression: '[TaskGroup]',
            },
            sequence: 0,
            additionalSorting: [
                {
                    order: 'asc',
                    orderBy: {
                        scalarExpression: '[SortOrder]',
                    },
                    sequence: 1,
                },
            ],
        },
        paging: { start: 0, stop: SystemExecuteMaxRecordsToReturn },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[ValuationId] = '${valuationId}'`,
        },
    };

    const queryResult = useQuery<QueryExecuteResponse<ValuationWorkflowTask>>(
        [VALUATION_WORKFLOW_TASKS_KEY, valuationId],
        async () => {
            const response = await executeLiftSiftQuery(api.LiftSiftApi, requestBody, api.requestInit, api.ShowMessage);

            return {
                ...response,
                Data: ValuationWorkflowTaskMapper.Map(response.Data),
            };
        },
        { retry: 2, refetchOnWindowFocus: false },
    );

    return queryResult;
};

export default useValuationWorkflowTasks;
