import React from 'react';
import { GFCCellRendererParams } from '@samc/react-ui-grid';
import { WorkflowTaskDueDateRenderer as BaseWorkflowTaskDueDateRenderer } from './WorkflowTaskDueDateRenderer.Panel';

export const WorkflowTaskDueDateRenderer = (props: GFCCellRendererParams): React.ReactElement => {
    const { node } = props;

    return <BaseWorkflowTaskDueDateRenderer workflowTask={node.data} />;
};

export default WorkflowTaskDueDateRenderer;
