import { useRequestBase, RequestResult } from '@samc/react-ui-request';
import { QueryExecuteRequest, QueryExecuteResponse, useQBQuery } from '@samc/screen-config-api';
import { ApiContext } from '../../contexts/ApiContext/ApiContext';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';

export const VALUATION_WORKFLOW_TEMPLATE_STAGES_KEY = 'ValuationWorkflowTemplateStages';

export const useValuationWorkflowTemplateStages = (
    workflowTemplateId?: string,
): RequestResult<QueryExecuteResponse> => {
    const qbQuery = useQBQuery();
    const { SystemExecuteMaxRecordsToReturn } = useApplicationContext();

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_ValuationWorkflowTemplateStage',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'Name',
                scalarExpression: '[Name]',
            },
            {
                scalarDisplayName: 'Order',
                scalarExpression: '[SortOrder]',
            },
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
        ],
        sorting: {
            orderBy: {
                scalarExpression: '[SortOrder]',
            },
            order: 'asc',
        },
        paging: { start: 0, stop: SystemExecuteMaxRecordsToReturn },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[WorkflowTemplateId] = '${workflowTemplateId}'`,
        },
    };

    const result = useRequestBase({
        key: VALUATION_WORKFLOW_TEMPLATE_STAGES_KEY,
        query: qbQuery,
        params: [requestBody],
        requestContext: ApiContext,
        options: {
            enabled: !!workflowTemplateId,
        },
    });

    return result;
};

export default useValuationWorkflowTemplateStages;
