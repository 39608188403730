import { useRequestBase, RequestResult } from '@samc/react-ui-request';
import { QueryExecuteRequest, QueryExecuteResponse, useQBQuery } from '@samc/screen-config-api';
import { ApiContext } from '../../contexts/ApiContext/ApiContext';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';

export const VALUATION_WORKFLOW_TASK_STATUSES_KEY = 'ValuationWorkflowTaskStatuses';

export const useValuationWorkflowTaskStatuses = (): RequestResult<QueryExecuteResponse> => {
    const qbQuery = useQBQuery();
    const { SystemExecuteMaxRecordsToReturn } = useApplicationContext();

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_ValuationWorkflowTaskStatus',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
            {
                scalarDisplayName: 'ShortName',
                scalarExpression: '[CustomShortName]',
            },
            {
                scalarDisplayName: 'LongName',
                scalarExpression: '[LongName]',
            },
            {
                scalarDisplayName: 'SortOrder',
                scalarExpression: '[SortOrder]',
            },
        ],
        sorting: {
            orderBy: {
                scalarExpression: '[SortOrder]',
            },
            order: 'asc',
        },
        paging: { start: 0, stop: SystemExecuteMaxRecordsToReturn },
        summaryMode: false,
    };

    const result = useRequestBase({
        key: VALUATION_WORKFLOW_TASK_STATUSES_KEY,
        query: qbQuery,
        params: [requestBody],
        requestContext: ApiContext,
    });

    return result;
};

export default useValuationWorkflowTaskStatuses;
