/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCurrentUser, Entitlements } from '@samc/single-spa-authentication';
import { MessageBar, MessageBarType, UnsavedChangesDialog } from '@samc/react-ui-core';
import { useHistoryContext } from '@samc/react-ui-history';
import { Transition } from 'history';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import { AddValuationWizard as ValuationWizard } from '../../components';
import { decodeBase64 } from '../../util';

const ValuationWizardViewSet = (): React.ReactElement => {
    const [params] = useSearchParams();
    const [portfolioId, setPortfolioId] = useState<string | null>(null);
    const [assetId, setAssetId] = useState<string | null>(null);
    const [valuationGroupId, setValuationGroupId] = useState<string | null>(null);
    const [showModal, toggleModal] = useState(false);
    const [wizardIsDirty, setWizardisDirty] = useState(false);
    const [transition, setTransition] = useState<Transition>();

    const { browserHistory, hashHistory } = useHistoryContext();

    useEffect(() => {
        if (!wizardIsDirty) return (): void => undefined;

        const unblock = browserHistory.block((tx: Transition) => {
            toggleModal(true);
            setTransition({ ...tx });
        });

        return (): void => {
            unblock();
        };
    }, [browserHistory, hashHistory, wizardIsDirty]);

    const closeModal = useCallback(
        (discard: boolean) => {
            if (discard && transition) {
                setWizardisDirty(false);
                const { action, location, retry } = transition;
                retry();
                if (action === 'PUSH') {
                    setTimeout(() => browserHistory.push(location));
                }
            }

            toggleModal(false);
        },
        [browserHistory, transition],
    );

    const getAssetId = (): string | null => {
        return params.has('assetId') ? params.get('assetId') : null;
    };

    const getValuationGroupId = (): string | null => {
        return params.has('valuationGroupId') ? params.get('valuationGroupId') : null;
    };

    const URL_PARAMNAME_FILTER = 'filter';

    const getBase64FilterExpressionFromUrl = (): string | null => {
        return params.has(URL_PARAMNAME_FILTER) ? params.get(URL_PARAMNAME_FILTER) : null;
    };

    const encodedFilterExpression = getBase64FilterExpressionFromUrl();

    const filters = React.useMemo((): string[] => {
        if (!encodedFilterExpression || !encodedFilterExpression.length) return [];

        const decodedExpression = decodeBase64(encodedFilterExpression);
        try {
            const filterArray = JSON.parse(decodedExpression);
            if (filterArray && Array.isArray(filterArray)) {
                return filterArray;
            }
        } catch (e) {
            //
        }

        return [decodedExpression];
    }, [encodedFilterExpression]);

    React.useEffect(() => {
        if (params.has('portfolioId')) {
            if (portfolioId && portfolioId !== params.get('portfolioId')) {
                setPortfolioId(params.get('portfolioId'));
                setAssetId(null);
                setValuationGroupId(null);
            } else {
                setPortfolioId(params.get('portfolioId'));
                setAssetId(getAssetId());
                setValuationGroupId(getValuationGroupId());
            }
        }
        if (params.has('PortfolioId')) {
            if (portfolioId && portfolioId !== params.get('PortfolioId')) {
                setPortfolioId(params.get('PortfolioId'));
                setAssetId(null);
                setValuationGroupId(null);
            } else {
                setPortfolioId(params.get('PortfolioId'));
                setAssetId(getAssetId());
                setValuationGroupId(getValuationGroupId());
            }
        }
    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

    const user = useCurrentUser();

    const validateUser = React.useMemo(
        () => user.hasEntitlement(Entitlements.DataAccess.GenerateDomainAddEntitlementName('VALU_Valuation')),
        [user],
    );

    if (!user.isLoaded) return <div />;

    if (!validateUser)
        return (
            <div>
                <MessageBar
                    messageBarType={MessageBarType.error}
                    title="Error loading the Valuation Add Wizard"
                    text="You are not authorized to view this page. Please contact your administrator if you believe this is an error."
                />
            </div>
        );

    return (
        <EvaluatorConfigurationProvider value={{}}>
            <ValuationWizard
                key={`key_${portfolioId}`}
                assetId={assetId}
                valuationGroupId={valuationGroupId}
                portfolioId={portfolioId}
                setWizardisDirty={setWizardisDirty}
                filters={filters}
            />
            <UnsavedChangesDialog show={showModal} close={closeModal} />
        </EvaluatorConfigurationProvider>
    );
};

export default ValuationWizardViewSet;
