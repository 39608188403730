import { ValuationWorkflowTask } from '../models/ValuationWorkflowTask';

export class ValuationWorkflowTaskMapper {
    static Map = (records: Record<string, unknown>[]): ValuationWorkflowTask[] => {
        return records.map((record) => ({
            ActionsLog: record.ActionsLog as ValuationWorkflowTask['ActionsLog'],
            AdHocTaskFlag: record.AdHocTaskFlag as ValuationWorkflowTask['AdHocTaskFlag'],
            CanUserActionTask: record.__hidden__editable_TaskStatusId as ValuationWorkflowTask['CanUserActionTask'],
            CanUserOverrideDueDate:
                record.__hidden__editable_DueDateOverride as ValuationWorkflowTask['CanUserOverrideDueDate'],
            CompletedByDate: record.CompletedByDate as ValuationWorkflowTask['CompletedByDate'],
            CurrentUserIsEnabledForTask:
                record.CurrentUserIsEnabledForTask as ValuationWorkflowTask['CurrentUserIsEnabledForTask'],
            CurrentUserIsTaskOwner: record.CurrentUserIsTaskOwner as ValuationWorkflowTask['CurrentUserIsTaskOwner'],
            Description: record.Description as ValuationWorkflowTask['Description'],
            DueDate: record.DueDate as ValuationWorkflowTask['DueDate'],
            DueDateOverride: record.DueDateOverride as ValuationWorkflowTask['DueDateOverride'],
            HyperlinkPath: record.HyperlinkPath as ValuationWorkflowTask['HyperlinkPath'],
            Id: record.Id as ValuationWorkflowTask['Id'],
            Name: record.Name as ValuationWorkflowTask['Name'],
            ResponsibleParticipant: record.ResponsibleParticipant as ValuationWorkflowTask['ResponsibleParticipant'],
            TaskGroup: record.TaskGroup as ValuationWorkflowTask['TaskGroup'],
            TaskStageId: record.TaskStageId as ValuationWorkflowTask['TaskStageId'],
            ValuationId: record.ValuationId as ValuationWorkflowTask['ValuationId'],
            WorkflowTaskStatusId: record.TaskStatusId as ValuationWorkflowTask['WorkflowTaskStatusId'],
            WorkflowTemplateTaskId: record.WorkflowTemplateTaskId as ValuationWorkflowTask['WorkflowTemplateTaskId'],
        }));
    };
}

export default ValuationWorkflowTaskMapper;
