import React, { useCallback, useState } from 'react';
import { Button, UnsavedChangesDialog } from '@samc/react-ui-core';
import { useNavigate } from 'react-router';

interface PropTypes {
    workflow: Record<string, unknown> | undefined;
    setWizardisDirty: (state: boolean) => void;
}

const WorkflowWizardHead = ({ workflow, setWizardisDirty }: PropTypes): React.ReactElement => {
    const [showModal, toggleModal] = useState(false);

    const navigate = useNavigate();

    const closeModal = useCallback(
        (discard: boolean) => {
            toggleModal(false);
            setWizardisDirty(false);
            if (discard) {
                navigate(-1);
            }
        },
        [navigate, setWizardisDirty],
    );

    return (
        <div className="pageHeading">
            <span>
                Change Workflow
                {workflow && workflow.Name ? `: ${workflow.Name}` : ''}
            </span>

            <Button
                buttonType="primary"
                text="Cancel"
                onClick={(): void => {
                    toggleModal(true);
                }}
                className="pull-right"
            />

            <UnsavedChangesDialog show={showModal} close={closeModal} />
        </div>
    );
};

export default WorkflowWizardHead;
