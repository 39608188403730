import React, { useEffect, useMemo, useState } from 'react';
import { ConfirmationDialog } from '@samc/react-ui-core';
import { TaskStatus } from '@samc/screen-config-api';
import MethodologyGrid from './MethodologyGrid';
import {
    AddTask,
    Valuation,
    ValuationAssetMethodologiesVM,
    ValuationAssetMethodologySetupVM,
    ValuationAssetOtherDataScreensVM,
    ValuationAssetOtherValuesVM,
    ValuationDomains,
    ValuationListViewIds,
} from '../../static/ValuationConstants';
import { fetchMethodologies, getGridObjectFromList } from '../AddValutionWizard/AddValuationWizard.service';
import { useApiContext } from '../../../hooks/useApiContext';
import { getMethodologiesValidationResponse, getUpdatedTasksByMethodologySetup } from './ConfirmMethodologies.service';
import { createGuid } from '../../static/ValuationWizard.utils';
import { ConfirmMethodologiesPropTypes, optionType } from './ConfirmMethodologies.interfaces';
import { NavButtons } from '../AddValutionWizard/NavButtons';
import { GridStateType } from '../AddValutionWizard/AddValuations.interfaces';
import { DefaultValidationResponse, ValidationResponse } from '../AddValutionWizard/ValidationUtils';
import { BulkValuationSteps, ValuationSteps } from '../WizardProgress/ProgressConstants';
import SelectAssetOrValuation from './SelectAssetOrValuation';
import { validateMethodologies } from './ValidateConfirmMethodology';
import NavLoader from '../../atoms/NavLoader/NavLoader';

export const ConfirmMethodologies = ({
    valuation,
    setValuation,
    setCurrentStep,
    requestSetupDetails,
    setRequestSetupDetails,
    isBulk = false,
    filters,
    portfolioId,
}: ConfirmMethodologiesPropTypes): React.ReactElement => {
    const { ValuationApi, TaskApi, requestInit = {} } = useApiContext();

    const [confirmationData, updateConfirmationData] = useState<AddTask[]>(
        requestSetupDetails ? [] : [...JSON.parse(JSON.stringify(valuation.addTasks))],
    );

    const [isLoading, setLoading] = useState<boolean>(false);

    const [assets, updateAssets] = useState<optionType>({ list: [], selected: { id: '', displayText: '' } });
    const [valuations, updateValuations] = useState<optionType>({ list: [], selected: { id: '', displayText: '' } });

    const [validationResponse, setValidationResponse] = useState<ValidationResponse>({ ...DefaultValidationResponse });
    const [showPrompt, togglePrompt] = useState<boolean>(false);

    const [methodologyData, setMethodologyData] = useState<GridStateType>({
        apis: undefined,
        data: {},
        key: createGuid(),
    });
    const [otherScreensData, setOtherScreensData] = useState<GridStateType>({
        apis: undefined,
        data: {},
        key: createGuid(),
    });
    const [otherValuesData, setOtherValuesData] = useState<GridStateType>({
        apis: undefined,
        data: {},
        key: createGuid(),
    });

    const [regionalArea, updateRegionalArea] = useState<string>('');

    useEffect(() => {
        if (requestSetupDetails) {
            fetchMethodologies({ ...JSON.parse(JSON.stringify(valuation)) }, ValuationApi, requestInit ?? {}).then(
                (r: Valuation) => {
                    updateConfirmationData(r.addTasks || []);
                    setValuation({ ...JSON.parse(JSON.stringify(r)), addContacts: valuation.addContacts });
                    setRequestSetupDetails(false);
                },
            );
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (confirmationData.length)
            confirmationData.forEach((tasks) => {
                if (tasks.ValuationAssetMethodologySetup) {
                    const temp = tasks.ValuationAssetMethodologySetup.filter(
                        (va) => va.AssetId === assets?.selected.id && va.ValuationId === valuations?.selected.id,
                    ) as ValuationAssetMethodologySetupVM[];

                    if (temp && temp.length) {
                        updateRegionalArea(temp[0].RegionalArea);

                        setMethodologyData({
                            ...methodologyData,
                            data: getGridObjectFromList(
                                temp[0].ValuationAssetMethodologies as ValuationAssetMethodologiesVM[],
                                'VAMethodologiesId',
                            ),
                            key: createGuid(),
                        });

                        setOtherScreensData({
                            ...otherScreensData,
                            data: getGridObjectFromList(
                                temp[0].ValuationAssetOtherDataScreens as ValuationAssetOtherDataScreensVM[],
                                'VAOtherDataScreensId',
                            ),
                            key: createGuid(),
                        });

                        setOtherValuesData({
                            ...otherValuesData,
                            data: getGridObjectFromList(
                                temp[0].ValuationAssetOtherValues as ValuationAssetOtherValuesVM[],
                                'VAOtherValuesId',
                            ),
                            key: createGuid(),
                        });
                    }
                }
            });
    }, [valuations, assets]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (confirmationData && assets && valuations) {
            updateConfirmationData(
                getUpdatedTasksByMethodologySetup(
                    confirmationData,
                    assets,
                    valuations,
                    methodologyData,
                    otherScreensData,
                    otherValuesData,
                ),
            );
        }
    }, [methodologyData, otherScreensData, otherValuesData]); // eslint-disable-line react-hooks/exhaustive-deps

    const Grids = useMemo(() => {
        if (assets?.selected?.id && valuations?.selected?.id) {
            // in ValidateConfirmMethodology, this is the requestIdentifier.
            const assetValuationIds = `${assets.selected.id}-${valuations.selected.id}`;
            const { childResponses = {} } = validationResponse;

            return [
                {
                    gridParams: {
                        domainId: ValuationDomains.VAM,
                        listViewID: ValuationListViewIds.WMS,
                        label: 'Methodologies',
                        primaryKey: 'VAMethodologiesId',
                    },
                    gridData: methodologyData,
                    updateGridData: setMethodologyData,
                    gridErrors: childResponses[`${ValuationDomains.VAM}-${assetValuationIds}`]
                        ? childResponses[`${ValuationDomains.VAM}-${assetValuationIds}`].messages
                        : [],
                },
                {
                    gridParams: {
                        domainId: ValuationDomains.VAODS,
                        listViewID: ValuationListViewIds.WVAODS,
                        label: 'Other Data Input Screens',
                        primaryKey: 'VAOtherDataScreensId',
                    },
                    gridData: otherScreensData,
                    updateGridData: setOtherScreensData,
                    gridErrors: childResponses[`${ValuationDomains.VAODS}-${assetValuationIds}`]
                        ? childResponses[`${ValuationDomains.VAODS}-${assetValuationIds}`].messages
                        : [],
                },
                {
                    gridParams: {
                        domainId: ValuationDomains.VAOV,
                        listViewID: ValuationListViewIds.WAOV,
                        label: 'Other Values',
                        primaryKey: 'VAOtherValuesId',
                    },
                    gridData: otherValuesData,
                    updateGridData: setOtherValuesData,
                    gridErrors: childResponses[`${ValuationDomains.VAOV}-${assetValuationIds}`]
                        ? childResponses[`${ValuationDomains.VAOV}-${assetValuationIds}`].messages
                        : [],
                },
            ];
        }
        return [];
    }, [
        assets?.selected,
        methodologyData,
        otherScreensData,
        otherValuesData,
        validationResponse,
        valuations?.selected,
    ]);

    const onResponse = (stay: boolean): void => {
        if (!stay) {
            setCurrentStep(
                isBulk ? BulkValuationSteps.SetupMethodologyAndApproach : ValuationSteps.SetupMethodologyAndApproach,
            );
        }
        togglePrompt(!stay);
    };

    const onBack = (): void => togglePrompt(true);

    const onContinue = (): void => {
        setLoading(true);

        validateMethodologies(confirmationData || [{}], assets, TaskApi, requestInit).then((taskParams): void => {
            const [response, request] = taskParams;

            setLoading(false);

            if (response.statusCode !== TaskStatus.Completed) {
                setValidationResponse(getMethodologiesValidationResponse(request, response));
            } else {
                setValuation({ addTasks: confirmationData, addContacts: valuation.addContacts });
                setCurrentStep(isBulk ? BulkValuationSteps.CreateValuations : ValuationSteps.CreateValuations);
            }
        });
    };

    const onSkip = (): void =>
        setCurrentStep(isBulk ? BulkValuationSteps.CreateValuations : ValuationSteps.CreateValuations);

    return (
        <>
            <NavButtons
                isDisabled={isLoading}
                onBack={onBack}
                onContinue={onContinue}
                onSkip={onSkip}
                validationResponse={{ ...validationResponse }}
                updateValidationResponse={setValidationResponse}
                domainNameMap={{
                    ValuationAssetMethodologySetup: 'ValuationName',
                    ValuationAssetMethodologies: 'ValuationName',
                    ValuationAssetOtherDataScreens: 'ValuationName',
                    ValuationAssetOtherValues: 'ValuationName',
                }}
            >
                <NavLoader isLoading={isLoading} label="Validating Methodologies..." />
            </NavButtons>

            <div className="confirm-methodologies" style={{ flex: '1 0 auto' }}>
                <SelectAssetOrValuation
                    valuations={valuations}
                    updateValuations={updateValuations}
                    assets={assets}
                    updateAssets={updateAssets}
                    tasks={confirmationData}
                />

                {Grids.map(({ gridData, gridParams, updateGridData, gridErrors }) => (
                    <MethodologyGrid
                        key={gridData.key}
                        gridData={gridData}
                        gridParams={gridParams}
                        updateGridData={updateGridData}
                        errors={gridErrors}
                        regionalArea={regionalArea}
                        filters={filters}
                        portfolioId={portfolioId}
                    />
                ))}
            </div>

            {showPrompt && (
                <ConfirmationDialog
                    giveAnswer={onResponse}
                    detail="Moving back to the previous step in the wizard will remove any changes made on  this screen. Please confirm you would like to go back."
                    confirmText="Stay on this screen"
                    cancelText="Go Back"
                    title="Unsaved Changes"
                />
            )}
        </>
    );
};

export default ConfirmMethodologies;
