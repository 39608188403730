import React from 'react';
import { TaskRequest, TaskResponse, TaskStatus } from '@samc/screen-config-api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { submitTask } from '../api/Requests';
import { useApiContext } from '../../hooks/useApiContext';
import { VALUATION_WORKFLOW_TASKS_KEY } from '../useValuationWorkflowTasks/useValuationWorkflowTasks';
import { TaskRequestBuilder } from '../../builders/TaskRequestBuilder/TaskRequestBuilder';

interface Props {
    contactsAssignedToRowsToAdd: { UserId: string }[];
    contactsAssignedToRowsToDelete: { Id: string }[];
    contactsEnabledForRowsToAdd: { UserId: string }[];
    contactsEnabledForRowsToDelete: { Id: string }[];
    contactsViewableByRowsToAdd: { UserId: string }[];
    contactsViewableByRowsToDelete: { Id: string }[];
    valuationId: string;

    actionableFlag?: boolean | null;
    adHocTaskFlag?: boolean | null;
    description?: string | null;
    dueDateCalculated?: string | null;
    id?: string | null;
    name?: string | null;
    taskStatusId?: string | null;
}

export const useValuationWorkflowTaskSaver = (): [
    UseMutationResult<[TaskResponse, TaskRequest], unknown, Props, unknown>,
] => {
    const api = useApiContext();
    const queryClient = useQueryClient();

    const onSave = React.useCallback(
        async ({
            actionableFlag = false,
            adHocTaskFlag = false,
            contactsAssignedToRowsToAdd,
            contactsAssignedToRowsToDelete,
            contactsEnabledForRowsToAdd,
            contactsEnabledForRowsToDelete,
            contactsViewableByRowsToAdd,
            contactsViewableByRowsToDelete,
            description,
            dueDateCalculated,
            id,
            name,
            taskStatusId,
            valuationId,
        }: Props): Promise<[TaskResponse, TaskRequest]> => {
            let masterDecrementingIndex = -2;

            const workflowTaskId = id ?? '-1';

            const taskRequestBuilder = new TaskRequestBuilder(
                'VALU_ValuationWorkflowTask',
                'VALU_ValuationWorkflowTask',
                'WorkflowAdHocTaskForm - VALU_ValuationWorkflowTask',
            ).withPayload(workflowTaskId, {
                ActionableFlag: actionableFlag,
                AdHocTaskFlag: adHocTaskFlag,
                Description: description,
                DueDateCalculated: dueDateCalculated,
                Id: workflowTaskId,
                Name: name,
                NormalizedSortOrder: 1,
                TaskStatusId: taskStatusId,
                ValuationId: valuationId,
            });

            if (!id) {
                taskRequestBuilder.asAddTask();
            }

            if (contactsAssignedToRowsToAdd.length >= 1 || contactsAssignedToRowsToDelete.length >= 1) {
                taskRequestBuilder.withChildRequest(
                    'VALU_ValuationWorkflowTaskOwner',
                    'VALU_ValuationWorkflowTaskOwner',
                    'WorkflowAdHocTaskForm - VALU_ValuationWorkflowTaskOwner',
                );

                contactsAssignedToRowsToAdd.forEach((contact) => {
                    const simpleIndexAsString = String(masterDecrementingIndex);

                    taskRequestBuilder
                        .withPayload(simpleIndexAsString, {
                            Id: simpleIndexAsString,
                            OwnerUserId: contact.UserId,
                            WorkflowTaskId: workflowTaskId,
                        })
                        .asAddTask();

                    masterDecrementingIndex--;
                });

                contactsAssignedToRowsToDelete.forEach((rowToDelete) => {
                    taskRequestBuilder
                        .withPayload(rowToDelete.Id, {
                            Id: rowToDelete.Id,
                        })
                        .asDeleteTask();
                });
            }

            if (contactsEnabledForRowsToAdd.length >= 1 || contactsEnabledForRowsToDelete.length >= 1) {
                taskRequestBuilder.withChildRequest(
                    'VALU_ValuationWorkflowTaskEnabledUsers',
                    'VALU_ValuationWorkflowTaskEnabledUsers',
                    'WorkflowAdHocTaskForm - VALU_ValuationWorkflowTaskEnabledUsers',
                );

                contactsEnabledForRowsToAdd.forEach((contactToAdd) => {
                    const simpleIndexAsString = String(masterDecrementingIndex);

                    taskRequestBuilder
                        .withPayload(simpleIndexAsString, {
                            Id: simpleIndexAsString,
                            UserId: contactToAdd.UserId,
                            WorkflowTaskId: workflowTaskId,
                        })
                        .asAddTask();

                    masterDecrementingIndex--;
                });

                contactsEnabledForRowsToDelete.forEach((contactToDelete) => {
                    taskRequestBuilder
                        .withPayload(contactToDelete.Id, {
                            Id: contactToDelete.Id,
                        })
                        .asDeleteTask();
                });
            }

            if (contactsViewableByRowsToAdd.length >= 1 || contactsViewableByRowsToDelete.length >= 1) {
                taskRequestBuilder.withChildRequest(
                    'VALU_ValuationWorkflowTaskViewableUsers',
                    'VALU_ValuationWorkflowTaskViewableUsers',
                    'WorkflowAdHocTaskForm - VALU_ValuationWorkflowTaskViewableUsers',
                );

                contactsViewableByRowsToAdd.forEach((contactToAdd) => {
                    const simpleIndexAsString = String(masterDecrementingIndex);

                    taskRequestBuilder
                        .withPayload(simpleIndexAsString, {
                            Id: simpleIndexAsString,
                            UserId: contactToAdd.UserId,
                            WorkflowTaskId: workflowTaskId,
                        })
                        .asAddTask();

                    masterDecrementingIndex--;
                });

                contactsViewableByRowsToDelete.forEach((contactToDelete) => {
                    taskRequestBuilder
                        .withPayload(contactToDelete.Id, {
                            Id: contactToDelete.Id,
                        })
                        .asDeleteTask();
                });
            }

            const taskRequest = taskRequestBuilder.build();

            return submitTask(
                api.TaskApi,
                taskRequest.domainId,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                taskRequest.fieldConfigurationId!,
                taskRequest.requestIdentifier,
                taskRequest.payload,
                api.requestInit ?? {},
                false,
                api.FetchApi,
            );
        },
        [api.FetchApi, api.TaskApi, api.requestInit],
    );

    const saveMutation = useMutation<[TaskResponse, TaskRequest], unknown, Props>(onSave, {
        onSuccess: (data: [TaskResponse, TaskRequest<Record<string, unknown>>]) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [result, _request] = data;
            if (result.statusCode === TaskStatus.Completed && result.messageCount === 0) {
                api.ShowMessage(`Task Saved Successfully`, 'success');
                queryClient.invalidateQueries([VALUATION_WORKFLOW_TASKS_KEY]);
            } else {
                api.ShowMessage(`Failed to Save Task`, 'error');
            }
        },
        onError: (e) => {
            throw e;
        },
    });

    return [saveMutation];
};

export default useValuationWorkflowTaskSaver;
