/* istanbul ignore file */
import React from 'react';
import { FormHelpers, FormManagementContextProvider, FormState } from '@samc/react-ui-form';
import { TaskStatus } from '@samc/screen-config-api';
import { HeaderContextProvider } from '@samc/react-ui-history';
import { IPanelProps, IRenderFunction, Panel, PanelType } from '@fluentui/react';
import {
    PROPERTY_NAME_CONTACTS_ASSIGNED_TO,
    ValuationContact,
    ValuationWorkflowTask,
    useValuationWorkflowTaskSaver as _useValuationWorkflowTaskSaver,
} from '../../../valuationApi';
import { useValidationMessage as _useValidationMessage } from '../../hooks/useValidationMessage/useValidationMessage';
import { useWorkflowTaskValidationPopupContext } from '../../contexts/WorkflowTaskValidationPopupContext';
import { WorkflowEditAdHocTaskForm } from '../../molecules/WorkflowEditAdHocTaskForm/WorkflowEditAdHocTaskForm';
import { PanelHeader } from '../../atoms/PanelHeader/PanelHeader';

interface Props {
    initialContactsAssignedTo?: { Id: string; UserId: string }[];
    initialContactsEnabledFor?: { Id: string; UserId: string }[];
    initialContactsViewableBy?: { Id: string; UserId: string }[];
    /**
     * A ValuationWorkflowTask object to use as the initial data for the form.
     */
    initialFormData: ValuationWorkflowTask;
    /**
     * If true, the Panel is displayed, if false the Panel is hidden.
     */
    isOpen: boolean;
    /**
     * The Id of the Valuation for which ValuationContacts will be fetched.
     */
    valuationId: string;

    /**
     * Callback on click of Cancel button.
     */
    onCancel?: () => void | Promise<void>;
    /**
     * Callback on click of Submit and Close button.
     */
    onSubmitAndClose?: () => void | Promise<void>;
    /**
     * Callback on successful submission of the Form.
     */
    onSubmitSuccess?: () => void;
    /**
     * DI override of the hook to show a Validation Message.
     */
    useValidationMessage?: typeof _useValidationMessage;
    /**
     * DI override of the hook to access the Mutation for saving a ValuationWorkflowTask.
     */
    useValuationWorkflowTaskSaver?: typeof _useValuationWorkflowTaskSaver;
}

export const WorkflowEditAdHocTaskPanel = ({
    initialContactsAssignedTo = [],
    initialContactsEnabledFor = [],
    initialContactsViewableBy = [],
    initialFormData,
    isOpen,
    onCancel,
    onSubmitAndClose,
    onSubmitSuccess,
    useValidationMessage = _useValidationMessage,
    useValuationWorkflowTaskSaver = _useValuationWorkflowTaskSaver,
    valuationId,
}: Props): React.ReactElement => {
    const [saveTask] = useValuationWorkflowTaskSaver();
    const [showValidationMessage] = useValidationMessage({});
    const { setValidationRequest, setValidationResponse, setVisible } = useWorkflowTaskValidationPopupContext();

    const dirtinessScope = React.useRef<HTMLDivElement>(null);

    const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
    const [contactsAssignedToRowsToAdd, setContactsAssignedToRowsToAdd] = React.useState<ValuationContact[]>([]);
    const [contactsAssignedToRowsToDelete, setContactsAssignedToRowsToDelete] = React.useState<ValuationContact[]>([]);
    const [contactsEnabledForRowsToAdd, setContactsEnabledForRowsToAdd] = React.useState<ValuationContact[]>([]);
    const [contactsEnabledForRowsToDelete, setContactsEnabledForRowsToDelete] = React.useState<ValuationContact[]>([]);
    const [contactsViewableByRowsToAdd, setContactsViewableByRowsToAdd] = React.useState<ValuationContact[]>([]);
    const [contactsViewableByRowsToDelete, setContactsViewableByRowsToDelete] = React.useState<ValuationContact[]>([]);

    const handleFormStateChange = (state: FormState<ValuationWorkflowTask>): void => {
        setIsFormValid(state.isValid);
    };

    const onContactAssignedToDeselected = (contact?: ValuationContact): void => {
        if (!contact) return;

        setContactsAssignedToRowsToAdd(contactsAssignedToRowsToAdd.filter((c) => c.UserId !== contact.UserId));

        if (initialContactsAssignedTo.some((c) => c.UserId === contact.UserId)) {
            setContactsAssignedToRowsToDelete([...contactsAssignedToRowsToDelete, contact]);
        }
    };

    const onContactAssignedToSelected = (contact?: ValuationContact): void => {
        if (!contact) return;

        setContactsAssignedToRowsToDelete(contactsAssignedToRowsToDelete.filter((c) => !(c.UserId === contact.UserId)));

        if (initialContactsAssignedTo.some((c) => c.UserId === contact.UserId)) return;

        setContactsAssignedToRowsToAdd([...contactsAssignedToRowsToAdd, contact]);
    };

    const onContactEnabledForDeselected = (contact?: ValuationContact): void => {
        if (!contact) return;

        setContactsEnabledForRowsToAdd(contactsEnabledForRowsToAdd.filter((c) => !(c.UserId === contact.UserId)));

        if (initialContactsEnabledFor.some((c) => c.UserId === contact.UserId)) {
            setContactsEnabledForRowsToDelete([...contactsEnabledForRowsToDelete, contact]);
        }
    };

    const onContactEnabledForSelected = (contact?: ValuationContact): void => {
        if (!contact) return;

        setContactsEnabledForRowsToDelete(contactsEnabledForRowsToDelete.filter((c) => !(c.UserId === contact.UserId)));

        if (initialContactsEnabledFor.some((c) => c.UserId === contact.UserId)) return;

        setContactsEnabledForRowsToAdd([...contactsEnabledForRowsToAdd, contact]);
    };

    const onContactViewableByDeselected = (contact?: ValuationContact): void => {
        if (!contact) return;

        setContactsViewableByRowsToAdd(contactsViewableByRowsToAdd.filter((c) => !(c.UserId === contact.UserId)));

        if (initialContactsViewableBy.some((c) => c.UserId === contact.UserId)) {
            setContactsViewableByRowsToDelete([...contactsViewableByRowsToDelete, contact]);
        }
    };

    const onContactViewableBySelected = (contact?: ValuationContact): void => {
        if (!contact) return;

        setContactsViewableByRowsToDelete(contactsViewableByRowsToDelete.filter((c) => !(c.UserId === contact.UserId)));

        if (initialContactsViewableBy.some((c) => c.UserId === contact.UserId)) return;

        setContactsViewableByRowsToAdd([...contactsViewableByRowsToAdd, contact]);
    };

    const onFormSubmit = async (
        values: ValuationWorkflowTask,
        helpers: FormHelpers<ValuationWorkflowTask>,
    ): Promise<void> => {
        if (contactsAssignedToRowsToAdd.length === 0 && initialContactsAssignedTo.length === 0) {
            showValidationMessage(values.Name || 'New Task', 'At least one user must be assigned to the task.', [
                PROPERTY_NAME_CONTACTS_ASSIGNED_TO,
            ]);

            return;
        }

        if (
            contactsAssignedToRowsToAdd.length === 0 &&
            initialContactsAssignedTo.every((c) => contactsAssignedToRowsToDelete.some((d) => d.UserId === c.UserId))
        ) {
            showValidationMessage(values.Name || 'New Task', 'At least one user must be assigned to the task.', [
                PROPERTY_NAME_CONTACTS_ASSIGNED_TO,
            ]);

            return;
        }

        const [result, request] = await saveTask.mutateAsync({
            actionableFlag: true,
            adHocTaskFlag: true,
            contactsAssignedToRowsToAdd,
            contactsAssignedToRowsToDelete,
            contactsEnabledForRowsToAdd,
            contactsEnabledForRowsToDelete,
            contactsViewableByRowsToAdd,
            contactsViewableByRowsToDelete,
            description: values.Description,
            dueDateCalculated: values.DueDate,
            id: values.Id,
            name: values.Name,
            taskStatusId: 'Incomplete',
            valuationId,
        });

        if (result.statusCode === TaskStatus.Completed) {
            onSubmitSuccess?.();
            await helpers.onReset();

            return;
        }

        setValidationRequest(request);
        setValidationResponse(result);
        setVisible(true);
    };

    const navigationContentRenderer: IRenderFunction<IPanelProps> = (): React.ReactElement => (
        <PanelHeader
            dirtinessScope={dirtinessScope}
            cancelDisabledOverride={false}
            onCancel={onCancel}
            onSubmitAndClose={onSubmitAndClose}
            submitDisabledOverride={!isFormValid}
            title="Ad-Hoc Task"
        />
    );

    return (
        <Panel
            isOpen={isOpen}
            onRenderNavigationContent={navigationContentRenderer}
            type={PanelType.medium}
            popupProps={{ ref: dirtinessScope }}
            layerProps={{ eventBubblingEnabled: true }}
        >
            <HeaderContextProvider>
                <FormManagementContextProvider
                    initialData={initialFormData}
                    onSubmit={onFormSubmit}
                    onFormStateChange={handleFormStateChange}
                >
                    <WorkflowEditAdHocTaskForm
                        onContactAssignedToDeselected={onContactAssignedToDeselected}
                        onContactAssignedToSelected={onContactAssignedToSelected}
                        onContactEnabledForDeselected={onContactEnabledForDeselected}
                        onContactEnabledForSelected={onContactEnabledForSelected}
                        onContactViewableByDeselected={onContactViewableByDeselected}
                        onContactViewableBySelected={onContactViewableBySelected}
                        valuationId={valuationId}
                        workflowTaskId={initialFormData.Id}
                    />
                </FormManagementContextProvider>
            </HeaderContextProvider>
        </Panel>
    );
};

export default WorkflowEditAdHocTaskPanel;
