import { useEvaluator } from '@samc/expressions-react';
import { FieldConfigurationMember, useFieldConfigurationByDomain } from '@samc/screen-config-api';

const useEvaluatedRuleByDomainConfig = (
    domainId: string,
    viewFieldName: FieldConfigurationMember['viewFieldName'],
): {
    evaluateRule: (params: Record<string, unknown>, expression: keyof FieldConfigurationMember) => unknown;
    fieldConfiguration: FieldConfigurationMember | undefined;
} => {
    const { data: fieldConfigData } = useFieldConfigurationByDomain(domainId);
    const { evaluate } = useEvaluator();
    const fieldConfigMembers = fieldConfigData?.at(0)?.fieldConfigurationMembers;
    const fieldConfiguration = fieldConfigMembers?.find((members) => members.viewFieldName === viewFieldName);

    const evaluateRule = (evalData: Record<string, unknown>, expression: keyof FieldConfigurationMember): unknown =>
        evaluate(evalData, fieldConfiguration ? (fieldConfiguration[expression] as string) : '');

    return {
        fieldConfiguration,
        evaluateRule,
    };
};

export default useEvaluatedRuleByDomainConfig;
