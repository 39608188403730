import React from 'react';
import { toastError, ToastMessage, toastSuccess } from '@samc/react-ui-core';
import { TaskStatus, ViewSetAction } from '@samc/screen-config-api';
import { apiProvider } from '../../../valuationApi';
import { useApiContext } from '../../../hooks/useApiContext';

interface Props {
    valuationGroupId: string | undefined;
    refreshViewSet: () => void;
    updateAction: React.Dispatch<ViewSetAction | undefined>;
}

const DeleteValuationGroup = ({ valuationGroupId, refreshViewSet, updateAction }: Props): React.ReactElement => {
    const api = useApiContext();
    React.useEffect((): void => {
        apiProvider
            .submitTask(
                api.ValuationApi,
                'ValuationGroup',
                'ValuationGroup',
                'ValuationGroup Grid',
                {
                    [String(valuationGroupId)]: {
                        ValuationGroupId: String(valuationGroupId),
                        __DELETED__: true,
                    },
                },
                api.requestInit ?? {},
            )
            .then(([response]): void => {
                updateAction(undefined);
                if (response.statusCode !== TaskStatus.Completed) {
                    toastError(<ToastMessage title="Error" message="Something went wrong" />);
                } else {
                    toastSuccess(<ToastMessage title="Success" message="Valuation Group deleted successfully." />);
                    refreshViewSet();
                }
            });
    }, []);
    return <></>;
};

export default DeleteValuationGroup;
