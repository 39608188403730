import { TaskRequest, TaskResponse } from '@samc/screen-config-api';
import {
    AddTask,
    MethodologyTemplate,
    Valuation,
    ValuationAssetMethodologiesVM,
    ValuationAssetOtherDataScreensVM,
    ValuationAssetOtherValuesVM,
} from '../../static/ValuationConstants';
import { GridStateType } from '../AddValutionWizard/AddValuations.interfaces';
import { ValuationMethod, ValuationType } from '../AddValutionWizard/ValuationWizard.enums';
import { ListType, optionType } from './ConfirmMethodologies.interfaces';
import { ValidationResponse, getCustomizedErrors } from '../AddValutionWizard/ValidationUtils';

export const getValuationPicklistData = (addTasks: AddTask[]): optionType => {
    let valuationNames: ListType[] = [];

    if (
        addTasks.length === 1 &&
        addTasks[0].ValuationType === ValuationType.SingleAsset &&
        addTasks[0].ValuationMethod === ValuationMethod.SingleValuation
    ) {
        valuationNames = addTasks.map(
            ({ ValuationName, ValuationUniqId, ValuationAssetMethodologySetup }): ListType => ({
                id: ValuationUniqId || '',
                displayText:
                    ValuationAssetMethodologySetup &&
                    (ValuationAssetMethodologySetup[0].MethodologyTemplateId?.toUpperCase() ===
                        MethodologyTemplate.Manual ||
                        ValuationAssetMethodologySetup[0].MethodologyTemplateId?.toUpperCase() ===
                            MethodologyTemplate.None)
                        ? `* ${ValuationName}`
                        : ValuationName || '',
            }),
        );
    } else {
        valuationNames = addTasks.map(
            ({ ValuationName, ValuationUniqId }): ListType => ({
                id: ValuationUniqId || '',
                displayText: ValuationName || '',
            }),
        );
    }

    return { list: valuationNames, selected: valuationNames[0] };
};

export const getAssetPicklistData = (
    addTasks: AddTask[],
    selectedValuation: ListType,
    selectedAsset: ListType,
): optionType => {
    const selected = addTasks.filter(({ ValuationUniqId }): boolean => ValuationUniqId === selectedValuation.id);

    if (
        selected.length &&
        selected[0].ValuationAssetMethodologySetup &&
        selected[0].ValuationAssetMethodologySetup.length
    ) {
        let assetList: ListType[] = [];

        if (
            selected[0].ValuationMethod === ValuationMethod.SingleValuation &&
            selected[0].ValuationType === ValuationType.SingleAsset
        ) {
            assetList = selected[0].ValuationAssetMethodologySetup.map(
                ({ AssetId, AssetName, MethodologyTemplateId }) => ({
                    id: AssetId || '',
                    displayText:
                        MethodologyTemplateId?.toUpperCase() === MethodologyTemplate.Manual ||
                        MethodologyTemplateId?.toUpperCase() === MethodologyTemplate.None
                            ? `* ${AssetName}`
                            : AssetName || '',
                }),
            );
        } else {
            assetList = selected[0].ValuationAssetMethodologySetup.map(({ AssetId, AssetName }) => ({
                id: AssetId || '',
                displayText: AssetName || '',
            }));
        }

        return {
            list: assetList,
            selected: assetList.find(({ id }) => id === selectedAsset.id) ?? assetList[0],
        };
    }

    return { list: [], selected: { id: '', displayText: '' } };
};

/**
 * @deprecated
 * This methods modified the row ids for previously added rows,
 * this ensures the ids for newly added rows (pinned & grid) do not conflict.
 */
export const changeRowIds = (valuation: Valuation): Valuation => {
    const startIndex = -999999999;

    const updateIds = <
        T extends ValuationAssetMethodologiesVM | ValuationAssetOtherDataScreensVM | ValuationAssetOtherValuesVM,
    >(
        list: T[] | undefined,
        primaryField: string,
    ): T[] =>
        list
            ? list.map((item, index) => ({
                  ...item,
                  [primaryField]: `${startIndex + index}`,
                  _newRowId: `${startIndex + index}`,
              }))
            : [];

    return {
        addTasks: valuation.addTasks?.map(({ ValuationAssetMethodologySetup, ...task }) => ({
            ...task,
            ValuationAssetMethodologySetup: ValuationAssetMethodologySetup?.map(
                ({
                    ValuationAssetMethodologies,
                    ValuationAssetOtherDataScreens,
                    ValuationAssetOtherValues,
                    ...vams
                }) => ({
                    ...vams,
                    ValuationAssetMethodologies: updateIds(ValuationAssetMethodologies, 'VAMethodologiesId'),
                    ValuationAssetOtherDataScreens: updateIds(ValuationAssetOtherDataScreens, 'VAOtherDataScreensId'),
                    ValuationAssetOtherValues: updateIds(ValuationAssetOtherValues, 'VAOtherValuesId'),
                }),
            ),
        })),
    };
};

/**
 * Updates the grid data for Methodologies, OtherScreen & OtherValues grids into the valuation object
 */
export const getUpdatedTasksByMethodologySetup = (
    addTasks: AddTask[],
    assets: optionType,
    valuations: optionType,
    methodologiesData: GridStateType,
    otherScreensData: GridStateType,
    otherValuesData: GridStateType,
): AddTask[] =>
    addTasks.map((task) => ({
        ...task,
        ValuationAssetMethodologySetup: task.ValuationAssetMethodologySetup?.map(
            ({
                AssetId,
                ValuationId,
                ValuationAssetMethodologies,
                ValuationAssetOtherDataScreens,
                ValuationAssetOtherValues,
                ...vm
            }) => {
                const isSelected = AssetId === assets?.selected.id && ValuationId === valuations?.selected.id;

                return {
                    AssetId,
                    ValuationId,
                    ValuationAssetMethodologies: (isSelected
                        ? Object.values(methodologiesData.data)
                        : ValuationAssetMethodologies) as ValuationAssetMethodologiesVM[],
                    ValuationAssetOtherDataScreens: (isSelected
                        ? Object.values(otherScreensData.data)
                        : ValuationAssetOtherDataScreens) as ValuationAssetOtherDataScreensVM[],
                    ValuationAssetOtherValues: (isSelected
                        ? Object.values(otherValuesData.data)
                        : ValuationAssetOtherValues) as ValuationAssetOtherValuesVM[],
                    ...vm,
                };
            },
        ),
    }));

export const modifyMessages = (
    messages: TaskResponse['messages'],
    task: Record<string, unknown>,
    domainId: string,
): TaskResponse['messages'] => {
    const domainNameToGrid: Record<string, string> = {
        ValuationAssetMethodologies: 'Methodologies',
        ValuationAssetOtherDataScreens: 'Other Data Input Screens',
        ValuationAssetOtherValues: 'Other Values',
    };

    return messages.map((message) => ({
        ...message,
        recordIdentifier: `${domainNameToGrid[domainId]} (Asset: ${task.AssetName}, Valuation: ${task.ValuationName})`,
    }));
};

export const updateChildResponse = (
    { payload }: TaskRequest,
    { childResponses }: TaskResponse,
): TaskResponse['childResponses'] => {
    const updatedResponses: Record<string, TaskResponse> = {};
    const tasksByIdentifiers: Record<string, Record<string, unknown>> = {};

    const childIdToGridIdentifier: Record<string, (string | number)[]> = {};

    Object.keys(payload).forEach((payloadKey) => {
        payload[payloadKey].__CHILD_REQUESTS__?.forEach((cr) => {
            Object.keys(cr.payload).forEach((childKey) => {
                const gridIdentifier = `${cr.domainId}-${cr.payload[childKey].AssetId}-${cr.payload[childKey].ValuationId}`;
                childIdToGridIdentifier[gridIdentifier] ??= [];
                childIdToGridIdentifier[gridIdentifier].push(childKey);
                tasksByIdentifiers[gridIdentifier] = payload[payloadKey];
            });
        });
    });

    Object.keys(tasksByIdentifiers).forEach((gridIdentifier) => {
        const domainId = gridIdentifier.split('-')[0];
        if (childResponses[domainId]) {
            updatedResponses[gridIdentifier] = { ...childResponses[domainId] };
            updatedResponses[gridIdentifier].requestIdentifier = gridIdentifier;
            const messages = updatedResponses[gridIdentifier].messages.filter(
                (x) => x.recordIdentifier && childIdToGridIdentifier[gridIdentifier].indexOf(x.recordIdentifier) >= 0,
            );
            updatedResponses[gridIdentifier].messages = modifyMessages(
                messages,
                tasksByIdentifiers[gridIdentifier],
                domainId,
            );
            updatedResponses[gridIdentifier].messageCount = messages.length;
        }
    });

    return updatedResponses;
};

export const getMethodologiesValidationResponse = (
    request: TaskRequest,
    response: TaskResponse,
): ValidationResponse => ({
    ...response,
    childResponses: updateChildResponse(request, response),
    list: getCustomizedErrors(response, request),
    messages: getCustomizedErrors(response, request),
    showList: true,
    request,
});

export const filterDeletedRecords = (records: Record<string, Record<string, unknown>>): Record<string, unknown> => {
    const updatedRecords: Record<string, unknown> = {};

    Object.keys(records).forEach((recordKey) => {
        if (!records[recordKey]?.__DELETED__) updatedRecords[recordKey] = records[recordKey];
    });

    return updatedRecords;
};
