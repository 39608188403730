import React from 'react';
import { localeDateFormat } from '@samc/react-ui-formatting';
import { styled } from 'styletron-react';
import { IconButton } from '@fluentui/react';
import { ValuationWorkflowTask } from '../../../valuationApi';

/* istanbul ignore next */
const StyledFlexWrapper = styled('div', () => ({
    height: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));

/* istanbul ignore next */
const StyledEditButton = styled(IconButton, () => ({
    height: '100%',
}));

const DateRenderer = ({ date: dateAsString }: { date?: string | null }): React.ReactElement => {
    if (!dateAsString) {
        return <span />;
    }

    const date = new Date(dateAsString);

    return <span>{localeDateFormat(date)}</span>;
};

interface Props {
    onClick: () => void;
    workflowTask: ValuationWorkflowTask;
}

export const WorkflowTaskDueDateRenderer = ({ onClick, workflowTask }: Props): React.ReactElement => {
    if (!workflowTask.CanUserOverrideDueDate) {
        return (
            <StyledFlexWrapper>
                <DateRenderer date={workflowTask.DueDateOverride ?? workflowTask.DueDate} />
            </StyledFlexWrapper>
        );
    }

    return (
        <StyledFlexWrapper>
            <DateRenderer date={workflowTask.DueDateOverride ?? workflowTask.DueDate} />
            <StyledEditButton ariaLabel="Edit" iconProps={{ iconName: 'Edit' }} onClick={onClick} title="Edit" />
        </StyledFlexWrapper>
    );
};

export default WorkflowTaskDueDateRenderer;
