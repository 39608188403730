import { EsInputMenuOption } from '../../../valuationApi';
import { MenuItem } from '../../atoms/NestedMenu/MenuItem';

export const esInputMenuOptionsToNestedMenuOptions = (
    esInputMenuOptions: EsInputMenuOption[] | undefined,
    setSelectedOption?: (option: EsInputMenuOption) => void,
): MenuItem<EsInputMenuOption>[] | undefined => {
    return esInputMenuOptions?.map((o): MenuItem<EsInputMenuOption> => {
        const { shortName, childItems, id, formViewId } = o;

        const hasChildren = !!childItems && childItems.length > 0;
        const hasFormView = !!formViewId;

        return {
            displayText: shortName,
            onClick: (): void => {
                if (!setSelectedOption) return;

                if (!hasFormView) {
                    // if the item itself does nothing, route to first child
                    if (hasChildren) setSelectedOption(childItems[0]);
                } else setSelectedOption(o);
            },
            id,
            data: o,
            isDisabled: !hasChildren && !hasFormView,
            ...(hasChildren && {
                children: esInputMenuOptionsToNestedMenuOptions(childItems, setSelectedOption),
            }),
        };
    });
};

export const esInputMenuOptionExists = (needleId: string, items: EsInputMenuOption[]): false | EsInputMenuOption => {
    for (let i = 0; i < items.length; i++) {
        const { id, childItems } = items[i];

        if (id === needleId) return items[i];
        if (childItems) {
            const nestedOutput = esInputMenuOptionExists(needleId, childItems);
            if (nestedOutput) return nestedOutput;
        }
    }

    return false;
};

export default { esInputMenuOptionsToNestedMenuOptions };
