import React from 'react';
import { ToastMessage, toastError } from '@samc/react-ui-core';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ViewSetAction } from '@samc/screen-config-api';
import { Notifications, ViewID } from '../../static/ValuationConstants';

export interface Valuation extends Record<string, unknown> {
    Id?: string;
    StatusId?: string;
}

interface Props {
    valuations: Valuation[];
    refreshViewSet: () => void;
    updateAction: (action: ViewSetAction | undefined) => void;
}

export const ChangeWorkflow = ({ valuations, refreshViewSet, updateAction }: Props): React.ReactElement => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const [URLSearchParams] = useSearchParams();
    const valuationId: string | null = URLSearchParams.get('primaryKeyValue');

    React.useEffect((): void => {
        const scheduleValuation: Valuation[] = [];
        const nonScheduleValuationValuation: Valuation[] = [];
        valuations.forEach((v: Valuation): void => {
            if (v.StatusId === 'Scheduled') {
                scheduleValuation.push(v);
            } else {
                nonScheduleValuationValuation.push(v);
            }
        });
        updateAction(undefined);
        if (scheduleValuation.length > 0 && nonScheduleValuationValuation.length === 0) {
            navigate(`/ChangeWorkflowWizard${search}&ValuationIds=${valuations.map((num) => num.Id).toString()}`);
        }

        if (scheduleValuation !== null && nonScheduleValuationValuation === null) {
            navigate(`/ChangeWorkflowWizard${search}&ValuationIds=${valuationId}`);
        }
        if (nonScheduleValuationValuation !== null && nonScheduleValuationValuation.length > 0) {
            toastError(<ToastMessage title={ViewID.ChangeWorkflow} message={Notifications.ACTIVE_VALUATION} />);
        }
        if (valuationId === null && valuations.length === 0) {
            toastError(<ToastMessage title={ViewID.ChangeWorkflow} message={Notifications.CHANGE_WORKFLOW} />);
        }
        if (valuationId !== null && valuationId.length > 0) {
            navigate(`/ChangeWorkflowWizard${search}&ValuationIds=${valuationId}`);
        }
    }, [valuations, valuationId, navigate, refreshViewSet, updateAction, search]);

    return <></>;
};

export default ChangeWorkflow;
