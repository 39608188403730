/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { SelectWorkflow } from './SelectWorkflow';
import WorkflowWizardHead from '../WizardHead/WorkflowWizardHead';
import { WorkflowSteps } from '../WizardProgress/ProgressConstants';
import { Valuation, FormViews, ValuationAssetVM, AddTask, ValuationContactVM } from '../../static/ValuationConstants';
import { useQBQueryToGetDomain } from './useQBQueryToGetDomain';
import './ChangeWorkflowWizard.scss';
import AddContacts from '../AddContacts/AddContacts';
import { useValuationContactsValuationIds } from '../../../valuationApi/useValuationContactsValuationIds/useValuationContactsValuationIds';
import { SetupValuationsGrid } from '../AddValutionWizard';
import { SetupMethodType } from '../AddValutionWizard/AddValuations.interfaces';
import { CycleMethod, ValuationMethod, ValuationType } from '../AddValutionWizard/ValuationWizard.enums';

export interface WizardProps {
    portfolioId: string;
    valuationsIds: string;
    setWizardisDirty: (state: boolean) => void;
    filters?: string[];
}
export interface fieldConfigurationState {
    scalarExpression: string;
    scalarDisplayName: string;
}
export const ChangeWorkflowWizard = ({
    portfolioId,
    valuationsIds,
    setWizardisDirty,
    filters,
}: WizardProps): React.ReactElement => {
    const [currentStep, setCurrentStep] = useState<WorkflowSteps>(WorkflowSteps.SelectWorkflow);

    const [gridData, setGridData] = useState<Valuation>({});
    const [valuationWorkflow, setValuationWorkflow] = useState<Record<string, unknown>>();
    const setupMethod: SetupMethodType = {
        valuationMethod: ValuationMethod.ChangeValuationWorkflow,
        cycleMethod: CycleMethod.undefined,
    };

    const result = useQBQueryToGetDomain({
        primaryViewId: FormViews.MultiValuationSetupByAssetScreen,
        valuationsIds,
        domainId: 'VALU_Valuation',
    });

    const { data: valuationContactsResponse } = useValuationContactsValuationIds(valuationsIds) || {};

    const onSelectWorkflowWizard = (record?: Record<string, unknown> | undefined): void => {
        setWizardisDirty(true);
        setValuationWorkflow(record);
        const valuationData =
            result?.qbQueryData &&
            result?.qbQueryData.addTasks?.map((item) => {
                const valuationContacts = valuationContactsResponse?.Data.map((vitem) => {
                    if (vitem.ValuationId === item.Id) {
                        return vitem as ValuationContactVM;
                    }
                    return {} as ValuationContactVM;
                });
                return {
                    ...item,
                    ValuationUniqId: item.Id,
                    ValuationContacts: valuationContacts,
                    ValuationWorkflowTemplateId: record?.Id as string,
                    FundIds: item.FundIds as string,
                    InvestmentIds: item.InvestmentIds as string,
                    ValuationAsset: (item.AssetId as string)?.split(',').map((_item) => {
                        return { AssetId: _item } as ValuationAssetVM;
                    }),
                } as AddTask;
            });
        setGridData({
            addTasks: valuationData,
        });
    };

    return (
        <div className="changeWorkflowWizard">
            <WorkflowWizardHead workflow={valuationWorkflow} setWizardisDirty={setWizardisDirty} />

            <ProgressBar
                steps={['Select Workflow', 'Update Valuation Data', 'Update Valuation Contacts']}
                currentStep={currentStep}
            />

            <div className="userSteps" style={{ display: 'flex', flex: '1 0 auto', flexDirection: 'column' }}>
                {currentStep === WorkflowSteps.SelectWorkflow && (
                    <SelectWorkflow
                        setValuationWorkflow={onSelectWorkflowWizard}
                        portfolioId={portfolioId}
                        valuationWorkflow={valuationWorkflow}
                        setCurrentStep={setCurrentStep}
                    />
                )}

                {currentStep === WorkflowSteps.UpdateValuation && (
                    <SetupValuationsGrid
                        setupMethod={setupMethod}
                        dataForGrid={gridData.addTasks}
                        setCurrentStep={setCurrentStep}
                        requestParams={{
                            enableValGroup: true,
                            portfolioId,
                            valuationAsset: { type: ValuationType.ChangeWorkflow },
                            portfolioTypeId: result?.portfolioTypeId as string,
                            valuationMethod: setupMethod.valuationMethod,
                            covValuationMethod: setupMethod.cycleMethod,
                            CreatedBy: '',
                            valuationGroupAssets: [],
                        }}
                        setValuation={setGridData}
                        filters={filters}
                    />
                )}

                {currentStep === WorkflowSteps.UpdateValuationContact && (
                    <AddContacts isBulk={false} valuation={gridData} filters={filters} setValuation={setGridData} />
                )}
            </div>
        </div>
    );
};

export default ChangeWorkflowWizard;
