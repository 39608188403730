import React from 'react';
import { MessageType } from '@samc/react-ui-request';
import { toastError, toastInfo, ToastMessage, toastSuccess, toastWarning } from '@samc/react-ui-core';

export const ShowMessage = (msg: string, messageType?: MessageType): void => {
    switch (messageType) {
        case 'error':
            toastError(<ToastMessage title="Error" message={msg} />);
            break;
        case 'success':
            toastSuccess(<ToastMessage title="Success" message={msg} />);
            break;
        case 'warning':
            toastWarning(<ToastMessage title="Warning" message={msg} />);
            break;
        case 'info':
            toastInfo(<ToastMessage title="Info" message={msg} />);
            break;
        default:
            toastInfo(<ToastMessage title="API Message" message={msg} />);
    }
};

export default ShowMessage;
