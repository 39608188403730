/* istanbul ignore file */
import React from 'react';
import { WorkflowAddAdHocTaskPanel } from '../WorkflowAddAdHocTaskPanel/WorkflowAddAdHocTaskPanel';

interface Props {
    children?: ({ openPanel }: { openPanel: () => void }) => React.ReactNode;
    /**
     * The Id of the Valuation to fetch.
     */
    valuationId: string;
}

export const WorkflowModal = ({ children, valuationId }: Props): React.ReactElement => {
    const [isPanelOpen, setPanelOpen] = React.useState<boolean>(false);

    const openPanel = (): void => {
        setPanelOpen(true);
    };

    const closePanel = (): void => {
        setPanelOpen(false);
    };

    return (
        <>
            {children?.({ openPanel })}
            <WorkflowAddAdHocTaskPanel
                isOpen={isPanelOpen}
                onCancel={closePanel}
                onSubmitSuccess={closePanel}
                valuationId={valuationId}
            />
        </>
    );
};

export default WorkflowModal;
