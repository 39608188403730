import React, { useCallback, useState } from 'react';
import { ChangeDataArgs, useGridApis } from '@samc/react-ui-grid';
import { useApiContext, useQBQuery } from '@samc/screen-config-api';
import { HybridGrid } from './HybridGrid';
import { FormViews, Valuation, ValuationDomains } from '../../static/ValuationConstants';
import { createGuid } from '../../static/ValuationWizard.utils';
import { formattedDate, getDefaultViewFromPortfolio } from './AddValuationWizard.service';
import {
    getAssetDetailsById,
    getValuationFromGridData,
    getValuationGroupNameById,
} from './BulkValuationWizard.service';
import { GridStateType } from './AddValuations.interfaces';
import { SelectionBox } from '../../atoms/SelectionBox/SelectionBox';
import { SetupType } from './BulkValuationWizard.enums';
import { NavButtons } from './NavButtons';
import { DefaultValidationResponse, ValidationResponse, validateSetupData } from './ValidationUtils';
import { BulkValuationSteps, Steps } from '../WizardProgress/ProgressConstants';
import useEvaluatedRuleByDomainConfig from './useEvaluatedRuleByDomainConfig';
import NavLoader from '../../atoms/NavLoader/NavLoader';

export interface BulkValuationsProps {
    portfolioTypeId: string | unknown;
    portfolioId: string | unknown;
    assetId: string | unknown;
    valuationGroupId: string | unknown;
    assetOrGroupBulk: SetupType;
    enableValGroup: boolean;
    setAssetOrGroup: (param: SetupType) => void;
    setCurrentStep: (pararms: Steps) => void;
    valuation: Valuation;
    setValuation: (params: Valuation) => void;
}

export const SetupBulkValuations = ({
    portfolioTypeId,
    portfolioId,
    assetId,
    valuationGroupId,
    assetOrGroupBulk,
    enableValGroup,
    setCurrentStep,
    setAssetOrGroup,
    valuation,
    setValuation,
}: BulkValuationsProps): React.ReactElement => {
    const { apis, onGridApiChanged } = useGridApis<Record<string, unknown>>();
    const [isLoading, setLoading] = useState<boolean>(false);

    const [setupData, updateSetupData] = useState<GridStateType>({
        data: {},
        apis, // apis never changes, this is safe... but silly, I know
    });

    const [validationResponse, updateValidationResponse] = useState<ValidationResponse>({
        ...DefaultValidationResponse,
    });

    const viewId = getDefaultViewFromPortfolio(
        assetOrGroupBulk === SetupType.ASSETS ? FormViews.MultipleAssetsBulk : FormViews.MultipleValutionGroupsBulk,
        assetOrGroupBulk === SetupType.ASSETS ? 'ValuationSetupAssetDefaultView' : 'ValuationSetupGroupDefaultView',
    );

    const api = useApiContext();
    const qbQuery = useQBQuery();

    const { evaluateRule } = useEvaluatedRuleByDomainConfig(ValuationDomains.Valuation, 'ValuationName');

    const handleOnChangeData = useCallback(
        async ({ cellValueChangedEvent, record }: ChangeDataArgs<Record<string, unknown>>): Promise<void> => {
            const { field } = cellValueChangedEvent?.colDef ?? { field: undefined };
            const clonedRecord: Record<string, unknown> = {
                ...record,
                ValuationUniqId: record.ValuationUniqId || createGuid(),
                ValuationIsReadonly: 0,
            };

            /* AssetNameGroupName needs to be fetched everytime an Asset or a Valuation group is changed */
            const isAssetIdOrValuationGroupId = field === 'AssetId' || field === 'ValuationGroupId';
            if (isAssetIdOrValuationGroupId) {
                if (record.AssetId && !record.AssetNameGroupName) {
                    const assetDetails = await getAssetDetailsById(qbQuery, record.AssetId as string);

                    clonedRecord.AssetNameGroupName = assetDetails.Name;
                    clonedRecord.ValuationRegion = assetDetails.RegionalArea;
                    clonedRecord.FundIds = assetDetails.Fund_Id;
                    clonedRecord.InvestmentIds = assetDetails.Investment_Id;
                }

                if (record.ValuationGroupId) {
                    const valuationGroupDetails = await getValuationGroupNameById(
                        qbQuery,
                        record.ValuationGroupId as string,
                    );

                    clonedRecord.AssetNameGroupName = valuationGroupDetails.ValuationGroupName;
                    clonedRecord.ValuationRegion = valuationGroupDetails.RegionalArea;
                }
            }

            // make changes related to previous data in a setState to avoid race conditions
            updateSetupData((previousSetupData) => {
                if (!isAssetIdOrValuationGroupId) {
                    const prevRecord = previousSetupData.data[record.Id as string] as Record<string, unknown>;
                    clonedRecord.AssetNameGroupName = prevRecord?.AssetNameGroupName ?? undefined;
                }

                /* Evaluates valuation name by calculation rule (needed if the field doesn't already exists in view) */
                clonedRecord.ValuationName = evaluateRule(
                    {
                        Asset_Group: '',
                        ValuationReportTypeId: '',
                        ValuationScopeId: '',
                        ValuationEngagementDate: '',
                        ValuationDateofValue: '',
                        ...clonedRecord,
                    },
                    'calculationRule',
                );

                return {
                    ...previousSetupData,
                    data: { ...previousSetupData.data, [clonedRecord.Id as string]: clonedRecord },
                };
            });
        },
        [evaluateRule, qbQuery],
    );

    const onBodyGridReady = (): void => {
        updateSetupData((c) => ({
            ...c,
            data: valuation.addTasks
                ? valuation.addTasks.reduce(
                      /* __ADDED__ & _newRowId properties are removed for existing rows to show them in the grid */
                      (acc, { __ADDED__, _newRowId, ...row }) => ({
                          ...acc,
                          /* This workaround avoids duplicate -ve key as Id when navigating back to this grid & adding new rows */
                          [`${row.ValuationUniqId}`]: { ...row, Id: row.ValuationUniqId },
                      }),
                      {},
                  )
                : {},
        }));
    };

    const assetFilters = React.useMemo(
        () => [
            `([PortfolioId] = '${portfolioId}')`,
            `[Portfolio_Id]='${portfolioId}'`,
            `([Portfolio_Ids] contains '${portfolioId}')`,
        ],
        [portfolioId],
    );

    const filters = React.useMemo(
        () => [
            `([PortfolioId] = '${portfolioId}') and ([ValuationGroupAssetCount] > 0) and ((ISNULLOREMPTY([ValuationGroupDisabledDate])) or ([ValuationGroupDisabledDate]>'${formattedDate}'))`,
            `[Portfolio_Id]='${portfolioId}'`,
            `([Portfolio_Ids] contains '${portfolioId}')`,
        ],
        [portfolioId],
    );

    const handleAssetOrGroup = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            updateSetupData((c) => ({ ...c, data: {} }));
            updateValidationResponse({ ...DefaultValidationResponse });
            setAssetOrGroup(event.target.value as SetupType);
        }
    };

    const onContinue = async (): Promise<void> => {
        setLoading(true);
        const tempValuation = await getValuationFromGridData(
            Object.values(setupData.data) as Record<string, unknown>[],
            {
                qbQuery,
                assetOrGroupBulk,
                enableValGroup,
                portfolioId: portfolioId as string,
                portfolioTypeId: portfolioTypeId as string,
            },
        );

        validateSetupData({
            TaskApi: api.TaskApi,
            requestInit: api.requestInit || {},
            addTasks: tempValuation.addTasks ?? [],
            updateErrors: updateValidationResponse,
            onSuccess: () => {
                setValuation(tempValuation);
                setCurrentStep(BulkValuationSteps.AddContacts);
            },
            setLoading,
        });
    };

    return (
        <>
            <NavButtons
                onContinue={onContinue}
                retrieveDefaults={(): void => undefined}
                isDisabled={isLoading}
                validationResponse={validationResponse}
                updateValidationResponse={updateValidationResponse}
                domainNameMap={{ VALU_Valuation: 'ValuationName' }}
            >
                <NavLoader isLoading={isLoading} label="Validating..." />
            </NavButtons>

            <div className="asset-or-group">
                <SelectionBox
                    name="Step1Bulk"
                    value={SetupType.ASSETS}
                    checked={assetOrGroupBulk === SetupType.ASSETS}
                    label="Create Valuations for Assets"
                    onChange={handleAssetOrGroup}
                />

                <SelectionBox
                    name="Step1Bulk"
                    value={SetupType.VALUATION_GROUP}
                    checked={assetOrGroupBulk === SetupType.VALUATION_GROUP}
                    label="Create Valuations for Valuation Group"
                    disabled={!enableValGroup}
                    onChange={handleAssetOrGroup}
                />
            </div>

            {assetOrGroupBulk !== SetupType.undefined && (
                <div className="asset-or-group-grid">
                    <HybridGrid
                        gridData={Object.values(setupData.data) as Record<string, unknown>[]}
                        domainId={ValuationDomains.Valuation}
                        listViewID={viewId}
                        gridOptions={{
                            defaultRowSpacing: 'normal',
                            isEditingDefault: true,
                            rowSelection: 'multiple',
                            rowsPerPage: 500,
                            suppressAddRowButton: false,
                            suppressColumnFilters: true,
                            suppressDeleteButton: false,
                            suppressDeleteConfirmationModal: true,
                            suppressEditToggle: true,
                            suppressExcelExport: true,
                            suppressFullScreen: false,
                            suppressRowSpacing: true,
                            suppressViewSelector: false,
                            suppressSelectionCheckbox: false,
                            suppressRefresh: true,
                        }}
                        onChangeData={handleOnChangeData}
                        defaultData={{
                            PortfolioTypeId: portfolioTypeId,
                            PortfolioId: portfolioId,
                            PortfoliosId: portfolioId,
                            Portfolio_Id: portfolioId,
                            AssetId: assetOrGroupBulk === SetupType.ASSETS ? assetId : undefined,
                            ValuationGroupId:
                                assetOrGroupBulk === SetupType.VALUATION_GROUP ? valuationGroupId : undefined,
                            ValuationIsReadonly: 0,
                            ForBulkValuation: assetOrGroupBulk,
                            HideOneOff: null,
                        }}
                        key={assetOrGroupBulk}
                        filters={assetOrGroupBulk === SetupType.ASSETS ? assetFilters : filters}
                        onGridApiChanged={onGridApiChanged}
                        onBodyGridReady={onBodyGridReady}
                        scope={`BulkSetup_${portfolioId}`}
                        errorMessages={validationResponse.list}
                        essentialFields={['AssetNameGroupName', 'StatusId']}
                        mappedFields={{
                            ValuationMonth: 'ValuationPeriodTypeId',
                            ValuationQuarter: 'ValuationPeriod',
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default SetupBulkValuations;
