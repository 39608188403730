import { TaskRequest, TaskResponse } from '@samc/screen-config-api';
import { apiProvider } from '../../../valuationApi';
import {
    GridIdentifiers,
    Valuation,
    ValuationAssetMethodologiesVM,
    ValuationAssetMethodologySetupVM,
    ValuationAssetOtherDataScreensVM,
    ValuationAssetOtherValuesVM,
    ValuationDomains,
} from '../../static/ValuationConstants';

const removePrimary = <
    T extends ValuationAssetMethodologiesVM | ValuationAssetOtherDataScreensVM | ValuationAssetOtherValuesVM,
>(
    record: T,
): T => {
    const newRecord = { ...record };
    delete newRecord.VAMethodologiesId;
    delete newRecord.VAOtherDataScreensId;
    delete newRecord.VAOtherValuesId;

    return newRecord;
};

const getCustomPayload = (
    ValuationAssetMethodologySetup: ValuationAssetMethodologySetupVM[],
    valuationIdSequence: number,
): Record<string, unknown> | void => {
    const valuationKey = `${valuationIdSequence}`;
    let methodologies = {};

    if (ValuationAssetMethodologySetup && ValuationAssetMethodologySetup.length) {
        methodologies = ValuationAssetMethodologySetup.reduce(
            (
                child,
                {
                    VAMethodologySetupId, // eslint-disable-line @typescript-eslint/no-unused-vars
                    AssetId,
                    ValuationAssetMethodologies,
                    ValuationAssetOtherDataScreens,
                    ValuationAssetOtherValues,
                    ...vam
                },
                i,
            ) => {
                const methodologySequence = `${valuationIdSequence}${Math.floor(100000 + Math.random() * 9000)}${i}`;
                const children: unknown[] = [];
                const vams = { __ADDED__: true };

                Object.assign(vams, { ...vam, PortfolioId: vam.PortfoliosId, AssetId });
                let uniqueIndex = 1;
                const getChildRequest = <
                    T extends
                        | ValuationAssetMethodologiesVM
                        | ValuationAssetOtherDataScreensVM
                        | ValuationAssetOtherValuesVM,
                >(
                    id: string,
                    requestIdentifier: GridIdentifiers,
                    griddata: T[],
                ): Record<string, unknown> => ({
                    domainId: id,
                    fieldConfigurationId: id,
                    requestIdentifier,
                    payload: griddata.reduce((acc, record) => {
                        return {
                            ...acc,
                            [`${valuationKey}${methodologySequence.replace('-', '')}${++uniqueIndex}`]: removePrimary({
                                ...record,
                                AssetId,
                                VAMethodologySetupId: methodologySequence,
                                ValuationAssetId: methodologySequence,
                                __ADDED__: true,
                                ValuationId: valuationKey,
                            }),
                        };
                    }, {}),
                });

                if (ValuationAssetMethodologies && ValuationAssetMethodologies.length) {
                    children.push(
                        getChildRequest(
                            'ValuationAssetMethodologies',
                            GridIdentifiers.VA_METHODOLOGIES_GRID,
                            ValuationAssetMethodologies,
                        ),
                    );
                }

                if (ValuationAssetOtherDataScreens && ValuationAssetOtherDataScreens.length) {
                    children.push(
                        getChildRequest(
                            'ValuationAssetOtherDataScreens',
                            GridIdentifiers.VA_OTHERDATASCREENS_GRID,
                            ValuationAssetOtherDataScreens,
                        ),
                    );
                }

                if (ValuationAssetOtherValues && ValuationAssetOtherValues.length) {
                    children.push(
                        getChildRequest(
                            'ValuationAssetOtherValues',
                            GridIdentifiers.VA_OTHERVALUES_GRID,
                            ValuationAssetOtherValues,
                        ),
                    );
                }

                return {
                    ...child,
                    [methodologySequence]: {
                        ...vams,
                        ValuationId: valuationKey,
                        ValuationAssetId: methodologySequence,
                        __CHILD_REQUESTS__: children,
                        __ADDED__: true,
                    },
                };
            },
            {},
        );
    }

    return methodologies;
};

export const getValuationObject = (payload: Valuation): Record<string, unknown> => {
    const contactsData = { ...payload.addContacts } ?? {};

    delete contactsData.gridData;

    const contacts = Object.values(contactsData) as unknown as Record<string, unknown>[];

    if (payload.addTasks) {
        return payload.addTasks.reduce(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (p, { ValuationId, ValuationAssetMethodologySetup, ValuationAsset, ...task }, i) => {
                const valuationIdSequence = -1 - i;

                return {
                    ...p,
                    [`${valuationIdSequence}`]: {
                        ...task,
                        // pass in AssetId to determine if user is Authorized to add a Valuation for this Asset
                        AssetId: ValuationAsset?.length === 1 ? ValuationAsset[0].AssetId : undefined,
                        Id: valuationIdSequence,
                        AssetNameGroupName: String(task.AssetsName ? task.AssetsName : task.ValuationGroupName),
                        __ADDED__: true,
                        __CHILD_REQUESTS__: [
                            {
                                domainId: 'ValuationAsset',
                                fieldConfigurationId: 'ValuationAsset',
                                requestIdentifier: 'ValuationAsset Grid',
                                payload: ValuationAsset?.reduce((acc, record, index) => {
                                    const valuationAssetSequence = `${valuationIdSequence}00${index}`;

                                    return {
                                        ...acc,
                                        [`${valuationAssetSequence}`]: {
                                            ...record,
                                            __ADDED__: true,
                                            ValuationId: `${valuationIdSequence}`,
                                        },
                                    };
                                }, {}),
                            },
                            {
                                domainId: 'ValuationAssetMethodologySetup',
                                fieldConfigurationId: 'ValuationAssetMethodologySetup',
                                requestIdentifier: 'ValuationAssetMethodologies Grid',
                                payload: getCustomPayload(ValuationAssetMethodologySetup ?? [], valuationIdSequence),
                            },
                            {
                                domainId: 'VALU_ValuationContact',
                                fieldConfigurationId: 'VALU_ValuationContact',
                                requestIdentifier: 'VALU_ValuationContact_VW',
                                payload: {
                                    ...contacts
                                        .filter((x) => x.ValuationUniqId === ValuationId)
                                        ?.reduce((acc, row, index) => {
                                            const valuationContactSequence = `${valuationIdSequence}0000${index}`;

                                            return {
                                                ...acc,
                                                [`${valuationContactSequence}`]: {
                                                    ...row,
                                                },
                                            };
                                        }, {}),
                                },
                            },
                        ],
                    },
                };
            },
            {},
        );
    }
    return {};
};

export const SubmitValuations = (
    payload: Valuation,
    TaskApi: string,
    requestInit: RequestInit,
    overrideCodes?: string[],
): Promise<[TaskResponse, TaskRequest]> =>
    apiProvider.submitTask(
        TaskApi,
        ValuationDomains.Valuation,
        ValuationDomains.Valuation,
        'Create Valuation Grid',
        getValuationObject(payload) as TaskRequest['payload'],
        requestInit,
        false,
        undefined,
        overrideCodes,
    );

export default SubmitValuations;
