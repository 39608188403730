/* istanbul ignore file */
import React from 'react';
import { FormHelpers, FormManagementContextProvider, FormState } from '@samc/react-ui-form';
import { TaskStatus } from '@samc/screen-config-api';
import { HeaderContextProvider } from '@samc/react-ui-history';
import { IPanelProps, IRenderFunction, Panel, PanelType } from '@fluentui/react';
import {
    PROPERTY_NAME_CONTACTS_ASSIGNED_TO,
    ValuationWorkflowTask,
    useValuationWorkflowTaskSaver as _useValuationWorkflowTaskSaver,
} from '../../../valuationApi';
import { useValidationMessage as _useValidationMessage } from '../../hooks/useValidationMessage/useValidationMessage';
import { useWorkflowTaskValidationPopupContext } from '../../contexts/WorkflowTaskValidationPopupContext';
import { PanelHeader } from '../../atoms/PanelHeader/PanelHeader';
import { WorkflowAdHocTaskForm } from '../../molecules/WorkflowAdHocTaskForm/WorkflowAdHocTaskForm';

interface Props {
    /**
     * If true, the Panel is displayed, if false the Panel is hidden.
     */
    isOpen: boolean;
    /**
     * The Id of the Valuation for which ValuationContacts will be fetched.
     */
    valuationId: string;

    /**
     * A ValuationWorkflowTask object to use as the initial data for the form.
     */
    initialFormData?: ValuationWorkflowTask;
    /**
     * Callback on click of Cancel button.
     */
    onCancel?: () => void;
    /**
     * Callback on click of Submit and Close button.
     */
    onSubmitAndClose?: () => void;
    /**
     * Callback on successful submission of the Form.
     */
    onSubmitSuccess?: () => void;
    /**
     * DI override of the hook to show a Validation Message.
     */
    useValidationMessage?: typeof _useValidationMessage;
    /**
     * DI override of the hook to access the Mutation for saving a ValuationWorkflowTask.
     */
    useValuationWorkflowTaskSaver?: typeof _useValuationWorkflowTaskSaver;
}

export const WorkflowAddAdHocTaskPanel = ({
    initialFormData,
    isOpen,
    onCancel,
    onSubmitAndClose,
    onSubmitSuccess,
    useValidationMessage = _useValidationMessage,
    useValuationWorkflowTaskSaver = _useValuationWorkflowTaskSaver,
    valuationId,
}: Props): React.ReactElement => {
    const [saveTask] = useValuationWorkflowTaskSaver();
    const [showValidationMessage] = useValidationMessage({});
    const { setValidationRequest, setValidationResponse, setVisible } = useWorkflowTaskValidationPopupContext();

    const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
    const dirtinessScope = React.useRef<HTMLDivElement>(null);

    const handleFormStateChange = (state: FormState<ValuationWorkflowTask>): void => {
        setIsFormValid(state.isValid);
    };

    const onFormSubmit = async (
        values: ValuationWorkflowTask,
        helpers: FormHelpers<ValuationWorkflowTask>,
    ): Promise<void> => {
        if (!values.ContactsAssignedTo || values.ContactsAssignedTo.length === 0) {
            showValidationMessage(values.Name || 'New Task', 'At least one user must be assigned to the task.', [
                PROPERTY_NAME_CONTACTS_ASSIGNED_TO,
            ]);

            return;
        }

        const [result, request] = await saveTask.mutateAsync({
            actionableFlag: true,
            adHocTaskFlag: true,
            contactsAssignedToRowsToAdd: values.ContactsAssignedTo || [],
            contactsAssignedToRowsToDelete: [],
            contactsEnabledForRowsToAdd: values.ContactsEnabledFor || [],
            contactsEnabledForRowsToDelete: [],
            contactsViewableByRowsToAdd: values.ContactsViewableBy || [],
            contactsViewableByRowsToDelete: [],
            description: values.Description,
            dueDateCalculated: values.DueDate,
            id: values.Id,
            name: values.Name,
            taskStatusId: 'Incomplete',
            valuationId,
        });

        if (result.statusCode === TaskStatus.Completed) {
            onSubmitSuccess?.();
            await helpers.onReset();

            return;
        }

        setValidationRequest(request);
        setValidationResponse(result);
        setVisible(true);
    };

    const navigationContentRenderer: IRenderFunction<IPanelProps> = (): React.ReactElement => (
        <PanelHeader
            dirtinessScope={dirtinessScope}
            cancelDisabledOverride={false}
            onCancel={onCancel}
            onSubmitAndClose={onSubmitAndClose}
            submitDisabledOverride={!isFormValid}
            title="Ad-Hoc Task"
        />
    );

    return (
        <Panel
            isOpen={isOpen}
            onRenderNavigationContent={navigationContentRenderer}
            type={PanelType.medium}
            popupProps={{ ref: dirtinessScope }}
            layerProps={{ eventBubblingEnabled: true }}
        >
            <HeaderContextProvider>
                <FormManagementContextProvider
                    initialData={initialFormData}
                    onSubmit={onFormSubmit}
                    onFormStateChange={handleFormStateChange}
                >
                    <WorkflowAdHocTaskForm valuationId={valuationId} />
                </FormManagementContextProvider>
            </HeaderContextProvider>
        </Panel>
    );
};

export default WorkflowAddAdHocTaskPanel;
