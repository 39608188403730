import React from 'react';
import { styled } from 'styletron-react';
import { BaseButton, Button, IconButton } from '@fluentui/react';
import { useApplicationContext } from '../../../hooks/useApplicationContext/useApplicationContext';

/* istanbul ignore next */
const StyledFlexWrapper = styled('div', () => ({
    height: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));

/* istanbul ignore next */
const StyledReassignButton = styled(IconButton, () => ({
    height: '100%',
}));

interface Props {
    displayValue?: string;
    onClickReassignTask?: React.MouseEventHandler<
        HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement | BaseButton | Button
    >;
    onClickResendNotification?: React.MouseEventHandler<
        HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button
    >;
}

export const WorkflowResponsibleParticipantRenderer = ({
    displayValue,
    onClickResendNotification,
    onClickReassignTask,
}: Props): React.ReactElement => {
    const { CanUserReassignTask, CanUserResendNotification } = useApplicationContext();

    return (
        <StyledFlexWrapper>
            <span>{displayValue}</span>
            <div>
                {CanUserResendNotification && (
                    <StyledReassignButton
                        className="resend-notification-button"
                        ariaLabel="Resend Notification"
                        iconProps={{ iconName: 'Mail' }}
                        onClick={onClickResendNotification}
                        title="Resend Notification"
                    />
                )}
                {CanUserReassignTask && (
                    <StyledReassignButton
                        className="reassign-task-button"
                        ariaLabel="Reassign"
                        iconProps={{ iconName: 'People' }}
                        onClick={onClickReassignTask}
                        title="Reassign"
                    />
                )}
            </div>
        </StyledFlexWrapper>
    );
};

export default WorkflowResponsibleParticipantRenderer;
