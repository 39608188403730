import { QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { UseQueryResult, useQuery } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';
import { useApiContext } from '../../hooks/useApiContext';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';

export const VALUATION_WORKFLOW_TASK_ENABLED_USERS_KEY = 'ValuationWorkflowTaskEnabledUsers';

export const useValuationWorkflowTaskEnabledUsers = (workflowTaskId: string): UseQueryResult<QueryExecuteResponse> => {
    const api = useApiContext();
    const { SystemExecuteMaxRecordsToReturn } = useApplicationContext();

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_ValuationWorkflowTaskEnabledUsers',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
            {
                scalarDisplayName: 'UserId',
                scalarExpression: '[UserId]',
            },
            {
                scalarDisplayName: 'WorkflowTaskId',
                scalarExpression: '[WorkflowTaskId]',
            },
        ],
        sorting: {},
        paging: { start: 0, stop: SystemExecuteMaxRecordsToReturn },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[WorkflowTaskId] = '${workflowTaskId}'`,
        },
    };

    const queryResult = useQuery<QueryExecuteResponse>(
        [VALUATION_WORKFLOW_TASK_ENABLED_USERS_KEY, workflowTaskId],
        () => executeLiftSiftQuery(api.LiftSiftApi, requestBody, api.requestInit, api.ShowMessage),
        { retry: 2, refetchOnWindowFocus: false },
    );

    return queryResult;
};

export default useValuationWorkflowTaskEnabledUsers;
