import React, { useEffect, useState } from 'react';
import { DomainViewMember, useQBQuery } from '@samc/screen-config-api';
import { FormData } from '@samc/react-ui-form';
import { useStyletron } from 'styletron-react';
import { ErrorBoundary } from '@samc/react-ui-core';
import SelectAssetOrGroup from './SelectAssetOrGroup';
import { SelectSetupMethod } from './SelectSetupMethod';
import { CreateValuations } from './CreateValuations/CreateValuations';
import { SelectCycleMethod } from './SelectCycleMethod';
import { SetupValuationsGrid } from './SetupValuationsGrid';
import { MethodologyAndApproachSetup } from './MethodologyAndApproachSetup';
import './AddValuationWizard.scss';
import {
    Valuation,
    ValuationGroupAsset,
    CreatedBy,
    ValuationAssetMethodologySetupVM,
    Status,
    ValuationAssetVM,
    AddTask,
} from '../../static/ValuationConstants';
import { createGuid, customColumns, GetAssetColumns, GetValGroupColumns } from '../../static/ValuationWizard.utils';
import WizardHead from '../WizardHead/WizardHead';
import { CycleTemplateDataType, defaultCycleTemplateData } from './ReportCycleGrids/ReportCycleConstants';
import { ConfirmMethodologies } from '../ConfirmMethodologies/ConfirmMethodologies';
import { fetchAssetsForValuationGroup, formattedDate, getQBData } from './AddValuationWizard.service';
import { GridStateType, SetupMethodType } from './AddValuations.interfaces';
import { ReportCycleTemplateGrid } from './ReportCycleGrids/ReportCycleTemplateGrid';
import { ReportCyclePeriodsGrid } from './ReportCycleGrids/ReportCyclePeriodsGrid';
import { CycleMethod, ValuationMethod, ValuationType } from './ValuationWizard.enums';
import { Step3 } from './Step3';
import WizardProgress from '../WizardProgress/WizardProgress';
import AddContacts from '../AddContacts/AddContacts';
import { Steps, ValuationSteps } from '../WizardProgress/ProgressConstants';

interface WizardProps {
    portfolioId: string | null;
    assetId?: string | null;
    valuationGroupId?: string | null;
    setWizardisDirty: (state: boolean) => void;
    filters?: string[];
}

export const AddValuationWizard = ({
    portfolioId,
    assetId,
    valuationGroupId,
    setWizardisDirty,
    filters,
}: WizardProps): React.ReactElement => {
    const qbQuery = useQBQuery();
    const adhocListViewMembers: DomainViewMember[] = [...customColumns];

    const [valuation, setValuation] = React.useState<Valuation>({});
    const [valuationAsset, setValuationAsset] = React.useState<Record<string, unknown>>();
    const [valuationGroupAssets, setValuationGroupAssets] = React.useState<ValuationGroupAsset[]>([]);
    const [currentStep, setCurrentStep] = React.useState<Steps>(ValuationSteps.SelectAssetOrValuationGroup);
    const [enableValGroup, setEnableValGroup] = React.useState<boolean>(false);
    const [portfolioTypeId, setPortfolioTypeId] = React.useState<unknown>();
    const [setupMethod, updateSetupMethod] = useState<SetupMethodType>({
        valuationMethod: ValuationMethod.undefined,
        cycleMethod: CycleMethod.undefined,
    });
    const [formData, setFormData] = React.useState<FormData>();
    const [cycleTemplateData, setCycleTemplateData] = useState<CycleTemplateDataType>({ ...defaultCycleTemplateData });

    const [reportData, updateReportData] = useState<GridStateType>({
        apis: undefined, // will be populated by child
        data: {},
    });

    const [requestSetupDetails, setRequestSetupDetails] = useState<boolean>(false);

    const [css] = useStyletron();

    const setWizardAssets = async (record?: Record<string, unknown> | undefined): Promise<void> => {
        if (record) {
            if (record.type === ValuationType.ValuationGroup && record.ValuationGroupId) {
                const assets = await fetchAssetsForValuationGroup(String(record.ValuationGroupId), qbQuery);
                setValuationGroupAssets(assets);
            }
        }
        setValuationAsset(record);
        setValuation({});
        updateSetupMethod({ valuationMethod: ValuationMethod.undefined, cycleMethod: CycleMethod.undefined });
        setFormData({
            PortfolioTypeId: portfolioTypeId,
            PortfolioId: portfolioId,
            Portfolio_Id: portfolioId,
            StatusId: Status.Scheduled,
            ValuationIsReadonly: 0,
            ValuationGroupId: record?.ValuationGroupId || null,
            ValuationAssetsId: record?.Id || '',
            AssetNameGroupName: String(record && record.ValuationGroupName ? record.ValuationGroupName : record?.Name),
        });

        setWizardisDirty(true);
    };

    const setValuationMethod = (valuationMethodValue: string): void => {
        const addTasks: AddTask[] = [];
        if (
            valuationAsset?.type === ValuationType.ValuationGroup &&
            valuationMethodValue === ValuationMethod.SingleValuation
        ) {
            const valuationAssetVMList: ValuationAssetVM[] = [];
            const valuationAssetMethodologySetupVMList: ValuationAssetMethodologySetupVM[] = [];
            if (valuationGroupAssets && valuationGroupAssets.length > 0) {
                const fundIds = [...new Set(valuationGroupAssets.map((item) => item.fundId))].join(',');
                const investmentIds = [...new Set(valuationGroupAssets.map((item) => item.investmentId))].join(',');
                for (let index = 0; index < valuationGroupAssets.length; index++) {
                    const asset = valuationGroupAssets[index];
                    const valuationAssetVM: ValuationAssetVM = {
                        AssetId: asset.assetsId,
                        AssetName: asset.assetName,
                        PortfoliosId: asset.portfoliosId,
                        ValuationGroupId: asset.valuationGroupId,
                        ExcludeAssetFromReporting: false,
                        ExcludeAssetFromReportingExplanation: '',
                    };

                    valuationAssetMethodologySetupVMList.push({
                        RegionalArea: asset.RegionalArea,
                        VAMethodologySetupId: createGuid(),
                        AssetId: asset.assetsId,
                        AssetName: asset.assetName,
                        PortfoliosId: asset.portfoliosId,
                        PortfolioTypeId: String(portfolioTypeId),
                        ValuationName: '',
                        MethodologyTemplateId: null,
                        ValuationGroupId: asset.valuationGroupId,
                        AllAsset_CopyFromPriorValuationId: null,
                    });
                    valuationAssetVMList.push(valuationAssetVM);
                }
                addTasks.push({
                    EnableValGroup: enableValGroup,
                    PortfoliosId: String(portfolioId),
                    PortfolioId: String(portfolioId),
                    Portfolio_Id: String(portfolioId),
                    PortfolioTypeId: String(portfolioTypeId),
                    ValuationAssetsId: null,
                    AssetsName: '',
                    ValuationType: valuationAsset?.type,
                    ValuationGroupId: String(valuationAsset?.ValuationGroupId),
                    ValuationGroupName: String(valuationAsset?.ValuationGroupName),
                    ValuationAsset: valuationAssetVMList,
                    ValuationAssetMethodologySetup: valuationAssetMethodologySetupVMList,
                    ValuationMethod: valuationMethodValue,
                    CreatedBy,
                    StatusId: Status.Scheduled,
                    ValuationRegion: valuationAsset?.RegionalArea ? String(valuationAsset?.RegionalArea) : null,
                    ValuationIsReadonly: 0,
                    FundIds: fundIds,
                    InvestmentIds: investmentIds,
                });
            } else {
                addTasks.push({
                    EnableValGroup: enableValGroup,
                    PortfoliosId: String(portfolioId),
                    PortfolioId: String(portfolioId),
                    Portfolio_Id: String(portfolioId),
                    PortfolioTypeId: String(portfolioTypeId),
                    ValuationAssetsId: null,
                    AssetsName: '',
                    ValuationType: valuationAsset?.type,
                    ValuationGroupId: String(valuationAsset?.ValuationGroupId),
                    ValuationGroupName: String(valuationAsset?.ValuationGroupName),
                    ValuationAsset: [],
                    ValuationAssetMethodologySetup: [],
                    ValuationMethod: valuationMethodValue,
                    CreatedBy,
                    StatusId: Status.Scheduled,
                    ValuationRegion: null,
                    ValuationIsReadonly: 0,
                    FundIds: null,
                    InvestmentIds: null,
                });
            }
        }

        if (
            valuationAsset?.type === ValuationType.SingleAsset &&
            valuationMethodValue === ValuationMethod.SingleValuation
        ) {
            const valuationAssetVM: ValuationAssetVM = {
                AssetId: String(valuationAsset.Id),
                AssetName: String(valuationAsset?.Name),
                PortfoliosId: String(portfolioId),
                ValuationGroupId: null,
                ExcludeAssetFromReporting: false,
                ExcludeAssetFromReportingExplanation: '',
                FundId: String(valuationAsset.Fund_Id),
                InvestmentId: String(valuationAsset.Investment_Id),
            };

            addTasks.push({
                EnableValGroup: true,
                PortfoliosId: String(portfolioId),
                PortfolioId: String(portfolioId),
                Portfolio_Id: String(portfolioId),
                PortfolioTypeId: String(portfolioTypeId),
                ValuationAssetsId: valuationAsset?.Id ? String(valuationAsset?.Id) : null,
                AssetsName: String(valuationAsset?.Name),
                ValuationType: String(valuationAsset?.type),
                ValuationGroupId: null,
                ValuationGroupName: '',
                ValuationMethod: valuationMethodValue,
                ValuationAsset: [],
                CreatedBy,
                StatusId: Status.Scheduled,
                ValuationIsReadonly: 0,
                FundIds: String(valuationAsset.Fund_Id),
                InvestmentIds: String(valuationAsset.Investment_Id),
            });
            addTasks[0].ValuationAsset = [valuationAssetVM];
            addTasks[0].ValuationAssetMethodologySetup = [
                {
                    RegionalArea: valuationAsset.RegionalArea as string,
                    VAMethodologySetupId: createGuid(),
                    AssetId: String(valuationAsset.Id),
                    AssetName: String(valuationAsset?.Name),
                    PortfoliosId: String(portfolioId),
                    PortfolioTypeId: String(portfolioTypeId),
                    ValuationName: '',
                    MethodologyTemplateId: null,
                    ValuationGroupId: null,
                    AllAsset_CopyFromPriorValuationId: null,
                },
            ];
        }

        setValuation({
            ...valuation,
            addTasks,
        });

        setFormData({
            PortfolioTypeId: portfolioTypeId,
            PortfolioId: portfolioId,
            Portfolio_Id: portfolioId,
            ValuationIsReadonly: 0,
            ValuationGroupId: valuationAsset?.ValuationGroupId || null,
            ValuationAssetsId: valuationAsset?.Id || '',
            ValuationRegion: valuationAsset?.RegionalArea ? valuationAsset.RegionalArea : null,
            AssetNameGroupName: String(
                valuationAsset && valuationAsset.ValuationGroupName
                    ? valuationAsset.ValuationGroupName
                    : valuationAsset?.Name,
            ),
        });
    };

    useEffect(() => {
        setValuationMethod(setupMethod.valuationMethod);
    }, [setupMethod.valuationMethod]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Updates step-3 form data into valuation object
     */
    const setValuationFormData = (fd: FormData): void => {
        const { addTasks = [] } = valuation;
        const firstTask = { ...addTasks[0] };

        const updatedFormData = {
            ...fd,
            ValuationUniqId: fd.ValuationUniqId ? (fd.ValuationUniqId as string) : createGuid(), // create id if not available
        };

        if (fd.ValuationName && addTasks[0].ValuationAssetMethodologySetup) {
            firstTask.ValuationAssetMethodologySetup = addTasks[0].ValuationAssetMethodologySetup.map(
                (methodology) => ({
                    ...methodology,
                    ValuationName: fd.ValuationName as string,
                    ValuationId: updatedFormData.ValuationUniqId as string,
                }),
            );
        }

        const notNullProperties = Object.keys(fd).reduce(
            (acc, elementKey) => (fd[elementKey] != null ? { ...acc, [elementKey]: fd[elementKey] } : { ...acc }),
            {},
        );

        firstTask.ValuationUniqId = updatedFormData.ValuationUniqId as string;
        firstTask.ValuationId = updatedFormData.ValuationUniqId as string;
        firstTask.AssetId = firstTask.ValuationAssetsId ? (firstTask.ValuationAssetsId as string) : undefined;
        setValuation({ addTasks: [{ ...firstTask, ...notNullProperties }] });
        setFormData(updatedFormData);
    };

    useEffect(() => {
        if (portfolioId) {
            getQBData({
                qbQuery,
                domainId: 'POMA_Portfolio',
                advancedExpression: `[Id] = '${portfolioId}'`,
                adhocListViewMembers,
                // User may not have full access to the portfolio, but we still need these fields
                includeLimitedAccessRecords: true,
            }).then((r) => {
                if (r && r.Data && r.Data.length === 1) {
                    const isShowValGroup = Boolean(r.Data[0].EnableValuationGroup);
                    const typeCode = r.Data[0].TypeCode;
                    setEnableValGroup(isShowValGroup);
                    setPortfolioTypeId(typeCode);
                }
            });
        }
    }, [portfolioId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (assetId) {
            getQBData({
                qbQuery,
                domainId: 'POMA_Asset',
                advancedExpression: `[Id] = '${assetId}' && [PortfolioId] = '${portfolioId}'`,
                adhocListViewMembers: GetAssetColumns(),
            }).then((r) => {
                if (r && r.Data && r.Data.length === 1) {
                    const objData = {
                        Id: r.Data[0].Id,
                        Name: r.Data[0]['Asset Names'],
                        type: ValuationType.SingleAsset,
                        primaryKey: r.Data[0].Id,
                        RegionalArea: r.Data[0].RegionalArea,
                        Fund_Id: r.Data[0].Fund_Id as string,
                        Investment_Id: r.Data[0].Investment_Id as string,
                    };
                    setWizardAssets(objData);
                }
            });
        }
    }, [assetId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (valuationGroupId && enableValGroup) {
            getQBData({
                qbQuery,
                domainId: 'ValuationGroup',
                adhocListViewMembers: GetValGroupColumns(),
                advancedExpression: `[Id] = '${valuationGroupId}' and [PortfolioId] = '${portfolioId}' and ([ValuationGroupAssetCount] > 0) and ((ISNULLOREMPTY([ValuationGroupDisabledDate])) or ([ValuationGroupDisabledDate]>'${formattedDate}'))`,
            }).then((r) => {
                if (r && r.Data && r.Data.length === 1) {
                    const objData = {
                        ValuationGroupId: r.Data[0].ValuationGroupId,
                        ValuationGroupName: r.Data[0]['Valuation Group Name'],
                        type: ValuationType.ValuationGroup,
                        primaryKey: r.Data[0].ValuationGroupId,
                    };
                    setWizardAssets(objData);
                }
            });
        }
    }, [valuationGroupId, enableValGroup]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={css({
                backgroundColor: '#ececec',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            })}
        >
            <ErrorBoundary>
                <WizardHead assetOrGroup={valuationAsset} setWizardisDirty={setWizardisDirty} />

                <WizardProgress currentStep={currentStep} isBulk={false} />

                {/* Step 1 */}
                {currentStep === ValuationSteps.SelectAssetOrValuationGroup && (
                    <SelectAssetOrGroup
                        setValuationAsset={setWizardAssets}
                        portfolioId={portfolioId}
                        enableValGroup={enableValGroup}
                        valuationAsset={valuationAsset}
                        setCurrentStep={setCurrentStep}
                    />
                )}

                {/* Step 2 */}
                {currentStep === ValuationSteps.SelectSetupMethod && (
                    <SelectSetupMethod
                        setupMethod={setupMethod}
                        updateSetupMethod={updateSetupMethod}
                        portfolioTypeId={String(portfolioTypeId)}
                        setCurrentStep={setCurrentStep}
                    />
                )}

                {/* Step 2.1 */}
                {currentStep === ValuationSteps.SelectCycleMethod && (
                    <SelectCycleMethod
                        setupMethod={setupMethod}
                        updateSetupMethod={updateSetupMethod}
                        setCurrentStep={setCurrentStep}
                    />
                )}

                {/* Step 2.2 */}
                {currentStep === ValuationSteps.SetupCycleTemplate && (
                    <ReportCycleTemplateGrid
                        cycleTemplateData={cycleTemplateData}
                        setCycleTemplateData={setCycleTemplateData}
                        setCurrentStep={setCurrentStep}
                        portfolioId={portfolioId}
                        updateReportData={updateReportData}
                        setValuation={setValuation}
                    />
                )}

                {/* Step 2.3 */}
                {currentStep === ValuationSteps.SetupReportPeriods && (
                    <ReportCyclePeriodsGrid
                        cycleTemplateData={cycleTemplateData}
                        setCurrentStep={setCurrentStep}
                        reportData={reportData}
                        updateReportData={updateReportData}
                        setValuation={setValuation}
                        requestParams={{
                            PortfolioId: portfolioId,
                            ValuationGroupId: valuationAsset?.ValuationGroupId || null,
                            AssetsId: valuationAsset?.Id || '',
                            portfolioTypeId,
                            enableValGroup,
                            valuationAsset,
                            valuationGroupAssets,
                        }}
                    />
                )}

                {/* Step 3 */}
                {currentStep === ValuationSteps.SingleValutionForm && (
                    <Step3
                        setValuationFormData={setValuationFormData}
                        formData={
                            formData || {
                                PortfolioTypeId: portfolioTypeId,
                            }
                        }
                        setCurrentStep={setCurrentStep}
                        filters={filters}
                        valuation={valuation}
                    />
                )}

                {/* Step 3.1 */}
                {currentStep === ValuationSteps.SetupValuations && (
                    <SetupValuationsGrid
                        setupMethod={setupMethod}
                        dataForGrid={valuation.addTasks}
                        setCurrentStep={setCurrentStep}
                        requestParams={{
                            enableValGroup,
                            portfolioId,
                            valuationAsset: valuationAsset ?? {},
                            portfolioTypeId: portfolioTypeId as string,
                            valuationMethod: setupMethod.valuationMethod,
                            covValuationMethod: setupMethod.cycleMethod,
                            CreatedBy,
                            valuationGroupAssets,
                        }}
                        setValuation={setValuation}
                        filters={filters}
                    />
                )}

                {/* Step 4 */}
                {currentStep === ValuationSteps.AddContacts && (
                    <AddContacts
                        isBulk={false}
                        setCurrentStep={setCurrentStep}
                        setupMethod={setupMethod}
                        valuation={valuation}
                        setValuation={setValuation}
                        filters={filters}
                    />
                )}

                {/* Step 5 */}
                {currentStep === ValuationSteps.SetupMethodologyAndApproach && (
                    <MethodologyAndApproachSetup
                        valuation={valuation}
                        setValuation={setValuation}
                        setCurrentStep={setCurrentStep}
                        setRequestSetupDetails={setRequestSetupDetails}
                        portfolioId={portfolioId}
                    />
                )}

                {/* Step 5.1 */}
                {currentStep === ValuationSteps.ConfirmMethodologies && (
                    <ConfirmMethodologies
                        valuation={valuation}
                        setValuation={setValuation}
                        setCurrentStep={setCurrentStep}
                        requestSetupDetails={requestSetupDetails}
                        setRequestSetupDetails={setRequestSetupDetails}
                        portfolioId={portfolioId ?? ''}
                        filters={filters}
                    />
                )}

                {/* Step 6 */}
                {currentStep === ValuationSteps.CreateValuations && (
                    <CreateValuations
                        valuation={valuation}
                        setCurrentStep={setCurrentStep}
                        setWizardisDirty={setWizardisDirty}
                    />
                )}
            </ErrorBoundary>
        </div>
    );
};

export default AddValuationWizard;
