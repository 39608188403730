/* istanbul ignore file */
import React from 'react';
import { ActionButton, BaseButton, Button, Modal } from '@fluentui/react';
import { Loader, ProgressStepper, SitusHeader } from '@samc/react-ui-core';
import { useThemeContext, ThemedComponent } from '@samc/react-ui-theme';
import { styled } from 'styletron-react';
import {
    fetchApi as _fetchApi,
    useValuation as _useValuation,
    useValuationContacts as _useValuationContacts,
    useValuationWorkflowTask as _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners as _useValuationWorkflowTaskOwners,
    useValuationWorkflowTaskStatuses as _useValuationWorkflowTaskStatuses,
    Valuation,
    ValuationApiOverrides,
    ValuationWorkflowTask,
    ValuationWorkflowTaskStatus,
    ValuationWorkflowTemplateStage,
    ValuationWorkflowTemplateTaskStatus,
} from '../../../valuationApi';
import { FilteredWorkflowTaskGrid } from '../FilteredWorkflowTaskGrid/FilteredWorkflowTaskGrid';
import { useApplicationContext } from '../../../hooks/useApplicationContext/useApplicationContext';

/* istanbul ignore next */
const StyledModalContainer = styled('div', () => ({
    width: '90vw',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
}));

/* istanbul ignore next */
const StyledFlexContainer = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    gap: '1.5rem',
}));

/* istanbul ignore next */
const StyledFlexColumn = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
}));

/* istanbul ignore next */
const StyledFlexRow = styled('div', () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
}));

/* istanbul ignore next */
const StyledFlexRowCentered = styled('div', () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}));

/* istanbul ignore next */
const StyledLabel = styled('span', ({ theme }: ThemedComponent) => ({
    fontWeight: 'bold',
    fontSize: '18px',
    color: theme.BaseColors.baseDarkBlue.toString(),
}));

/* istanbul ignore next */
const StyledValue = styled('span', () => ({
    fontSize: '16px',
}));

/* istanbul ignore next */
const StyledActionButton = styled(ActionButton, () => ({
    height: '100%',
}));

interface Props
    extends Pick<
        ValuationApiOverrides,
        | 'fetchApi'
        | 'useValuation'
        | 'useValuationContacts'
        | 'useValuationWorkflowTask'
        | 'useValuationWorkflowTaskOwners'
        | 'useValuationWorkflowTaskStatuses'
    > {
    /**
     * The id of the current WorkflowTemplateStage.
     */
    currentWorkflowTemplateStageId: string;
    /**
     * The value to display to represent the Valuations current WorkflowTemplateStatus.
     */
    currentWorkflowTemplateStatusName: string;
    /**
     * Determines whether the Modal is showing or hidden.
     */
    isOpen: boolean;
    /**
     * The Id of the Valuation to fetch.
     */
    valuationId: string;
    /**
     * The collection of ValuationWorkflowTasks to display in the Grid.
     */
    workflowTasks: ValuationWorkflowTask[];
    /**
     * A collection of ValuationWorkflowTaskStatuses which will be combined with the ValuationWorkflowTasks
     * to determine the ValuationWorkflowTaskStatus of a given ValuationWorkflowTask.
     */
    workflowTaskStatuses: ValuationWorkflowTaskStatus[];
    /**
     * A collection of ValuationWorkflowTemplateStages which will separate ValuationWorkflowTasks in the grid
     * by ValuationWorkflowTaskStatus.Name, using Full Width Rows.
     */
    workflowTemplateStages: ValuationWorkflowTemplateStage[];
    /**
     * A collection of ValuationWorkflowTemplateTaskStatuses which will be combined with the ValuationWorkflowTasks
     * to determine the ValuationWorkflowTemplateTaskStatuses of a given ValuationWorkflowTask.
     */
    workflowTemplateTaskStatuses: ValuationWorkflowTemplateTaskStatus[];
    onClickAddAdHocTask: React.MouseEventHandler<
        HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement | BaseButton | Button
    >;
    /**
     * Callback when the user selects the Close button.
     * @param event The triggering event.
     */
    onClickClose?: (
        event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>,
    ) => void;
    onClickViewWorkflowTemplate?: React.MouseEventHandler<
        HTMLButtonElement | HTMLAnchorElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button
    >;
    /**
     * Boolean indicator of if the WorkflowTaskGrid should display in the errored state.
     */
    workflowTaskGridIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowTaskGrid should display in the loading state.
     */
    workflowTaskGridIsLoading?: boolean;
}

export const WorkflowModal = ({
    currentWorkflowTemplateStageId,
    currentWorkflowTemplateStatusName,
    fetchApi = _fetchApi,
    isOpen,
    onClickAddAdHocTask,
    onClickClose,
    onClickViewWorkflowTemplate,
    useValuation = _useValuation,
    useValuationContacts = _useValuationContacts,
    useValuationWorkflowTask = _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners = _useValuationWorkflowTaskOwners,
    useValuationWorkflowTaskStatuses = _useValuationWorkflowTaskStatuses,
    valuationId,
    workflowTaskGridIsErrored,
    workflowTaskGridIsLoading,
    workflowTasks,
    workflowTaskStatuses,
    workflowTemplateStages,
    workflowTemplateTaskStatuses,
}: Props): React.ReactElement => {
    const theme = useThemeContext();
    const { CanUserAddAdHocTask, CanUserViewWorkflowTemplate } = useApplicationContext();

    const valuationRequest = useValuation(valuationId);

    const currentWorkflowTemplateStage = React.useMemo(() => {
        return workflowTemplateStages.find((stage) => stage.Id === currentWorkflowTemplateStageId);
    }, [currentWorkflowTemplateStageId, workflowTemplateStages]);

    const stages = React.useMemo(() => {
        return workflowTemplateStages.map((stage) => {
            return stage.Name as string;
        });
    }, [workflowTemplateStages]);

    const currentStageIndex = React.useMemo(() => {
        return stages.findIndex((stage) => stage === currentWorkflowTemplateStage?.Name) + 1;
    }, [stages, currentWorkflowTemplateStage]);

    return (
        <Modal isOpen={isOpen} layerProps={{ eventBubblingEnabled: true }}>
            <StyledModalContainer>
                <Loader isLoading={valuationRequest.isLoading} isError={valuationRequest.isError}>
                    <SitusHeader
                        title={`Task Status: ${(valuationRequest.data?.Data[0] as unknown as Valuation)?.Name}`}
                        rejectionButtonText="Close"
                        onRejectionButtonClicked={onClickClose}
                    />
                </Loader>
                <StyledFlexContainer>
                    <StyledFlexRow>
                        <StyledFlexColumn>
                            <div>
                                <StyledLabel theme={theme}>Current Stage: </StyledLabel>
                                <StyledValue>{currentWorkflowTemplateStage?.Name}</StyledValue>
                            </div>
                            <div>
                                <StyledLabel theme={theme}>Current Status: </StyledLabel>
                                <StyledValue>{currentWorkflowTemplateStatusName}</StyledValue>
                            </div>
                        </StyledFlexColumn>
                        <StyledFlexColumn>
                            {CanUserViewWorkflowTemplate && (
                                <StyledActionButton
                                    iconProps={{ iconName: 'ViewOriginal' }}
                                    onClick={onClickViewWorkflowTemplate}
                                >
                                    View Workflow Template
                                </StyledActionButton>
                            )}
                            {CanUserAddAdHocTask && (
                                <StyledActionButton iconProps={{ iconName: 'AddTo' }} onClick={onClickAddAdHocTask}>
                                    Add an Ad-Hoc Task
                                </StyledActionButton>
                            )}
                            <StyledActionButton
                                iconProps={{ iconName: 'Go' }}
                                href="#/screens/VALU_ValuationWorkflowTask_ValuationTasks/ValuationTasks"
                            >
                                Go to Bulk Update Task Screen
                            </StyledActionButton>
                        </StyledFlexColumn>
                    </StyledFlexRow>
                    <StyledFlexRow>
                        <div style={{ width: '100%' }}>
                            <ProgressStepper steps={stages} currentStep={currentStageIndex} />
                        </div>
                    </StyledFlexRow>
                    <StyledFlexRowCentered>
                        <Loader isLoading={!!workflowTaskGridIsLoading} isError={!!workflowTaskGridIsErrored}>
                            <FilteredWorkflowTaskGrid
                                defaultIncludedWorkflowTemplateStageId={currentWorkflowTemplateStage?.Id}
                                fetchApi={fetchApi}
                                valuationId={valuationId}
                                useValuationContacts={useValuationContacts}
                                useValuationWorkflowTask={useValuationWorkflowTask}
                                useValuationWorkflowTaskOwners={useValuationWorkflowTaskOwners}
                                useValuationWorkflowTaskStatuses={useValuationWorkflowTaskStatuses}
                                workflowTasks={workflowTasks}
                                workflowTaskStatuses={workflowTaskStatuses}
                                workflowTemplateStages={workflowTemplateStages}
                                workflowTemplateTaskStatuses={workflowTemplateTaskStatuses}
                            />
                        </Loader>
                    </StyledFlexRowCentered>
                </StyledFlexContainer>
            </StyledModalContainer>
        </Modal>
    );
};

export default WorkflowModal;
