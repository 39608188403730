import { ProgressIndicator } from '@fluentui/react';
import React from 'react';

interface NavLoaderProps {
    isLoading: boolean;
    label: string;
}

const NavLoader: React.FC<NavLoaderProps> = ({ isLoading, label }) => {
    return (
        <>
            {isLoading && (
                <ProgressIndicator
                    styles={{
                        root: { width: '100%', textAlign: 'left', padding: '0px 12px' },
                        itemProgress: { padding: 0 },
                        itemName: { paddingTop: 2 },
                    }}
                    label={label}
                />
            )}
        </>
    );
};

export default NavLoader;
