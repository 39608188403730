import { SsfValueFormatter, localeDateFormat } from '@samc/react-ui-formatting';
import { GridFieldConfiguration } from '@samc/react-ui-grid';
import { defaultTheme } from '@samc/react-ui-theme';
import { FieldConfigurationMember, InputType, TaskResponse } from '@samc/screen-config-api';
import { CellClassParams, CellStyle, ProcessHeaderForExportParams, RowClassRules } from 'ag-grid-community';
import { StyleObject } from 'styletron-react';

export interface RowType {
    name: string;
    child: {
        id: string;
        name: string;
        fontRule: string;
    }[];
}

export enum ComparisonColId {
    DATE = 'date',
    PP = 'P/P',
    YY = 'Y/Y',
}

export interface ComparisonResponseType {
    dataResponse: {
        data: Record<string, string>[];
    };
    configSection: {
        section: RowType[];
    };
    ppValuationFields: Record<string, string> | null;
    yyValuationFields: Record<string, string> | null;
}

export interface ComparisonErrorType extends TaskResponse {
    status?: string;
    error?: string;
}

/**
 *
 * @param section object of the API response
 * @param data object from API response
 * @param fieldConfigurationMembers
 * @returns row data for the include datewise values, fontRules.
 */

export const getRowDataWithConfigurations = (
    section: RowType[],
    data: Record<string, unknown>[],
    fieldConfigurationMembers: FieldConfigurationMember[],
): Record<string, unknown>[] => {
    const rowData: Record<string, unknown>[] = [];

    const getRowDataValues = (
        _data: Record<string, unknown>[],
        child: RowType['child'][0],
    ): Record<string, unknown> => {
        const dataValues: Record<string, unknown> = {};

        _data.forEach((col: Record<string, unknown>) => {
            if (col[child.id]) {
                dataValues[
                    col.Valuation_ValuationDateofValue === ComparisonColId.PP ||
                    col.Valuation_ValuationDateofValue === ComparisonColId.YY
                        ? `${col.Valuation_ValuationDateofValue}`
                        : `${col.Valuation_ValuationDateofValue}_${col.ValuationAssetId}`
                ] = col[child.id];
            }
        });

        return dataValues;
    };

    section.forEach((row: RowType) => {
        if (row.child && row.child.length) {
            row.child.forEach((rowChild) => {
                if (rowChild.id.includes('DEAPropertyLoans') || rowChild.id.includes('DEAEntityLoans')) {
                    const loanLabel = rowChild.id.includes('DEAPropertyLoans') ? 'DEAPropertyLoans' : 'DEAEntityLoans';
                    data.forEach((val: Record<string, unknown>) => {
                        if (val[loanLabel]) {
                            (val[loanLabel] as Record<string, unknown>[]).forEach((loan: Record<string, unknown>) => {
                                const rowDataValues = getRowDataValues(data, rowChild);

                                rowData.push({
                                    ...rowChild, // name is required as field label & fontRule for styling the values
                                    sectionLabel: loanLabel,
                                    loan: loan.primaryKey,
                                    fieldConfig: fieldConfigurationMembers.find(
                                        (fcm) => fcm.viewFieldName === rowChild.id,
                                    ),
                                    ...rowDataValues,
                                });
                            });
                        }
                    });
                } else {
                    rowData.push({
                        ...rowChild, // name is required as field label & fontRule for styling the values
                        sectionLabel: row.name,
                        fieldConfig: fieldConfigurationMembers.find((fcm) => fcm.viewFieldName === rowChild.id),
                        ...data.reduce(
                            (acc, col: Record<string, unknown>) => ({
                                ...acc,
                                [col.Valuation_ValuationDateofValue === ComparisonColId.PP ||
                                col.Valuation_ValuationDateofValue === ComparisonColId.YY
                                    ? `${col.Valuation_ValuationDateofValue}`
                                    : `${col.Valuation_ValuationDateofValue}_${col.ValuationAssetId}`]:
                                    col[rowChild.id],
                            }),
                            {},
                        ),
                    });
                }
            });
        }
    });

    return rowData;
};

/**
 * @param css - styletron css function
 * @returns style class rules
 */
export const getComparisonRowClassRules = (css: (style: StyleObject) => string): RowClassRules => ({
    [css({
        backgroundColor: `${defaultTheme.TableColors.rowColors.selected.toString()} !important`,
        fontWeight: 'bold',
    })]: ({ data }) => data === undefined,
});

export const getValueAlignmentByInput = (inputType: InputType): CellStyle => {
    switch (inputType) {
        case InputType.Number:
        case InputType.CurrencyField:
        case InputType.MeasurementField:
        case InputType.Percent:
            return { textAlign: 'right' };
        default:
            return { textAlign: 'right' };
    }
};

/**
 * Evaluates the values against the font rules for each grid cell
 * @returns style for grid cell
 */
export const getCellStyleByFontRules = ({ data, value, column }: CellClassParams): CellStyle => {
    const red = defaultTheme.TextColors.error.toString();
    const green = defaultTheme.TextColors.success.toString();

    const fontRule = data?.fontRule;

    const style = {
        ...getValueAlignmentByInput(data?.fieldConfig?.inputType ?? InputType.Text),
        color: defaultTheme.TextColors.dark.toString(),
    };

    if (data?.id === 'ValueConclusionChange' || data?.id === 'ValuePctChange') {
        const cellValue = typeof value === 'string' ? value.replace(/[^0-9-]/g, '') : value;
        style.color = parseFloat(cellValue) >= 0 ? green : red;
    }

    const colId = column.getColId();

    if (fontRule && value && (colId === ComparisonColId.PP || colId === ComparisonColId.YY)) {
        const cellValue = data.calcFlag && typeof value === 'string' ? value.replace(/[^0-9-]/g, '') : value;

        switch (fontRule) {
            case 'Pos = Green; Neg = Red':
                return { ...style, color: parseInt(cellValue, 10) >= 0 ? green : red };
            case 'Pos = Red; Neg = Green':
                return { ...style, color: parseInt(cellValue, 10) <= 0 ? green : red };
            case 'Black':
            case 'N/A':
            default:
        }
    }

    return style;
};

export const getCompareColDef = (field: string, headerName: string): GridFieldConfiguration => ({
    field,
    headerName,
    width: 150,
    cellRenderer: 'pivotCellRenderer',
    cellStyle: getCellStyleByFontRules,
});

export const getComparisonErrors = (errorResponse: ComparisonErrorType | undefined): TaskResponse[] =>
    errorResponse
        ? [
              {
                  messages: errorResponse.messages.map(({ title, ...params }) => ({
                      ...params,
                      title: errorResponse.error ?? title,
                      detail: title,
                  })),
                  prompts: [],
                  childResponses: {},
                  newIds: errorResponse.newIds,
                  statusCode: 1,
                  messageCount: 1,
                  deletedIds: [],
                  updatedIds: [],
                  requestIdentifier: '',
                  domainId: '',
              },
          ]
        : [];

export const getFormattedHeaderDate = (date: string): string => SsfValueFormatter(date, 'mm/dd/yyyy', 'date') as string;

export const processHeaderCallback = ({ column }: ProcessHeaderForExportParams): string => {
    const { headerName = '', headerComponentParams = {} } = column.getColDef();
    const { date = new Date() } = headerComponentParams;

    return headerName === ComparisonColId.DATE ? localeDateFormat(new Date(date), undefined, false) : headerName;
};
