import React from 'react';
import { SitusHeader, ControlBar, useDirtinessPrompt } from '@samc/react-ui-core';
import { useHeaderContext } from '@samc/react-ui-history';

export interface PanelHeaderProps {
    /**
     * The HTML scope under which to look for dirty components and populate buttons/etc.
     */
    dirtinessScope: HTMLElement | React.RefObject<HTMLElement> | React.MutableRefObject<HTMLElement>;
    /**
     * The text to display in the Header.
     */
    title: string;
    /**
     * Overrides the default disabled state of the cancel button.
     */
    cancelDisabledOverride?: boolean;
    /**
     * Callback on click of Cancel button. Called after the internal call to resetForm().
     */
    onCancel?: () => void;
    /**
     * Callback on click of Submit and Close button. Called after the internal call to submitForm().
     */
    onSubmitAndClose?: () => void;
    /**
     * Override of the submitDisabled prop to determine if the submit button is disabled or not.
     */
    submitDisabledOverride?: boolean;
}

export const PanelHeader = (props: PanelHeaderProps): React.ReactElement => {
    const { title, cancelDisabledOverride, dirtinessScope, onCancel, onSubmitAndClose, submitDisabledOverride } = props;

    const { promptAndContinue, save, isDirty } = useDirtinessPrompt({ scope: dirtinessScope });

    const { controlBarProps } = useHeaderContext();

    const handleResetForm = async (): Promise<void> => {
        await promptAndContinue(() => {
            onCancel?.();
        });
    };

    const handleSubmitAndClose = async (): Promise<void> => {
        await save();
        onSubmitAndClose?.();
    };

    return (
        <SitusHeader title={title}>
            <ControlBar
                cancel={handleResetForm}
                submitAndClose={handleSubmitAndClose}
                submitDisabled={(submitDisabledOverride ?? controlBarProps?.submitDisabled) || !isDirty}
                cancelDisabled={cancelDisabledOverride ?? controlBarProps?.cancelDisabled}
            />
        </SitusHeader>
    );
};

export default PanelHeader;
