import React, { useCallback, useState } from 'react';
import { Button, UnsavedChangesDialog } from '@samc/react-ui-core';
import { useNavigate } from 'react-router';

interface PropTypes {
    assetOrGroup: Record<string, unknown> | undefined;
    setWizardisDirty: (state: boolean) => void;
}

const WizardHead = ({ assetOrGroup, setWizardisDirty }: PropTypes): React.ReactElement => {
    const [showModal, toggleModal] = useState(false);

    const navigate = useNavigate();

    const closeModal = useCallback(
        (discard: boolean) => {
            toggleModal(false);
            setWizardisDirty(false);
            if (discard) {
                navigate(-1);
            }
        },
        [navigate, setWizardisDirty],
    );

    return (
        <div className="pageHeading">
            <span>
                Add Valuation
                {assetOrGroup && assetOrGroup.Name ? `: ${assetOrGroup.Name}` : ''}
                {assetOrGroup && assetOrGroup.ValuationGroupName ? `: ${assetOrGroup.ValuationGroupName}` : ''}
            </span>

            <Button
                buttonType="primary"
                text="Cancel"
                onClick={(): void => {
                    toggleModal(true);
                }}
                className="pull-right"
            />

            <UnsavedChangesDialog show={showModal} close={closeModal} />
        </div>
    );
};

export default WizardHead;
