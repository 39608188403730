import { QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { UseQueryResult, useQuery } from 'react-query';
import { useApiContext } from '../../hooks/useApiContext';
import { executeLiftSiftQuery } from '../api/Requests';
import { ValuationWorkflowParticipant } from '../models/ValuationWorkflowParticipant';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';

export const VALUATION_WORKFLOW_PARTICIPANTS_KEY = 'VALU_ValuationWorkflowParticipant';

export const useValuationWorkflowParticipants = (
    workflowTemplateIds: string[],
): UseQueryResult<QueryExecuteResponse<ValuationWorkflowParticipant>> => {
    const api = useApiContext();
    const { SystemExecuteMaxRecordsToReturn } = useApplicationContext();

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_ValuationWorkflowParticipant',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarExpression: '[Id]',
                scalarDisplayName: 'Id',
            },
            {
                scalarExpression: '[Name]',
                scalarDisplayName: 'Name',
            },
            {
                scalarExpression: '[RoleId]',
                scalarDisplayName: 'RoleId',
            },
            {
                scalarExpression: '[AllowableCompanyTypes]',
                scalarDisplayName: 'AllowableCompanyTypes',
            },
            {
                scalarExpression: '[DefaultAssetContact]',
                scalarDisplayName: 'DefaultAssetContact',
            },
            {
                scalarExpression: '[DefaultFundContact]',
                scalarDisplayName: 'DefaultFundContact',
            },
            {
                scalarExpression: '[DefaultInvestmentContact]',
                scalarDisplayName: 'DefaultInvestmentContact',
            },
            {
                scalarExpression: '[DefaultPortfolioContact]',
                scalarDisplayName: 'DefaultPortfolioContact',
            },
            {
                scalarExpression: '[RoleRequiredAtCreation]',
                scalarDisplayName: 'RoleRequiredAtCreation',
            },
            {
                scalarExpression: '[RoleTypeId]',
                scalarDisplayName: 'RoleTypeId',
            },
            {
                scalarExpression: '[WorkflowTemplateId]',
                scalarDisplayName: 'WorkflowTemplateId',
            },
        ],
        sorting: {
            order: 'asc',
            orderBy: {
                scalarExpression: 'IIF(ISNULLOREMPTY([ParticipantSortOrder]),9999,[ParticipantSortOrder])',
            },
        },
        paging: { start: 0, stop: SystemExecuteMaxRecordsToReturn },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[IsActive] = true and [WorkflowTemplateId] in ('${workflowTemplateIds.join("','")}')`,
        },
    };

    const queryResult = useQuery<QueryExecuteResponse<ValuationWorkflowParticipant>>(
        [VALUATION_WORKFLOW_PARTICIPANTS_KEY, workflowTemplateIds],
        () =>
            executeLiftSiftQuery(
                api.LiftSiftApi,
                requestBody,
                api.requestInit,
                api.ShowMessage,
            ) as unknown as QueryExecuteResponse<ValuationWorkflowParticipant>,
        { retry: 2, refetchOnWindowFocus: false },
    );

    return queryResult;
};

export default useValuationWorkflowParticipants;
