export interface SectionType {
    name: string;
    child: {
        id: string;
        name: string;
        fontRule: string;
    }[];
}

export enum LoanType {
    PROPERTY = 'DEAPropertyLoans',
    ENTITY = 'DEAEntityLoans',
}

export interface ComparisonDataType {
    dataResponse: { data: Record<string, unknown>[] };
    configSection: { section: SectionType[] };
    ppValuationFields: Record<string, unknown> | null;
    yyValuationFields: Record<string, unknown> | null;
}
