import React from 'react';
import { useLocation } from 'react-router-dom';
import { ListViewMember, useListView, DomainViewMember, ListView } from '@samc/screen-config-api';
import { TooltipHost } from '@fluentui/react';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { HeaderContextProvider } from '@samc/react-ui-history';
import { useDirtinessPrompt } from '@samc/react-ui-core';
import FieldDifference from './FieldDifference';
import { useEsInputData } from '../../../valuationApi/useEsInputData/useEsInputData';
import { usePomaAsset } from '../../../valuationApi/usePomaAsset/usePomaAsset';
import { useEsInputDataDefault } from '../../../valuationApi/useEsInputDataDefault/useEsInputDataDefault';
import { FieldDifferenceVM, KeyValueVM } from '../../static/ValuationConstants';
import { decodeBase64ToObject } from '../../../util';

interface Props {
    esInputDataId: string;
    params: CustomScreenParams;
}

export const ESINPUT_TAB_ID = 'ESINPUT';
export const ESINPUTDATA_DOMAIN_ID = 'VALU_ESInputData';
export const ESINPUT_SIDE_PANEL_FORMVIEW_ID = 'VALU_EsInputSidePanelForm';
export const ESInputAssetFieldDifferenceView = 'ESInputAssetFieldDifferenceView';
export const URL_PARAMNAME_DEFAULTS = 'defaults';

export const FieldDifferenceWrapper = (props: Props): JSX.Element => {
    const { params, esInputDataId } = props;
    const { filters } = params;
    const location = useLocation();
    const parameters = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [showModal, toggleModal] = React.useState<boolean>(false);
    const [listViewMembers, setListViewMembers] = React.useState<ListViewMember[]>([]);

    const getBase64DefaultValuesFromUrl = (): string | null => {
        return parameters.has(URL_PARAMNAME_DEFAULTS) ? parameters.get(URL_PARAMNAME_DEFAULTS) : null;
    };

    const encodedDefaultValuesJson = getBase64DefaultValuesFromUrl();

    const defaultValues = React.useMemo(() => {
        if (!encodedDefaultValuesJson || !encodedDefaultValuesJson.length) {
            return undefined;
        }
        try {
            return decodeBase64ToObject<Record<string, unknown>>(encodedDefaultValuesJson);
        } catch {
            console.error(`Unable to decode or parse default data '${encodedDefaultValuesJson}'.`); // eslint-disable-line no-console
            return undefined;
        }
    }, [encodedDefaultValuesJson]);

    if (filters && filters.indexOf('[IsLatestVersion]=1') < 0) {
        filters.push('[IsLatestVersion]=1');
    }
    const { data: listView } = useListView(ESInputAssetFieldDifferenceView);

    // globally scoped
    const { promptAndContinue, isDirty } = useDirtinessPrompt({ scope: document.body });

    const [keyChange, setkeyChange] = React.useState<number>(1);
    React.useEffect(() => {
        if (isDirty === false) {
            setkeyChange(keyChange + 1);
        }
    }, [isDirty]);

    // ES input data default
    const { data: esInputDataDefault } = useEsInputDataDefault();
    const adhocListViewMemberList: DomainViewMember[] = React.useMemo(() => {
        if (esInputDataDefault?.Data !== undefined) {
            return esInputDataDefault?.Data?.map((item: { SharedFieldId: string }) => {
                return {
                    scalarDisplayName: `${item.SharedFieldId}`,
                    scalarExpression: `[${item.SharedFieldId}]`,
                } as DomainViewMember;
            });
        }
        return [];
    }, [esInputDataDefault?.Data]);
    // ES input data
    const useEsInput = useEsInputData(esInputDataId as string, adhocListViewMemberList, keyChange);
    const { data: esInputData } = useEsInput;
    // Poma Asset
    const { data: pomaAsset } = usePomaAsset(
        (esInputData?.Data[0] as Record<string, unknown>)?.AssetId as string,
        adhocListViewMemberList,
        keyChange,
    );

    const esInputPivotData: KeyValueVM[] = React.useMemo(() => {
        if (esInputData?.Data[0] !== undefined) {
            return Object.entries(esInputData?.Data[0]).map(([key, value]) => {
                return { name: key, data: value } as KeyValueVM;
            });
        }
        return [];
    }, [esInputData?.Data[0]]);
    const assetPivotData: KeyValueVM[] = React.useMemo(() => {
        if (pomaAsset?.Data[0] !== undefined) {
            return Object.entries(pomaAsset?.Data[0]).map(([key, value]) => {
                return { name: key, data: value } as KeyValueVM;
            });
        }
        return [];
    }, [pomaAsset?.Data[0]]);
    const fieldDifferenceData: FieldDifferenceVM[] = React.useMemo(() => {
        if (
            pomaAsset?.Data !== undefined &&
            esInputData?.Data !== undefined &&
            esInputDataDefault?.Data !== undefined
        ) {
            return esInputDataDefault?.Data?.map((item: { SharedFieldId: string }) => {
                const esInput = esInputPivotData.find((x) => x.name === item.SharedFieldId);
                const asset = assetPivotData.find((x) => x.name === item.SharedFieldId);
                if (esInput !== undefined && asset !== undefined) {
                    if (esInput.data !== asset.data) {
                        return {
                            FieldName: item.SharedFieldId,
                            AssetValue: asset.data,
                            ValuationValue: esInput.data,
                        } as FieldDifferenceVM;
                    }
                } else if (!(esInput === undefined && asset === undefined)) {
                    return {
                        FieldName: item.SharedFieldId,
                        AssetValue: asset?.data,
                        ValuationValue: esInput?.data,
                    } as FieldDifferenceVM;
                }
                return {} as FieldDifferenceVM;
            }).filter((value) => Object.keys(value).length !== 0);
        }
        return [];
    }, [pomaAsset?.Data, esInputData?.Data, esInputDataDefault?.Data]);

    const [gridData, setgridData] = React.useState<Record<string, unknown>[]>([
        { Entity_Type_Custom: 'Asset Value' },
        { Entity_Type_Custom: 'Valuation Value' },
    ]);

    React.useEffect(() => {
        if (listView !== undefined && fieldDifferenceData.length > 0) {
            fieldDifferenceData.forEach((item) => {
                setgridData((previousGridData) => {
                    return [
                        { ...previousGridData[0], [`${item.FieldName}`]: item.AssetValue },
                        { ...previousGridData[1], [`${item.FieldName}`]: item.ValuationValue },
                    ];
                });
            });
            const listViewMembersList: ListViewMember[] = [
                {
                    viewFieldName: 'Entity_Type_Custom',
                    scalarExpression: '[Entity_Type_Custom]',
                    scalarDisplayName: 'Entity Type',
                    summaryDisplayName: 'Entity Type',
                    sequence: 1,
                    width: 150,
                },
            ];
            listView.listViewMembers.forEach((item) => {
                const check = fieldDifferenceData.find((x) => x.FieldName === item.viewFieldName);
                if (check !== undefined) {
                    listViewMembersList.push(item);
                }
            });
            setListViewMembers(listViewMembersList);
        }
    }, [fieldDifferenceData, fieldDifferenceData.length]);

    const closeModal = (): void => {
        toggleModal(false);
    };
    if (!esInputData || !pomaAsset || fieldDifferenceData.length === 0) return <></>;

    return (
        <>
            <TooltipHost content="There are differences between the Asset Record and the ES Input data. Click here to view differences. Note: Changes are based on last load of the screen. Unsaved changes are not considered. Click Save to view latest count">
                <button
                    type="button"
                    onClick={(event): void => {
                        promptAndContinue(() => {
                            toggleModal(true);
                        });
                        event.preventDefault();
                    }}
                    className="nav-link"
                    style={{
                        background: 'transparent',
                        border: '0',
                        color: '#62a7d9',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}
                >
                    {`${fieldDifferenceData.length} Asset Field Difference${fieldDifferenceData.length > 1 ? 's' : ''}`}
                </button>
            </TooltipHost>
            {showModal && (
                <HeaderContextProvider>
                    <FieldDifference
                        AssetId={esInputData?.Data[0]?.AssetId as string}
                        closeModal={closeModal}
                        gridData={gridData}
                        showModal={showModal}
                        listviewMembers={listViewMembers}
                        listView={listView as ListView}
                        locationParam={{
                            defaults: encodedDefaultValuesJson as string,
                            PortfolioId: defaultValues?.PortfolioId as string,
                        }}
                    />
                </HeaderContextProvider>
            )}
        </>
    );
};

export default FieldDifferenceWrapper;
