import { PicklistDisplaySetting, PicklistSetting } from '@samc/picklist-api';
import { GridFieldConfigurationWithPicklistField } from '@samc/picklist-core';
import { QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { PicklistFieldGetterParams } from '@samc/picklist-core/lib/grid/types/PicklistFieldGetter';
import { CellClassParams, CellStyle } from 'ag-grid-community';
import { ValuationWorkflowParticipant } from '../../../valuationApi/models/ValuationWorkflowParticipant';
import {
    AddTask,
    CC_Contact_Picklist,
    CC_ViewForValuationContact,
    VALU_ValuationWorkflowTemplate_Picklist,
} from '../../static/ValuationConstants';
import { getQBData } from '../AddValutionWizard/AddValuationWizard.service';

export interface cc_data {
    roleId: string;
    valuationId: string;
    workflowTemplateId: string;
    contacts?: string[];
}

export const primaryPicklistFields = [
    {
        scalarExpression: '[RoleTypeIds]',
        scalarDisplayName: 'RoleTypeIds',
    },
    {
        scalarExpression: '[RoleIds]',
        scalarDisplayName: 'RoleIds',
    },
    {
        scalarExpression: '[InactivationDate]',
        scalarDisplayName: 'InactivationDate',
    },
    {
        scalarExpression: '[UserStatus]',
        scalarDisplayName: 'UserStatus',
    },
    {
        scalarExpression: '[CompanyInactivationDate]',
        scalarDisplayName: 'CompanyInactivationDate',
    },
    {
        scalarExpression: '[CompanyTypeRestrictions]',
        scalarDisplayName: 'CompanyTypeRestrictions',
    },
];

export const GetDefaultContacts = async (
    qbQuery: (params: QueryExecuteRequest) => Promise<QueryExecuteResponse>,
    domainName: string,
    advancedExpression: string,
): Promise<QueryExecuteResponse> => {
    const t = await getQBData({
        qbQuery,
        domainId: domainName,
        adhocListViewMembers: [
            {
                scalarExpression: '[ContactId]',
                scalarDisplayName: 'ContactId',
            },
        ],
        advancedExpression,
        stop: 50000,
    });
    return t;
};

export const cellStyle = (params: CellClassParams): CellStyle | null => {
    const roleId = params.column.getColId();
    const { value } = params;

    return params.data[`roleRequiredAtCreation_${roleId}`]
        ? { backgroundColor: value === '' ? '#FFE3E3' : '#E4F7DA' }
        : null;
};

export const getPicklistFieldforContacts =
    (selected?: ValuationWorkflowParticipant) =>
    (params: PicklistFieldGetterParams<Record<string, undefined>>): Record<string, unknown> => ({
        // concat roleId along with workflowtemplateId to get both id in getPicklist callback.
        id: `${selected?.RoleId}_${params.data.WorkflowTemplateId}`,
        picklistId: CC_Contact_Picklist,
        displaySetting: PicklistDisplaySetting.CustomLongName,
        setting: PicklistSetting.MultiGridSelectInput,
        listViewId: CC_ViewForValuationContact,
        valueSetting: PicklistDisplaySetting.Id,
    });

export const getContactGridColumns = (
    distinctRoles: Record<string, string>,
    workflowParticipants: ValuationWorkflowParticipant[],
): GridFieldConfigurationWithPicklistField[] => {
    return [
        {
            field: 'ValuationName',
            displayNameRule: 'Valuation Name',
        },
        {
            field: 'ValuationWorkflowTemplateId',
            displayNameRule: 'Workflow',
            picklistField: {
                id: VALU_ValuationWorkflowTemplate_Picklist,
                picklistId: VALU_ValuationWorkflowTemplate_Picklist,
                displaySetting: PicklistDisplaySetting.CustomLongName,
                setting: PicklistSetting.MultiGridSelectInput,
                valueSetting: PicklistDisplaySetting.Id,
            },
        },
        {
            field: 'CompanyId',
            displayNameRule: 'CompanyId',
            visibleRule: 'false',
        },
        ...Object.keys(distinctRoles).map((roleId) => {
            const selected = workflowParticipants?.find((row) => row.RoleId === roleId);

            return {
                field: selected?.RoleId as string,
                displayNameRule: selected?.Name as string,
                editableRule: `[enableForValuation_${selected?.RoleId}]`,
                cellStyle,
                picklistField: getPicklistFieldforContacts(selected),
            };
        }),
    ] as unknown as GridFieldConfigurationWithPicklistField[];
};

export const getContactGridData = (
    workflowParticipants: ValuationWorkflowParticipant[] | undefined,
    valuationAddTasks: AddTask[] | undefined,
    defaultContactsList: cc_data[],
    fetchDefaultContact = false,
): Record<string, unknown>[] =>
    valuationAddTasks
        ? valuationAddTasks.map(
              ({
                  ValuationUniqId,
                  ValuationName,
                  ValuationWorkflowTemplateId,
                  StatusId,
                  Portfolio_Id,
                  ValuationContacts,
                  FundIds,
                  InvestmentIds,
                  ValuationAssetsId,
                  ValuationGroupId,
              }: AddTask) => {
                  const selected =
                      workflowParticipants?.filter((row) => ValuationWorkflowTemplateId === row.WorkflowTemplateId) ??
                      [];
                  let td = {};
                  for (let index = 0; index < selected.length; index++) {
                      const contact = defaultContactsList.find(
                          (row) =>
                              row.roleId === selected[index]?.RoleId &&
                              ValuationWorkflowTemplateId === row.workflowTemplateId &&
                              ValuationUniqId === row.valuationId,
                      );
                      // const picklistValue = contact?.contacts ? [...new Set(contact?.contacts as string[])].join() : '';
                      let picklistValue;

                      if (fetchDefaultContact) {
                          picklistValue = contact?.contacts ? [...new Set(contact?.contacts as string[])].join() : '';
                      } else {
                          const filterContacts = ValuationContacts?.filter(
                              (w) => w.RoleId === selected[index]?.RoleId,
                          ).map(({ ContactId }) => ContactId);

                          picklistValue =
                              filterContacts && filterContacts !== null ? [...new Set(filterContacts)].join() : '';
                      }

                      td = {
                          ...td,
                          ...selected[index],
                          CompanyId: null,
                          [`enableForValuation_${selected[index]?.RoleId}`]: true,
                          [`roleRequiredAtCreation_${selected[index]?.RoleId}`]:
                              selected[index]?.RoleRequiredAtCreation,
                          [`${selected[index]?.RoleId}`]: picklistValue,
                      };
                  }
                  return {
                      ...td,
                      ValuationName,
                      ValuationWorkflowTemplateId,
                      ValuationId: ValuationUniqId,
                      StatusId,
                      Portfolio_Id,
                      FundIds,
                      InvestmentIds,
                      AssetIds: ValuationAssetsId,
                      ValuationGroupId,
                  };
              },
          )
        : [];

export const getSelectedContacts = (
    row: ValuationWorkflowParticipant,
    item: Record<string, unknown>,
): Record<string, unknown>[] => {
    const contactIds = (item[row.RoleId] ? (item[row.RoleId] as string) : '').split(',');

    return contactIds.map((contactId) => ({
        RoleId: row.RoleId as string,
        RoleName: row.Name as string,
        ValuationWorkflowParticipantId: row.Id as string,
        CompanyId: '',
        ContactId: contactId,
        Portfolio_Id: item.Portfolio_Id as string,
        StatusId: item.StatusId,
        ValuationName: item.ValuationName,
    }));
};
