/* istanbul ignore file */
import React from 'react';
import { ErrorBoundary } from '@samc/react-ui-core';
import { useQueryClient } from 'react-query';
import { HeaderContextProvider } from '@samc/react-ui-history';
import { EditingProvider } from '@samc/react-ui-grid';
import {
    VALUATION_WORKFLOW_STATUS_KEY,
    VALUATION_WORKFLOW_TASKS_KEY,
    ValuationWorkflowTask,
    ValuationWorkflowTaskStatus,
    ValuationWorkflowTemplateStage,
    ValuationWorkflowTemplateTask,
    ValuationWorkflowTemplateTaskStatus,
} from '../../../valuationApi';
import { WorkflowBox } from '../../organisms/WorkflowBox/WorkflowBox';
import { WorkflowModalContextProvider } from '../../../contexts/WorkflowModalContext/WorkflowModalContext';
import { CaseInsensitiveStringEquals } from '../../functions/CaseInsensitiveStringEquals';
import { ValuationHub } from '../../../enums/ValuationHub';
import { useApiContext } from '../../../hooks/useApiContext';
import { ValuationEvent } from '../../../models/ValuationEvent';

interface Props {
    /**
     * The id of the Valuation for which to render the WorkflowBox.
     */
    valuationId: string;
}

export const WorkflowBoxRequestWrapper = ({ valuationId }: Props): React.ReactElement => {
    const queryClient = useQueryClient();

    const {
        useValuationWorkflowStatus,
        useValuationHub,
        useValuationWorkflowTasks,
        useValuationWorkflowTaskStatuses,
        useValuationWorkflowTemplateStages,
        useValuationWorkflowTemplateTasks,
        useValuationWorkflowTemplateTaskStatuses,
    } = useApiContext();

    // Initial requests
    const workflowStatusRequest = useValuationWorkflowStatus(valuationId);
    const workflowTasksRequest = useValuationWorkflowTasks(valuationId);
    const workflowTaskStatusesRequest = useValuationWorkflowTaskStatuses();

    // Dependent requests
    const workflowTemplateStagesRequest = useValuationWorkflowTemplateStages(
        workflowStatusRequest.data?.valuationWorkflowTemplateId,
    );
    const workflowTemplateTaskIds = React.useMemo(() => {
        if (!workflowTasksRequest.data?.Data) return undefined;

        return (workflowTasksRequest.data?.Data as unknown as ValuationWorkflowTask[])
            .filter((task) => task.WorkflowTemplateTaskId)
            .map((task) => task.WorkflowTemplateTaskId as string);
    }, [workflowTasksRequest.data?.Data]);
    const workflowTemplateTaskStatusesRequest = useValuationWorkflowTemplateTaskStatuses(workflowTemplateTaskIds);
    const workflowTemplateTasksRequest = useValuationWorkflowTemplateTasks(
        workflowStatusRequest.data?.valuationWorkflowTemplateId,
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_isConnected, connection] = useValuationHub();

    React.useEffect(() => {
        connection.on(ValuationHub.ValuationEventFired, (eventModel: ValuationEvent) => {
            if (CaseInsensitiveStringEquals(valuationId, eventModel.valuationId)) {
                queryClient.invalidateQueries([VALUATION_WORKFLOW_TASKS_KEY]);
                queryClient.invalidateQueries([VALUATION_WORKFLOW_STATUS_KEY]);
            }
        });

        return () => {
            connection.off(ValuationHub.ValuationEventFired);
        };
    }, [connection, queryClient, valuationId]);

    return (
        <ErrorBoundary>
            <HeaderContextProvider>
                <EditingProvider>
                    <WorkflowModalContextProvider>
                        <WorkflowBox
                            currentWorkflowTemplateStageId={
                                workflowStatusRequest.data?.valuationWorkflowTemplateStageId as string
                            }
                            currentWorkflowTemplateStatusName={
                                workflowStatusRequest.data?.valuationWorkflowTemplateStatus as string
                            }
                            openTasksCountForUser={workflowStatusRequest.data?.openTasksCountForUser as number}
                            openTasksCountTotal={workflowStatusRequest.data?.openTasksCountTotal as number}
                            valuationId={valuationId}
                            workflowBoxIsNoData={!workflowStatusRequest.data?.valuationWorkflowTemplateStatus}
                            workflowTaskGridIsErrored={
                                workflowTasksRequest.isError ||
                                workflowTemplateStagesRequest.isError ||
                                workflowTaskStatusesRequest.isError ||
                                workflowTemplateTaskStatusesRequest.isError
                            }
                            workflowTaskGridIsLoading={
                                workflowTasksRequest.isLoading ||
                                workflowTemplateStagesRequest.isLoading ||
                                workflowTaskStatusesRequest.isLoading ||
                                workflowTemplateTaskStatusesRequest.isLoading
                            }
                            workflowTasks={workflowTasksRequest.data?.Data as unknown as ValuationWorkflowTask[]}
                            workflowTaskStatuses={
                                workflowTaskStatusesRequest.data?.Data as unknown as ValuationWorkflowTaskStatus[]
                            }
                            workflowTemplateStages={
                                workflowTemplateStagesRequest.data?.Data as unknown as ValuationWorkflowTemplateStage[]
                            }
                            workflowTemplateTaskStatuses={
                                workflowTemplateTaskStatusesRequest.data
                                    ?.Data as unknown as ValuationWorkflowTemplateTaskStatus[]
                            }
                            workflowTemplateTasks={
                                workflowTemplateTasksRequest.data?.Data as unknown as ValuationWorkflowTemplateTask[]
                            }
                            workflowStatusRequestIsErrored={workflowStatusRequest.isError}
                            workflowStatusRequestIsLoading={workflowStatusRequest.isLoading}
                            workflowTemplateStagesRequestIsErrored={workflowTemplateStagesRequest.isError}
                            workflowTemplateStagesRequestIsLoading={workflowTemplateStagesRequest.isLoading}
                            workflowTemplateTasksRequestIsErrored={workflowTemplateTasksRequest.isError}
                            workflowTemplateTasksRequestIsLoading={workflowTemplateTasksRequest.isLoading}
                        />
                    </WorkflowModalContextProvider>
                </EditingProvider>
            </HeaderContextProvider>
        </ErrorBoundary>
    );
};

export default WorkflowBoxRequestWrapper;
