import React from 'react';
import { HeaderContextProvider } from '@samc/react-ui-history';
import { useSearchParams } from 'react-router-dom';
import { useStyletron } from 'styletron-react';
import { FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { toastError, ToastMessage, toastSuccess, Text, useDirtinessSync } from '@samc/react-ui-core';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { FormView, TaskRequest, TaskResponse } from '@samc/screen-config-api';
import TaskStatus from '@samc/screen-config-api/lib/model/Tasks/Enums/TaskStatus';
import TaskResponsePopup from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { apiProvider } from '../../../valuationApi';
import { useApiContext } from '../../../hooks/useApiContext';
import { TwoGrids } from '../ValuationTwoGrid/TwoGrids';
import './CustomTabRenderer.scss';

export const CustomTabRenderer = ({
    filters,
    tabId,
    primaryKeyValue,
    defaultData,
}: CustomScreenParams): JSX.Element => {
    const [selectedRows, setSelectedRows] = React.useState<Record<string, unknown>[]>([]);
    const [newGroupID, setNewGroupID] = React.useState<string | undefined>(primaryKeyValue);
    const api = useApiContext();

    const [css] = useStyletron();
    const [searchParams] = useSearchParams();
    const valuationId: string | null = searchParams.get('primaryKeyValue');
    const dirtinessScope = React.useRef<HTMLDivElement>(null);

    const [requestResponse, setRequestResponse] = React.useState<{
        request: TaskRequest[];
        response: TaskResponse[];
    }>();

    const gridDataPayloadvaluationAssets = React.useMemo(
        () =>
            selectedRows
                .filter((u): boolean => !u.__DELETED__)
                .reduce(
                    (acc, row, index) => ({
                        ...acc,
                        [`${-index - 1}`]: {
                            ValuationAssetId: String(`${-index - 1}`),
                            ValuationId: String(valuationId),
                            AssetId: String(row.Id),
                            PortfoliosId: String(row.Fund_PortfolioId),
                            AssetName: String(row.Name),
                            ExcludeAssetFromReporting: false,
                            FundId: String(row.Investment_FundId),
                            InvestmentId: String(row.InvestmentId),
                            __ADDED__: true,
                        },
                    }),
                    {},
                ) as Record<string, unknown>,
        [selectedRows, valuationId],
    );

    const gridDataPayload = React.useMemo(
        () =>
            selectedRows
                .filter((u): boolean => !u.__DELETED__)
                .reduce(
                    (acc, row, index) => ({
                        ...acc,
                        [`${-index - 1}`]: {
                            ValuationGroupId: String(newGroupID),
                            AssetsId: String(row.Id),
                            PortfoliosId: String(row.Fund_PortfolioId),
                            AssetName: String(row.Name),
                            FundId: String(row.Investment_FundId),
                            InvestmentId: String(row.InvestmentId),
                            __ADDED__: true,
                            __INDEX__: index + 1,
                        },
                    }),
                    {},
                ) as Record<string, unknown>,
        [newGroupID, selectedRows],
    );

    const onSubmitValuationGroupAsset = React.useCallback(
        async (overrideCodes?: string[]): Promise<void> => {
            const payload = gridDataPayload as TaskRequest['payload'];

            let response: TaskResponse;
            let request: TaskRequest;
            try {
                [response, request] = await apiProvider.submitTask(
                    api.TaskApi,
                    'ValuationGroupAsset',
                    'ValuationGroupAsset',
                    'ValuationGroupAsset Grid',
                    payload as TaskRequest['payload'],
                    api.requestInit ?? {},
                    false,
                    undefined,
                    overrideCodes,
                );
            } catch (e) {
                toastError(<ToastMessage title="Error" message="Something went wrong" />);
                throw e;
            }

            if (response.statusCode !== TaskStatus.Completed) {
                setRequestResponse({ request: [request], response: [response] });
                throw new Error('Task did not complete');
            } else {
                toastSuccess(<ToastMessage title="Success" message="Valuation Group saved successfully" />);
                setSelectedRows([]); // Resets grid selection data to ensure form is not dirty anymore
            }
        },
        [api.TaskApi, api.requestInit, gridDataPayload],
    );

    const onSubmitValuationAsset = React.useCallback(
        async (overrideCodes?: string[]): Promise<void> => {
            const payload = gridDataPayloadvaluationAssets as TaskRequest['payload'];

            let response: TaskResponse;
            let request: TaskRequest;
            try {
                [response, request] = await apiProvider.submitTask(
                    api.TaskApi,
                    'ValuationAsset',
                    'ValuationAsset',
                    'ValuationAsset Grid',
                    payload as TaskRequest['payload'],
                    api.requestInit ?? {},
                    false,
                    undefined,
                    overrideCodes,
                );
            } catch (e) {
                toastError(<ToastMessage title="Error" message="Something went wrong" />);
                throw e;
            }

            if (response.statusCode !== TaskStatus.Completed) {
                if (!overrideCodes) {
                    setRequestResponse({ request: [request], response: [response] });
                }

                throw new Error('Task did not complete');
            } else {
                toastSuccess(<ToastMessage title="Success" message="Valuation saved successfully" />);
                setSelectedRows([]); // Resets grid selection data to ensure form is not dirty anymore
            }
        },
        [api.TaskApi, api.requestInit, gridDataPayloadvaluationAssets],
    );

    const onAddGroup = React.useCallback(
        (_formView: FormView, _request: TaskRequest<Record<string, unknown>>[], response: TaskResponse[]) => {
            if (response[0].newIds && Object.values(response[0].newIds)[0]) {
                setNewGroupID(Object.values(response[0].newIds)[0] as string);
            }
        },
        [],
    );

    const setVisible = React.useCallback((): void => setRequestResponse(undefined), []);

    const onSaveSelectedRows = React.useCallback((): Promise<void> => {
        if (tabId === 'AddAssetToValuation') return onSubmitValuationAsset();
        return onSubmitValuationGroupAsset();
    }, [onSubmitValuationAsset, onSubmitValuationGroupAsset, tabId]);

    const onResetSelectedRows = React.useCallback((): void => {
        setSelectedRows([]);
    }, []);

    // syncs the dirtiness state as it relates specifically to selected rows
    useDirtinessSync({
        isDirty: selectedRows.length > 0,
        data: selectedRows,
        scope: dirtinessScope, // scope needs to be above forms/grids, lest the save handler be blocked by them
        blockChildren: false, // if we block, other dirty elements will not save properly
        onSave: onSaveSelectedRows,
        onReset: onResetSelectedRows,
    });

    const formScreenClass = css({
        flex: 1,
    });

    return (
        <div ref={dirtinessScope} className={css({ flex: 1, display: 'flex', overflow: 'hidden' })}>
            {tabId === 'GroupDetails' && (
                <FormScreenWrapper
                    className={formScreenClass}
                    formViewId="ValuationGroupPopUp"
                    primaryKeyValue={newGroupID}
                    filters={filters}
                    defaultData={defaultData}
                    onSubmit={onAddGroup}
                    ServiceDrivenViewSet={ServiceDrivenViewSet}
                    GridScreenWrapper={GridScreenWrapper}
                />
            )}

            {tabId === 'ValuationsByValuationGroup' && (
                <FormScreenWrapper
                    className={formScreenClass}
                    formViewId="61faa006f0031d6727aa75bc"
                    primaryKeyValue={newGroupID}
                    filters={filters}
                    defaultData={defaultData}
                    ServiceDrivenViewSet={ServiceDrivenViewSet}
                    GridScreenWrapper={GridScreenWrapper}
                />
            )}

            {tabId === 'ValuationSetupInfo' && (
                <>
                    {newGroupID && (
                        <FormScreenWrapper
                            className={formScreenClass}
                            formViewId="e0495808-e30c-4e3e-b999-fc8cd24fc689"
                            primaryKeyValue={newGroupID}
                            filters={filters}
                            defaultData={defaultData}
                            ServiceDrivenViewSet={ServiceDrivenViewSet}
                            GridScreenWrapper={GridScreenWrapper}
                        />
                    )}

                    {!newGroupID && (
                        <div style={{ padding: '3rem' }}>
                            <Text variant="large" weight="bold">
                                Please fill out the required information on the Group Info tab and save first.
                            </Text>
                        </div>
                    )}
                </>
            )}

            {tabId === 'ValuationGroupAsset' && (
                <>
                    {newGroupID && (
                        <>
                            <HeaderContextProvider>
                                <FormScreenWrapper
                                    className={formScreenClass}
                                    formViewId="61fd605f57268ef276d9ec91"
                                    ServiceDrivenViewSet={ServiceDrivenViewSet}
                                    GridScreenWrapper={GridScreenWrapper}
                                >
                                    <TwoGrids
                                        previousData={selectedRows}
                                        setData={setSelectedRows}
                                        portfolioFilter={filters && filters.length > 0 ? filters[0].toString() : ''}
                                        valuationGroupId={newGroupID}
                                        viewSetTabId={tabId}
                                    />
                                </FormScreenWrapper>
                            </HeaderContextProvider>
                            <TaskResponsePopup
                                responses={requestResponse?.response ?? []}
                                requests={
                                    requestResponse?.request
                                        ? requestResponse.request
                                        : [
                                              {
                                                  domainId: '',
                                                  requestIdentifier: '',
                                                  payload: {},
                                              },
                                          ]
                                }
                                visible={requestResponse?.response !== undefined}
                                setVisible={setVisible}
                                submitWithOverrides={onSubmitValuationGroupAsset}
                            />
                        </>
                    )}

                    {!newGroupID && (
                        <div style={{ padding: '3rem' }}>
                            <Text variant="large" weight="bold">
                                Please fill out the required information on the Group Info tab and save first.
                            </Text>
                        </div>
                    )}
                </>
            )}

            {tabId === 'AddAssetToValuation' && (
                <>
                    {valuationId && (
                        <>
                            <HeaderContextProvider>
                                <FormScreenWrapper
                                    className={formScreenClass}
                                    formViewId="61fd605f57268ef276d9ec91"
                                    ServiceDrivenViewSet={ServiceDrivenViewSet}
                                    GridScreenWrapper={GridScreenWrapper}
                                >
                                    <TwoGrids
                                        previousData={selectedRows}
                                        setData={setSelectedRows}
                                        portfolioFilter={filters && filters.length > 0 ? filters[0].toString() : ''}
                                        valuationId={valuationId}
                                        viewSetTabId={tabId}
                                    />
                                </FormScreenWrapper>
                            </HeaderContextProvider>
                            <TaskResponsePopup
                                responses={requestResponse?.response ?? []}
                                requests={
                                    requestResponse?.request
                                        ? requestResponse.request
                                        : [
                                              {
                                                  domainId: '',
                                                  requestIdentifier: '',
                                                  payload: {},
                                              },
                                          ]
                                }
                                visible={requestResponse?.response !== undefined}
                                setVisible={setVisible}
                                submitWithOverrides={onSubmitValuationAsset}
                            />
                        </>
                    )}

                    {!valuationId && (
                        <div style={{ padding: '3rem' }}>
                            <Text variant="large" weight="bold">
                                Please fill out the required information on the Valuation Details tab and save first.
                            </Text>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default CustomTabRenderer;
