export enum ValuationType {
    SingleAsset = 'SingleAsset',
    ValuationGroup = 'ValuationGroup',
    Assets = 'Assets',
    undefined = 'undefined',
    ChangeWorkflow = 'ChangeWorkflow',
}

export enum ValuationMethod {
    SingleValuation = 'SingleValuation',
    CycleofValuations = 'CycleofValuations',
    MultipleValuations = 'MultipleValuations',
    undefined = 'undefined',
    ChangeValuationWorkflow = 'ChangeValuationWorkflow',
}

export enum CycleMethod {
    CreateCycleManually = 'CreateCycleManually',
    CopyLastCycle = 'CopyLastCycle',
    UseCyCleTemplate = 'UseCyCleTemplate',
    undefined = 'undefined',
}
