import React, { useState } from 'react';
import { ErrorList } from '@samc/react-ui-core';
import { TaskMessage } from '@samc/screen-config-api';
import { ChangeDataArgs } from '@samc/react-ui-grid';
import { SectionHeader } from '@samc/react-ui-form';
import { GridStateType } from '../AddValutionWizard/AddValuations.interfaces';
import { HybridGrid } from '../AddValutionWizard/HybridGrid';
import { GridInput } from './ConfirmMethodologies.interfaces';
import { filterDeletedRecords } from './ConfirmMethodologies.service';

interface PropTypes {
    gridParams: GridInput;
    errors: TaskMessage[];
    gridData: GridStateType;
    updateGridData: (params: GridStateType) => void;
    regionalArea: string;
    filters?: string[];
    portfolioId: string;
}

const MethodologyGrid = ({
    gridData,
    gridParams,
    updateGridData,
    errors,
    regionalArea,
    filters,
    portfolioId,
}: PropTypes): React.ReactElement => {
    const [initialData] = useState(gridData.data as Record<string, Record<string, unknown>>);

    const onChangeData = (args: ChangeDataArgs<Record<string, unknown>>): void => {
        const updatedData: Record<string, Record<string, unknown>> = { ...initialData, ...args.changedData };

        updateGridData({ ...gridData, data: filterDeletedRecords(updatedData) });
    };

    return (
        <>
            <div className="methodology-grid-head">
                <SectionHeader title={gridParams.label} />
                <div className="ml-auto">
                    <ErrorList errors={errors} />
                </div>
            </div>
            <div
                className="methodology-and-approach-setup"
                style={{
                    display: 'flex',
                    flex: '1 0 auto',
                    flexDirection: 'column',
                    width: '100%',
                    borderTop: '1px solid #d1d1d1',
                }}
            >
                <HybridGrid
                    gridData={Object.values(gridData.data) as Record<string, unknown>[]}
                    domainId={gridParams.domainId}
                    listViewID={gridParams.listViewID}
                    scope={`${gridParams.primaryKey}_${portfolioId}`}
                    defaultData={{
                        RegionalArea: regionalArea,
                        IsEditable: true,
                    }}
                    filters={filters}
                    gridOptions={{
                        defaultRowSpacing: 'normal',
                        isEditingDefault: true,
                        rowsPerPage: 15,
                        suppressExcelExport: false,
                        suppressAddRowButton: false,
                        suppressEditToggle: false,
                        suppressColumnFilters: true,
                        suppressFullScreen: false,
                        suppressRefresh: false,
                        suppressRowSpacing: false,
                        suppressDeleteButton: false,
                        suppressDeleteConfirmationModal: false,
                        suppressViewSelector: false,
                        suppressSelectionCheckbox: false,
                        suppressAggregateToggle: true,
                    }}
                    errorMessages={errors}
                    onChangeData={onChangeData}
                    style={{ height: '40vh', display: 'flex', flexDirection: 'column' }}
                />
            </div>
        </>
    );
};
export default MethodologyGrid;
