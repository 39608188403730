import React, { useState } from 'react';
import { ChangeDataArgs } from '@samc/react-ui-grid';
import { useApiContext } from '@samc/screen-config-api';
import { SectionHeader } from '@samc/react-ui-form';
import { useStyletron } from 'styletron-react';

import { MethodologyAndApproachSetupProps, ValuationAssetMethodologySetup } from './BulkValuationWizard.interfaces';
import { getGridDataFromValuations, getUpdatedValuationFromSetupData } from './BulkValuationWizard.service';
import { HybridGrid } from './HybridGrid';
import { NavButtons } from './NavButtons';
import { DefaultValidationResponse, ValidationResponse, validateMethodologySetup } from './ValidationUtils';
import { ValuationDomains } from '../../static/ValuationConstants';
import { BulkValuationSteps, ValuationSteps } from '../WizardProgress/ProgressConstants';
import NavLoader from '../../atoms/NavLoader/NavLoader';

export const MethodologyAndApproachSetup = ({
    valuation,
    setValuation,
    setCurrentStep,
    setRequestSetupDetails,
    isBulk = false,
    portfolioId,
}: MethodologyAndApproachSetupProps): React.ReactElement => {
    const { TaskApi, requestInit } = useApiContext();
    const [validationResponse, setValidationResponse] = useState<ValidationResponse>({ ...DefaultValidationResponse });
    const [isLoading, setLoading] = useState<boolean>(false);

    const [gridData, setGridData] = React.useState<Record<string, unknown>>(
        valuation.addTasks ? getGridDataFromValuations(valuation.addTasks) : {},
    );

    const onChangeData = ({ record }: ChangeDataArgs<Record<string, unknown>>): void =>
        setGridData((c) => ({ ...c, [record.VAMethodologySetupId as string]: record }));

    const onBodyGridReady = (): void => {
        setRequestSetupDetails(true);
    };

    const onBack = (): void => setCurrentStep(isBulk ? BulkValuationSteps.AddContacts : ValuationSteps.AddContacts);

    const onContinue = async (): Promise<void> => {
        setLoading(true);
        const tempValuation = getUpdatedValuationFromSetupData(
            valuation,
            Object.values(gridData) as ValuationAssetMethodologySetup[],
        );

        validateMethodologySetup({
            TaskApi,
            requestInit: requestInit || {},
            addTasks: tempValuation.addTasks?.length ? tempValuation.addTasks : [{}],
            updatevalidationResponse: setValidationResponse,
            isConfirmMethodology: false,
            onSuccess: () => {
                setValuation({ ...tempValuation, addContacts: valuation.addContacts });
                setCurrentStep(isBulk ? BulkValuationSteps.ConfirmMethodologies : ValuationSteps.ConfirmMethodologies);
            },
            setLoading,
        });
    };

    const onSkip = (): void =>
        setCurrentStep(isBulk ? BulkValuationSteps.CreateValuations : ValuationSteps.CreateValuations);

    const filters = React.useMemo(
        () => [`(([PortfolioIds] contains '${portfolioId}') OR ([IsSystemDefault] = true) )`],
        [portfolioId],
    );
    const [css] = useStyletron();

    return (
        <>
            <NavButtons
                isDisabled={isLoading}
                onBack={onBack}
                onSkip={onSkip}
                onContinue={onContinue}
                validationResponse={validationResponse}
                updateValidationResponse={setValidationResponse}
                domainNameMap={{ VALU_Valuation: 'ValuationName' }}
            >
                <NavLoader isLoading={isLoading} label="Validating Methodology and Approach..." />
            </NavButtons>

            <div
                className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 0 auto',
                    margin: '12px',
                })}
            >
                <SectionHeader title="Select Methodology and Approach Setup" />
                <div
                    className="methodology-and-approach-setup"
                    style={{
                        display: 'flex',
                        flex: '1 0 auto',
                        flexDirection: 'column',
                        width: '100%',
                        borderTop: '1px solid #d1d1d1',
                    }}
                >
                    <HybridGrid
                        gridData={Object.values(gridData) as Record<string, unknown>[]}
                        domainId={ValuationDomains.Valuation}
                        listViewID="ValuationAssetMethodologySetup"
                        gridOptions={{
                            defaultRowSpacing: 'normal',
                            isEditingDefault: true,
                            rowsPerPage: 1500,
                            suppressAddRowButton: true,
                            suppressColumnFilters: true,
                            suppressDeleteButton: true,
                            suppressDeleteConfirmationModal: true,
                            suppressEditToggle: true,
                            suppressFullScreen: false,
                            suppressRowSpacing: true,
                            suppressViewSelector: true,
                            suppressExcelExport: true,
                            suppressRefresh: true,
                            suppressSelectionCheckbox: true,
                            suppressAggregateToggle: true,
                        }}
                        errors={validationResponse.list}
                        filters={filters}
                        onChangeData={onChangeData}
                        onBodyGridReady={onBodyGridReady}
                    />
                </div>
            </div>
        </>
    );
};

export default MethodologyAndApproachSetup;
