import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ConfirmationDialog, Loader, ToastMessage, toastError, toastSuccess, useRefWrapper } from '@samc/react-ui-core';
import {
    PicklistField,
    PicklistItem,
    PicklistItemGetterParams,
    usePicklist,
    usePicklistGetter,
    usePicklistItemGetter,
    SortingData,
    PicklistItemGetterResponse,
    Picklist,
} from '@samc/picklist-api';
import { useEvaluator } from '@samc/expressions-react';
import { TaskRequest, TaskStatus, useQBQuery } from '@samc/screen-config-api';
import { usePicklistGridFieldConfigurations } from '@samc/screen-config-core/lib/hooks/usePicklistGridFieldConfigurations/usePicklistGridFieldConfigurations';
import { getPicklistFieldSort } from '@samc/picklist-core/lib/atoms/controls/Picklist/PicklistFunctions';
import { transformPicklistGridFieldConfigurations } from '@samc/picklist-core';
import { GridPicklistManager } from '@samc/picklist-core/lib/utilities/GridPicklistManager/GridPicklistManager';
import useGridFieldsFromListview from '@samc/screen-config-core/lib/hooks/useGridFieldsFromListview/useGridFieldsFromListview';
import useGridInjector from '@samc/screen-config-core/lib/hooks/useGridInjector/useGridInjector';
import { NavButtons } from '../AddValutionWizard/NavButtons';
import { SetupMethodType } from '../AddValutionWizard/AddValuations.interfaces';
import { BulkValuationSteps, Steps, ValuationSteps } from '../WizardProgress/ProgressConstants';
import { ValuationMethod } from '../AddValutionWizard/ValuationWizard.enums';
import {
    AddTask,
    CC_Contact_Picklist,
    Valuation,
    VALU_ValuationWorkflowTemplate_Picklist,
    ValuationAssetVM,
} from '../../static/ValuationConstants';
import {
    GetDefaultContacts,
    cc_data,
    getContactGridData,
    getContactGridColumns,
    getSelectedContacts,
    primaryPicklistFields,
} from './AddContacts.service';
import { useValuationWorkflowParticipants as _useValuationWorkflowParticipants } from '../../../valuationApi/useValuationWorkflowParticipants/useValuationWorkflowParticipants';
import { ValuationApiOverrides, apiProvider } from '../../../valuationApi';
import { useApiContext } from '../../../hooks/useApiContext';
import {
    DefaultValidationResponse,
    ValidationResponse,
    getValidationResponse,
} from '../AddValutionWizard/ValidationUtils';
import { ValuationWorkflowParticipant } from '../../../valuationApi/models/ValuationWorkflowParticipant';
import { UpdateValuations } from '../ChangeWorkflow/ChangeWorkflowWizard.service';
import AddContactsGrid from './AddContactsGrid';
import NavLoader from '../../atoms/NavLoader/NavLoader';

const _usePicklistGridFieldConfigurations = usePicklistGridFieldConfigurations.withHooks({
    useGridFieldsFromListview,
    useGridInjector,
});

interface AddContactsProps extends Pick<ValuationApiOverrides, 'useValuationWorkflowParticipants'> {
    isBulk: boolean;
    setupMethod?: SetupMethodType;
    setCurrentStep?: (step: Steps) => void;
    valuation: Valuation;
    filters?: string[];
    setValuation: (params: Valuation) => void;
}

const AddContacts = ({
    isBulk,
    setCurrentStep,
    setupMethod,
    valuation,
    setValuation,
    filters,
    useValuationWorkflowParticipants = _useValuationWorkflowParticipants,
}: AddContactsProps): React.ReactElement => {
    const { evaluate } = useEvaluator();
    const { TaskApi, requestInit } = useApiContext();
    const getPicklistItems = usePicklistItemGetter();
    const getDBPicklist = usePicklistGetter();
    const qbQuery = useQBQuery();
    const navigate = useNavigate();

    const {
        data: contactPicklist,
        isLoading: isLoadingContactPicklist,
        isError: isErrorContactPicklist,
    } = usePicklist(CC_Contact_Picklist);

    const [defaultContactsList, setDefaultContactsList] = useState<cc_data[]>([]);
    const [showPrompt, togglePrompt] = useState<[boolean, boolean]>([false, false]); // [show, navigateBack]
    const [gridData, setGridData] = useState<Record<string, unknown>[]>([]);
    const [fetchDefaultContact, setFetchDefaultContact] = useState<boolean>(false);
    const [validationResponse, updateValidationResponse] = useState<ValidationResponse>({
        ...DefaultValidationResponse,
    });
    const [stopEditing, setStopEditing] = useState<() => Promise<Record<string, unknown>[]>>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isLoadingDefaultContacts, setLoadingDefaultContacts] = useState<boolean>(false);

    const workflowTemplateIds: string[] = valuation.addTasks
        ? valuation.addTasks
              .filter(({ ValuationWorkflowTemplateId }) => ValuationWorkflowTemplateId)
              .map(({ ValuationWorkflowTemplateId }) => ValuationWorkflowTemplateId ?? '')
        : [];
    const {
        data: workflowParticipantsResponse,
        isLoading: isLoadingParticipants,
        isError: isErrorParticipants,
    } = useValuationWorkflowParticipants(workflowTemplateIds);

    const workflowParticipants = workflowParticipantsResponse?.Data;

    const distinctRoles = React.useMemo(
        () =>
            workflowParticipants?.reduce(
                (acc, { RoleId, Name }) => ({
                    ...acc,
                    [RoleId as string]: Name as string,
                }),
                {},
            ) as Record<string, string>,
        [workflowParticipants],
    );

    const distinctRolesWithWorkflowTemplateId = React.useMemo(
        () =>
            workflowParticipants?.reduce(
                (acc, { RoleId, Name, WorkflowTemplateId }) => ({
                    ...acc,
                    [`${RoleId}_${WorkflowTemplateId}` as string]: Name as string,
                }),
                {},
            ) as Record<string, string>,
        [workflowParticipants],
    );

    const gridFields = React.useMemo(() => {
        if (!distinctRoles || !workflowParticipants) return [];
        return getContactGridColumns(distinctRoles, workflowParticipants);
    }, [distinctRoles, workflowParticipants]);

    const picklists = React.useMemo(() => {
        if (!distinctRolesWithWorkflowTemplateId) return {};
        const contactPicklists = Object.keys(distinctRolesWithWorkflowTemplateId).reduce(
            (acc, roleWithWorflowTemplateId) => {
                // split the Ids. - [roleId, WorkflowtemplateId]
                const Ids = roleWithWorflowTemplateId.split('_');
                const selected = workflowParticipants?.find(
                    (row) => row.RoleId === Ids[0] && row.WorkflowTemplateId === Ids[1],
                );
                const initialFilters: string[] = [];

                filters?.forEach((id) => {
                    initialFilters.push(id);
                });

                let companyIdFilter = '';
                const companyIds = selected?.AllowableCompanyTypes?.split(',');

                companyIds?.forEach((id) => {
                    if (companyIdFilter === '') companyIdFilter = `DoesContain('${id}', [CompanyTypeRestrictions])`;
                    else companyIdFilter = `${companyIdFilter} OR DoesContain('${id}', [CompanyTypeRestrictions])`;
                });

                if (companyIdFilter !== '') initialFilters.push(companyIdFilter);

                if (selected?.RoleTypeId.includes('GENERALCONTACT')) {
                    initialFilters.push(`[IsActive] = true`);
                } else {
                    initialFilters.push(`DoesContain('${selected?.RoleId}', [RoleIds])`);
                    initialFilters.push(`[IsActive] = true`);
                }
                initialFilters.push(`ISNULL([CompanyInactivationDate])`);

                const sortData: SortingData | undefined = {
                    order: 'asc',
                    orderBy: {
                        scalarExpression: 'CONCAT([CompanyName],[Name])',
                    },
                };

                return {
                    ...acc,
                    [roleWithWorflowTemplateId]: new GridPicklistManager({
                        // We will show a loader if the contactPicklist is not yet loaded.
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        picklistGetter: async () => contactPicklist!,
                        itemGetter: async (picklist, params): Promise<PicklistItemGetterResponse> => {
                            const filterList =
                                params.ids?.length !== undefined && params.ids?.length > 0
                                    ? []
                                    : initialFilters.map((x) => Object.assign(x, { idAllowed: false }));

                            return getPicklistItems(picklist, {
                                ...params,
                                filters: [...(params.filters ?? []), ...filterList],
                                additionalFields: [...(params.additionalFields ?? []), ...primaryPicklistFields],
                                hardReload: true,
                                sortOverride: !params.sortOverride ? sortData : params.sortOverride,
                            } as PicklistItemGetterParams<keyof PicklistItem>);
                        },
                    }),
                };
            },
            {} as Record<string, GridPicklistManager>,
        );

        const workflowTemplatePicklist = new GridPicklistManager({
            picklistGetter: async (): Promise<Picklist> => {
                return getDBPicklist(true, VALU_ValuationWorkflowTemplate_Picklist);
            },
            itemGetter: async (picklist, params): Promise<PicklistItemGetterResponse> => {
                const newParams: typeof params = { ...params };

                const defaultSort = getPicklistFieldSort();

                if (!newParams.ids) {
                    newParams.sortOverride = newParams.sortOverride ?? defaultSort;

                    const newFilters: string[] = [];
                    if (filters) newFilters.push(...filters);
                    if (newParams.filters) newFilters.push(...newParams.filters); // param takes precedence

                    newParams.filters = newFilters;
                }

                return getPicklistItems(picklist, newParams);
            },
        });

        return {
            ...contactPicklists,
            [VALU_ValuationWorkflowTemplate_Picklist]: workflowTemplatePicklist,
        } as Record<string, GridPicklistManager>;
    }, [
        contactPicklist,
        distinctRolesWithWorkflowTemplateId,
        filters,
        getDBPicklist,
        getPicklistItems,
        workflowParticipants,
    ]);

    const picklistsRef = useRefWrapper(picklists);
    // this function will never re-calculate, safe to pass to grid as callbacks
    const getPicklist = React.useCallback(
        (picklistField: PicklistField) => {
            const curVal = picklistsRef.current[picklistField.id];
            return curVal;
        },
        [picklistsRef],
    );

    const { wrappedFields, frameworkComponents } = React.useMemo(
        () =>
            transformPicklistGridFieldConfigurations(
                gridFields,
                getPicklist,
                evaluate,
                undefined,
                _usePicklistGridFieldConfigurations,
            ),
        [evaluate, getPicklist, gridFields],
    );

    useEffect(() => {
        if (valuation?.addContacts?.gridData) {
            setGridData(valuation.addContacts.gridData as Record<string, unknown>[]);
        } else {
            setGridData(
                getContactGridData(workflowParticipants, valuation.addTasks, defaultContactsList, fetchDefaultContact),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultContactsList, valuation.addTasks, workflowParticipants, fetchDefaultContact]);

    const gotoPreviousStep = (): void => {
        setValuation({ addTasks: valuation.addTasks }); // removes addContacts object on back

        if (setCurrentStep) {
            if (isBulk) {
                setCurrentStep(BulkValuationSteps.SetupValuations);
            } else {
                setCurrentStep(
                    setupMethod && setupMethod.valuationMethod === ValuationMethod.SingleValuation
                        ? ValuationSteps.SingleValutionForm
                        : ValuationSteps.SetupValuations,
                );
            }
        }
    };

    const onBack = (): void => togglePrompt([true, true]);

    const GetContacts = async (advancedExpression: string, domainName: string): Promise<string[]> => {
        if (advancedExpression && advancedExpression !== null && advancedExpression !== '') {
            const contacts = await GetDefaultContacts(qbQuery, domainName, advancedExpression);

            return contacts.Data.map((data) => {
                return data.ContactId;
            }) as string[];
        }

        return [];
    };

    const fetchDefaultContacts = async (
        selected: (ValuationWorkflowParticipant | undefined)[],
        ValuationAsset: ValuationAssetVM[] | undefined,
        FundIds: string | null | undefined,
        InvestmentIds: string | null | undefined,
        Portfolio_Id: string | null | undefined,
        defaultContacts: cc_data[],
        ValuationUniqId: string,
        ValuationWorkflowTemplateId: string,
    ): Promise<void> => {
        /* eslint-disable no-await-in-loop */
        for (let index = 0; index < selected.length; index++) {
            let assetContactExpression = '';
            const defaultAssetContact = selected[index]?.DefaultAssetContact;
            const assetId = ValuationAsset?.map((item) => item.AssetId).join(',');
            if (
                defaultAssetContact &&
                defaultAssetContact !== null &&
                defaultAssetContact !== '' &&
                assetId &&
                assetId !== null &&
                assetId !== ''
            ) {
                assetContactExpression = `DoesContain([RoleId], '${defaultAssetContact}') And DoesContain([AssetId], '${assetId}')`;
            }
            const assetContactList = await GetContacts(assetContactExpression, 'CC_AssetContacts');
            let fundContactExpression = '';
            const defaultFundContact = selected[index]?.DefaultFundContact;
            const fundId = FundIds;
            if (
                defaultFundContact &&
                defaultFundContact !== null &&
                defaultFundContact !== '' &&
                fundId &&
                fundId !== null &&
                fundId !== ''
            ) {
                fundContactExpression = `DoesContain([RoleId], '${defaultFundContact}') And DoesContain([FundId], '${fundId}')`;
            }
            const fundContactList = await GetContacts(fundContactExpression, 'CC_FundContacts');
            let investContactExpression = '';
            const defaultInvestmentContact = selected[index]?.DefaultInvestmentContact;
            const investmentId = InvestmentIds;
            if (
                defaultInvestmentContact &&
                defaultInvestmentContact !== null &&
                defaultInvestmentContact !== '' &&
                investmentId &&
                investmentId !== null &&
                investmentId !== ''
            ) {
                investContactExpression = `DoesContain([RoleId], '${defaultInvestmentContact}') And DoesContain([InvestmentId], '${investmentId}')`;
            }
            const investmentContactList = await GetContacts(investContactExpression, 'CC_InvestmentContacts');
            let portfolioContactExpression = '';
            const defaultPortfolioContact = selected[index]?.DefaultPortfolioContact;
            const portfolioId = Portfolio_Id;
            if (
                defaultPortfolioContact &&
                defaultPortfolioContact !== null &&
                defaultPortfolioContact !== '' &&
                portfolioId &&
                portfolioId !== null &&
                portfolioId !== ''
            ) {
                portfolioContactExpression = `DoesContain([RoleId], '${defaultPortfolioContact}') And DoesContain([PortfolioId], '${portfolioId}')`;
            }

            const portfolioContactList = await GetContacts(portfolioContactExpression, 'CC_PortfolioContacts');

            defaultContacts.push({
                roleId: selected[index]?.RoleId as string,
                valuationId: ValuationUniqId as string,
                workflowTemplateId: ValuationWorkflowTemplateId as string,
                contacts: [...assetContactList, ...fundContactList, ...investmentContactList, ...portfolioContactList],
            });
            setDefaultContactsList([...defaultContacts]);
        }
        /* eslint-enable no-await-in-loop */
    };

    const onSetDefaultContacts = (): void => {
        togglePrompt([true, false]);
    };

    const getDefaultContact = async (): Promise<void> => {
        const defaultContacts: cc_data[] = [];
        setDefaultContactsList([]);
        if (valuation.addTasks) {
            await valuation.addTasks.map(
                async ({
                    ValuationUniqId,
                    ValuationWorkflowTemplateId,
                    FundIds,
                    InvestmentIds,
                    Portfolio_Id,
                    ValuationAsset,
                }: AddTask) => {
                    const selected = Object.keys(distinctRoles).map((roleId: unknown) => {
                        return workflowParticipants?.find(
                            (row) => row.RoleId === roleId && ValuationWorkflowTemplateId === row.WorkflowTemplateId,
                        );
                    });
                    /* eslint-disable no-await-in-loop */

                    await fetchDefaultContacts(
                        selected,
                        ValuationAsset,
                        FundIds,
                        InvestmentIds,
                        Portfolio_Id,
                        defaultContacts,
                        ValuationUniqId as string,
                        ValuationWorkflowTemplateId as string,
                    );
                    /* eslint-enable no-await-in-loop */
                },
            );
        }
    };

    const onResponse = async (stay: boolean): Promise<void> => {
        if (!showPrompt[1] && stay) {
            setLoadingDefaultContacts(true);
            setFetchDefaultContact(true);
            await getDefaultContact();
            setTimeout(() => {
                setLoadingDefaultContacts(false);
            }, 2000);
        } else if (showPrompt[1] && !stay) {
            gotoPreviousStep();
        }

        togglePrompt([false, false]);
    };

    const gridDataPayload = (rowData = gridData): Record<string, unknown> =>
        rowData.reduce(
            (acc, row, index) => ({
                ...acc,
                [`${-index - 1}`]: {
                    ...row,
                    ValuationUniqId: row.ValuationId,
                    Roles: workflowParticipants
                        ?.filter((x) => x.WorkflowTemplateId === (row.WorkflowTemplateId as string))
                        ?.reduce(
                            (_acc: Record<string, unknown>[], role) => [..._acc, ...getSelectedContacts(role, row)],
                            [],
                        ),
                    __ADDED__: true,
                    __INDEX__: index + 1,
                },
            }),
            {},
        );

    const submitRequest = (overrideCodes?: string[], rowData: Record<string, unknown>[] = gridData): void => {
        const payload = gridDataPayload(rowData) as TaskRequest['payload'];

        if (requestInit) {
            apiProvider
                .submitTask(
                    TaskApi,
                    'VALU_ValuationContact',
                    'VALU_ValuationContact',
                    'VALU_ValuationContact_VW',
                    payload as TaskRequest['payload'],
                    requestInit,
                    undefined,
                    undefined,
                    overrideCodes,
                )
                .then(([response, request]) => {
                    setLoading(false);

                    if (response.statusCode !== TaskStatus.Completed) {
                        const updatedRequest = {
                            ...request,
                            payload: Object.keys(request.payload).reduce(
                                (acc, key) => ({
                                    ...acc,
                                    [key]: { ...request.payload[key], Name: request.payload[key].ValuationName },
                                }),
                                {},
                            ),
                        };

                        updateValidationResponse(getValidationResponse(updatedRequest, response));
                    } else {
                        setValuation({ ...valuation, addContacts: { ...payload, gridData: rowData } });
                        if (setCurrentStep) {
                            setCurrentStep(
                                isBulk
                                    ? BulkValuationSteps.SetupMethodologyAndApproach
                                    : ValuationSteps.SetupMethodologyAndApproach,
                            );
                        }
                    }
                });
        }
    };

    const onSubmitWithOverrides = (codes: string[]): void => submitRequest(codes);

    const onContinue = (): void => {
        setLoading(true);

        stopEditing?.().then((rowData) => {
            submitRequest(undefined, rowData);
        });
    };

    const finishExecute = (valuationObject: Valuation, overrideCodes: string[]): Promise<void> => {
        const showError = (message = 'Something went wrong'): void => {
            toastError(<ToastMessage title="Error" message={message} />);
        };

        if (requestInit) {
            return UpdateValuations(valuationObject, TaskApi, requestInit, overrideCodes)
                .then(([response, request]) => {
                    setLoading(false);

                    if (response.statusCode !== TaskStatus.Completed) {
                        updateValidationResponse(getValidationResponse(request, response));
                    } else {
                        toastSuccess(
                            <ToastMessage title="Success" message="Valuation Workflow Update Successfully" />,
                            {
                                onClose: () => {
                                    navigate(-1);
                                },
                            },
                        );
                    }
                })
                .catch((e) => {
                    showError(e);
                    throw e; // need to rethrow
                });
        }

        return Promise.reject(new Error('RequestInit not defined'));
    };

    const onFinish = (): Promise<void> => {
        setLoading(true);

        if (!stopEditing) throw new Error('Cannot retrieve data');
        return stopEditing().then((rowData) => {
            const valuationContactPayload = gridDataPayload(rowData);
            setValuation({ ...valuation, addContacts: valuationContactPayload });
            const valuationObject: Valuation = { ...valuation, addContacts: valuationContactPayload };
            finishExecute(valuationObject, []);
        });
    };

    const onFinishWithOverrides = (overrideCodes: string[]): void => {
        finishExecute(valuation, overrideCodes);
    };

    return (
        <Loader
            isLoading={!!isLoadingParticipants || !!isLoadingContactPicklist}
            isError={!!isErrorParticipants || !!isErrorContactPicklist}
        >
            <>
                <NavButtons
                    onBack={setCurrentStep ? onBack : undefined}
                    onContinue={setCurrentStep ? onContinue : onFinish}
                    continueLabel={setCurrentStep ? 'Continue' : 'Finish'}
                    isDisabled={isLoading || isLoadingDefaultContacts}
                    onSetDefaultContacts={onSetDefaultContacts}
                    validationResponse={validationResponse}
                    updateValidationResponse={updateValidationResponse}
                    domainNameMap={{ VALU_ValuationContact: 'ValuationName', VALU_Valuation: 'ValuationName' }}
                    submitWithOverrides={setCurrentStep ? onSubmitWithOverrides : onFinishWithOverrides}
                >
                    <NavLoader
                        isLoading={isLoading || isLoadingDefaultContacts}
                        label={isLoadingDefaultContacts ? 'Loading Default Contacts' : 'Validating Contacts...'}
                    />
                </NavButtons>

                <AddContactsGrid
                    wrappedFields={wrappedFields}
                    frameworkComponents={frameworkComponents}
                    gridData={gridData}
                    setGridData={setGridData}
                    setStopEditing={setStopEditing}
                    onSubmit={onFinish}
                />

                {showPrompt[0] && (
                    <ConfirmationDialog
                        giveAnswer={onResponse}
                        detail={
                            showPrompt[1]
                                ? 'Moving back to the previous step in the wizard will remove any changes made on  this screen. Please confirm you would like to go back.'
                                : 'By pulling in default contacts from the workflow template, any preselected options below will be removed. Are you sure you want to pull in the default contacts?'
                        }
                        confirmText={showPrompt[1] ? 'Stay on this screen' : 'Continue'}
                        cancelText="Go Back"
                        title={showPrompt[1] ? 'Unsaved Changes' : 'Pull in the default contacts'}
                    />
                )}
            </>
        </Loader>
    );
};

export default AddContacts;
