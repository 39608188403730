export const LABELS = {
    SELECT_SETUP_LABEL: 'SELECT WHAT YOU WOULD LIKE TO SET UP',
    SETUP_TYPE: {
        SINGLE: 'Single Valuation',
        CYCLE: 'Cycle of Valuations',
        MULTIPLE: 'Multiple Valuations (Manually Entered)',
    },
    SETUP_VALUATIONS: {
        MANUAL_CYCLE: 'I will create all the valuations in the cycle manually.',
        COPY_CYCLE: 'Copy Valuation Setup from last cycle.',
        TEMPLATE_CYCLE: 'Use a Cycle Template to set up my valuations.',
    },
    RC_CYCLE_INFO: 'Cycle Info',
    RC_TEMPLATE: 'Report Cycle Templates',
};

export const MESSAGES = {};
