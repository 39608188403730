import { GridFieldConfiguration } from '@samc/react-ui-grid';

export enum ReportCycleActions {
    SET_CYCLE_TEMPLATE = 0,
    SET_CYCLE_YEARS = 1,
    SET_REPORT_DATA = 2,
    SET_PERIOD_COLUMNS = 3,
    SET_PERIOD_DATA = 4,
    RESET_DATA = 5,
}

export interface ActionType {
    type: ReportCycleActions;
    payload: unknown;
}

export interface ReportCycleDataType {
    cycleTemplate: Record<string, unknown>[];
    reportCycleYears: number;
    data: Record<string, unknown>;
    periodColumns: GridFieldConfiguration[];
    periodData: Record<string, unknown>[];
}

export interface CycleTemplateDataType {
    template: Record<string, unknown>[];
    years: number;
}

export const defaultCycleTemplateData = {
    template: [],
    years: 3,
};

export const ReportCycleDefaultData: ReportCycleDataType = {
    cycleTemplate: [],
    reportCycleYears: 3,
    data: {},
    periodColumns: [],
    periodData: [],
};

export const Labels = {
    ENTER_PERIODS_TEXT: 'Enter which periods the report type will be performed in or SKIP to enter in the next step',
};
