import * as GenericApiContext from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';
import type { Api } from '../contexts/ApiContext';

export const REQUIREDURIS = [
    'AggregatorApi',
    'FetchApi',
    'FolderApi',
    'LiftSiftApi',
    'QueryAuthorizationApi',
    'ReportingApi',
    'StrataApi',
    'TaskApi',
    'useTaskSubmitter',
    'useValuation',
    'useValuationContacts',
    'useValuationWorkflowLogs',
    'useValuationWorkflowStatus',
    'useValuationWorkflowTask',
    'useValuationWorkflowTaskEnabledUsers',
    'useValuationHub',
    'useValuationWorkflowTaskOwners',
    'useValuationWorkflowTasks',
    'useValuationWorkflowTaskStatuses',
    'useValuationWorkflowTaskViewableUsers',
    'useValuationWorkflowTemplateStages',
    'useValuationWorkflowTemplateTasks',
    'useValuationWorkflowTemplateTaskStatuses',
    'ValuationApi',
    'QueryClient',
];

export const useApiContext = (suppressErrors?: boolean): Api => {
    GenericApiContext.useAssertApiKeys(REQUIREDURIS, suppressErrors);

    return GenericApiContext.useApiContext() as unknown as Api;
};

export default useApiContext;
