import { ValuationContact } from './ValuationContact';
import { ValuationWorkflowTaskStatus } from './ValuationWorkflowTaskStatus';
import { ValuationWorkflowTemplateTaskStatus } from './ValuationWorkflowTemplateTaskStatus';

export const PROPERTY_NAME_CONTACTS_ASSIGNED_TO = 'ContactsAssignedTo';
export const PROPERTY_NAME_CONTACTS_ENABLED_FOR = 'ContactsEnabledFor';
export const PROPERTY_NAME_CONTACTS_VIEWABLE_BY = 'ContactsViewableBy';

export interface ValuationWorkflowTask {
    CanUserActionTask: boolean;
    CanUserOverrideDueDate: boolean;
    CurrentUserIsEnabledForTask: boolean;
    CurrentUserIsTaskOwner: boolean;
    Id: string;
    TaskGroup: number;
    ValuationId: string;

    ActionsLog?: string | null;
    AdHocTaskFlag?: number | null;
    CompletedByDate?: string | null;
    ContactsAssignedTo?: ValuationContact[];
    ContactsEnabledFor?: ValuationContact[];
    ContactsViewableBy?: ValuationContact[];
    Description?: string | null;
    DueDate?: string | null;
    DueDateOverride?: string | null;
    HyperlinkPath?: string | null;
    Name?: string | null;
    ResponsibleParticipant?: string | null;
    TaskStageId?: string | null;
    WorkflowTaskStatus?: ValuationWorkflowTaskStatus;
    WorkflowTaskStatusId?: string | null;
    WorkflowTemplateTaskId?: string | null;
    WorkflowTemplateTaskStatuses?: ValuationWorkflowTemplateTaskStatus[];
}
