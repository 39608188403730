import React, { useCallback, useEffect, useState } from 'react';
import { ChangeDataArgs, Grid, GridFieldConfiguration } from '@samc/react-ui-grid';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { HR } from '../../atoms/StyledComponents/StyledComponents';

interface AddContactsGridProps {
    wrappedFields: GridFieldConfiguration[];
    frameworkComponents: Record<string, unknown>;
    gridData: Record<string, unknown>[];
    setGridData: (args: Record<string, unknown>[]) => void;
    setStopEditing: (args: () => () => Promise<Record<string, unknown>[]>) => void;
    onSubmit: () => Promise<void>;
}

const AddContactsGrid = ({
    wrappedFields,
    frameworkComponents,
    gridData,
    setGridData,
    setStopEditing,
    onSubmit,
}: AddContactsGridProps): React.ReactElement => {
    const [api, setApi] = useState<GridApi>();
    const [gridKey, setGridKey] = useState<number>(1);

    const findMissingValues = (source: Record<string, unknown>, target: Record<string, unknown>): string[] =>
        Object.keys(source).filter((key) => source[key] !== target[key] && target[key] === '');

    const onChangeData = useCallback(
        ({ record }: ChangeDataArgs<Record<string, unknown>>): void => {
            let missingFields = [];

            setGridData(
                gridData.map((row) => {
                    missingFields = findMissingValues(row, record);
                    return row.ValuationId === record.ValuationId ? { ...record } : { ...row };
                }),
            );

            // Renders the grid again to clear cell render overrides
            if (missingFields.length) setGridKey(gridKey + 1);
        },
        [gridData, gridKey, setGridData],
    );

    useEffect(() => {
        if (api) {
            setStopEditing(() => (): Promise<Record<string, unknown>[]> => {
                return new Promise((resolve) => {
                    const _gridData: Record<string, unknown>[] = [];
                    api.stopEditing();
                    api.forEachNode(({ data }) => {
                        _gridData.push({ ...data });
                    });

                    resolve(_gridData);
                });
            });
        }
    }, [api, onChangeData, setStopEditing]);

    const onBodyGridReady = (event: GridReadyEvent): void => setApi(event.api);

    return (
        <div className="add-contacts-grid">
            <HR />

            <Grid
                key={gridKey}
                isEditingDefault
                suppressEditToggle
                suppressAddRowButton
                suppressDeleteButton
                suppressClearFilter
                suppressExcelExport
                suppressRowSpacing
                suppressDeleteConfirmationModal
                suppressColumnFilters
                suppressRowSelector
                fields={wrappedFields}
                frameworkComponents={frameworkComponents}
                data={gridData}
                onChangeData={onChangeData}
                onBodyGridReady={onBodyGridReady}
                onSubmit={onSubmit}
            />
        </div>
    );
};

export default AddContactsGrid;
