import { useRequestBase, RequestResult } from '@samc/react-ui-request';
import { QueryExecuteRequest, QueryExecuteResponse, useQBQuery } from '@samc/screen-config-api';
import { ApiContext } from '../../contexts/ApiContext/ApiContext';

export const VALUATION_KEY = 'Valuation';

export const useValuation = (valuationId: string): RequestResult<QueryExecuteResponse> => {
    const qbQuery = useQBQuery();

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_Valuation',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
            {
                scalarDisplayName: 'Name',
                scalarExpression: '[Name]',
            },
            {
                scalarDisplayName: 'StatusId',
                scalarExpression: '[StatusId]',
            },
        ],
        sorting: {},
        paging: { start: 0, stop: 1 },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[Id] = '${valuationId}'`,
        },
    };

    const result = useRequestBase({
        key: VALUATION_KEY,
        query: qbQuery,
        params: [requestBody],
        requestContext: ApiContext,
    });

    return result;
};

export default useValuation;
