/* eslint-disable no-param-reassign */
import { TaskMessage, TaskRequest, TaskResponse, TaskStatus } from '@samc/screen-config-api';
import { AllGridApis } from '@samc/react-ui-grid';
import { GridType } from '@samc/react-ui-grid/lib/organisms/BaseGrid/AddedRowGrid/Types';
import { isNullOrUndefined } from '@samc/react-ui-core';
import {
    AddTask,
    GridIdentifiers,
    ValuationAssetMethodologiesVM,
    ValuationAssetOtherDataScreensVM,
    ValuationAssetOtherValuesVM,
    ValuationDomains,
} from '../../static/ValuationConstants';
import { apiProvider } from '../../../valuationApi';
import { createGuid, getFinalGridData } from '../../static/ValuationWizard.utils';
import { GridStateType } from './AddValuations.interfaces';

export interface ValidationResponse extends TaskResponse {
    list: TaskMessage[];
    showList: boolean;
    request: TaskRequest;
}

export const DefaultValidationResponse: ValidationResponse = {
    messages: [],
    list: [],
    showList: true,
    request: {
        domainId: '',
        requestIdentifier: '',
        payload: {},
    },
    prompts: [],
    childResponses: {},
    statusCode: TaskStatus.Completed,
    messageCount: 0,
    newIds: {},
    updatedIds: [],
    deletedIds: [],
    requestIdentifier: '',
    domainId: '',
};

export const getMessageWithRowIndexed = (index: number, message: string): string =>
    message.toLowerCase().indexOf('row') < 0 ? `Row ${index} - ${message}` : message;

export const getCustomizedErrors = (response: TaskResponse, { payload }: TaskRequest): TaskMessage[] => {
    let msgs: TaskMessage[] = response.messages;

    if (response.messages.length) {
        msgs = response.messages.map(({ recordIdentifier, title, ...err }) => ({
            ...err,
            title:
                recordIdentifier &&
                payload[recordIdentifier] &&
                !Number.isNaN(Number(payload[recordIdentifier].__INDEX__))
                    ? getMessageWithRowIndexed(Number(payload[recordIdentifier].__INDEX__), title)
                    : title,
            recordIdentifier,
            response,
        }));
    }

    return msgs;
};

export const updateChildResponse = (request: TaskRequest, response: TaskResponse): TaskResponse => {
    if (Object.values(response.childResponses).length) {
        Object.keys(response.childResponses).forEach((ri) => {
            if (response.childResponses[ri].messages.length) {
                Object.values(request.payload).forEach((p) => {
                    p.__CHILD_REQUESTS__?.forEach((childrequest) => {
                        if (childrequest.requestIdentifier === ri) {
                            Object.keys(childrequest.payload).forEach((crp, i) => {
                                response.childResponses[ri].messages = response.childResponses[ri].messages.map(
                                    (msg) => ({
                                        ...msg,
                                        title:
                                            msg.recordIdentifier === crp
                                                ? getMessageWithRowIndexed(1 + i, msg.title)
                                                : msg.title,
                                    }),
                                );
                            });
                        }
                    });
                });
            }
        });
    }

    return { ...response };
};

/**
 * Modifies the childResponses in the response object by adding the row numbers against the error messages
 * and modifies the recordIdentifier to include the request identifier to fix issues with duplicate
 * applicable record incase of multiple child requests with same domain (confirm methodology).
 */
export const modifyChildResponse = (response: TaskResponse['childResponses']): TaskResponse['childResponses'] =>
    Object.values(response).length
        ? Object.keys(response).reduce(
              (children, childKey) => ({
                  ...children,
                  [childKey]: response[childKey].messages.length
                      ? {
                            ...response[childKey],
                            messages: response[childKey].messages.map((message) => ({
                                ...message,
                                title: getMessageWithRowIndexed(
                                    Math.abs(parseInt(message.recordIdentifier as string, 10)),
                                    message.title,
                                ),
                                recordIdentifier: response[childKey].requestIdentifier + message.recordIdentifier,
                            })),
                        }
                      : response[childKey],
              }),
              {},
          )
        : response;

export const getValidationResponse = (request: TaskRequest, response: TaskResponse): ValidationResponse => ({
    ...response,
    childResponses: response.childResponses ? modifyChildResponse(response.childResponses) : {},
    list: getCustomizedErrors(response, request),
    messages: getCustomizedErrors(response, request),
    showList: true,
    request,
});

type RequestType = {
    [id: string]: Record<string, unknown> & {
        __CHILD_REQUESTS__?: TaskRequest[];
        __ADDED__?: true;
        __DELETED__?: true;
    };
};

export const createTaskRequest = (
    domainId: string,
    fieldConfigurationId: string,
    requestIdentifier: string,
    payload: unknown,
): TaskRequest => ({
    domainId,
    fieldConfigurationId,
    requestIdentifier,
    payload: payload as RequestType,
});

export const validateSetupData = ({
    TaskApi,
    requestInit,
    addTasks,
    updateErrors,
    onSuccess = (): void => undefined,
    setLoading = (): void => undefined,
}: {
    TaskApi: string | undefined;
    requestInit: RequestInit;
    addTasks: AddTask[];
    updateErrors: (e: ValidationResponse) => void;
    onSuccess: () => void;
    setLoading: (param: boolean) => void;
}): void => {
    const payload = addTasks.reduce(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (p, { ValuationId, ValuationAssetMethodologySetup, ValuationAsset, ...task }, index) => ({
            ...p,
            [`-${++index}`]: {
                ...task,
                // pass in AssetId to determine if user is Authorized to add a Valuation for this Asset
                AssetId: ValuationAsset?.length === 1 ? ValuationAsset[0].AssetId : undefined,
                Id: `-${index}`,
                __ADDED__: true,
            },
        }),
        {},
    );

    apiProvider
        .submitTask(
            TaskApi,
            ValuationDomains.Valuation,
            ValuationDomains.Valuation,
            GridIdentifiers.VALUATION_SETUP,
            payload as TaskRequest['payload'],
            requestInit,
        )
        .then(([response, request]): void => {
            setLoading(false);
            if (response.statusCode !== TaskStatus.Completed) {
                updateErrors(getValidationResponse(request, response));
            } else onSuccess();
        });
};

const removePrimary = <
    T extends ValuationAssetMethodologiesVM | ValuationAssetOtherDataScreensVM | ValuationAssetOtherValuesVM,
>(
    record: T,
): T => {
    const newRecord = { ...record };
    delete newRecord.VAMethodologiesId;
    delete newRecord.VAOtherDataScreensId;
    delete newRecord.VAOtherValuesId;

    return newRecord;
};

export const validateMethodologySetup = ({
    TaskApi,
    requestInit,
    addTasks,
    isConfirmMethodology,
    updatevalidationResponse: updateErrors,
    onSuccess = (): void => undefined,
    setLoading = (): void => undefined,
}: {
    TaskApi: string | undefined;
    requestInit: RequestInit;
    addTasks: AddTask[];
    isConfirmMethodology: boolean;
    updatevalidationResponse: React.Dispatch<React.SetStateAction<ValidationResponse>>;
    onSuccess: () => void;
    setLoading: (param: boolean) => void;
}): void => {
    let pseq = 0;
    const payload = isConfirmMethodology
        ? addTasks.reduce(
              (
                  p,
                  {
                      ValuationAssetMethodologySetup,
                      ValuationUniqId = createGuid(), // eslint-disable-line @typescript-eslint/no-unused-vars
                  },
              ) => {
                  let assets = {};

                  if (ValuationAssetMethodologySetup && ValuationAssetMethodologySetup.length) {
                      assets = ValuationAssetMethodologySetup.reduce(
                          (
                              child,
                              {
                                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                  ValuationGroupId,
                                  VAMethodologySetupId, // eslint-disable-line @typescript-eslint/no-unused-vars
                                  AssetId,
                                  ValuationAssetMethodologies,
                                  ValuationAssetOtherDataScreens,
                                  ValuationAssetOtherValues,
                                  ...vam
                              },
                          ) => {
                              --pseq;
                              const children: unknown[] = [];
                              const vams = {};

                              Object.assign(vams, { ...vam, PortfolioId: vam.PortfoliosId, AssetId });

                              if (ValuationAssetMethodologies) {
                                  let seq = 0;
                                  children.push({
                                      domainId: 'ValuationAssetMethodologies',
                                      fieldConfigurationId: 'ValuationAssetMethodologies',
                                      requestIdentifier: GridIdentifiers.VA_METHODOLOGIES_GRID,
                                      payload: ValuationAssetMethodologies.reduce((acc, record) => {
                                          return {
                                              ...acc,
                                              [`${--seq}`]: removePrimary({
                                                  ...record,
                                                  AssetId,
                                                  VAMethodologySetupId: `${pseq}`,
                                                  ValuationAssetId: `${pseq}`,
                                                  __ADDED__: true,
                                                  ValuationId: ValuationUniqId,
                                              }),
                                          };
                                      }, {}),
                                  });
                              }

                              if (ValuationAssetOtherDataScreens) {
                                  let seq = 0;
                                  children.push({
                                      domainId: 'ValuationAssetOtherDataScreens',
                                      fieldConfigurationId: 'ValuationAssetOtherDataScreens',
                                      requestIdentifier: GridIdentifiers.VA_OTHERDATASCREENS_GRID,
                                      payload: ValuationAssetOtherDataScreens.reduce((acc, record) => {
                                          return {
                                              ...acc,
                                              [`${--seq}`]: removePrimary({
                                                  ...record,
                                                  AssetId,
                                                  VAMethodologySetupId: `${pseq}`,
                                                  ValuationAssetId: `${pseq}`,
                                                  __ADDED__: true,
                                                  ValuationId: ValuationUniqId,
                                              }),
                                          };
                                      }, {}),
                                  });
                              }

                              if (ValuationAssetOtherValues) {
                                  let seq = 0;
                                  children.push({
                                      domainId: 'ValuationAssetOtherValues',
                                      fieldConfigurationId: 'ValuationAssetOtherValues',
                                      requestIdentifier: GridIdentifiers.VA_OTHERVALUES_GRID,
                                      payload: ValuationAssetOtherValues.reduce((acc, record) => {
                                          return {
                                              ...acc,
                                              [`${--seq}`]: removePrimary({
                                                  ...record,
                                                  AssetId,
                                                  VAMethodologySetupId: `${pseq}`,
                                                  ValuationAssetId: `${pseq}`,
                                                  __ADDED__: true,
                                                  ValuationId: ValuationUniqId,
                                              }),
                                          };
                                      }, {}),
                                  });
                              }

                              return {
                                  ...child,
                                  [`${pseq}`]: {
                                      ...vams,
                                      ValuationAssetId: createGuid(),
                                      __CHILD_REQUESTS__: children,
                                      __ADDED__: true,
                                  },
                              };
                          },
                          {},
                      );
                  }

                  return {
                      ...p,
                      ...assets,
                  };
              },
              {},
          )
        : addTasks.reduce((p, { ValuationAssetMethodologySetup }, index) => {
              if (ValuationAssetMethodologySetup && ValuationAssetMethodologySetup.length) {
                  return ValuationAssetMethodologySetup.reduce(
                      (
                          acc,
                          {
                              VAMethodologySetupId, // eslint-disable-line @typescript-eslint/no-unused-vars
                              ValuationGroupId, // eslint-disable-line @typescript-eslint/no-unused-vars
                              ValuationAssetMethodologies, // eslint-disable-line @typescript-eslint/no-unused-vars
                              ValuationAssetOtherDataScreens, // eslint-disable-line @typescript-eslint/no-unused-vars
                              ValuationAssetOtherValues, // eslint-disable-line @typescript-eslint/no-unused-vars
                              ...vams
                          },
                          i,
                      ) => ({
                          ...acc,
                          [`-${++index}${i}`]: {
                              ...vams,
                              ValuationAssetId: createGuid(),
                              __ADDED__: true,
                              PortfolioId: vams.PortfoliosId,
                          },
                      }),
                      p,
                  );
              }

              return { ...p };
          }, {});

    apiProvider
        .submitTask(
            TaskApi,
            'ValuationAssetMethodologySetup',
            'ValuationAssetMethodologySetup',
            isConfirmMethodology ? GridIdentifiers.VA_METHODOLOGYSETUP_GRID : GridIdentifiers.METHODOLOGY_SETUP,
            payload as TaskRequest['payload'],
            requestInit,
        )
        .then(([response, request]): void => {
            setLoading(false);
            if (response.statusCode !== TaskStatus.Completed) {
                updateErrors(getValidationResponse(request, response));
            } else onSuccess();
        });
};

/**
 * Adds row number to the payload
 */

export const addRowIndex = (
    apis: AllGridApis,
    data: Record<string, Record<string, unknown>>,
    primaryKey: string,
): Record<string, Record<string, unknown>> => {
    const gridData: Record<string, Record<string, unknown>> = {};

    const updateGridData = (rowData: Record<string, unknown>, index: number): void =>
        Object.keys(data).forEach((rowKey) => {
            const row = data[rowKey] as Record<string, unknown>;
            if (row[primaryKey] === rowData[primaryKey]) gridData[rowKey] = { ...row, __INDEX__: index + 1 };
        });

    let newRowCount = 0;
    /* Adds row index for newly added grid rows */
    apis.ifDefined(GridType.Adds, ({ gridApi }) => {
        gridApi.forEachNode((node) => {
            const { data: _data, rowIndex } = node;

            newRowCount++;
            if (_data && !isNullOrUndefined(rowIndex)) updateGridData(_data, rowIndex);
        });
    });

    /* Adds row index for existing grid rows */
    apis.ifDefined(GridType.Body, ({ gridApi }) => {
        gridApi.forEachNode(({ data: rowData, rowIndex }) => {
            if (rowData && !isNullOrUndefined(rowIndex)) updateGridData(rowData, rowIndex + newRowCount);
        });
    });

    return gridData;
};

/**
 * Adds row index and gets last changes in the grid
 * @deprecated
 *
 */
export const addRowIndexWithLastChanges = (
    { apis, data }: GridStateType,
    primaryKey: string,
): Record<string, Record<string, unknown>> => {
    const gridData: Record<string, Record<string, unknown>> = {};

    if (apis) {
        if (Object.keys(data).length) {
            data = getFinalGridData(apis, data, primaryKey);
        }

        let newRowCount = 0;
        /* Adds row index for new grid rows */
        apis.ifDefined(GridType.Adds, ({ gridApi }) => {
            gridApi.forEachNode((node) => {
                const { data: rowData, rowIndex } = node;

                if (rowIndex && rowData) {
                    Object.keys(data).forEach((rowKey) => {
                        const row = data[rowKey] as Record<string, unknown>;
                        if (row[primaryKey] === rowData[primaryKey]) gridData[rowKey] = { ...row, __INDEX__: rowIndex };
                    });
                }

                newRowCount++;
            });
        });

        /* Adds row index for existing grid rows */
        apis.ifDefined(GridType.Body, ({ gridApi }) => {
            gridApi.forEachNode(({ id, rowIndex }) => {
                Object.keys(data).forEach((rowKey) => {
                    const row = data[rowKey] as Record<string, unknown>;
                    if (row[primaryKey] === id && rowIndex !== null)
                        gridData[rowKey] = { ...row, __INDEX__: rowIndex + newRowCount };
                });
            });
        });
    }

    return gridData;
};

export const isFormValid = (data: Record<string, unknown>): boolean => {
    const properties = [
        'PortfolioTypeId',
        'ValuationDateofValue',
        'ValuationMonth',
        'ValuationPeriodTypeId',
        'ValuationScopeId',
        'ValuationWorkflowTemplateId',
        'ValuationYear',
    ];

    return properties.every((prop) => data[prop] !== undefined && data[prop] !== '');
};
