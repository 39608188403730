/* istanbul ignore file */
import React from 'react';
import { ThemedComponent, useThemeContext } from '@samc/react-ui-theme';
import { styled } from 'styletron-react';
import { Shimmer } from '@fluentui/react';
import {
    ValuationWorkflowTask,
    ValuationWorkflowTaskStatus,
    ValuationWorkflowTemplateStage,
    ValuationWorkflowTemplateTask,
    ValuationWorkflowTemplateTaskStatus,
} from '../../../valuationApi';
import { WorkflowModal } from '../WorkflowModal/WorkflowModal';
import { useWorkflowModalContext } from '../../../hooks/useWorkflowModalContext/useWorkflowModalContext';
import { useApiContext } from '../../../hooks/useApiContext';
import { ControllerBarBox } from '../ControllerBarBox/ControllerBarBox';

interface Props {
    /**
     * The id of the current WorkflowTemplateStage.
     */
    currentWorkflowTemplateStageId: string;
    /**
     * The value to display to represent the Valuations current WorkflowTemplateStatus.
     */
    currentWorkflowTemplateStatusName: string;
    /**
     * The value to display to represent the number of open tasks for the current user.
     */
    openTasksCountForUser: number;
    /**
     * The value to display to represent the number of total open tasks for the Valuation.
     */
    openTasksCountTotal: number;
    /**
     * The id of the Valuation for which to render the WorkflowBox.
     */
    valuationId: string;
    /**
     * The collection of ValuationWorkflowTasks to display in the Grid.
     */
    workflowTasks: ValuationWorkflowTask[];
    /**
     * A collection of ValuationWorkflowTaskStatuses which will be combined with the ValuationWorkflowTasks
     * to determine the ValuationWorkflowTaskStatus of a given ValuationWorkflowTask.
     */
    workflowTaskStatuses: ValuationWorkflowTaskStatus[];
    /**
     * A collection of ValuationWorkflowTemplateStages which will separate ValuationWorkflowTasks in the grid
     * by ValuationWorkflowTaskStatus.Name, using Full Width Rows.
     */
    workflowTemplateStages: ValuationWorkflowTemplateStage[];
    /**
     * A collection of ValuationWorkflowTemplateTasks which will comprise the contents of the WorkflowTemplatePanel.
     */
    workflowTemplateTasks: ValuationWorkflowTemplateTask[];
    /**
     * A collection of ValuationWorkflowTemplateTaskStatuses which will be combined with the ValuationWorkflowTasks
     * to determine the ValuationWorkflowTemplateTaskStatuses of a given ValuationWorkflowTask.
     */
    workflowTemplateTaskStatuses: ValuationWorkflowTemplateTaskStatus[];
    /**
     * Boolean indicator of if the WorkflowBox should be hidden.
     */
    workflowBoxIsNoData?: boolean;
    /**
     * Boolean indicator of if the WorkflowTaskGrid should display in the errored state.
     */
    workflowTaskGridIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowTaskGrid should display in the loading state.
     */
    workflowTaskGridIsLoading?: boolean;
    /**
     * Boolean indicator of if the WorkflowStatus request errored.
     */
    workflowStatusRequestIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowStatus is still loading.
     */
    workflowStatusRequestIsLoading?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks request errored.
     */
    workflowTemplateStagesRequestIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks are still loading.
     */
    workflowTemplateStagesRequestIsLoading?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks request errored.
     */
    workflowTemplateTasksRequestIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks are still loading.
     */
    workflowTemplateTasksRequestIsLoading?: boolean;
}
/* istanbul ignore next */
const StyledClickableBox = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    cursor: 'pointer',
}));

/* istanbul ignore next */
const StyledFlexRow = styled('span', () => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5em',
    justifyContent: 'space-between',
}));

/* istanbul ignore next */
const StyledWorkflowTemplateStatusName = styled('span', ({ theme }: ThemedComponent) => ({
    fontWeight: 'bold',
    color: `${theme.TextColors.secondary}`,
}));

/* istanbul ignore next */
const StyledOpenTasksCount = styled('span', ({ theme }: ThemedComponent) => ({
    color: `${theme.FormColors.fieldColors.error.fontColor}`,
}));

/* istanbul ignore next */
const StyledTotalTasksCount = styled('span', ({ theme }: ThemedComponent) => ({
    color: `${theme.TextColors.secondary}`,
}));

export const WorkflowBox = (props: Props): React.ReactElement => {
    const theme = useThemeContext();
    const {
        currentWorkflowTemplateStageId,
        currentWorkflowTemplateStatusName,
        openTasksCountForUser,
        openTasksCountTotal,
        valuationId,
        workflowBoxIsNoData,
        workflowStatusRequestIsErrored,
        workflowStatusRequestIsLoading,
        workflowTaskGridIsErrored,
        workflowTaskGridIsLoading,
        workflowTasks,
        workflowTaskStatuses,
        workflowTemplateStages,
        workflowTemplateStagesRequestIsErrored,
        workflowTemplateStagesRequestIsLoading,
        workflowTemplateTasks,
        workflowTemplateTasksRequestIsErrored,
        workflowTemplateTasksRequestIsLoading,
        workflowTemplateTaskStatuses,
    } = props;

    const {
        FetchApi: fetchApi,
        useValuation,
        useValuationContacts,
        useValuationWorkflowTask,
        useValuationWorkflowTaskOwners,
        useValuationWorkflowTaskStatuses,
    } = useApiContext();

    const { isOpen: isModalOpen, setIsOpen: setIsModalOpen } = useWorkflowModalContext();

    const openModal = (): void => {
        setIsModalOpen(true);
    };

    const closeModal = (): void => {
        setIsModalOpen(false);
    };

    if (workflowBoxIsNoData) {
        return <></>;
    }

    return (
        <ControllerBarBox
            isLoading={!!workflowTemplateStagesRequestIsLoading}
            isError={!!workflowStatusRequestIsErrored || !!workflowTemplateStagesRequestIsErrored}
        >
            <>
                <StyledClickableBox role="button" aria-hidden onClick={openModal}>
                    <div>
                        <Shimmer isDataLoaded={!workflowStatusRequestIsLoading}>
                            <StyledWorkflowTemplateStatusName theme={theme}>
                                {currentWorkflowTemplateStatusName}
                            </StyledWorkflowTemplateStatusName>
                        </Shimmer>
                    </div>
                    <Shimmer isDataLoaded={!workflowStatusRequestIsLoading}>
                        <StyledFlexRow>
                            <div>
                                <span>Your Open Tasks: </span>
                                <StyledOpenTasksCount theme={theme}>{openTasksCountForUser}</StyledOpenTasksCount>
                            </div>
                            <div>
                                <span>Total Open Tasks: </span>
                                <StyledTotalTasksCount theme={theme}>{openTasksCountTotal}</StyledTotalTasksCount>
                            </div>
                        </StyledFlexRow>
                    </Shimmer>
                </StyledClickableBox>
                <WorkflowModal
                    currentWorkflowTemplateStageId={currentWorkflowTemplateStageId}
                    currentWorkflowTemplateStatusName={currentWorkflowTemplateStatusName}
                    fetchApi={fetchApi}
                    isOpen={isModalOpen}
                    onRejectionButtonClicked={closeModal}
                    useValuation={useValuation}
                    useValuationContacts={useValuationContacts}
                    useValuationWorkflowTask={useValuationWorkflowTask}
                    useValuationWorkflowTaskOwners={useValuationWorkflowTaskOwners}
                    useValuationWorkflowTaskStatuses={useValuationWorkflowTaskStatuses}
                    valuationId={valuationId}
                    workflowTaskGridIsErrored={workflowTaskGridIsErrored}
                    workflowTaskGridIsLoading={workflowTaskGridIsLoading}
                    workflowTasks={workflowTasks}
                    workflowTaskStatuses={workflowTaskStatuses}
                    workflowTemplateStages={workflowTemplateStages}
                    workflowTemplateTasks={workflowTemplateTasks}
                    workflowTemplateTaskStatuses={workflowTemplateTaskStatuses}
                    workflowTemplateStagesRequestIsErrored={workflowTemplateStagesRequestIsErrored}
                    workflowTemplateStagesRequestIsLoading={workflowTemplateStagesRequestIsLoading}
                    workflowTemplateTasksRequestIsErrored={workflowTemplateTasksRequestIsErrored}
                    workflowTemplateTasksRequestIsLoading={workflowTemplateTasksRequestIsLoading}
                />
            </>
        </ControllerBarBox>
    );
};

export default WorkflowBox;
