import React from 'react';
import { ActionButton } from '@fluentui/react';
import { styled } from 'styletron-react';
import { ThemeContextType, useThemeContext } from '@samc/react-ui-theme';

/* istanbul ignore next */
const StyledActionButton = styled(
    ActionButton,
    ({ disabled, ourTheme }: { disabled?: boolean | undefined; ourTheme: ThemeContextType }) => ({
        border: 'unset',
        color: disabled
            ? ourTheme.ProgressStepperColors.markerBorderPending.toString()
            : ourTheme.BaseColors.baseLightBlue.toString(),
        height: '100%',
    }),
);

interface Props {
    disabled?: boolean | undefined;
    onClick?: () => void;
    text?: string;
}

export const WorkflowTaskActionButton = ({ disabled, onClick, text }: Props): React.ReactElement => {
    const theme = useThemeContext();

    return (
        <StyledActionButton onClick={onClick} ourTheme={theme} disabled={disabled}>
            {text}
        </StyledActionButton>
    );
};

export default WorkflowTaskActionButton;
