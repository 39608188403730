/* istanbul ignore file */
import React from 'react';
import { ErrorBoundary } from '@samc/react-ui-core';
import { HeaderContextProvider } from '@samc/react-ui-history';
import { EditingProvider } from '@samc/react-ui-grid';
import { DEATrackingHelperBox } from '../../organisms/DEATrackingHelperBox/DEATrackingHelperBox';
import { useDEATrackingHelper } from '../../../valuationApi/useDEATrackingHelper/useDEATrackingHelper';
import useDEInvestmentValueAckStatus from '../../../valuationApi/useDEInvestmentValueAckStatus/useDEInvestmentValueAckStatus';

interface Props {
    /**
     * The id of the Valuation for which to render the WorkflowBox.
     */
    valuationId: string;
    refreshViewSet: () => void;
}

export const DEATrackingHelperRequestWrapper = ({ valuationId, refreshViewSet }: Props): React.ReactElement => {
    const deaTrackingData = useDEATrackingHelper(valuationId);
    const deInvestmentValueAck = useDEInvestmentValueAckStatus(valuationId);

    return (
        <ErrorBoundary>
            <HeaderContextProvider>
                <EditingProvider>
                    <DEATrackingHelperBox
                        deaHelperResponse={deaTrackingData}
                        valuationId={valuationId}
                        deaInvestmentValueAckResponse={deInvestmentValueAck}
                        refreshViewSet={refreshViewSet}
                    />
                </EditingProvider>
            </HeaderContextProvider>
        </ErrorBoundary>
    );
};

export default DEATrackingHelperRequestWrapper;
