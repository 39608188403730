import React from 'react';
import { styled } from 'styletron-react';
import { DefaultButton, IButtonProps } from '@fluentui/react';
import { useThemeContext, ThemeContextType } from '@samc/react-ui-theme';
import {
    fetchApi as _fetchApi,
    useValuationContacts as _useValuationContacts,
    useValuationWorkflowTask as _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners as _useValuationWorkflowTaskOwners,
    useValuationWorkflowTaskStatuses as _useValuationWorkflowTaskStatuses,
    ValuationApiOverrides,
    ValuationWorkflowTask,
    ValuationWorkflowTaskStatus,
    ValuationWorkflowTemplateStage,
    ValuationWorkflowTemplateTaskStatus,
} from '../../../valuationApi';
import { WorkflowTaskGrid } from '../../molecules/WorkflowTaskGrid/WorkflowTaskGrid';

interface Props
    extends Pick<
        ValuationApiOverrides,
        | 'fetchApi'
        | 'useValuationContacts'
        | 'useValuationWorkflowTask'
        | 'useValuationWorkflowTaskOwners'
        | 'useValuationWorkflowTaskStatuses'
    > {
    /**
     * The Id of the Valuation to fetch.
     */
    valuationId: string;
    /**
     * The collection of ValuationWorkflowTasks to display in the Grid.
     */
    workflowTasks: ValuationWorkflowTask[];
    /**
     * A collection of ValuationWorkflowTaskStatuses which will be combined with the ValuationWorkflowTasks
     * to determine the ValuationWorkflowTaskStatus of a given ValuationWorkflowTask.
     */
    workflowTaskStatuses: ValuationWorkflowTaskStatus[];
    /**
     * A collection of ValuationWorkflowTemplateStages which will separate ValuationWorkflowTasks in the grid
     * by ValuationWorkflowTaskStatus.Name, using Full Width Rows.
     */
    workflowTemplateStages: ValuationWorkflowTemplateStage[];
    /**
     * A collection of ValuationWorkflowTemplateTaskStatuses which will be combined with the ValuationWorkflowTasks
     * to determine the ValuationWorkflowTemplateTaskStatuses of a given ValuationWorkflowTask.
     */
    workflowTemplateTaskStatuses: ValuationWorkflowTemplateTaskStatus[];
    /**
     * The Id of the WorkflowTemplateStage to set as the initial filter state of the Grid. Or put another way,
     * all WorkflowTemplateStages except the stage with the provided Id will be excluded from the grid in its
     * initial state.
     */
    defaultIncludedWorkflowTemplateStageId?: string;
}

/* istanbul ignore next */
const StyledRootFlexContainer = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1.5rem',
}));

/* istanbul ignore next */
const StyledFlexContainer = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '2px',
}));

/* istanbul ignore next */
const StyledFlexRow = styled('div', () => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
}));

/* istanbul ignore next */
const StyledButton = styled(DefaultButton, ({ checked, ourTheme }: IButtonProps & { ourTheme: ThemeContextType }) => ({
    color: checked ? ourTheme.TextColors.light.toString() : ourTheme.BaseColors.baseDarkBlue.toString(),
    backgroundColor: checked ? ourTheme.BaseColors.baseDarkBlue.toString() : ourTheme.TextColors.light.toString(),
    border: checked ? `1px solid ${ourTheme.BaseColors.baseDarkBlue.toString()}` : `1px solid lightgray`,
    borderRadius: '0',
    ':hover': {
        color: ourTheme.TextColors.light.toString(),
        backgroundColor: ourTheme.BaseColors.baseDarkBlue.toString(),
        border: `1px solid ${ourTheme.BaseColors.baseDarkBlue.toString()}`,
    },
}));

export const FilteredWorkflowTaskGrid = ({
    defaultIncludedWorkflowTemplateStageId,
    fetchApi = _fetchApi,
    valuationId,
    useValuationContacts = _useValuationContacts,
    useValuationWorkflowTask = _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners = _useValuationWorkflowTaskOwners,
    useValuationWorkflowTaskStatuses = _useValuationWorkflowTaskStatuses,
    workflowTasks,
    workflowTaskStatuses,
    workflowTemplateStages,
    workflowTemplateTaskStatuses,
}: Props): React.ReactElement => {
    const theme = useThemeContext();

    /**
     * Reduces the array of ValuationWorkflowTemplateStages to an object with which we can track filter state:
     *
     * {
     *     id-of-workflow-template-stage-0: true,
     *     id-of-workflow-template-stage-1: true,
     *     ...
     * }
     */
    const initialFilterState = workflowTemplateStages.reduce(
        (acc, cur) => {
            acc[cur.Id] = false || defaultIncludedWorkflowTemplateStageId === cur.Id;
            return acc;
        },
        {} as { [stage: string]: boolean },
    );

    const [filterStateByStage, setFilterStateByStage] = React.useState<{ [stage: string]: boolean }>(
        initialFilterState,
    );

    const includeAllStagesFilterState = workflowTemplateStages.reduce(
        (acc, cur) => {
            acc[cur.Id] = true;
            return acc;
        },
        {} as { [stage: string]: boolean },
    );

    const filterStateIncludesAllStages = React.useMemo(() => {
        return workflowTemplateStages.every((stage) => filterStateByStage[stage.Id]);
    }, [filterStateByStage, workflowTemplateStages]);

    const handleFilterByAllStagesButtonClick = (): void => {
        if (filterStateIncludesAllStages) {
            setFilterStateByStage(initialFilterState);
        } else {
            setFilterStateByStage(includeAllStagesFilterState);
        }
    };

    const [filterByAdHocChecked, setFilterByAdHocChecked] = React.useState<boolean>(false);

    const handleFilterByAdHocButtonClick = (): void => {
        setFilterByAdHocChecked(!filterByAdHocChecked);
    };

    /**
     * A callback generator to handle updating filterStateByStage for all of ValuationWorkflowTemplateStages.
     *
     * @param stageId The id of the ValuationWorkflowTemplateStage for which to generate a click callback function.
     * @returns An on click callback function which will update the filterStateByStage for the given stageId.
     */
    const handleFilterByTemplateStageButtonClickCallbackGenerator = (stageId: string): (() => void) => {
        return () => setFilterStateByStage({ ...filterStateByStage, [stageId]: !filterStateByStage[stageId] });
    };

    const [filterByOpenTasks, setFilterByOpenTasks] = React.useState<boolean>(false);

    const handleFilterByOpenTasksButtonClick = (): void => {
        setFilterByOpenTasks(true);
    };

    const handleFilterByAllTasksButtonClick = (): void => {
        setFilterByOpenTasks(false);
    };

    /**
     * Filters the full collection of ValuationWorkflowTemplateStages by the currently selected Stage toggle button filters.
     */
    const filteredValuationWorkflowTemplateStages = React.useMemo(() => {
        return workflowTemplateStages.filter((stage) => filterStateByStage[stage.Id]);
    }, [filterStateByStage, workflowTemplateStages]);

    /**
     * Filters the full collection of ValuationWorkflowTasks by the filtered collection of ValuationWorkflowTemplateStages,
     * as well as the currently selected All Tasks/Open Tasks toggle button filters.
     */
    const filteredValuationWorkflowTasks = React.useMemo(() => {
        return workflowTasks.filter(
            (task) =>
                (filteredValuationWorkflowTemplateStages.find(
                    (stage) => stage.Id.toLowerCase() === task.TaskStageId?.toLowerCase(),
                ) ||
                    (filterByAdHocChecked && task.AdHocTaskFlag)) &&
                ((filterByOpenTasks && !task.CompletedByDate) || !filterByOpenTasks),
        );
    }, [filterByAdHocChecked, filterByOpenTasks, filteredValuationWorkflowTemplateStages, workflowTasks]);

    return (
        <StyledRootFlexContainer>
            <StyledFlexRow>
                <StyledFlexContainer>
                    <StyledFlexRow>
                        <StyledButton
                            toggle
                            checked={filterStateIncludesAllStages}
                            text="ALL STAGES"
                            onClick={handleFilterByAllStagesButtonClick}
                            ourTheme={theme}
                        />
                        {workflowTemplateStages.map((stage) => {
                            return (
                                <StyledButton
                                    key={stage.Id}
                                    toggle
                                    checked={filterStateByStage[stage.Id]}
                                    text={stage.Name?.toUpperCase()}
                                    onClick={handleFilterByTemplateStageButtonClickCallbackGenerator(stage.Id)}
                                    ourTheme={theme}
                                />
                            );
                        })}
                        <StyledButton
                            toggle
                            checked={filterByAdHocChecked}
                            text="AD HOC TASKS"
                            onClick={handleFilterByAdHocButtonClick}
                            ourTheme={theme}
                        />
                    </StyledFlexRow>
                    <StyledFlexRow>
                        <StyledButton
                            toggle
                            checked={!filterByOpenTasks}
                            text="ALL TASKS"
                            onClick={handleFilterByAllTasksButtonClick}
                            ourTheme={theme}
                        />
                        <StyledButton
                            toggle
                            checked={filterByOpenTasks}
                            text="OPEN TASKS"
                            onClick={handleFilterByOpenTasksButtonClick}
                            ourTheme={theme}
                        />
                    </StyledFlexRow>
                </StyledFlexContainer>
            </StyledFlexRow>
            <WorkflowTaskGrid
                fetchApi={fetchApi}
                valuationId={valuationId}
                useValuationContacts={useValuationContacts}
                useValuationWorkflowTask={useValuationWorkflowTask}
                useValuationWorkflowTaskOwners={useValuationWorkflowTaskOwners}
                useValuationWorkflowTaskStatuses={useValuationWorkflowTaskStatuses}
                workflowTasks={filteredValuationWorkflowTasks}
                workflowTaskStatuses={workflowTaskStatuses}
                workflowTemplateStages={filteredValuationWorkflowTemplateStages}
                workflowTemplateTaskStatuses={workflowTemplateTaskStatuses}
            />
        </StyledRootFlexContainer>
    );
};

export default FilteredWorkflowTaskGrid;
