/* istanbul ignore file */
import React from 'react';
import { useApiContext } from '../../../hooks/useApiContext';
import { WorkflowEditAdHocTaskPanel as BaseWorkflowEditAdHocTaskPanel } from './WorkflowEditAdHocTaskPanel.FormContext';
import {
    ValuationWorkflowTask,
    ValuationWorkflowTaskEnabledUser,
    ValuationWorkflowTaskOwner,
    ValuationWorkflowTaskViewableUser,
} from '../../../valuationApi';

interface Props {
    /**
     * A ValuationWorkflowTask object to use as the initial data for the form.
     */
    initialFormData: ValuationWorkflowTask;
    /**
     * If true, the Panel is displayed, if false the Panel is hidden.
     */
    isOpen: boolean;
    /**
     * The Id of the Valuation for which ValuationContacts will be fetched.
     */
    valuationId: string;
    /**
     * Callback on click of Cancel button.
     */
    onCancel: () => void | Promise<void>;
    /**
     * Callback on successful submission of the Form.
     */
    onSubmitSuccess?: () => void;
}

export const WorkflowEditAdHocTaskPanel = ({
    onCancel,
    initialFormData,
    isOpen,
    valuationId,
    onSubmitSuccess,
}: Props): React.ReactElement => {
    const {
        useValuationWorkflowTaskEnabledUsers,
        useValuationWorkflowTaskOwners,
        useValuationWorkflowTaskViewableUsers,
    } = useApiContext();

    const valuationWorkflowTaskEnabledUsersRequest = useValuationWorkflowTaskEnabledUsers(initialFormData?.Id);

    const initialContactsEnabledFor = valuationWorkflowTaskEnabledUsersRequest.data
        ?.Data as unknown as ValuationWorkflowTaskEnabledUser[];

    const valuationWorkflowTaskOwnersRequest = useValuationWorkflowTaskOwners(initialFormData?.Id);

    const initialContactsAssignedTo = valuationWorkflowTaskOwnersRequest.data
        ?.Data as unknown as ValuationWorkflowTaskOwner[];

    const valuationWorkflowTaskViewableUsersRequest = useValuationWorkflowTaskViewableUsers(initialFormData?.Id);

    const initialContactsViewableBy = valuationWorkflowTaskViewableUsersRequest.data
        ?.Data as unknown as ValuationWorkflowTaskViewableUser[];

    return (
        <BaseWorkflowEditAdHocTaskPanel
            initialContactsAssignedTo={initialContactsAssignedTo}
            initialContactsEnabledFor={initialContactsEnabledFor}
            initialContactsViewableBy={initialContactsViewableBy}
            initialFormData={initialFormData}
            isOpen={isOpen}
            onCancel={onCancel}
            onSubmitSuccess={onSubmitSuccess}
            valuationId={valuationId}
        />
    );
};

export default WorkflowEditAdHocTaskPanel;
