/* istanbul ignore file */
import React from 'react';
import { FormManagementContextProvider } from '@samc/react-ui-form';
import { TaskStatus } from '@samc/screen-config-api';
import { HeaderContextProvider } from '@samc/react-ui-history';
import { IPanelProps, IRenderFunction, Panel, PanelType } from '@fluentui/react';
import {
    PROPERTY_NAME_CONTACTS_ASSIGNED_TO,
    useValuationWorkflowTaskOwnersSaver as _useValuationWorkflowTaskOwnersSaver,
    ValuationApiOverrides,
    ValuationWorkflowTask,
    ValuationContact,
    useValuationWorkflowTaskOwners,
    ValuationWorkflowTaskOwner,
} from '../../../valuationApi';
import { useValidationMessage as _useValidationMessage } from '../../hooks/useValidationMessage/useValidationMessage';
import { useWorkflowTaskValidationPopupContext } from '../../contexts/WorkflowTaskValidationPopupContext';
import { WorkflowTaskReassignForm } from '../../molecules/WorkflowTaskReassignForm/WorkflowTaskReassignForm';
import { PanelHeader } from '../../atoms/PanelHeader/PanelHeader';

const EMPTY_OWNERS_ARRAY: ValuationWorkflowTaskOwner[] = [];

interface Props
    extends Pick<
        ValuationApiOverrides,
        | 'fetchApi'
        | 'useValuationWorkflowTask'
        | 'useValuationWorkflowTaskOwners'
        | 'useValuationWorkflowTaskOwnersSaver'
    > {
    /**
     * If true, the Panel is displayed, if false the Panel is hidden.
     */
    isOpen: boolean;
    /**
     * The Id of the Valuation for which ValuationContacts will be fetched.
     */
    valuationId: string;
    /**
     * The Id of the ValuationWorkflowTask to be fetched.
     */
    valuationWorkflowTaskId: string;

    initialContactsAssignedTo?: { Id: string; UserId: string }[];
    /**
     * A ValuationWorkflowTask object to use as the initial data for the form.
     */
    initialFormData?: ValuationWorkflowTask;
    /**
     * Callback on click of Cancel button.
     */
    onCancel?: () => void | Promise<void>;
    /**
     * Callback on click of Submit and Close button.
     */
    onSubmitAndClose?: () => void | Promise<void>;
    /**
     * Callback on successful submission of the Form.
     */
    onSubmitSuccess?: () => void;
    /**
     * DI override of the hook to show a Validation Message.
     */
    useValidationMessage?: typeof _useValidationMessage;
}

export const WorkflowTaskReassignPanel = ({
    initialContactsAssignedTo = [],
    initialFormData,
    isOpen,
    onCancel,
    onSubmitAndClose,
    onSubmitSuccess,
    useValidationMessage = _useValidationMessage,
    useValuationWorkflowTaskOwnersSaver = _useValuationWorkflowTaskOwnersSaver,
    valuationId,
    valuationWorkflowTaskId,
}: Props): React.ReactElement => {
    const [saveTaskOwners] = useValuationWorkflowTaskOwnersSaver();
    const [showValidationMessage] = useValidationMessage({});
    const { setValidationRequest, setValidationResponse, setVisible } = useWorkflowTaskValidationPopupContext();

    const dirtinessScope = React.useRef<HTMLDivElement>(null);

    const [contactsAssignedToRowsToAdd, setContactsAssignedToRowsToAdd] = React.useState<ValuationContact[]>([]);
    const [contactsAssignedToRowsToDelete, setContactsAssignedToRowsToDelete] = React.useState<ValuationContact[]>([]);

    const existingOwners = useValuationWorkflowTaskOwners(valuationWorkflowTaskId).data?.Data as unknown as
        | ValuationWorkflowTaskOwner[]
        | undefined;

    const onContactAssignedToDeselected = (contact?: ValuationContact): void => {
        if (!contact) return;

        setContactsAssignedToRowsToAdd(contactsAssignedToRowsToAdd.filter((c) => c.UserId !== contact.UserId));

        if (initialContactsAssignedTo.some((c) => c.UserId === contact.UserId)) {
            setContactsAssignedToRowsToDelete([...contactsAssignedToRowsToDelete, contact]);
        }
    };

    const onContactAssignedToSelected = (contact?: ValuationContact): void => {
        if (!contact) return;

        setContactsAssignedToRowsToDelete(contactsAssignedToRowsToDelete.filter((c) => !(c.UserId === contact.UserId)));

        if (initialContactsAssignedTo.some((c) => c.UserId === contact.UserId)) return;

        setContactsAssignedToRowsToAdd([...contactsAssignedToRowsToAdd, contact]);
    };

    const onFormSubmit = async (values: ValuationWorkflowTask): Promise<void> => {
        if (!values.ContactsAssignedTo || values.ContactsAssignedTo.length === 0) {
            showValidationMessage(values.Name || 'New Task', 'At least one user must be assigned to the task.', [
                PROPERTY_NAME_CONTACTS_ASSIGNED_TO,
            ]);
            return;
        }

        const [result, request] = await saveTaskOwners.mutateAsync({
            contactsToAssign: contactsAssignedToRowsToAdd,
            contactsToRemove: contactsAssignedToRowsToDelete,
            workflowTaskId: valuationWorkflowTaskId,
            existingOwners: existingOwners ?? EMPTY_OWNERS_ARRAY,
        });

        if (result.statusCode === TaskStatus.Completed) {
            onSubmitSuccess?.();

            return;
        }

        setValidationRequest(request);
        setValidationResponse(result);
        setVisible(true);
    };

    const navigationContentRenderer: IRenderFunction<IPanelProps> = (): React.ReactElement => (
        <PanelHeader
            cancelDisabledOverride={false}
            onCancel={onCancel}
            onSubmitAndClose={onSubmitAndClose}
            title="Reassign Task"
            dirtinessScope={dirtinessScope}
        />
    );

    return (
        <HeaderContextProvider>
            <Panel
                isOpen={isOpen}
                onRenderNavigationContent={navigationContentRenderer}
                type={PanelType.medium}
                layerProps={{ eventBubblingEnabled: true }}
                popupProps={{ ref: dirtinessScope }}
            >
                <FormManagementContextProvider onSubmit={onFormSubmit} initialData={initialFormData}>
                    <WorkflowTaskReassignForm
                        onContactAssignedToDeselected={onContactAssignedToDeselected}
                        onContactAssignedToSelected={onContactAssignedToSelected}
                        valuationId={valuationId}
                        workflowTaskId={valuationWorkflowTaskId}
                    />
                </FormManagementContextProvider>
            </Panel>
        </HeaderContextProvider>
    );
};

export default WorkflowTaskReassignPanel;
