/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { GridApi, IRowNode } from 'ag-grid-community';

interface Props {
    /**
     * An element to render alongside the expand or collapse button.
     */
    children: React.ReactElement;
    /**
     * The grid api. This component will call onGroupExpandedOrCollapsed on this instance of the grid api.
     */
    api: GridApi<unknown>;
    /**
     * The row node. This component will set the expanded property of the row node.
     */
    node: IRowNode<unknown>;
}

/**
 * Much of the layout here is copied from what AgGrid would setup by default in order to use it in a custom cell-renderer.
 */
export const AgGridExpandAndCollapse = ({ api, children, node }: Props): React.ReactElement => {
    const expandNode = (): void => {
        // eslint-disable-next-line no-param-reassign
        node.expanded = true;
        api.onGroupExpandedOrCollapsed();
    };

    const collapseNode = (): void => {
        // eslint-disable-next-line no-param-reassign
        node.expanded = false;
        api.onGroupExpandedOrCollapsed();
    };

    return (
        <span className="ag-cell-wrapper ag-cell-expandable ag-row-group ag-row-group-indent-0">
            <span
                className={`ag-group-expanded ${!node.expanded ? 'ag-hidden' : ''}`}
                role="button"
                onClick={collapseNode}
            >
                <span className="ag-icon ag-icon-tree-open" />
            </span>
            <span
                className={`ag-group-contracted ${node.expanded ? 'ag-hidden' : ''}`}
                role="button"
                onClick={expandNode}
            >
                <span className="ag-icon ag-icon-tree-closed" />
            </span>
            {children}
        </span>
    );
};

export default AgGridExpandAndCollapse;
