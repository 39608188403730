/* eslint-disable no-shadow */
import React from 'react';

/* eslint-disable no-nested-ternary */
export enum StepState {
    CURRENT = 'current',
    DONE = 'done',
    PENDING = 'pending',
}

const colors = {
    bgPrimary: '#ececec',
    bgSecondary: '#d9d9d9',
    black: '#000',
    cyanBlue: '#2fb4ff',
    ferrario: '#133d91',
    silver: '#a6a6a6',
    white: '#fff',
};

const getBarStyle = (s: string, partialProgress: number | undefined): React.CSSProperties => ({
    content: '',
    width: '100%',
    height: '0.1rem',
    background:
        s === StepState.DONE
            ? colors.ferrario
            : s === StepState.CURRENT && partialProgress
              ? `linear-gradient(to right, ${colors.ferrario} 0%, ${colors.ferrario} ${partialProgress}%, ${colors.silver} ${partialProgress}%, ${colors.silver} 100%)`
              : colors.silver,
    position: 'absolute',
    top: '0.9rem',
    left: 'calc(50% + 0.93rem)',
});

const ComponentWrapper = {
    padding: '1rem 0',
    textAlign: 'center',
    fontFamily: 'G-Source Sans Pro-n4, Arial',
    backgroundColor: colors.bgPrimary,
} as React.CSSProperties;

const StepStyle = {
    width: '87%',
    display: 'flex',
    counterReset: 'step',
    margin: '0 auto',
} as React.CSSProperties;

const getLabelStyle = (s: string): React.CSSProperties => ({
    fontWeight: s === StepState.CURRENT ? 'bold' : 'normal',
    color: s === StepState.DONE ? colors.ferrario : s === StepState.PENDING ? colors.silver : colors.black,
});

const getMarkerStyle = (s: string): React.CSSProperties => ({
    border: `1px solid ${s === StepState.CURRENT ? colors.ferrario : colors.silver}`,
    backgroundColor:
        s === StepState.CURRENT ? colors.cyanBlue : s === StepState.PENDING ? colors.bgSecondary : colors.white,
    color: s === StepState.CURRENT ? colors.white : s === StepState.DONE ? colors.ferrario : colors.silver,
    fontWeight: s === StepState.CURRENT ? 'bold' : 'normal',
    height: '1.8rem',
    lineHeight: '1.8rem',
    width: '1.8rem',
    borderRadius: '50%',
    display: 'block',
    textAlign: 'center',
    margin: '0 auto 0.5rem auto',
});

const getStepState = (currentStep: number, index: number): string =>
    currentStep === index ? StepState.CURRENT : currentStep < index ? StepState.PENDING : StepState.DONE;

export default {
    ComponentWrapper,
    StepStyle,
    getBarStyle,
    getMarkerStyle,
    getLabelStyle,
    getStepState,
};
