import { TaskRequest, TaskResponse, TaskStatus } from '@samc/screen-config-api';
import {
    AddTask,
    CreatedBy,
    GridIdentifiers,
    Status,
    Valuation,
    ValuationContactVM,
    ValuationDomains,
} from '../../static/ValuationConstants';
import { GridDataToValuationsParams } from '../AddValutionWizard/BulkValuationWizard.interfaces';
import { submitTask } from '../../../valuationApi/api/Requests';
import { ValidationResponse, getValidationResponse } from '../AddValutionWizard/ValidationUtils';
import { getAssetDetailsById, getValuationGroupNameById } from '../AddValutionWizard/BulkValuationWizard.service';

export const getValuationWorkflowFromGridData = async (
    data: Record<string, unknown>[],
    { qbQuery, assetOrGroupBulk, enableValGroup, portfolioId, portfolioTypeId }: GridDataToValuationsParams,
): Promise<Valuation> => {
    let addTasks: AddTask[] = [];

    if (data.length) {
        addTasks = await Promise.all(
            data.map(async (row) => {
                const task: AddTask = {
                    ...row,
                    ForChangeValuationWorkflow: true,
                    ValuationIsReadonly: 0,
                    ValuationUniqId: String(row.Id ?? ''),
                    EnableValGroup: enableValGroup,
                    PortfolioId: portfolioId,
                    PortfoliosId: portfolioId,
                    Portfolio_Id: portfolioId,
                    PortfolioTypeId: portfolioTypeId as string,
                    ValuationAssetsId: (row.AssetId as string) ?? null,
                    ValuationType: assetOrGroupBulk,
                    CreatedBy,
                };

                const valuationAssetsIds = typeof row.AssetId === 'string' ? row.AssetId.split(',') : [];
                if (row.AssetId && valuationAssetsIds.length && !row.ValuationGroupId) {
                    const assetDetails = await getAssetDetailsById(qbQuery, String(row.AssetId));
                    if (assetDetails.Name !== undefined) {
                        task.AssetsName = assetDetails.Name
                            ? String(assetDetails.Name)
                            : String(assetDetails.ShortName);
                        task.ValuationRegion = assetDetails.RegionalArea as string;
                    }
                }

                if (row.ValuationGroupId) {
                    const vgDetails = await getValuationGroupNameById(qbQuery, task.ValuationGroupId as string);

                    task.ValuationGroupName = vgDetails.ValuationGroupName as string;
                    task.ValuationRegion = vgDetails.RegionalArea as string;
                    task.ValuationAssetsId = '';
                }

                task.ValuationContacts = [...((row.ValuationContacts ?? []) as ValuationContactVM[])];
                task.ForBulkValuation = assetOrGroupBulk;
                task.ValuationId = task.ValuationUniqId;

                return task;
            }),
        );
    }

    return {
        addTasks: addTasks.length
            ? addTasks
            : [
                  {
                      ForBulkValuation: assetOrGroupBulk,
                      PortfolioId: portfolioId as string,
                      Portfolio_Id: portfolioId as string,
                      PortfoliosId: portfolioId as string,
                      PortfolioTypeId: portfolioTypeId as string,
                      StatusId: Status.Scheduled,
                      ValuationIsReadonly: 0,
                  },
              ],
    };
};

export const validateSetupData = ({
    TaskApi,
    requestInit,
    addTasks,
    updateErrors,
    onSuccess = (): void => undefined,
    overrideCodes,
}: {
    TaskApi: string | undefined;
    requestInit: RequestInit;
    addTasks: AddTask[];
    updateErrors: (e: ValidationResponse) => void;
    onSuccess: () => void;
    overrideCodes: string[];
}): void => {
    const payload = addTasks.reduce(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (p, { ValuationId, ValuationAssetMethodologySetup, ValuationAsset, ...task }) => ({
            ...p,
            [`${ValuationId}`]: { ...task },
        }),
        {},
    );

    submitTask(
        TaskApi,
        ValuationDomains.Valuation,
        ValuationDomains.Valuation,
        GridIdentifiers.VALUATION_SETUP,
        payload as TaskRequest['payload'],
        requestInit,
        undefined,
        undefined,
        overrideCodes,
    ).then(([response, request]): void => {
        if (response.statusCode !== TaskStatus.Completed) {
            updateErrors(getValidationResponse(request, response));
        } else onSuccess();
    });
};

const exixtingContacts = (
    ValuationContacts: ValuationContactVM[] | undefined,
    task: Record<string, unknown>,
): Record<string, unknown> => {
    if (ValuationContacts) {
        return ValuationContacts.reduce((acc, item) => {
            return {
                ...acc,
                [`${item.Id}`]: {
                    __DELETED__: true,
                    Id: item.Id,
                    ValuationId: item.ValuationId,
                    RoleId: item.RoleId,
                    CompanyId: item.CompanyId,
                    ContactId: item.ContactId,
                    UserId: item.UserId,
                    ValuationWorkflowParticipantId: item.ValuationWorkflowParticipantId,
                    StatusId: task.StatusId,
                    Portfolio_Id: task.Portfolio_Id,
                    WorkflowTemplateId: task.ValuationWorkflowTemplateId,
                },
            };
        }, {}) as unknown as Record<string, unknown>;
    }
    return '' as unknown as Record<string, unknown>;
};
const contactPayloads = (addContacts: Record<string, unknown>[] | undefined, seq: number): Record<string, unknown> => {
    if (addContacts) {
        return addContacts.reduce(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (p, task, index) => ({
                ...p,
                [`${-index - seq - 1}`]: { ...task },
            }),
            {},
        );
    }
    return '' as unknown as Record<string, unknown>;
};

export const getValuationObject = (payload: Valuation, overrideCodes: string[]): Record<string, unknown> => {
    if (payload.addTasks) {
        const addContacts = Object.values(payload.addContacts ?? {}) as Record<string, unknown>[];
        return payload.addTasks.reduce(
            (
                p,
                { ValuationId, ValuationAssetMethodologySetup, ValuationAsset, ValuationContacts, ...task }, // eslint-disable-line @typescript-eslint/no-unused-vars
                index,
            ) => ({
                ...p,
                [`${ValuationId}`]: {
                    ...task,
                    Id: ValuationId,
                    AssetNameGroupName: task.Asset_Group,
                    __CHILD_REQUESTS__: [
                        {
                            overrideCodes,
                            domainId: 'VALU_ValuationContact',
                            fieldConfigurationId: 'VALU_ValuationContact',
                            requestIdentifier: 'VALU_ValuationContact_VW',
                            payload: {
                                ...contactPayloads(
                                    addContacts.filter((x) => x.ValuationUniqId === ValuationId),
                                    index,
                                ),
                                ...exixtingContacts(
                                    ValuationContacts?.filter((x) => x.ValuationId === ValuationId),
                                    task,
                                ),
                            },
                        },
                    ],
                },
            }),
            {},
        );
    }
    return {};
};

export const UpdateValuations = (
    payload: Valuation,
    TaskApi: string | undefined,
    requestInit: RequestInit,
    overrideCodes: string[] = [],
): Promise<[TaskResponse, TaskRequest]> =>
    submitTask(
        TaskApi,
        ValuationDomains.Valuation,
        ValuationDomains.Valuation,
        'Change Workflow',
        getValuationObject(payload, overrideCodes) as TaskRequest['payload'],
        requestInit,
        false,
        undefined,
        overrideCodes.length ? overrideCodes : undefined,
    );
