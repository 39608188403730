/* eslint-disable no-use-before-define */
import { ViewSet, ViewType } from '@samc/screen-config-api';

/**
 * Extracts the form id for the first tab of the view set, if it is a form
 */
export const getFormViewIdFromViewSet = (viewSet: ViewSet): string | undefined => {
    const { tabs } = viewSet;
    const [tab] = tabs;

    if (!tab) return undefined;
    const { defaultLayout } = tab;
    if (!defaultLayout) return undefined;
    const { viewId, type } = defaultLayout;

    if (type !== ViewType.Form) return undefined;
    return viewId;
};

export default {};
