/* istanbul ignore file */
import React from 'react';
import { IPanelProps, IRenderFunction, Panel, PanelType } from '@fluentui/react';
import { FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { useQueryClient } from 'react-query';
import { PanelHeader } from '../../atoms/PanelHeader/PanelHeader';
import { VALUATION_WORKFLOW_TASKS_KEY } from '../../../valuationApi';

const WORKFLOW_TASK_DUE_DATE_FORM_VIEW_ID = 'VALU_WorkflowTaskDueDatePanelForm';

interface Props {
    /**
     * If true, the WorkflowTaskDueDatePanel is displayed, if false the WorkflowTaskDueDatePanel is hidden.
     */
    isOpen: boolean;
    /**
     * The Id of the ValuationWorkflowTask that will be passed as the primaryKeyValue of the FormScreen.
     */
    workflowTaskId: string;

    /**
     * Callback on click of Cancel button.
     */
    onCancel?: () => void;
    /**
     * Callback on click of Submit and Close button.
     */
    onSubmitAndClose?: () => void;
}

export const WorkflowTaskDueDatePanel = (props: Props): React.ReactElement => {
    const { isOpen, onCancel, onSubmitAndClose, workflowTaskId } = props;

    const queryClient = useQueryClient();
    const dirtinessScope = React.useRef<HTMLDivElement>(null);

    const handleSubmitAndClose = (): void => {
        onSubmitAndClose?.();
        queryClient.invalidateQueries([VALUATION_WORKFLOW_TASKS_KEY]);
    };

    const navigationContentRenderer: IRenderFunction<IPanelProps> = (): React.ReactElement => (
        <PanelHeader
            cancelDisabledOverride={false}
            onCancel={onCancel}
            onSubmitAndClose={handleSubmitAndClose}
            title="Workflow Template"
            dirtinessScope={dirtinessScope}
        />
    );

    return (
        <Panel
            isOpen={isOpen}
            onRenderNavigationContent={navigationContentRenderer}
            type={PanelType.medium}
            layerProps={{ eventBubblingEnabled: true }}
            popupProps={{ ref: dirtinessScope }}
        >
            <FormScreenWrapper
                ServiceDrivenViewSet={ServiceDrivenViewSet}
                GridScreenWrapper={GridScreenWrapper}
                formViewId={WORKFLOW_TASK_DUE_DATE_FORM_VIEW_ID}
                primaryKeyValue={workflowTaskId}
            />
        </Panel>
    );
};

export default WorkflowTaskDueDatePanel;
