import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Router as CustomRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
    LayerWrapper,
    PromptContextProvider,
    ToastMessageContainer,
    WysiwygLicenseProvider,
} from '@samc/react-ui-core';
import { AuthorizationWrapper, useCurrentUser } from '@samc/single-spa-authentication';
import { StrataProvider } from '@samc/filestorage-react';
import { HeaderContextProvider, HistoryProvider } from '@samc/react-ui-history';
import { ClientConfiguration } from '@samc/single-spa-client-configuration';
import { AgGridLicenseProvider } from '@samc/react-ui-grid';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';
import { ThemeProvider, defaultTheme } from '@samc/react-ui-theme';
import { LSThemeWrapper } from '@samc/vmsnext-querybuilder';
import { ApplicationContextProvider } from './contexts/ApplicationContext/ApplicationContext';
import { Router } from './templates/Router/Router';
import { ApiContextProvider } from './contexts/ApiContext';
import { WorkflowTaskValidationPopupContextProvider } from './components';
import type { SingleSpaProps } from './root.component.checks';

const queryClient = new QueryClient();
type RootBaseProps = SingleSpaProps & {
    authToken: string | undefined;
    clientConfiguration: ClientConfiguration;
    jwt: string | undefined;
    tenantName: string;
};

interface AuthorizedRootBaseProps extends Pick<SingleSpaProps, 'browserHistory' | 'hashHistory'> {
    headers: Record<string, string>;
    tenantName: string;
}

export const AuthorizedRootBase = (props: AuthorizedRootBaseProps): React.ReactElement<RootBaseProps> => {
    const { hashHistory, browserHistory, headers, tenantName } = props;

    const [staticRequestInit] = React.useState<RequestInit>({});

    const { id: currentUserIdGuid } = useCurrentUser();
    const [location, setLocation] = React.useState(hashHistory.location);

    React.useEffect(() => {
        return hashHistory.listen((l) => setLocation(l.location));
    }, [hashHistory]);

    // by maintaining a static request init, it never becomes stale
    React.useEffect(() => {
        Object.assign<RequestInit, RequestInit>(staticRequestInit, { headers });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headers]);

    return (
        <QueryClientProvider client={queryClient}>
            <LSThemeWrapper>
                <ThemeProvider style={{ width: '100%', height: '100%' }} value={defaultTheme}>
                    <LayerWrapper>
                        <HistoryProvider value={{ browserHistory, hashHistory }}>
                            <PromptContextProvider>
                                <HeaderContextProvider>
                                    <EvaluatorConfigurationProvider
                                        value={{ currentUserId: currentUserIdGuid.toString() }}
                                    >
                                        <DndProvider backend={HTML5Backend}>
                                            <StrataProvider>
                                                <CustomRouter location={location} navigator={hashHistory}>
                                                    <ApplicationContextProvider>
                                                        <ApiContextProvider
                                                            requestInit={staticRequestInit}
                                                            tenantName={tenantName}
                                                        >
                                                            <WorkflowTaskValidationPopupContextProvider>
                                                                <>
                                                                    <Router />
                                                                    <ToastMessageContainer
                                                                        className="single-spa-valuation"
                                                                        position="top-center"
                                                                        draggable
                                                                        pauseOnHover
                                                                        pauseOnFocusLoss
                                                                        autoClose={4000}
                                                                    />
                                                                </>
                                                            </WorkflowTaskValidationPopupContextProvider>
                                                        </ApiContextProvider>
                                                    </ApplicationContextProvider>
                                                </CustomRouter>
                                            </StrataProvider>
                                        </DndProvider>
                                    </EvaluatorConfigurationProvider>
                                </HeaderContextProvider>
                            </PromptContextProvider>
                        </HistoryProvider>
                    </LayerWrapper>
                </ThemeProvider>
            </LSThemeWrapper>
        </QueryClientProvider>
    );
};

/**
 * Inner Root layer which takes validated parameters from the Check layers and injects them into the component hierarchy
 */
export const RootBase = (props: RootBaseProps): React.ReactElement | null => {
    const { authToken, clientConfiguration, jwt, tenantName } = props;

    const headers = React.useMemo(
        () => ({
            Authorization: `Bearer ${authToken}`,
            'X-Tenant': tenantName,
        }),
        [authToken, tenantName],
    );

    return (
        <AgGridLicenseProvider license={clientConfiguration.agGridLicense}>
            <WysiwygLicenseProvider value={clientConfiguration.tinyMceLicense as string}>
                <AuthorizationWrapper
                    config={{
                        ...clientConfiguration,
                        defaultHeaders: Object.entries(headers).map(([k, v]) => ({ name: k, value: v })),
                    }}
                    jwt={jwt}
                >
                    <AuthorizedRootBase {...props} headers={headers} />
                </AuthorizationWrapper>
            </WysiwygLicenseProvider>
        </AgGridLicenseProvider>
    );
};

export default RootBase;
