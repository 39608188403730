/* istanbul ignore file */
import React from 'react';
import { WorkflowTaskReassignPanel } from '../../organisms/WorkflowTaskReassignPanel/WorkflowTaskReassignPanel';
import { WorkflowResponsibleParticipantRenderer as BaseWorkflowResponsibleParticipantRenderer } from './WorkflowResponsibleParticipantRenderer.Prompt';
import { ValuationApiOverrides, ValuationWorkflowTask } from '../../../valuationApi';

interface Props
    extends Pick<
        ValuationApiOverrides,
        | 'fetchApi'
        | 'useValuationWorkflowTask'
        | 'useValuationWorkflowTaskOwners'
        | 'useValuationWorkflowTaskOwnersSaver'
    > {
    workflowTask: ValuationWorkflowTask;

    displayValue?: string;
}

export const WorkflowResponsibleParticipantRenderer = ({
    displayValue,
    fetchApi,
    workflowTask,
    useValuationWorkflowTask,
    useValuationWorkflowTaskOwners,
    useValuationWorkflowTaskOwnersSaver,
}: Props): React.ReactElement => {
    const [isPanelOpen, setPanelOpen] = React.useState<boolean>(false);

    const closePanel = (): void => {
        setPanelOpen(false);
    };

    const openPanel = (): void => {
        setPanelOpen(true);
    };

    return (
        <>
            <BaseWorkflowResponsibleParticipantRenderer
                displayValue={displayValue}
                onClickReassignTask={openPanel}
                workflowTask={workflowTask}
            />
            {isPanelOpen && (
                <WorkflowTaskReassignPanel
                    fetchApi={fetchApi}
                    isOpen={isPanelOpen}
                    onCancel={closePanel}
                    onSubmitSuccess={closePanel}
                    useValuationWorkflowTask={useValuationWorkflowTask}
                    useValuationWorkflowTaskOwners={useValuationWorkflowTaskOwners}
                    useValuationWorkflowTaskOwnersSaver={useValuationWorkflowTaskOwnersSaver}
                    valuationId={workflowTask.ValuationId}
                    valuationWorkflowTaskId={workflowTask.Id}
                />
            )}
        </>
    );
};

export default WorkflowResponsibleParticipantRenderer;
