import React, { useState } from 'react';
import { ChangeDataArgs, Grid, GridFieldConfiguration, useGridApis } from '@samc/react-ui-grid';
import { ConfirmationDialog, EvaluatedWysiwygViewer } from '@samc/react-ui-core';
import { SectionHeader } from '@samc/react-ui-form';
import { usePicklist } from '@samc/picklist-api';
import { useStyletron } from 'styletron-react';
import { Spinner } from '@fluentui/react';
import { useEvaluator } from '@samc/expressions-react';
import { useApiContext } from '../../../../hooks/useApiContext';
import { updateColumns, getTaskFromCyclePeriod, getValuationFromCycleData } from './ReportCycle.service';
import { Labels, CycleTemplateDataType } from './ReportCycleConstants';
import { GridStateType } from '../AddValuations.interfaces';
import { NavButtons } from '../NavButtons';
import { Valuation, ValuationPicklist } from '../../../static/ValuationConstants';
import { createGuid } from '../../../static/ValuationWizard.utils';
import { ValuationSteps } from '../../WizardProgress/ProgressConstants';

interface Props {
    reportData: GridStateType;
    updateReportData: (params: GridStateType) => void;
    setCurrentStep: (param: number) => void;
    setValuation: (params: Valuation) => void;
    requestParams: Record<string, unknown>;
    cycleTemplateData: CycleTemplateDataType;
}
export const ReportCyclePeriodsGrid = ({
    setCurrentStep,
    reportData,
    updateReportData,
    setValuation,
    requestParams,
    cycleTemplateData,
}: Props): React.ReactElement => {
    const { apis, onGridApiChanged } = useGridApis<Record<string, unknown>>();

    const { data: valuationQuarterData } = usePicklist(ValuationPicklist.ValuationQuarter);
    const { data: sourceTypeData } = usePicklist(ValuationPicklist.Source);
    const { data: valuationMonthData } = usePicklist(ValuationPicklist.ValuationMonth);
    const { data: reportTypeData } = usePicklist(ValuationPicklist.ReportType);
    const { data: scopeData } = usePicklist(ValuationPicklist.Scope);
    const { data: frequencyData } = usePicklist(ValuationPicklist.Frequency);

    const { template, years } = cycleTemplateData;
    const apiContext = useApiContext();
    const { evaluate } = useEvaluator();
    const [showPrompt, togglePrompt] = useState<boolean>(false);

    const [periodColumns, setPeriodColumns] = React.useState<GridFieldConfiguration[]>([]);
    const [frameworkComponents, setFrameworkComponents] = React.useState<Record<string, unknown>>();

    const onChangeData = (args: ChangeDataArgs<Record<string, unknown>>): void =>
        updateReportData({ ...reportData, data: args.data.reduce((a, d) => ({ ...a, [`${d.id}`]: d }), {}) });

    const onBack = (): void => togglePrompt(true);

    const onResponse = (stay: boolean): void => {
        if (!stay) {
            setCurrentStep(ValuationSteps.SetupCycleTemplate);
        }
        togglePrompt(!stay);
    };

    const onContinue = async (): Promise<void> => {
        const tasks = await getTaskFromCyclePeriod(requestParams, apiContext, reportData.data);

        if (tasks?.length) {
            setValuation({
                addTasks: getValuationFromCycleData(
                    tasks,
                    Boolean(requestParams.enableValGroup),
                    requestParams.portfolioTypeId as string,
                    requestParams.valuationAsset as Record<string, unknown>,
                ),
            });
        }

        setCurrentStep(ValuationSteps.SetupValuations);
    };

    const onSkip = (): void => setCurrentStep(ValuationSteps.SetupValuations);

    // share apis upstream
    React.useEffect(() => {
        updateReportData({ ...reportData, apis });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apis]);

    React.useEffect(() => {
        if (
            valuationQuarterData &&
            valuationMonthData &&
            reportTypeData &&
            scopeData &&
            frequencyData &&
            sourceTypeData
        ) {
            updateColumns({
                template,
                years,
                setPeriodData: Object.keys(reportData.data).length
                    ? (): void => undefined
                    : (args: Record<string, unknown>[]): void =>
                          updateReportData({
                              ...reportData,
                              data: args.reduce((a, d) => ({ ...a, [`${d.id || createGuid()}`]: d }), {}),
                          }),
                setPeriodColumns,
                setFrameworkComponents,
                api: apiContext,
                valuationQuarterData,
                valuationMonthData,
                reportTypeData,
                scopeData,
                frequencyData,
                sourceTypeData,
                evaluate,
            });
        }
    }, [valuationQuarterData, valuationMonthData, reportTypeData, scopeData, frequencyData, sourceTypeData]); // eslint-disable-line react-hooks/exhaustive-deps

    const [css] = useStyletron();

    const data = React.useMemo(() => Object.values(reportData.data) as Record<string, unknown>[], [reportData.data]);

    return (
        <>
            <NavButtons
                isDisabled={periodColumns.length === 0}
                onBack={onBack}
                onContinue={onContinue}
                onSkip={onSkip}
            />

            <div className="report-period-grid">
                <SectionHeader title="Report Periods" />

                <div
                    className={css({
                        marginTop: '3px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: 'stretch',
                        borderTop: '1px solid #d1d1d1',
                    })}
                >
                    <EvaluatedWysiwygViewer evaluatedValue={Labels.ENTER_PERIODS_TEXT} />
                </div>

                {!frameworkComponents || periodColumns.length === 0 ? (
                    <Spinner />
                ) : (
                    <Grid
                        onSubmit={onContinue}
                        isEditingDefault
                        suppressEditToggle
                        suppressAddRowButton
                        suppressDeleteButton
                        suppressClearFilter
                        suppressExcelExport
                        suppressRowSpacing
                        suppressDeleteConfirmationModal
                        suppressColumnFilters={false}
                        suppressFullScreen
                        frameworkComponents={frameworkComponents}
                        rowSelection="none"
                        data={data}
                        fields={periodColumns}
                        onChangeData={onChangeData}
                        gridOptions={{
                            suppressPaginationPanel: true,
                        }}
                        stopEditingWhenCellsLoseFocus
                        onGridApiChanged={onGridApiChanged}
                    />
                )}

                {showPrompt && (
                    <ConfirmationDialog
                        giveAnswer={onResponse}
                        detail="Moving back to the previous step in the wizard will remove any changes made on this screen. Please confirm you would like to go back."
                        confirmText="Stay on this screen"
                        cancelText="Go Back"
                        title="Unsaved Changes"
                    />
                )}
            </div>
        </>
    );
};

export default ReportCyclePeriodsGrid;
