import { UseQueryResult, useQuery } from 'react-query';
import { WorkflowStatus } from '../models/WorkflowStatus';
import { useApiContext } from '../../hooks/useApiContext';
import { getValuationWorkflowStatus } from '../api/Requests';

export const VALUATION_WORKFLOW_STATUS_KEY = 'ValuationWorkflowStatus';

export const useValuationWorkflowStatus = (valuationId?: string): UseQueryResult<WorkflowStatus | undefined> => {
    const apiContext = useApiContext();

    const queryFn = (): Promise<WorkflowStatus | undefined> =>
        getValuationWorkflowStatus(apiContext.ValuationApi, valuationId, apiContext.requestInit);

    const queryResult = useQuery([VALUATION_WORKFLOW_STATUS_KEY, valuationId], queryFn, {
        retry: 2,
        refetchOnWindowFocus: false,
    });

    return queryResult;
};

export default useValuationWorkflowStatus;
