import React, { useEffect, useState } from 'react';
import { TooltipHost } from '@fluentui/react';
import { Button, ErrorList } from '@samc/react-ui-core';
import {
    DomainNameMap,
    TaskResponsePopup,
} from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { TaskResponse } from '@samc/screen-config-api';
import { useStyletron } from 'styletron-react';
import { DefaultValidationResponse, ValidationResponse } from './ValidationUtils';

interface ControlButtonsProps {
    onBack?: () => void;
    onContinue: () => void;
    isDisabled: boolean;
    continueLabel?: string;
    retrieveDefaults?: () => void;
    onSkip?: () => void;
    validationResponse?: ValidationResponse;
    updateValidationResponse?: (params: ValidationResponse) => void;
    domainNameMap?: DomainNameMap;
    onSetDefaultContacts?: () => void;
    submitWithOverrides?: (params: string[]) => Promise<void> | void;
    children?: React.ReactNode;
}

export const NavButtons: React.FC<ControlButtonsProps> = ({
    onBack,
    onContinue,
    isDisabled = true,
    continueLabel = 'Continue',
    retrieveDefaults,
    onSkip,
    onSetDefaultContacts,
    validationResponse = { ...DefaultValidationResponse },
    updateValidationResponse = (): void => undefined,
    domainNameMap = {},
    submitWithOverrides,
    children,
}: ControlButtonsProps): React.ReactElement => {
    const [visible, setVisible] = useState(false);
    const { request } = validationResponse;
    const requests = React.useMemo(() => [request], [request]);
    const responses = React.useMemo(() => [validationResponse as TaskResponse], [validationResponse]);

    useEffect(() => {
        setVisible(
            validationResponse.messages.length > 0 ||
                Object.values(validationResponse.childResponses).some(({ messages }) => messages.length > 0),
        );
    }, [validationResponse.childResponses, validationResponse.messages.length]);

    const setTaskResponseVisible = (): void => {
        updateValidationResponse({ ...validationResponse, messages: [] });
        setVisible(false);
    };

    const [css] = useStyletron();
    const buttonClass = css({
        minWidth: 'max-content',
        marginRight: '5px',
    });

    return (
        <div className="control-button-box">
            {children}
            {validationResponse.showList && <ErrorList errors={validationResponse.list} />}

            {onBack && <Button buttonType="secondary" text="Back" onClick={onBack} className={buttonClass} />}

            {retrieveDefaults && (
                <TooltipHost content="Coming Soon">
                    <Button
                        buttonType="secondary"
                        disabled
                        text="Retrieve Valuation Defaults"
                        className={buttonClass}
                    />
                </TooltipHost>
            )}

            {onSetDefaultContacts && (
                <TooltipHost content="Each Valuation's Workflow has a Settings Screen managed by the administrative team. In the Workflow's Template, Admins can select whether a role on the Valuation is prepopulated with a user who is playing another role on the Valuation's related Asset(s). (Ex: The Primary Asset Manager is automatically a Reviewer; Portfolio Manager is the Portfolio Acknowledger.)  If defaults are included in the template, clicking this button will pull those default contacts into the Valuation Roles below. They can then be adjusted manually. Note: Any already selected users will be cleared out if defaults are pulled in.  Also, if the Admin did not select defaults for a role, nothing will be automatically pulled in for that Valuation Role. Contact your Administrator if the defaults being pulled in are inefficient or incorrect.">
                    <Button
                        buttonType="primary"
                        text="Set Default Contacts"
                        onClick={onSetDefaultContacts}
                        className={buttonClass}
                    />
                </TooltipHost>
            )}

            {onSkip && <Button buttonType="primary" text="Skip" onClick={onSkip} className={buttonClass} />}

            <Button
                buttonType="primary"
                text={continueLabel}
                onClick={onContinue}
                disabled={isDisabled}
                className={buttonClass}
            />

            <TaskResponsePopup
                requests={requests}
                responses={responses}
                visible={visible}
                setVisible={setTaskResponseVisible}
                domainNameMap={domainNameMap}
                submitWithOverrides={submitWithOverrides}
            />
        </div>
    );
};

export default NavButtons;
