import React from 'react';
import { TaskRequest, TaskResponse, TaskStatus } from '@samc/screen-config-api';
import { useMutation, UseMutationResult } from 'react-query';
import { submitTask } from '../api/Requests';
import { useApiContext } from '../../hooks/useApiContext';

/**
 * This is a hook which returns a UseMutationResult to submit any TaskRequest. Many of our hooks which submit tasks provide a simplified
 * interface for the caller, this one is intentionally much more broad so as to allow any TaskRequest.
 */
export const useTaskSubmitter = (): [
    UseMutationResult<[TaskResponse, TaskRequest], unknown, TaskRequest<Record<string, unknown>>, unknown>,
] => {
    const api = useApiContext();

    const onSave = React.useCallback(
        async (taskRequest: TaskRequest<Record<string, unknown>>): Promise<[TaskResponse, TaskRequest]> => {
            return submitTask(
                api.TaskApi,
                taskRequest.domainId,
                taskRequest.fieldConfigurationId as string,
                taskRequest.requestIdentifier,
                taskRequest.payload,
                api.requestInit ?? {},
                false,
                api.FetchApi,
            );
        },
        [api.FetchApi, api.TaskApi, api.requestInit],
    );

    const saveMutation = useMutation<[TaskResponse, TaskRequest], unknown, TaskRequest<Record<string, unknown>>>(
        onSave,
        {
            onSuccess: (data: [TaskResponse, TaskRequest<Record<string, unknown>>]) => {
                const [result] = data;

                if (result.statusCode === TaskStatus.Completed && result.messageCount === 0) {
                    api.ShowMessage(`Task completed successfully`, 'success');
                } else {
                    api.ShowMessage(`Failed to complete Task`, 'error');
                }
            },
            onError: (e) => {
                throw e;
            },
        },
    );

    return [saveMutation];
};

export default useTaskSubmitter;
