import React from 'react';
import { Loader } from '@samc/react-ui-core';
import { ThemedComponent, useThemeContext } from '@samc/react-ui-theme';
import { styled } from 'styletron-react';

interface Props {
    isLoading: boolean;
    isError: boolean;
    children: React.ReactElement;
}

/* istanbul ignore next */
const StyledContainer = styled('div', ({ theme }: ThemedComponent) => ({
    padding: '0.5em',
    backgroundColor: 'white',
    marginRight: '0.5em',
    borderRadius: '4px',
    border: `1px solid ${theme.FormColors.fieldColors.default.border.toString()}`,
    ':hover': {
        border: `1px solid ${theme.FormColors.fieldColors.default.hovered?.border.toString()}`,
    },
}));

export const ControllerBarBox = (props: Props): React.ReactElement => {
    const { isLoading, isError, children } = props;
    const theme = useThemeContext();
    return (
        <StyledContainer theme={theme}>
            <Loader isLoading={isLoading} isError={isError}>
                {children}
            </Loader>
        </StyledContainer>
    );
};

export default ControllerBarBox;
