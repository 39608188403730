import { styled } from 'styletron-react';

export const HR = styled('hr', {
    height: '1px',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
});

export const LABEL = styled('label', {
    display: 'block',
    marginTop: '1rem',
});

export const container = styled('div', {});
