import {
    MessageType,
    TaskMessage,
    TaskMessageCode,
    TaskRequest,
    TaskResponse,
    TaskStatus,
} from '@samc/screen-config-api';
import { useWorkflowTaskValidationPopupContext as _useWorkflowTaskValidationPopupContext } from '../../contexts/WorkflowTaskValidationPopupContext';

interface Props {
    useWorkflowTaskValidationPopupContext?: typeof _useWorkflowTaskValidationPopupContext;
}

export const useValidationMessage = ({
    useWorkflowTaskValidationPopupContext = _useWorkflowTaskValidationPopupContext,
}: Props): [showMessage: (applicableRecord: string, message: string, fields: string[]) => void] => {
    const { setValidationRequest, setValidationResponse, setVisible } = useWorkflowTaskValidationPopupContext();

    const showMessage = (applicableRecord: string, message: string, fields: string[]): void => {
        const taskRecord: Record<string, unknown> = {
            Name: applicableRecord,
        };

        const taskRequest: TaskRequest<Record<string, unknown>> = {
            payload: {
                '-1': taskRecord,
            },
            domainId: 'VALU_ValuationWorkflowTask',
            requestIdentifier: 'Parent Form',
        };

        const taskMessage: TaskMessage = {
            code: TaskMessageCode.Error,
            title: message,
            description: message,
            type: MessageType.Error,
            fields,
            stackTrace: undefined,
            index: 0,
            overridable: false,
            recordIdentifier: '-1',
        };

        const taskResponse: TaskResponse = {
            statusCode: TaskStatus.Failed,
            messageCount: 1,
            requestIdentifier: taskRequest.requestIdentifier,
            domainId: taskRequest.domainId,
            messages: [taskMessage],
            prompts: [],
            childResponses: {},
            updatedIds: [],
            newIds: [],
            deletedIds: [],
        };

        setValidationRequest(taskRequest);
        setValidationResponse(taskResponse);
        setVisible(true);
    };

    return [showMessage];
};

export default useValidationMessage;
