import React from 'react';
import { ConfirmationDialog } from '@samc/react-ui-core';
import { BaseButton, Button } from '@fluentui/react';
import { useResendNotification as _useResendNotification } from '../../../valuationApi/useResendNotification/useResendNotification';
import { WorkflowResponsibleParticipantRenderer as BaseWorkflowResponsibleParticipantRenderer } from './WorkflowResponsibleParticipantRenderer.Cell';
import { ValuationWorkflowTask } from '../../../valuationApi';

interface Props {
    workflowTask: ValuationWorkflowTask;

    displayValue?: string;
    onClickReassignTask?: React.MouseEventHandler<
        HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement | BaseButton | Button
    >;
    useResendNotification?: typeof _useResendNotification;
}

export const WorkflowResponsibleParticipantRenderer = ({
    displayValue,
    onClickReassignTask,
    useResendNotification = _useResendNotification,
    workflowTask,
}: Props): React.ReactElement => {
    const [isPromptOpen, setPromptOpen] = React.useState<boolean>(false);

    const openPrompt = (): void => {
        setPromptOpen(true);
    };

    const resendNotification = useResendNotification();

    const giveAnswer = async (value: boolean): Promise<void> => {
        if (value) {
            await resendNotification(workflowTask.Id);
        }

        setPromptOpen(false);
    };

    return (
        <>
            <BaseWorkflowResponsibleParticipantRenderer
                displayValue={displayValue}
                onClickReassignTask={onClickReassignTask}
                onClickResendNotification={openPrompt}
            />
            {isPromptOpen && (
                <ConfirmationDialog
                    title="Resend Notification"
                    detail="Do you want to resend the selected notification?"
                    giveAnswer={giveAnswer}
                />
            )}
        </>
    );
};

export default WorkflowResponsibleParticipantRenderer;
