import React from 'react';
import { useLocation, useParams, generatePath, useNavigate, NavigateOptions, createPath } from 'react-router-dom';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { ErrorBoundary, SidePanelWrapper, useDirtinessPrompt } from '@samc/react-ui-core';
import { useStyletron } from 'styletron-react';
import { SelectAndShow } from '@samc/react-ui-form';
import { HeaderContextProvider } from '@samc/react-ui-history';
import type { FormScreenState } from '@samc/screen-config-core/lib/model/FormScreenState/FormScreenState';
import { FormTabChangedEvent } from '@samc/react-ui-form/lib/forms/FormRenderer/FormRenderer';
import { ScreenConfigFormTab } from '@samc/screen-config-core/lib/model/ScreenConfigFormSkeleton/ScreenConfigFormSkeleton';
import { useSelectAndShowURLSearchParams } from '@samc/screen-config-core/lib/hooks/useSelectAndShowURLSearchParams/useSelectAndShowURLSearchParams';
import { useApplicationContext } from '../../../hooks/useApplicationContext/useApplicationContext';
import { EsInputMenuOption, useValuation } from '../../../valuationApi';
import { EsInputMenu, EsInputMenuSelectEvent } from '../../molecules/EsInputMenu/EsInputMenu';
import { VALU_AssetInformation } from '../../static/ValuationConstants';
import { decodeBase64ToObject } from '../../../util';
import ComparisonGrid2, { isDataHCROrVCR } from '../../molecules/ComparisonGrid/ComparisonGrid2';
import { FieldDifferenceWrapper, URL_PARAMNAME_DEFAULTS } from '../FieldDifference/FieldDifference.Wrapper';

interface Props {
    params: CustomScreenParams;

    esInputDataId?: string | null;
    onFormReset?: () => void;
}

export const ESINPUT_SIDE_PANEL_FORMVIEW_ID = 'VALU_EsInputSidePanelForm';
export const ESInputAssetFieldDifferenceView = 'ESInputAssetFieldDifferenceView';

interface EsInputTabUrlParams extends Record<string, string | undefined> {
    id: string;
    tabId: string;
    esInputMenuItem: string | undefined;
    subtabId: string | undefined;
}

export const EsInputTab = (props: Props): JSX.Element => {
    const { esInputDataId, params, onFormReset } = props;
    const { primaryKeyValue, filters: _inputFilters } = params;

    const { EsInputDataDomainId, EsInputPathFormat } = useApplicationContext();

    const urlParams = useParams() as EsInputTabUrlParams;
    const _navigate = useNavigate();
    const location = useLocation();
    const [_, __, deleteAllSelectAndShowURLSearchParams] = useSelectAndShowURLSearchParams();
    const valuationRequest = useValuation(primaryKeyValue as string);

    const [css] = useStyletron();
    const { promptAndContinue } = useDirtinessPrompt({ scope: document.body });

    const [currentMenuItem, setCurrentMenuItem] = React.useState<EsInputMenuOption>();
    const [valuationStatus, setValuationStatus] = React.useState(false);
    const [formData, _setFormData] = React.useState<object>();

    const resetInProgress = React.useRef(false);
    const mainFormState = React.useRef<FormScreenState>();
    const sidebarFormState = React.useRef<FormScreenState>();

    const encodedDefaultValuesJson = React.useMemo(() => {
        const defaultSearchParams = new URLSearchParams(location.search);
        return defaultSearchParams.get(URL_PARAMNAME_DEFAULTS);
    }, [location.search]);

    const defaultValues = React.useMemo(() => {
        if (!encodedDefaultValuesJson || !encodedDefaultValuesJson.length) {
            return undefined;
        }
        try {
            return decodeBase64ToObject<Record<string, unknown>>(encodedDefaultValuesJson);
        } catch {
            console.error(`Unable to decode or parse default data '${encodedDefaultValuesJson}'.`); // eslint-disable-line no-console
            return undefined;
        }
    }, [encodedDefaultValuesJson]);

    const filters = React.useMemo(() => {
        const output: string[] = [...(_inputFilters ?? [])];
        if (output.indexOf('[IsLatestVersion]=1') < 0) {
            output.push('[IsLatestVersion]=1');
        }

        return output;
    }, [_inputFilters]);

    const navigate = React.useCallback(
        (
            paramUpdates: Pick<Partial<EsInputTabUrlParams>, 'subtabId' | 'esInputMenuItem'>,
            options?: NavigateOptions,
        ): void => {
            const { subtabId, esInputMenuItem, ...rest } = urlParams;

            const pathParams = {
                ...rest,
                subtabId: subtabId ?? null,
                esInputMenuItem: esInputMenuItem ?? null,
                ...(paramUpdates.esInputMenuItem && {
                    // if menu item changes, should clear the subtab as the form view will change
                    subtabId: undefined,
                }),
                ...paramUpdates,
            };

            const newPath = generatePath(
                EsInputPathFormat,
                // must uri-encode all
                Object.entries(pathParams).reduce(
                    (all, [attr, val]) => (val ? { ...all, [attr]: encodeURIComponent(val) } : all),
                    pathParams,
                ),
            );

            _navigate(
                createPath({
                    pathname: newPath,
                    search: location.search,
                    hash: location.hash,
                }),
                options,
            );
        },
        [_navigate, location.hash, location.search, urlParams],
    );

    const onSSSelectionChange = React.useCallback(
        async (id: string | number, ss: SelectAndShow) => {
            if (!id) return false;

            if (ss.id !== EsInputDataDomainId) return true;

            if (esInputDataId === String(id)) return true; // same value

            try {
                await promptAndContinue();
                return true;
            } catch (e) {
                return false;
            }
        },
        [esInputDataId, promptAndContinue],
    );

    const onEsInputMenuSelectChange = React.useCallback(
        async (ev: EsInputMenuSelectEvent) => {
            const { option: newSelectedOption, userRequested } = ev;

            if (!newSelectedOption) return;
            if (newSelectedOption.id === urlParams.esInputMenuItem) return;

            try {
                await promptAndContinue(() =>
                    navigate(
                        {
                            esInputMenuItem: newSelectedOption.id,
                        },
                        {
                            replace: !userRequested,
                        },
                    ),
                );
            } catch {
                // ignore error
            }
        },
        [urlParams.esInputMenuItem, promptAndContinue, navigate],
    );

    const beforeSidebarReset = React.useCallback(
        ({ requestIdentifiers }: { requestIdentifiers?: string[] }): void => {
            const mainForm = mainFormState.current;
            if (requestIdentifiers || resetInProgress.current || !mainForm) return; // only when entire form is being reset

            // use resetInProgress to avoid infinite reset loops
            resetInProgress.current = true;

            // This gets blocked by userRouterBlocker...
            deleteAllSelectAndShowURLSearchParams();

            mainForm.formApi.resetForm().finally(() => {
                resetInProgress.current = false;
                onFormReset?.();
            });
        },
        [deleteAllSelectAndShowURLSearchParams],
    );

    const sidebarComponent = primaryKeyValue ? (
        <div className={css({ overflow: 'auto', maxHeight: '100%' })}>
            <HeaderContextProvider>
                <FormScreenWrapper
                    onFormStateChange={(fs): void => {
                        sidebarFormState.current = fs;
                    }}
                    formViewId={ESINPUT_SIDE_PANEL_FORMVIEW_ID}
                    filters={filters}
                    primaryKeyValue={primaryKeyValue}
                    onSelectAndShowSelectionChange={onSSSelectionChange}
                    beforeReset={beforeSidebarReset}
                    selectAndShowOptionOverrides={{
                        beforeReset: beforeSidebarReset,
                    }}
                    ServiceDrivenViewSet={ServiceDrivenViewSet}
                    GridScreenWrapper={GridScreenWrapper}
                />
            </HeaderContextProvider>
            {esInputDataId && (
                <EsInputMenu
                    esInputDataId={esInputDataId}
                    onSelect={onEsInputMenuSelectChange}
                    selectedKey={urlParams.esInputMenuItem}
                    afterSelection={setCurrentMenuItem}
                />
            )}
        </div>
    ) : (
        <MessageBar messageBarType={MessageBarType.info}>Select a valuation</MessageBar>
    );

    const onTabChanged = React.useCallback(
        (newTabName: string, ev: FormTabChangedEvent<ScreenConfigFormTab>): void => {
            const { userRequested } = ev;

            navigate(
                {
                    subtabId: newTabName,
                },
                {
                    replace: !userRequested,
                },
            );
        },
        [navigate],
    );

    const beforeMainFormReset = React.useCallback(({ requestIdentifiers }: { requestIdentifiers?: string[] }): void => {
        const sideForm = sidebarFormState.current;
        if (requestIdentifiers || resetInProgress.current || !sideForm) return; // only when entire form is being reset

        // use resetInProgress to avoid infinite reset loops
        resetInProgress.current = true;
        sideForm.formApi.resetForm().finally(() => {
            resetInProgress.current = false;
        });
    }, []);

    // keep validation status state up-to-date
    React.useEffect(() => {
        if (valuationRequest.data?.Data) {
            const statusId = String(valuationRequest.data?.Data[0].StatusId).toLowerCase();
            if (statusId === 'active' || statusId === 'archived') {
                setValuationStatus(true);
            } else {
                setValuationStatus(false);
            }
        }
    }, [valuationRequest.data?.Data]);

    // for type checking purposes
    const _hcrVcrCheckObj = { viewSetId: currentMenuItem?.viewSetId, formData };

    return (
        <ErrorBoundary>
            <SidePanelWrapper
                sidePanelProps={{
                    className: css({ overflow: 'auto' }),
                    children: sidebarComponent,
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        right: '4%',
                        marginTop: '15px',
                        color: '#62a7d9',
                        cursor: 'pointer',
                        fontWeight: '600',
                        textDecoration: 'underline',
                    }}
                >
                    {(defaultValues?.Portfolio_DisplayAssetValuationDifferenceCount as boolean) &&
                        currentMenuItem?.viewSetId === VALU_AssetInformation &&
                        esInputDataId && <FieldDifferenceWrapper esInputDataId={esInputDataId} params={params} />}
                </div>

                {!valuationStatus && (
                    <MessageBar messageBarType={MessageBarType.info}>
                        ES Input data cannot be entered if the Valuation Status is Scheduled or On Hold. Activate the
                        Valuation to start entering ES Input Data.
                    </MessageBar>
                )}
                {valuationStatus && !esInputDataId && (
                    <MessageBar messageBarType={MessageBarType.info}>Select an asset</MessageBar>
                )}
                {valuationStatus && !currentMenuItem?.formViewId && (
                    <MessageBar messageBarType={MessageBarType.error}>No formview selected</MessageBar>
                )}
                {valuationStatus && currentMenuItem?.formViewId && currentMenuItem.primaryKeyId && (
                    <div className={css({ height: '100%', overflow: 'auto' })}>
                        <div className={css({ clear: 'both' })}>
                            <FormScreenWrapper
                                key={`${currentMenuItem.formViewId}_${currentMenuItem.primaryKeyId}`}
                                primaryKeyValue={currentMenuItem.primaryKeyId}
                                formViewId={currentMenuItem.formViewId}
                                filters={filters}
                                initialTabIndexOrName={urlParams.subtabId}
                                onTabChanged={onTabChanged}
                                onFormStateChange={(fs): void => {
                                    mainFormState.current = fs;
                                    _setFormData(fs.data); // this allows initial grid population, vs onChange
                                }}
                                beforeReset={beforeMainFormReset}
                                ServiceDrivenViewSet={ServiceDrivenViewSet}
                                GridScreenWrapper={GridScreenWrapper}
                            />
                        </div>

                        {isDataHCROrVCR(_hcrVcrCheckObj) && (
                            <ComparisonGrid2
                                viewSetId={_hcrVcrCheckObj.viewSetId}
                                key={currentMenuItem.viewSetId}
                                formData={_hcrVcrCheckObj.formData}
                            />
                        )}
                    </div>
                )}
            </SidePanelWrapper>
        </ErrorBoundary>
    );
};

export default EsInputTab;
