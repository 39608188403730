import React from 'react';
import { PicklistCellRendererProps } from '@samc/picklist-core';
import {
    fetchApi as _fetchApi,
    useValuationWorkflowTask as _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners as _useValuationWorkflowTaskOwners,
    ValuationApiOverrides,
} from '../../../valuationApi';
import { WorkflowResponsibleParticipantRenderer } from '../../cell-renderers/WorkflowResponsibleParticipantRenderer/WorkflowResponsibleParticipantRenderer';

export const useWorkflowParticipantRenderer = ({
    fetchApi = _fetchApi,
    useValuationWorkflowTask = _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners = _useValuationWorkflowTaskOwners,
}: Pick<
    ValuationApiOverrides,
    'fetchApi' | 'useValuationContacts' | 'useValuationWorkflowTask' | 'useValuationWorkflowTaskOwners'
>): ((props: PicklistCellRendererProps) => React.ReactElement) => {
    const workflowResponsibleParticipantRenderFunction = React.useCallback(
        (props: PicklistCellRendererProps, displayValue?: string): React.ReactElement => {
            const { node } = props;

            return (
                <WorkflowResponsibleParticipantRenderer
                    displayValue={displayValue}
                    node={node}
                    fetchApi={fetchApi}
                    useValuationWorkflowTask={useValuationWorkflowTask}
                    useValuationWorkflowTaskOwners={useValuationWorkflowTaskOwners}
                />
            );
        },
        [fetchApi, useValuationWorkflowTask, useValuationWorkflowTaskOwners],
    );

    return workflowResponsibleParticipantRenderFunction;
};

export default useWorkflowParticipantRenderer;
