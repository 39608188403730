import { toastError, ToastMessage, toastSuccess } from '@samc/react-ui-core';
import { TaskStatus, useApiContext } from '@samc/screen-config-api';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AddTask, Valuation, ValuationDomains } from '../../../static/ValuationConstants';
import { BulkValuationSteps, Steps, ValuationSteps } from '../../WizardProgress/ProgressConstants';
import { SubmitValuations } from '../CreateValuationService';
import { HybridGrid } from '../HybridGrid';
import { NavButtons } from '../NavButtons';
import { DefaultValidationResponse, ValidationResponse, getValidationResponse } from '../ValidationUtils';
import { getDefaultViewFromPortfolio } from '../AddValuationWizard.service';
import NavLoader from '../../../atoms/NavLoader/NavLoader';

export const CreateValuations = ({
    valuation,
    setCurrentStep,
    setWizardisDirty,
    isBulk = false,
}: {
    valuation: Valuation;
    setCurrentStep: (params: Steps) => void;
    setWizardisDirty: (params: boolean) => void;
    isBulk?: boolean;
}): React.ReactElement => {
    const [valuationData, setValuationData] = useState<AddTask[]>(valuation.addTasks ? valuation.addTasks : []);
    const [validationResponse, setValidationResponse] = useState<ValidationResponse>({ ...DefaultValidationResponse });
    const [disableContinueButton, toggleDisabled] = useState<boolean>(false);
    const [overrideCodes, setOverrideCodes] = useState<string[]>();
    const [isLoading, setLoading] = useState<boolean>(false);

    const portfolioId = valuation.addTasks ? valuation.addTasks[0].PortfolioId : '';

    const api = useApiContext();
    const navigate = useNavigate();

    const generateData = (assets: AddTask[]): void => {
        const data = assets;

        for (let index = 0; index < data.length; index++) {
            const obj = data[index];
            const assetPrimaryMethodology: string[] = [];
            data[index].Id = data[index].ValuationUniqId;
            if (obj.ValuationAsset && obj.ValuationAsset.length) {
                if (obj.ValuationAssetMethodologySetup && obj.ValuationAssetMethodologySetup.length) {
                    for (let j = 0; j < obj.ValuationAssetMethodologySetup.length; j++) {
                        const objMethodologies = obj.ValuationAssetMethodologySetup[j];

                        if (
                            objMethodologies.ValuationAssetMethodologies &&
                            objMethodologies.ValuationAssetMethodologies.length
                        ) {
                            for (let k = 0; k < objMethodologies.ValuationAssetMethodologies.length; k++) {
                                const objPrimaryMethodologies = objMethodologies.ValuationAssetMethodologies[k];

                                if (objPrimaryMethodologies.Primary) {
                                    if (
                                        !assetPrimaryMethodology.includes(
                                            String(objPrimaryMethodologies.CombinationName),
                                        )
                                    ) {
                                        assetPrimaryMethodology.push(String(objPrimaryMethodologies.CombinationName));
                                    }
                                }
                            }
                        }
                    }
                }
            }
            const [methodology] = assetPrimaryMethodology;
            if (assetPrimaryMethodology.length === 0) obj.PrimaryMethodology = '';
            else if (assetPrimaryMethodology.length === 1) obj.PrimaryMethodology = methodology;
            else obj.PrimaryMethodology = 'Various';
        }

        setValuationData(data);
    };

    useEffect(() => {
        if (valuation.addTasks) generateData(valuation.addTasks);
    }, [valuation.addTasks]);

    const onBack = (): void =>
        setCurrentStep(isBulk ? BulkValuationSteps.ConfirmMethodologies : ValuationSteps.ConfirmMethodologies);

    const onContinue = useCallback((): void => {
        setLoading(true);
        toggleDisabled(true);

        const showError = (message = 'Something went wrong'): void => {
            toastError(<ToastMessage title="Error" message={message} />);
            setWizardisDirty(false); // Wizard can be exited at this point
            setLoading(false);
        };

        const valuationObject: Valuation = { ...valuation };

        /* eslint-disable no-param-reassign */
        if (valuationObject && valuationObject.addTasks && valuationObject.addTasks.length) {
            valuationObject.addTasks.forEach((task) => {
                task.ValuationAssetMethodologySetup?.forEach((assetMethodology) => {
                    delete assetMethodology.ValuationId;
                    delete assetMethodology.ValuationGroupId;
                    delete assetMethodology.VAMethodologySetupId;
                    assetMethodology.ValuationAssetMethodologies?.forEach((methodology) => {
                        delete methodology.VAMethodologiesId;
                    });
                    assetMethodology.ValuationAssetOtherDataScreens?.forEach((otherData) => {
                        delete otherData.VAOtherDataScreensId;
                    });
                    assetMethodology.ValuationAssetOtherValues?.forEach((otherValue) => {
                        delete otherValue.VAOtherValuesId;
                    });
                });
            });
        }
        /* eslint-enable no-param-reassign */

        if (api.requestInit) {
            SubmitValuations(valuationObject, api.TaskApi ?? '', api.requestInit, overrideCodes)
                .then(([response, request]) => {
                    setLoading(false);

                    if (response.statusCode !== TaskStatus.Completed) {
                        setValidationResponse(getValidationResponse(request, response));
                    } else {
                        toastSuccess(<ToastMessage title="Success" message="Valuation Created Successfully" />, {
                            onClose: () => {
                                navigate(-1);
                            },
                        });
                    }
                    setWizardisDirty(false); // Wizard can be exited at this point
                })
                .catch(showError);
        }
    }, [api.TaskApi, api.requestInit, navigate, overrideCodes, setWizardisDirty, valuation]);

    useEffect(() => {
        if (overrideCodes?.length) {
            onContinue();
        }
    }, [onContinue, overrideCodes?.length]);

    const onSubmitWithOverrides = (codes: string[]): void =>
        setOverrideCodes(codes && codes.length ? codes : undefined);

    const viewId = getDefaultViewFromPortfolio('WizardAddValuation', 'CreateValuationView');

    return (
        <>
            <NavButtons
                isDisabled={disableContinueButton}
                validationResponse={validationResponse}
                updateValidationResponse={setValidationResponse}
                onBack={onBack}
                onContinue={onContinue}
                continueLabel="Create Valuation"
                domainNameMap={{ VALU_Valuation: 'ValuationName' }}
                submitWithOverrides={onSubmitWithOverrides}
            >
                <NavLoader isLoading={isLoading} label="Creating Valuation(s)..." />
            </NavButtons>

            <div style={{ display: 'flex', flex: '1 0 auto', flexDirection: 'column', width: '100%' }}>
                <HybridGrid
                    gridData={valuationData as unknown as Record<string, unknown>[]}
                    domainId={ValuationDomains.Valuation}
                    listViewID={viewId}
                    gridOptions={{
                        defaultRowSpacing: 'normal',
                        isEditingDefault: false,
                        rowSelection: 'multiple',
                        rowsPerPage: 500,
                        suppressAddRowButton: true,
                        suppressColumnFilters: true,
                        suppressDeleteButton: true,
                        suppressDeleteConfirmationModal: true,
                        suppressEditToggle: true,
                        suppressFullScreen: false,
                        suppressRowSpacing: true,
                        suppressViewSelector: false,
                        suppressRefresh: true,
                        suppressExcelExport: true,
                    }}
                    errors={validationResponse.list}
                    scope={`CreateValuations_${portfolioId}`}
                />
            </div>
        </>
    );
};

export default CreateValuations;
