/* istanbul ignore file */
import React from 'react';
import {
    Field,
    FieldLabel,
    FieldRoot,
    FormSkeleton,
    InputType,
    SectionHeader,
    useFormManagementContext as _useFormManagementContext,
} from '@samc/react-ui-form';
import { styled } from 'styletron-react';
import { MessageBar } from '@samc/react-ui-core';
import { WorkflowContactGridAssignTo } from '../WorkflowContactGridAssignTo/WorkflowContactGridAssignTo';
import { WorkflowContactGridEnabledFor } from '../WorkflowContactGridEnabledFor/WorkflowContactGridEnabledFor';
import { WorkflowContactGridViewableBy } from '../WorkflowContactGridViewableBy/WorkflowContactGridViewableBy';

const formConfig: FormSkeleton = {
    title: 'Workflow Ad-Hoc Task Form',
    fields: {
        name: {
            field: 'Name',
            inputType: InputType.Text,
            editableRule: 'true',
            displayNameRule: "'Task Name'",
            requiredFieldRule: 'true',
        },
        description: {
            field: 'Description',
            inputType: InputType.MultilineText,
            editableRule: 'true',
            displayNameRule: "'Description'",
            requiredFieldRule: 'true',
        },
        dueDate: {
            field: 'DueDate',
            inputType: InputType.Date,
            editableRule: 'true',
            displayNameRule: "'Due Date'",
        },
    },
    gridFields: [],
    tabs: [],
};

/* istanbul ignore next */
const StyledFlexWrapper = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
}));

/* istanbul ignore next */
const StyledFieldLabelWrapper = styled('div', () => ({
    minWidth: '30ch',
    maxWidth: '30ch',
    display: 'flex',
    overflow: 'hidden',
    paddingRight: '4px',
}));

/* istanbul ignore next */
const StyledGridWrapper = styled('div', () => ({
    flex: '1 0 auto',
}));

/* istanbul ignore next */
const StyledFieldRoot = styled(FieldRoot, () => ({
    flex: '1',
    maxWidth: '100%',
    minWidth: '0px',
    width: '100%',
}));

/* istanbul ignore next */
const StyledFieldLabel = styled(FieldLabel, () => ({
    marginTop: 'unset',
}));

/* istanbul ignore next */
const StyledSectionWrapper = styled('div', () => ({
    marginTop: '5px',
    paddingTop: '10px',
}));

/* istanbul ignore next */
const StyledFieldWrapper = styled('div', () => ({
    borderTop: '1px solid #d1d1d1',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    paddingTop: '10px',
}));

interface Props {
    /**
     * The Id of the Valuation for which ValuationContacts will be fetched.
     */
    valuationId: string;

    /**
     * DI override of the hook to get the FormApi.
     */
    useFormManagementContext?: typeof _useFormManagementContext;
}

export const WorkflowAdHocTaskForm = ({
    useFormManagementContext = _useFormManagementContext,
    valuationId,
}: Props): React.ReactElement => {
    const { setConfig } = useFormManagementContext();

    React.useEffect(() => {
        setConfig(formConfig);
    }, [setConfig]);

    return (
        <StyledFlexWrapper>
            <StyledSectionWrapper>
                <SectionHeader title="Task Information" />
                <StyledFieldWrapper>
                    <Field Id="name" />
                    <Field Id="description" />
                    <Field Id="dueDate" />
                </StyledFieldWrapper>
            </StyledSectionWrapper>
            <StyledSectionWrapper>
                <SectionHeader title="Responsible Valuation Contact" />
                <StyledFieldWrapper>
                    <MessageBar text="The Valuation Admin is always enabled for ad-hoc tasks." />
                    <StyledFieldRoot>
                        <StyledFieldLabelWrapper>
                            <StyledFieldLabel labelText="Assign To" />
                        </StyledFieldLabelWrapper>
                        <StyledGridWrapper>
                            <WorkflowContactGridAssignTo valuationId={valuationId} />
                        </StyledGridWrapper>
                    </StyledFieldRoot>
                    <StyledFieldRoot>
                        <StyledFieldLabelWrapper>
                            <StyledFieldLabel labelText="Enabled For" />
                        </StyledFieldLabelWrapper>
                        <StyledGridWrapper>
                            <WorkflowContactGridEnabledFor valuationId={valuationId} />
                        </StyledGridWrapper>
                    </StyledFieldRoot>
                    <StyledFieldRoot>
                        <StyledFieldLabelWrapper>
                            <StyledFieldLabel labelText="Viewable By" />
                        </StyledFieldLabelWrapper>
                        <StyledGridWrapper>
                            <WorkflowContactGridViewableBy valuationId={valuationId} />
                        </StyledGridWrapper>
                    </StyledFieldRoot>
                </StyledFieldWrapper>
            </StyledSectionWrapper>
        </StyledFlexWrapper>
    );
};

export default WorkflowAdHocTaskForm;
