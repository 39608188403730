import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { VIEWSET_PAGE_PATH_FORMAT, ViewSetPage } from '../../pages/ViewSetPage/ViewSetPage';
import ValutionWizardViewSet from '../../pages/ViewSetPage/ValuationWizardViewSet';
import MultipleValuationWizardViewSet from '../../pages/ViewSetPage/MultipleValuationWizardViewSet';
import ChangeWorkflowWizardViewSet from '../../pages/ViewSetPage/ChangeWorkflowWizardViewSet';
import { HangfirePage } from '../../pages/HangfirePage/HangfirePage';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';

export const Router = (): React.ReactElement => {
    const { EsInputPathFormat, EsInputTabId } = useApplicationContext();

    return (
        <Routes>
            <Route path="/ValuationWizardHome" element={<ValutionWizardViewSet />} />
            <Route path="/MultipleValuationWizard" element={<MultipleValuationWizardViewSet />} />
            <Route path="/ChangeWorkflowWizard" element={<ChangeWorkflowWizardViewSet />} />
            <Route path={EsInputPathFormat} element={<ViewSetPage tabIdOverride={EsInputTabId} />} />
            <Route path={VIEWSET_PAGE_PATH_FORMAT} element={<ViewSetPage />} />
            <Route path="/valuation-hangfire" element={<HangfirePage />} />
        </Routes>
    );
};

export default Router;
