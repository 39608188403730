import React, { useCallback } from 'react';
import { Button, UnsavedChangesDialog } from '@samc/react-ui-core';
import { useNavigate } from 'react-router';

interface PropTypes {
    showModal: boolean;
    setWizardisDirty: (state: boolean) => void;
    toggleModal: (state: boolean) => void;
}

const BulkWizardHead = ({ showModal, toggleModal, setWizardisDirty }: PropTypes): React.ReactElement => {
    const navigate = useNavigate();

    const closeModal = useCallback(
        (discard: boolean) => {
            toggleModal(false);
            if (discard) {
                setWizardisDirty(false);
                navigate(-1);
            }
        },
        [navigate, setWizardisDirty, toggleModal],
    );

    return (
        <div
            style={{
                backgroundColor: 'rgb(74 70 70)',
            }}
            className="pageHeading"
        >
            <span
                style={{
                    color: 'white',
                    marginTop: '-5px',
                }}
            >
                Add Valuation
            </span>

            <Button
                buttonType="primary"
                text="Cancel"
                onClick={(): void => {
                    toggleModal(true);
                }}
                className="pull-right"
            />

            <UnsavedChangesDialog show={showModal} close={closeModal} />
        </div>
    );
};

export default BulkWizardHead;
