/* eslint-disable no-param-reassign */
import React from 'react';
import { ValuationWorkflowTask, ValuationWorkflowTaskStatus } from '../../../valuationApi';

const GetWorkflowTaskStatus = (
    workflowTaskStatusMap: {
        [taskStatusId: string]: ValuationWorkflowTaskStatus;
    },
    workflowTaskStatusId?: string | null,
): ValuationWorkflowTaskStatus | undefined => {
    if (!workflowTaskStatusId) return undefined;

    const status = workflowTaskStatusMap[workflowTaskStatusId];
    if (status) return status;

    return Object.values(workflowTaskStatusMap).find((x) => x.Id.toLowerCase() === workflowTaskStatusId.toLowerCase());
};

/**
 * Merges a collection of ValuationWorkflowTasks with a collection of ValuationWorkflowTaskStatuses.
 * The intention behind this is that the given ValuationWorkflowTask.WorkflowTaskStatuses will be undefined
 * prior to calling this hook. Afterwards ValuationWorkflowTask.WorkflowTaskStatuses will contain all
 * ValuationWorkflowTaskStatuses from the corresponding WorkflowTask.
 *
 * All Id comparisons are case-insensitive.
 *
 * @param workflowTasks A collection of ValuationWorkflowTasks for which WorkflowTaskStatuses is undefined.
 * @param workflowTaskStatuses A collection of all potentially related ValuationWorkflowTaskStatuses.
 * @returns A new collection of ValuationWorkflowTasks with WorkflowTaskStatuses containing any ValuationWorkflowTaskStatuses
 * for the WorkflowTask.
 */
export const useWorkflowTasksWithWorkflowTaskStatus = (
    workflowTasks: ValuationWorkflowTask[],
    workflowTaskStatuses: ValuationWorkflowTaskStatus[],
): ValuationWorkflowTask[] => {
    const workflowTaskStatusMap = React.useMemo(
        () =>
            workflowTaskStatuses.reduce(
                (map, taskStatus) => {
                    map[taskStatus.Id] = taskStatus;
                    return map;
                },
                {} as { [taskStatusId: string]: ValuationWorkflowTaskStatus },
            ),
        [workflowTaskStatuses],
    );

    const tasksWithWorkflowTaskStatus = React.useMemo(
        () =>
            workflowTasks.map(
                (task) =>
                    ({
                        ...task,
                        WorkflowTaskStatus: {
                            ...GetWorkflowTaskStatus(workflowTaskStatusMap, task.WorkflowTaskStatusId),
                        },
                        WorkflowTemplateTaskStatuses: task.WorkflowTemplateTaskStatuses?.map((templateTaskStatus) => ({
                            ...templateTaskStatus,
                            WorkflowTaskStatus: {
                                ...GetWorkflowTaskStatus(
                                    workflowTaskStatusMap,
                                    templateTaskStatus.WorkflowTaskStatusId,
                                ),
                            },
                        })),
                    }) as ValuationWorkflowTask,
            ),
        [workflowTasks, workflowTaskStatusMap],
    );

    return tasksWithWorkflowTaskStatus;
};

export default useWorkflowTasksWithWorkflowTaskStatus;
