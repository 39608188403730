import React from 'react';
import { Entitlements, useCurrentUser } from '@samc/single-spa-authentication';
import { ApplicationContextProvider as BaseApplicationContextProvider } from './ApplicationContext.Context';

export const ApplicationContextProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
    const currentUser = useCurrentUser();

    return (
        <BaseApplicationContextProvider
            CanUserAddAdHocTask={currentUser.hasEntitlement(
                Entitlements.DataAccess.GenerateDomainAddEntitlementName('VALU_ValuationWorkflowTask'),
            )}
            CanUserReassignTask={
                currentUser.hasEntitlement(
                    Entitlements.DataAccess.GenerateDomainAddEntitlementName('VALU_ValuationWorkflowTaskOwner'),
                ) ||
                currentUser.hasEntitlement(
                    Entitlements.DataAccess.GenerateDomainDeleteEntitlementName('VALU_ValuationWorkflowTaskOwner'),
                ) ||
                currentUser.hasEntitlement(
                    Entitlements.DataAccess.GenerateDomainUpdateEntitlementName('VALU_ValuationWorkflowTaskOwner'),
                )
            }
            CanUserResendNotification={currentUser.hasEntitlement(
                Entitlements.ValuationWorkflow.ValuationWorkflowSendNotificationReminder,
            )}
            CanUserUpdateAdHocTask={currentUser.hasEntitlement(
                Entitlements.DataAccess.GenerateDomainUpdateEntitlementName('VALU_ValuationWorkflowTask'),
            )}
            CanUserViewWorkflowTemplate={currentUser.hasEntitlement(
                Entitlements.ValuationWorkflow.ValuationWorkflowViewWorkflowTemplate,
            )}
            CurrentUser={{
                hasEntitlement: (e: string) => currentUser.hasEntitlement(e),
                id: currentUser.id.toString(),
            }}
            Entitlements={{
                AccessAllPortfolioData: Entitlements.DataAccess.AccessAllPortfolioData,
                AccessLimitedCompanyData: Entitlements.DataAccess.AccessLimitedCompanyData,
                AssignRoles: Entitlements.User.AssignRoles,
                ChangeWorkflow: Entitlements.DataAccess.ChangeWorkflow,
                CreateUsers: Entitlements.User.CreateUsers,
                DisableUsers: Entitlements.User.DisableUsers,
                GenerateDomainAddEntitlementName: Entitlements.DataAccess.GenerateDomainAddEntitlementName,
                GenerateDomainDeleteEntitlementName: Entitlements.DataAccess.GenerateDomainDeleteEntitlementName,
                GenerateDomainUpdateEntitlementName: Entitlements.DataAccess.GenerateDomainUpdateEntitlementName,
                GenerateFormViewLinkEntitlementName: Entitlements.FormViewLink.GenerateFormViewLinkEntitlementName,
                GenerateViewSetActionEntitlementName: Entitlements.ViewSetAction.GenerateViewSetActionEntitlementName,
                GenerateViewSetTabEntitlementName: Entitlements.ViewSetTab.GenerateViewSetTabEntitlementName,
                GenerateViewUpdateDownloadEntitlement: Entitlements.DocumentType.GenerateViewUpdateDownloadEntitlement,
                RequestUsers: Entitlements.User.RequestUsers,
                UpdateUsers: Entitlements.User.UpdateUsers,
                ManageReports: Entitlements.Reporting.ManageReports,
            }}
        >
            {children}
        </BaseApplicationContextProvider>
    );
};

export default ApplicationContextProvider;
