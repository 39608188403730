import React from 'react';
import { EntitlementNames, User } from '@samc/screen-config-api';
import { ApplicationContextProvider as ScreenConfigApplicationContextProvider } from '@samc/screen-config-api/lib/Contexts/ApplicationContext';

const _currentUser = {
    id: undefined,
    hasEntitlement: (): never => {
        throw new Error(
            'Using the default implementation of ApplicationContext.currentUser.hasEntitlement - this means the context is being consumed outside of a valid provider, which is likely a bug.',
        );
    },
};

const _assetDataDomainId = 'POMA_Asset';
const _deaDataDomainId = 'ValuationAsset';
const _deaTabId = 'DEBTEQUITYANALYSIS';
const _deaPathFormat = `/screens/:id/${_deaTabId}/:DEAMenuItem?/:subtabId?`;
const _esInputDataDomainId = 'VALU_ESInputData';
const _esInputTabId = 'ESINPUT';
const _esInputPathFormat = `/screens/:id/${_esInputTabId}/:esInputMenuItem?/:subtabId?`;
const _valuationAssetMethodologySetupDataDomainId = 'ValuationAssetMethodologySetup';
const _systemInternalIngressPort = 8443;
const _systemExecuteMaxRecordsToReturn = 2000000;

export interface ApplicationContextType {
    AssetDataDomainId: string;
    CanUserAddAdHocTask: boolean;
    CanUserReassignTask: boolean;
    CanUserResendNotification: boolean;
    CanUserUpdateAdHocTask: boolean;
    CanUserViewWorkflowTemplate: boolean;
    CurrentUser: User;
    DeaDataDomainId: string;
    DeaPathFormat: string;
    DeaTabId: string;
    Entitlements: EntitlementNames;
    EsInputDataDomainId: string;
    EsInputPathFormat: string;
    EsInputTabId: string;
    SystemExecuteMaxRecordsToReturn: number;
    SystemInternalIngressPort: number;
    ValuationAssetMethodologySetupDataDomainId: string;
}

export const ApplicationContext = React.createContext<ApplicationContextType>({
    AssetDataDomainId: _assetDataDomainId,
    CanUserAddAdHocTask: false,
    CanUserReassignTask: false,
    CanUserResendNotification: false,
    CanUserUpdateAdHocTask: false,
    CanUserViewWorkflowTemplate: false,
    CurrentUser: _currentUser,
    DeaDataDomainId: _deaDataDomainId,
    DeaPathFormat: _deaPathFormat,
    DeaTabId: _deaTabId,
    Entitlements: {} as EntitlementNames,
    EsInputDataDomainId: _esInputDataDomainId,
    EsInputPathFormat: _esInputPathFormat,
    EsInputTabId: _esInputTabId,
    SystemExecuteMaxRecordsToReturn: _systemExecuteMaxRecordsToReturn,
    SystemInternalIngressPort: _systemInternalIngressPort,
    ValuationAssetMethodologySetupDataDomainId: _valuationAssetMethodologySetupDataDomainId,
});

export const ApplicationContextProvider = ({
    AssetDataDomainId = _assetDataDomainId,
    CanUserAddAdHocTask = false,
    CanUserReassignTask = false,
    CanUserResendNotification = false,
    CanUserUpdateAdHocTask = false,
    CanUserViewWorkflowTemplate = false,
    children,
    CurrentUser = _currentUser,
    DeaDataDomainId = _deaDataDomainId,
    DeaPathFormat = _deaPathFormat,
    DeaTabId = _deaTabId,
    Entitlements = {} as EntitlementNames,
    EsInputDataDomainId = _esInputDataDomainId,
    EsInputPathFormat = _esInputPathFormat,
    EsInputTabId = _esInputTabId,
    SystemInternalIngressPort = _systemInternalIngressPort,
    SystemExecuteMaxRecordsToReturn = _systemExecuteMaxRecordsToReturn,
    ValuationAssetMethodologySetupDataDomainId = _valuationAssetMethodologySetupDataDomainId,
}: Partial<ApplicationContextType> & { children: React.ReactNode }): React.ReactElement => {
    const value: ApplicationContextType = React.useMemo(
        () => ({
            AssetDataDomainId,
            CanUserAddAdHocTask,
            CanUserReassignTask,
            CanUserResendNotification,
            CanUserUpdateAdHocTask,
            CanUserViewWorkflowTemplate,
            CurrentUser,
            DeaDataDomainId,
            DeaPathFormat,
            DeaTabId,
            Entitlements,
            EsInputDataDomainId,
            EsInputPathFormat,
            EsInputTabId,
            SystemInternalIngressPort,
            SystemExecuteMaxRecordsToReturn,
            ValuationAssetMethodologySetupDataDomainId,
        }),
        [
            AssetDataDomainId,
            CanUserAddAdHocTask,
            CanUserReassignTask,
            CanUserResendNotification,
            CanUserUpdateAdHocTask,
            CanUserViewWorkflowTemplate,
            CurrentUser,
            DeaDataDomainId,
            DeaPathFormat,
            DeaTabId,
            Entitlements,
            EsInputDataDomainId,
            EsInputPathFormat,
            EsInputTabId,
            SystemExecuteMaxRecordsToReturn,
            SystemInternalIngressPort,
            ValuationAssetMethodologySetupDataDomainId,
        ],
    );

    return (
        <ScreenConfigApplicationContextProvider currentUser={CurrentUser} Entitlements={Entitlements}>
            <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>
        </ScreenConfigApplicationContextProvider>
    );
};

export default ApplicationContextProvider;
