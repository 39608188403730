import { isNullOrUndefined } from '@samc/react-ui-core';
import { ValuationWorkflowTemplateTaskStatus } from '../../valuationApi';

/**
 * A compare function which fulfills the requirements of Array.prototype.sort() to sort
 * ValuationWorkflowTemplateTaskStatus by their respective ValuationWorkflowTaskStatus.SortOrder ascending.
 *
 * @param a The first ValuationWorkflowTemplateTaskStatus for comparison.
 * @param b The second ValuationWorkflowTemplateTaskStatus for comparison.
 * @returns 0 if either WorkflowTaskStatus.SortOrder is undefined for a or b or the values are equivalent,
 * otherwise a.WorkflowTaskStatus.SortOrder less b.WorkflowTaskStatus.SortOrder.
 */
export const WorkflowTemplateTaskStatusByWorkflowTaskStatusSortOrderAsc = (
    a: ValuationWorkflowTemplateTaskStatus,
    b: ValuationWorkflowTemplateTaskStatus,
): number => {
    // This is just a standard sort by SortOrder with some safeguards around possible undefined values.
    const aSortOrder = a.WorkflowTaskStatus?.SortOrder;
    const bSortOrder = b.WorkflowTaskStatus?.SortOrder;

    if (isNullOrUndefined(aSortOrder) || isNullOrUndefined(bSortOrder)) {
        return 0;
    }

    return aSortOrder - bSortOrder;
};

export default WorkflowTemplateTaskStatusByWorkflowTaskStatusSortOrderAsc;
