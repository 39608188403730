import React from 'react';
import { SectionHeader } from '@samc/react-ui-form';
import { LABELS } from '../../static/content';
import { SelectionBox } from '../../atoms/SelectionBox/SelectionBox';
import { SetupMethodType } from './AddValuations.interfaces';
import { NavButtons } from './NavButtons';
import { CycleMethod } from './ValuationWizard.enums';
import { ValuationSteps } from '../WizardProgress/ProgressConstants';

const Cycles = [
    { value: CycleMethod.CreateCycleManually, label: LABELS.SETUP_VALUATIONS.MANUAL_CYCLE, disabled: false },
    // { value: CycleMethod.CopyLastCycle, label: LABELS.SETUP_VALUATIONS.COPY_CYCLE, disabled: true },
    { value: CycleMethod.UseCyCleTemplate, label: LABELS.SETUP_VALUATIONS.TEMPLATE_CYCLE, disabled: false },
];

export const SelectCycleMethod = ({
    setupMethod,
    updateSetupMethod,
    setCurrentStep,
}: {
    setupMethod: SetupMethodType;
    updateSetupMethod: (params: SetupMethodType) => void;
    setCurrentStep: (param: number) => void;
}): React.ReactElement => {
    const onBack = (): void => setCurrentStep(ValuationSteps.SelectSetupMethod);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.checked) {
            updateSetupMethod({ ...setupMethod, cycleMethod: e.target.value as CycleMethod });
        }
    };

    const onContinue = (): void => {
        if (setupMethod.cycleMethod === CycleMethod.CreateCycleManually) {
            setCurrentStep(ValuationSteps.SetupValuations);
        } else if (setupMethod.cycleMethod === CycleMethod.UseCyCleTemplate) {
            setCurrentStep(ValuationSteps.SetupCycleTemplate);
        }
    };

    return (
        <>
            <NavButtons
                isDisabled={setupMethod.cycleMethod === CycleMethod.undefined}
                onBack={onBack}
                onContinue={onContinue}
            />

            <div className="center-box">
                <SectionHeader title={LABELS.SELECT_SETUP_LABEL} />

                {Cycles.map(
                    ({ value, label, disabled = false }): React.ReactElement => (
                        <SelectionBox
                            key={value}
                            name="choose-valuation-cycle"
                            value={value}
                            checked={value === setupMethod.cycleMethod}
                            label={label}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    ),
                )}
            </div>
        </>
    );
};

export default SelectCycleMethod;
