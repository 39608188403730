import React from 'react';
import { GFCCellRendererParams } from '@samc/react-ui-grid';
import {
    fetchApi as _fetchApi,
    useValuationWorkflowTaskStatuses as _useValuationWorkflowTaskStatuses,
    ValuationApiOverrides,
} from '../../../valuationApi';
import { WorkflowTaskActionsRenderer } from '../../cell-renderers/WorkflowTaskActionsRenderer/WorkflowTaskActionsRenderer';

export const useWorkflowTaskActionsRenderer = ({
    fetchApi = _fetchApi,
    useValuationWorkflowTaskStatuses = _useValuationWorkflowTaskStatuses,
}: Pick<ValuationApiOverrides, 'fetchApi' | 'useValuationWorkflowTaskStatuses'>): ((
    props: GFCCellRendererParams,
) => React.ReactElement) => {
    const workflowTaskActionsRendererFunction = React.useCallback(
        ({ node }: GFCCellRendererParams): React.ReactElement => {
            return (
                <WorkflowTaskActionsRenderer
                    fetchApi={fetchApi}
                    node={node}
                    useValuationWorkflowTaskStatuses={useValuationWorkflowTaskStatuses}
                />
            );
        },
        [fetchApi, useValuationWorkflowTaskStatuses],
    );

    return workflowTaskActionsRendererFunction;
};

export default useWorkflowTaskActionsRenderer;
