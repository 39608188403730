/* eslint-disable no-param-reassign */
import React from 'react';
import { ValuationWorkflowTask, ValuationWorkflowTemplateTaskStatus } from '../../../valuationApi';
import { CaseInsensitiveStringEquals } from '../../functions/CaseInsensitiveStringEquals';

/**
 * Merges a collection of ValuationWorkflowTasks with a collection of ValuationWorkflowTemplateTaskStatuses.
 * The intention behind this is that the given ValuationWorkflowTask.WorkflowTemplateTaskStatuses will be undefined
 * prior to calling this hook. Afterwards ValuationWorkflowTask.WorkflowTemplateTaskStatuses will contain all
 * ValuationWorkflowTemplateTaskStatuses from the corresponding WorkflowTemplateTask.
 *
 * All Id comparisons are case-insensitive.
 *
 * @param workflowTasks A collection of ValuationWorkflowTasks for which WorkflowTemplateTaskStatuses is undefined.
 * @param workflowTemplateTaskStatuses A collection of all potentially related ValuationWorkflowTemplateTaskStatuses.
 * @returns A new collection of ValuationWorkflowTasks with WorkflowTemplateTaskStatuses containing any ValuationWorkflowTemplateTaskStatuses
 * for the WorkflowTemplateTask.
 */
export const useWorkflowTasksWithWorkflowTemplateTaskStatuses = (
    workflowTasks: ValuationWorkflowTask[],
    workflowTemplateTaskStatuses: ValuationWorkflowTemplateTaskStatus[],
): ValuationWorkflowTask[] => {
    const workflowTemplateTaskStatusMap = React.useMemo(
        () =>
            workflowTemplateTaskStatuses.reduce(
                (map, templateTaskStatus) => {
                    map[templateTaskStatus.WorkflowTemplateTaskId.toLocaleUpperCase()] = [
                        ...(map[templateTaskStatus.WorkflowTemplateTaskId.toLocaleUpperCase()] || []),
                        templateTaskStatus,
                    ];
                    return map;
                },
                {} as { [templateTaskId: string]: ValuationWorkflowTemplateTaskStatus[] },
            ),
        [workflowTemplateTaskStatuses],
    );

    const tasksWithWorkflowTemplateTaskStatus = React.useMemo(
        () =>
            workflowTasks.map((task) => {
                if (!task.WorkflowTemplateTaskId) {
                    return task;
                }

                const workflowTemplateTaskStatusesForWorkflowTemplateTask =
                    workflowTemplateTaskStatusMap[task.WorkflowTemplateTaskId.toLocaleUpperCase()] || [];

                return {
                    ...task,
                    WorkflowTemplateTaskStatuses: [
                        ...workflowTemplateTaskStatusesForWorkflowTemplateTask.filter(
                            (templateTaskStatus) =>
                                !CaseInsensitiveStringEquals(
                                    templateTaskStatus.WorkflowTaskStatusId,
                                    task.WorkflowTaskStatusId,
                                ),
                        ),
                    ],
                } as ValuationWorkflowTask;
            }),
        [workflowTasks, workflowTemplateTaskStatusMap],
    );

    return tasksWithWorkflowTemplateTaskStatus;
};

export default useWorkflowTasksWithWorkflowTemplateTaskStatuses;
