import { MessageBar, MessageBarType, Spinner } from '@fluentui/react';
import React from 'react';
import { useStyletron } from 'styletron-react';
import { EsInputMenuOption } from '../../../valuationApi';
import { NestedMenu } from '../../atoms/NestedMenu/NestedMenu';
import { esInputMenuOptionExists, esInputMenuOptionsToNestedMenuOptions } from './EsInputMenu.utils';
import { useEntitledEsInputMenuOptions } from './useEntitledEsInputMenuOptions/useEntitledEsInputMenuOptions';

export interface EsInputMenuSelectEvent {
    option?: EsInputMenuOption;
    userRequested: boolean;
}

export interface Props {
    esInputDataId: string;
    className?: string;
    onSelect?: (ev: EsInputMenuSelectEvent) => void;
    /**
     * Called after a selection to retrieve the associated option (only if it exists)
     */
    afterSelection?: (option: EsInputMenuOption) => void;
    selectedKey?: string;
}

export const EsInputMenu = (props: Props): JSX.Element => {
    const { className, onSelect, selectedKey, esInputDataId, afterSelection } = props;

    const { entitledMenuOptions, isLoading } = useEntitledEsInputMenuOptions({
        esInputDataId,
    });

    const esInputMenuOptions = React.useMemo(
        () =>
            esInputMenuOptionsToNestedMenuOptions(entitledMenuOptions, (o) =>
                onSelect?.({ option: o, userRequested: true }),
            ),
        [entitledMenuOptions, onSelect],
    );

    React.useEffect(() => {
        if (!entitledMenuOptions || !onSelect) return;

        // backend generates menu item ids randomly, so we need to tolerate bad ids
        if (selectedKey) {
            const exists = esInputMenuOptionExists(selectedKey, entitledMenuOptions);
            if (exists) {
                afterSelection?.(exists);
                return;
            }
        }

        onSelect({ option: entitledMenuOptions[0], userRequested: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entitledMenuOptions, selectedKey]);

    const [css] = useStyletron();
    return (
        <div className={className}>
            {isLoading && <Spinner />}
            {!isLoading && !entitledMenuOptions && (
                <MessageBar messageBarType={MessageBarType.error}>Could not load ESINPUT menu options</MessageBar>
            )}
            {esInputMenuOptions && (
                <NestedMenu
                    className={css({ padding: '12px' })}
                    items={esInputMenuOptions}
                    selectedId={selectedKey}
                    forceExpanded
                />
            )}
        </div>
    );
};
