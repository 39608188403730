import useMutation from '@samc/screen-config-api/lib/useMutation/useMutation';
import { getHcrVcrGenerator } from '../../valuationApi';
import { HcrVcrRequestModel } from '../../valuationApi/models/HcrVcrRequestModel';
import { useApiContext } from '../useApiContext';

export const useHcrVcrReportGenerator = (): ((request: HcrVcrRequestModel) => Promise<void>) => {
    const { ReportingApi, requestInit } = useApiContext();

    const generator = getHcrVcrGenerator(ReportingApi, requestInit);
    const [generateExport] = useMutation(generator);

    return generateExport;
};

export default useHcrVcrReportGenerator;
