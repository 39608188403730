/* istanbul ignore file */
import React from 'react';
import { styled } from 'styletron-react';
import { BaseGrid, GridFieldConfiguration, useGridApis } from '@samc/react-ui-grid';
import { FieldConfigurationMember, InputType, PicklistSetting } from '@samc/picklist-api';
import { usePicklistGridFields as _usePicklistGridFields } from '@samc/picklist-core';
import { ColDef, GridOptions, IRowNode } from 'ag-grid-community';
import { MessageBar, MessageBarType, useDirtinessSync } from '@samc/react-ui-core';
import { Spinner } from '@fluentui/react';
import { GridType } from '@samc/react-ui-grid/lib/organisms/BaseGrid/AddedRowGrid/Types';
import { ValuationContact } from '../../../valuationApi/models/ValuationContact';
import { useApiContext } from '../../../hooks/useApiContext';

const fieldConfigurationMembers: Record<string, FieldConfigurationMember> = {
    RoleId: {
        viewFieldName: 'RoleId',
        visibleExpression: 'true',
        inputType: InputType.Picklist,
        picklistField: {
            id: 'Workflow_AdHoc_Task_Role_Picklist',
            picklistId: 'ROEN_Role_Picklist',
            displaySetting: 1,
            setting: PicklistSetting.Single,
        },
        tooltip: 'Role',
    },
    UserId: {
        viewFieldName: 'UserId',
        visibleExpression: 'true',
        inputType: InputType.Picklist,
        picklistField: {
            id: 'Workflow_AdHoc_Task_User_Picklist',
            picklistId: 'User',
            displaySetting: 1,
            setting: PicklistSetting.Single,
        },
        tooltip: 'User',
    },
};

const gridFields: GridFieldConfiguration[] = [
    {
        displayNameRule: 'Role',
        field: 'RoleId',
        type: 'string',
    },
    {
        displayNameRule: 'User',
        field: 'UserId',
        type: 'string',
    },
];

/* istanbul ignore next */
const StyledGridWrapper = styled('div', () => ({
    height: '25vh',
    minHeight: '250px',
}));

interface Props {
    onSave: () => Promise<void>;
    /**
     * The subset of ValuationContacts to set as selected when the grid is initially loaded.
     */
    initialGridSelection: { UserId: string }[];
    /**
     * The id of the Valuation for which to fetch Valuation Contacts.
     */
    valuationId: string;

    /**
     * Callback to be used to determine which rows are selectable. By default rows are selectable, so return `false` to make a row un-selectable.
     */
    isRowSelectable?: ColDef<ValuationContact>['checkboxSelection'];
    /**
     * Callback for each row in the grid called upon initial load to set the selected state of the row.
     */
    isRowSelected?: (row: IRowNode<ValuationContact>) => boolean;
    /**
     * Row is selected or deselected. The event contains the node in question, so call the node's `isSelected()` method to see if it was just selected or deselected.
     */
    onRowSelected?: GridOptions<ValuationContact>['onRowSelected'];
    /**
     * Row selection is changed. Use the grid API `getSelectedNodes()` or `getSelectedRows()` to get the new list of selected nodes / row data.
     */
    onSelectionChanged?: GridOptions<ValuationContact>['onSelectionChanged'];
    /**
     * Set to `true` to display a disabled checkbox when row is not selectable and checkboxes are enabled.
     */
    showDisabledCheckboxes?: ColDef<ValuationContact>['showDisabledCheckboxes'];
    /**
     * DI override of the hook to builds out the GridFieldConfigurations for a domain
     * that contains picklist fields.
     */
    usePicklistGridFields?: typeof _usePicklistGridFields;
}

export const WorkflowEditContactGrid = ({
    initialGridSelection,
    isRowSelectable,
    isRowSelected,
    onRowSelected: _onRowSelectedProp,
    onSelectionChanged,
    showDisabledCheckboxes,
    usePicklistGridFields = _usePicklistGridFields,
    valuationId,
    onSave: _onSaveProp,
}: Props): React.ReactElement => {
    const { useValuationContacts } = useApiContext();

    const { apis, onGridApiChanged } = useGridApis<ValuationContact>();
    const dirtinessScope = React.useRef<HTMLDivElement>(null);
    const [isDirty, setDirty] = React.useState(false);

    const { wrappedFields, frameworkComponents } = usePicklistGridFields(fieldConfigurationMembers, gridFields);

    const valuationContactsQuery = useValuationContacts(valuationId);

    const onRowSelected: typeof _onRowSelectedProp = (...params) => {
        const [ev] = params;
        const { source } = ev;
        if (source === 'api') return;

        setDirty(true);
        if (_onRowSelectedProp) _onRowSelectedProp(...params);
    };

    const selectInitialRows = (): void => {
        apis.ifDefined(GridType.Body, ({ gridApi }) => {
            gridApi.deselectAll();

            gridApi.forEachNode((node) =>
                node.setSelected(
                    (!!node.data && initialGridSelection.some((x) => x.UserId === node.data?.UserId)) ||
                        (isRowSelected?.(node) ?? false),
                ),
            );
        });
    };

    const onReset = (): void => {
        selectInitialRows();
        setDirty(false);
    };

    const onSave = async (): Promise<void> => {
        await _onSaveProp();
        setDirty(false);
    };

    useDirtinessSync({
        onReset,
        onSave,
        isDirty,
        scope: dirtinessScope,
    });

    if (valuationContactsQuery.isLoading) {
        return <Spinner data-testid="spinner-WorkflowContactGrid" />;
    }

    if (valuationContactsQuery.isError) {
        return <MessageBar text="Error fetching valuation contacts." messageBarType={MessageBarType.error} />;
    }

    return (
        <StyledGridWrapper ref={dirtinessScope}>
            <BaseGrid<ValuationContact>
                data={valuationContactsQuery.data?.Data as unknown as ValuationContact[]}
                fields={wrappedFields}
                frameworkComponents={frameworkComponents}
                onBodyGridReady={selectInitialRows}
                onRowSelected={onRowSelected}
                onSelectionChanged={onSelectionChanged}
                onGridApiChanged={onGridApiChanged}
                sizeColumnsToFit
                suppressPaginationPanel
                suppressAlternatingRowColors
                showDisabledCheckboxes={showDisabledCheckboxes}
                checkboxSelection={isRowSelectable}
            />
        </StyledGridWrapper>
    );
};

export default WorkflowEditContactGrid;
