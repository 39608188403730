/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { WorkflowDropDown } from './WorkflowDropDown';
import { NavButtons } from '../AddValutionWizard/NavButtons';
import { Workflow } from './ChangeWorkflowWizard.enums';
import { WorkflowSteps } from '../WizardProgress/ProgressConstants';

interface ValuationWorkflow {
    setValuationWorkflow: (record?: Record<string, unknown>) => void;
    valuationWorkflow?: Record<string | number, unknown>;
    portfolioId: string | null;
    setCurrentStep: (param: WorkflowSteps) => void;
}

export const SelectWorkflow = ({
    setValuationWorkflow,
    valuationWorkflow,
    portfolioId,
    setCurrentStep,
}: ValuationWorkflow): React.ReactElement => {
    const handleValuationType = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked && valuationWorkflow?.type !== event.target.value) {
            setValuationWorkflow({ type: event.target.value });
        }
    };

    const handleSetPrimaryKey = (record: Record<string, unknown>): void => {
        setValuationWorkflow({
            ...record,
            type: valuationWorkflow?.type,
        });
    };

    const onContinue = (): void => {
        setCurrentStep(WorkflowSteps.UpdateValuation);
    };

    const { Id: selectedKey } = valuationWorkflow ?? {};
    const initialSelectedKeys = React.useMemo(() => {
        if (selectedKey) return [String(selectedKey)];
        return [];
    }, [selectedKey]);

    return (
        <>
            <NavButtons
                isDisabled={valuationWorkflow === undefined || valuationWorkflow.primaryKey === undefined}
                onContinue={onContinue}
            />

            <div className="center-box assetorgroup">
                <div className="radio-label-dropdown-box">
                    <input
                        className="mt-5"
                        type="radio"
                        name="Step1"
                        checked
                        value={Workflow.ChangeWorkflow}
                        id={Workflow.ChangeWorkflow}
                        onChange={handleValuationType}
                    />

                    <label htmlFor={Workflow.ChangeWorkflow} className="label">
                        Select Workflow
                    </label>

                    <div className="record-dropdown">
                        <WorkflowDropDown
                            domainId="VALU_ValuationWorkflowTemplate"
                            onValueChanged={handleSetPrimaryKey}
                            key={
                                valuationWorkflow?.type === Workflow.ChangeWorkflowGroup
                                    ? new Date().getTime()
                                    : 'VALUATION_WORKFLOW'
                            }
                            filters={[`([ApplicablePortfolioIds] CONTAINS '${portfolioId}') AND ([IsActive] = '1')`]}
                            initialSelectedKeys={initialSelectedKeys}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelectWorkflow;
