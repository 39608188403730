import React from 'react';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { useSelectAndShowURLSearchParams } from '@samc/screen-config-core/lib/hooks/useSelectAndShowURLSearchParams/useSelectAndShowURLSearchParams';
import { MessageBar, MessageBarType } from '@samc/react-ui-core';
import { Spinner } from '@fluentui/react';
import { EsInputTab as EsInputTabBase } from './EsInputTab.FormScreen';
import { useApplicationContext } from '../../../hooks/useApplicationContext/useApplicationContext';
import { useEsInputDataById } from '../../../valuationApi/useEsInputDataById/useEsInputDataById';
import { useValuationAssetMethodologySetupData } from '../../../valuationApi/useValuationAssetMethodologySetupData/useValuationAssetMethodologySetupData';

interface Props {
    params: CustomScreenParams;

    onFormReset?: () => void;
}

export const EsInputTab = (props: Props): JSX.Element => {
    const { params, onFormReset } = props;

    const { AssetDataDomainId, DeaDataDomainId, EsInputDataDomainId, ValuationAssetMethodologySetupDataDomainId } =
        useApplicationContext();

    const [getSelectAndShowValue, setSelectAndShowValue] = useSelectAndShowURLSearchParams();

    const assetDataId = getSelectAndShowValue(AssetDataDomainId);
    const deaDataId = getSelectAndShowValue(DeaDataDomainId);
    const esInputDataId = getSelectAndShowValue(EsInputDataDomainId);
    const valuationAssetMethodologyDataId = getSelectAndShowValue(ValuationAssetMethodologySetupDataDomainId);

    const esInputDataQueryResult = useEsInputDataById(esInputDataId, true);

    const deaDataIdFromQuery = esInputDataQueryResult.data?.Data[0]?.ValuationAssetId as string | undefined;

    const valuationAssetMethodologySetupDataQueryResult = useValuationAssetMethodologySetupData(
        deaDataIdFromQuery,
        true,
    );

    if (esInputDataQueryResult.isLoading || valuationAssetMethodologySetupDataQueryResult.isLoading) {
        return <Spinner />;
    }

    if (esInputDataQueryResult.isError) {
        return <MessageBar messageBarType={MessageBarType.error} text="Failed to fetch ESInputData." />;
    }

    if (valuationAssetMethodologySetupDataQueryResult.isError) {
        return <MessageBar messageBarType={MessageBarType.error} text="Failed to fetch ValuationAssetMethodology." />;
    }

    const assetDataIdFromQuery = esInputDataQueryResult.data?.Data[0]?.AssetId as string | undefined;
    const valuationAssetMethodologySetupDataIdFromQuery = valuationAssetMethodologySetupDataQueryResult.data?.Data[0]
        ?.Id as string | undefined;

    if (assetDataIdFromQuery && assetDataId !== assetDataIdFromQuery) {
        setSelectAndShowValue(AssetDataDomainId, assetDataIdFromQuery);
    }

    if (deaDataIdFromQuery && deaDataId !== deaDataIdFromQuery) {
        setSelectAndShowValue(DeaDataDomainId, deaDataIdFromQuery);
    }

    if (
        valuationAssetMethodologySetupDataIdFromQuery &&
        valuationAssetMethodologyDataId !== valuationAssetMethodologySetupDataIdFromQuery
    ) {
        setSelectAndShowValue(
            ValuationAssetMethodologySetupDataDomainId,
            valuationAssetMethodologySetupDataIdFromQuery,
        );
    }

    return <EsInputTabBase esInputDataId={esInputDataId} params={params} onFormReset={onFormReset} />;
};

export default EsInputTab;
