import React from 'react';
import { IPanelProps, IRenderFunction, Panel, PanelType } from '@fluentui/react';
import { Loader, SitusHeader } from '@samc/react-ui-core';
import { styled } from 'styletron-react';
import { ValuationWorkflowTemplateStage, ValuationWorkflowTemplateTask } from '../../../valuationApi';
import { WorkflowTemplateGrid } from '../../molecules/WorkflowTemplateGrid/WorkflowTemplateGrid';

interface Props {
    isOpen: boolean;
    onClickClose: () => void;
    /**
     * A collection of ValuationWorkflowTemplateStages which will separate ValuationWorkflowTasks in the grid
     * by ValuationWorkflowTaskStatus.Name, using Full Width Rows.
     */
    workflowTemplateStages: ValuationWorkflowTemplateStage[];
    /**
     * A collection of ValuationWorkflowTemplateTasks which will comprise the contents of the WorkflowTemplatePanel.
     */
    workflowTemplateTasks: ValuationWorkflowTemplateTask[];
    /**
     * Boolean indicator of if the WorkflowTemplateTasks request errored.
     */
    workflowTemplateStagesRequestIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks are still loading.
     */
    workflowTemplateStagesRequestIsLoading?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks request errored.
     */
    workflowTemplateTasksRequestIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks are still loading.
     */
    workflowTemplateTasksRequestIsLoading?: boolean;
}

/* istanbul ignore next */
const StyledWrapper = styled('div', () => ({
    paddingTop: '16px',
}));

export const WorkflowTemplatePanel = (props: Props): React.ReactElement => {
    const {
        isOpen,
        onClickClose,
        workflowTemplateStages,
        workflowTemplateStagesRequestIsErrored,
        workflowTemplateStagesRequestIsLoading,
        workflowTemplateTasks,
        workflowTemplateTasksRequestIsErrored,
        workflowTemplateTasksRequestIsLoading,
    } = props;

    const onRenderNavigationContent: IRenderFunction<IPanelProps> = (_props, _defaultRender): React.ReactElement => (
        <SitusHeader title="Workflow Template" rejectionButtonText="Close" onRejectionButtonClicked={onClickClose} />
    );

    return (
        <Panel
            isOpen={isOpen}
            onRenderNavigationContent={onRenderNavigationContent}
            type={PanelType.medium}
            layerProps={{ eventBubblingEnabled: true }}
        >
            <StyledWrapper>
                <Loader
                    isLoading={!!workflowTemplateTasksRequestIsLoading || !!workflowTemplateStagesRequestIsLoading}
                    isError={!!workflowTemplateTasksRequestIsErrored || !!workflowTemplateStagesRequestIsErrored}
                >
                    <WorkflowTemplateGrid
                        workflowTemplateStages={workflowTemplateStages}
                        workflowTemplateTasks={workflowTemplateTasks}
                    />
                </Loader>
            </StyledWrapper>
        </Panel>
    );
};

export default WorkflowTemplatePanel;
