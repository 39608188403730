import React from 'react';
import { LightThemeFormColors } from '@samc/react-ui-theme';
import { createGuid } from '../../static/ValuationWizard.utils';

interface PropTypes {
    name: string;
    value: string;
    checked: boolean;
    label: string;
    id?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const SelectionBox = ({
    name,
    value,
    checked,
    onChange,
    label,
    id = createGuid(),
    disabled,
}: PropTypes): React.ReactElement => (
    <div className="radio-label-dropdown-box">
        <input
            id={id}
            type="radio"
            className="mt-5"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
        />
        <label
            htmlFor={id}
            style={{
                width: '400px',
                paddingLeft: '10px',
                display: 'flex',
                cursor: 'pointer',
                color: `${
                    disabled
                        ? LightThemeFormColors.fieldColors.default.border
                        : LightThemeFormColors.backgroundColors.light
                }`,
            }}
        >
            {label}
        </label>
    </div>
);

export default SelectionBox;
