import React from 'react';
import { ApiContextType, ApiContext, QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';
import { ValuationWorkflowTask } from '../models/ValuationWorkflowTask';
import { ValuationWorkflowTaskMapper } from '../mappers/ValuationWorkflowTaskMapper';

export const VALUATION_WORKFLOW_TASK_KEY = 'ValuationWorkflowTask';

export const useValuationWorkflowTask = (id: string): UseQueryResult<QueryExecuteResponse<ValuationWorkflowTask>> => {
    const api = React.useContext<ApiContextType>(ApiContext);

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_ValuationWorkflowTask',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
            {
                scalarDisplayName: 'Name',
                scalarExpression: '[Name]',
            },
            {
                scalarDisplayName: 'Description',
                scalarExpression: '[Description]',
            },
            {
                scalarDisplayName: 'DueDate',
                scalarExpression: '[DueDateCalculated]',
            },
            {
                scalarDisplayName: 'DueDateOverride',
                scalarExpression: '[DueDateOverride]',
            },
            {
                scalarDisplayName: 'ResponsibleParticipant',
                scalarExpression: '[TaskOwners]',
            },
            {
                scalarDisplayName: 'TaskStageId',
                scalarExpression: '[TaskStageId]',
            },
            {
                scalarDisplayName: 'TaskGroup',
                scalarExpression: '[TaskGroup]',
            },
            {
                scalarDisplayName: 'AdHocTaskFlag',
                scalarExpression: '[AdHocTaskFlag]',
            },
            {
                scalarDisplayName: 'TaskStatusId',
                scalarExpression: '[TaskStatusId]',
            },
            {
                scalarDisplayName: 'WorkflowTemplateTaskId',
                scalarExpression: '[WorkflowTemplateTaskId]',
            },
            {
                scalarDisplayName: 'CompletedByDate',
                scalarExpression: '[CompletedByDate]',
            },
            {
                scalarDisplayName: 'ValuationId',
                scalarExpression: '[ValuationId]',
            },
        ],
        sorting: {},
        paging: { start: 0, stop: 1 },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[Id] = '${id}'`,
        },
    };

    const queryResult = useQuery<QueryExecuteResponse<ValuationWorkflowTask>>(
        [VALUATION_WORKFLOW_TASK_KEY, id],
        async () => {
            const response = await executeLiftSiftQuery(api.LiftSiftApi, requestBody, api.requestInit, api.ShowMessage);

            return {
                ...response,
                Data: ValuationWorkflowTaskMapper.Map(response.Data),
            };
        },
        { retry: 2, refetchOnWindowFocus: false },
    );

    return queryResult;
};

export default useValuationWorkflowTask;
