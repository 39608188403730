import { AdHocFilter } from '@samc/screen-config-api';

export class AdHocFilterBuilder {
    private adHocFilter: AdHocFilter;

    public constructor() {
        this.adHocFilter = {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
        };
    }

    public withExpression(expression?: string): AdHocFilterBuilder {
        if (!expression) return this;

        this.adHocFilter.advancedExpression = expression;

        return this;
    }

    public build(): AdHocFilter {
        return this.adHocFilter;
    }

    /* istanbul ignore next */
    public debug(): void {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(this.adHocFilter, undefined, 2));
    }
}

export default AdHocFilterBuilder;
