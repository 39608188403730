import React from 'react';
import { ApiContextType, ApiContext, QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';
import { useApplicationContext } from '../../hooks/useApplicationContext/useApplicationContext';

export const VALUATION_CONTACTS_KEY = 'ValuationContacts';

export const useValuationContacts = (valuationId: string): UseQueryResult<QueryExecuteResponse> => {
    const api = React.useContext<ApiContextType>(ApiContext);
    const { SystemExecuteMaxRecordsToReturn } = useApplicationContext();

    const requestBody: QueryExecuteRequest = {
        domainId: 'VALU_ValuationContact',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'Id',
                scalarExpression: '[Id]',
            },
            {
                scalarDisplayName: 'RoleId',
                scalarExpression: '[RoleId]',
            },
            {
                scalarDisplayName: 'UserId',
                scalarExpression: '[UserId]',
            },
            {
                scalarDisplayName: 'IsValuationAdmin',
                scalarExpression: '[IsValuationAdmin]',
            },
        ],
        sorting: {
            order: 'asc',
            orderBy: {
                scalarExpression:
                    'IIF(ISNULLOREMPTY([RoleReplicas_CustomSortOrder]),9999,[RoleReplicas_CustomSortOrder])',
            },
        },
        paging: { start: 0, stop: SystemExecuteMaxRecordsToReturn },
        summaryMode: false,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[ValuationId] = '${valuationId}' AND ISNOTNULL([UserId]) AND [UserId] != '00000000-0000-0000-0000-000000000000'`,
        },
    };

    const queryResult = useQuery<QueryExecuteResponse>(
        [VALUATION_CONTACTS_KEY, valuationId],
        () => executeLiftSiftQuery(api.LiftSiftApi, requestBody, api.requestInit, api.ShowMessage),
        { retry: 2, refetchOnWindowFocus: false },
    );

    return queryResult;
};

export default useValuationContacts;
