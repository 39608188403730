/* eslint-disable no-unused-expressions */
import React from 'react';
import { Modal } from '@fluentui/react';
import { SitusHeader } from '@samc/react-ui-core/lib/atoms/Header/SitusHeader/SitusHeader';
import { useHistoryContext } from '@samc/react-ui-history';
import GridScreenInternal from '@samc/screen-config-core/lib/organisms/GridScreen/GridScreenInternal';
import {
    ListView,
    TaskResponse,
    QueryExecuteResponse,
    QueryExecuteRequest,
    ListViewMember,
} from '@samc/screen-config-api';
import GetDomainManager from '@samc/screen-config-core/lib/molecules/GetDomainManager/GetDomainManager';
import QueryBuilderContextProvider from '@samc/screen-config-core/lib/organisms/ConfigureDomain/QuerybuilderContextProvider/QuerybuilderContextProvider';
import { ControlBar } from '@samc/react-ui-core';
import { GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { QueryExecuteFetchHandler } from '@samc/screen-config-core/lib/molecules/QueryExecuteManager/Types';

interface FieldDifferenceProps {
    AssetId: string;
    showModal: boolean;
    closeModal: () => void;
    locationParam: { defaults: string; PortfolioId: string };
    gridData: Record<string, unknown>[];
    listView: ListView;
    listviewMembers: ListViewMember[];
}

const FieldDifference = ({
    showModal,
    closeModal,
    locationParam,
    AssetId,
    gridData,
    listView,
    listviewMembers,
}: FieldDifferenceProps): React.ReactElement => {
    const { browserHistory } = useHistoryContext();
    const listViewState = JSON.parse(JSON.stringify(listView));
    listViewState.listViewMembers = listviewMembers;

    const onFetch: QueryExecuteFetchHandler = React.useCallback(
        (summaryMode, pageInfo): Promise<[QueryExecuteResponse, QueryExecuteRequest]> =>
            Promise.resolve([
                summaryMode
                    ? {
                          Data: [],
                          TotalRecords: 0,
                          ViewMembers: [],
                      }
                    : {
                          Data: gridData.slice(pageInfo.start, pageInfo.stop),
                          TotalRecords: gridData.length,
                          ViewMembers: [],
                      },
                {} as QueryExecuteRequest,
            ]),
        [gridData],
    );

    const handleSubmit = React.useCallback((): Promise<TaskResponse> => Promise.resolve({} as TaskResponse), []);

    return (
        <Modal isOpen={showModal} onDismiss={closeModal} isBlocking={false} className="userModals">
            <div
                className="ag-theme-balham ag-theme-centricity disable-user-modal"
                style={{ display: 'block', width: '90vw', height: '46vw' }}
            >
                <SitusHeader title="Field Value Differences">
                    <ControlBar cancel={closeModal} cancelDisabled={false} additionalControls={undefined} />
                </SitusHeader>

                <div className="disable-modal-content">
                    <div style={{ padding: '20px', marginBottom: '10px', fontWeight: 'bold', fontSize: '13px' }}>
                        The following field have a value that differs between the Asset Record and this valuation&apos;s
                        Asset Info
                        <br />
                        <button
                            type="button"
                            onClick={(): void => {
                                browserHistory.push(
                                    `/portfolio-management/#/screens/POMA_AssetDetails/AssetInformation?PortfolioId=${locationParam.PortfolioId}&defaults=${locationParam.defaults}&primaryKeyValue=${AssetId}`,
                                );
                            }}
                            style={{
                                background: 'transparent',
                                border: '0',
                                color: '#62a7d9',
                                cursor: 'pointer',
                                fontWeight: '600',
                            }}
                        >
                            Click here to access the Asset Record.
                        </button>
                    </div>
                    <div style={{ padding: '20px' }}>
                        <GetDomainManager domainId="VALU_ESInputData">
                            {({ domain }): React.ReactElement => (
                                <QueryBuilderContextProvider domainId={domain.sequentialId}>
                                    <GridScreenInternal
                                        style={{
                                            height: '64vh',
                                            display: 'flex',
                                            flex: '1 0 auto',
                                            flexDirection: 'column',
                                        }}
                                        listView={listViewState as ListView}
                                        doFetch={onFetch}
                                        onSubmit={handleSubmit}
                                        extraListViewMembers={[]}
                                        sortable={false}
                                        gridDisplayOptions={{
                                            isEditingDefault: false,
                                            rowsPerPage: 15,
                                            suppressAddRowButton: false,
                                            suppressColumnFilters: false,
                                            suppressDeleteButton: true,
                                            suppressDeleteConfirmationModal: true,
                                            suppressEditToggle: true,
                                            suppressFullScreen: false,
                                            suppressRowSpacing: true,
                                            suppressViewSelector: true,
                                            suppressExcelExport: true,
                                            suppressRefresh: false,
                                            suppressSelectionCheckbox: true,
                                            suppressAggregateToggle: true,
                                        }}
                                        GridScreenWrapper={GridScreenWrapper}
                                        ServiceDrivenViewSet={ServiceDrivenViewSet}
                                    />
                                </QueryBuilderContextProvider>
                            )}
                        </GetDomainManager>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FieldDifference;
