import { RequestResult, useRequestBase } from '@samc/react-ui-request';
import { getEsInputMenu } from '../api/Requests';
import { ApiContext } from '../../contexts/ApiContext/ApiContext';
import { useApiContext } from '../../hooks/useApiContext';
import { EsInputMenuOption } from '../models/EsInputMenu';

export const ESINPUTMENUKEY = 'EsInputMenu';

export const useEsInputMenu = (esInputDataId?: string): RequestResult<EsInputMenuOption[]> => {
    const { ValuationApi, requestInit } = useApiContext();

    const query = async (api: string, _esInputDataId?: string): Promise<EsInputMenuOption[]> => {
        return getEsInputMenu(api, _esInputDataId, requestInit);
    };

    return useRequestBase({
        key: ESINPUTMENUKEY,
        query,
        params: [ValuationApi, esInputDataId],
        requestContext: ApiContext,
    });
};

export default useEsInputMenu;
