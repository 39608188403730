import React from 'react';
import { ApiContextType, ApiContext, QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../api/Requests';
import { DEATrackingHelper } from '../models/DEATrackingHelper';

export const DEA_TRACKING_HELPER_KEY = 'DEATrackingHelper';

export const useDEATrackingHelper = (id: string): UseQueryResult<QueryExecuteResponse<DEATrackingHelper>> => {
    const api = React.useContext<ApiContextType>(ApiContext);

    const requestBody: QueryExecuteRequest = {
        domainId: 'ValuationAsset',
        primaryViewId: '-1',
        adhocListViewMembers: [
            {
                scalarDisplayName: 'OpenDEChecklistItemsCount',
                scalarExpression: '[OpenDEChecklistItems]',
                summaryExpression: 'sum([OpenDEChecklistItems])',
                summaryDisplayName: 'OpenDEChecklistItemsCount',
            },
            {
                scalarDisplayName: 'DebtEquitValueAcknowledgementPending',
                scalarExpression: 'IIF(COALESCE([DebtEquitValueAcknowledgement], false) <> true, 1, 0)',
                summaryExpression: 'SUM(IIF(COALESCE([DebtEquitValueAcknowledgement], false) <> true, 1, 0))',
                summaryDisplayName: 'DebtEquitValueAcknowledgementPending',
            },
        ],
        sorting: {},
        paging: { start: 0, stop: 50 },
        summaryMode: true,
        adhocFilter: {
            filterName: 'adhoc',
            advancedInd: true,
            expressionLang: 'Centric',
            advancedExpression: `[ValuationId] = '${id}' AND [ExcludeAssetFromReporting] <> TRUE`,
        },
    };

    const queryResult = useQuery<QueryExecuteResponse<DEATrackingHelper>>(
        [DEA_TRACKING_HELPER_KEY],
        () =>
            executeLiftSiftQuery(
                api.LiftSiftApi,
                requestBody,
                api.requestInit,
                api.ShowMessage,
            ) as unknown as QueryExecuteResponse<DEATrackingHelper>,
        { retry: 2, refetchOnWindowFocus: false },
    );
    return queryResult;
};

export default useDEATrackingHelper;
