import React from 'react';
import { MessageBar, MessageBarType, Shimmer } from '@fluentui/react';
import { styled } from 'styletron-react';
import { IRowNode } from 'ag-grid-community';
import {
    useValuationWorkflowTaskStatuses as _useValuationWorkflowTaskStatuses,
    useValuationWorkflowTaskStatusIdSaver as _useValuationWorkflowTaskStatusIdSaver,
    ValuationApiOverrides,
    ValuationWorkflowTask,
    ValuationWorkflowTaskStatus,
} from '../../../valuationApi';
import { useWorkflowTaskValidationPopupContext } from '../../contexts/WorkflowTaskValidationPopupContext';
import { WorkflowTemplateTaskStatusByWorkflowTaskStatusSortOrderAsc } from '../../comparators/WorkflowTemplateTaskStatusByWorkflowTaskStatusSortOrderAsc';
import { WorkflowTaskActionButton } from '../../atoms/WorkflowTaskActionButton/WorkflowTaskActionButton';
import { ByWorkflowTaskStatusSortOrderAsc } from '../../comparators/ByWorkflowTaskStatusSortOrderAsc/ByWorkflowTaskStatusSortOrderAsc';

const STATUS_IDS_FOR_AD_HOC_TASKS: string[] = ['Completed', 'Incomplete', 'Waived'];

/* istanbul ignore next */
const StyledShimmer = styled(Shimmer, () => ({
    height: '100%',
    padding: '2px',
}));

interface Props
    extends Pick<
        ValuationApiOverrides,
        'fetchApi' | 'useValuationWorkflowTaskStatuses' | 'useValuationWorkflowTaskStatusIdSaver'
    > {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    node: IRowNode<any>;
}

/**
 * Custom cell-renderer for rendering the WorkflowTemplateTaskStatuses of a WorkflowTask as a
 * series of buttons to perform the given action on the WorkflowTask.
 *
 * If you find yourself with missing or erroneous buttons here, check useWorkflowTasksWithWorkflowTemplateTaskStatuses
 * where we build the array of WorkflowTemplateTaskStatuses on the WorkflowTask.
 */
export const WorkflowTaskActionsRenderer = ({
    node,
    useValuationWorkflowTaskStatuses = _useValuationWorkflowTaskStatuses,
    useValuationWorkflowTaskStatusIdSaver = _useValuationWorkflowTaskStatusIdSaver,
}: Props): React.ReactElement => {
    const workflowTask: ValuationWorkflowTask = node.data;

    const [isSaving, setIsSaving] = React.useState<boolean>(false);

    const [workflowTaskStatusMutation] = useValuationWorkflowTaskStatusIdSaver();
    const workflowTaskStatusesRequest = useValuationWorkflowTaskStatuses();

    const { setValidationRequest, setValidationResponse, setVisible } = useWorkflowTaskValidationPopupContext();

    const handleClickWorkflowActionButton = (newWorkflowTaskStatusId: string) => async () => {
        try {
            setIsSaving(true);
            const [result, request] = await workflowTaskStatusMutation.mutateAsync({
                name: workflowTask.Name,
                newWorkflowTaskStatusId,
                workflowTask,
            });
            setValidationRequest(request);
            setValidationResponse(result);
            setVisible(true);
        } catch (e) {
            /* empty */
        }

        setIsSaving(false);
    };

    if (isSaving || workflowTaskStatusesRequest.isLoading) {
        return <StyledShimmer styles={{ shimmerWrapper: { height: '100% !important' } }} />;
    }

    if (workflowTask.AdHocTaskFlag) {
        return (
            <>
                {(workflowTaskStatusesRequest.data?.Data as unknown as ValuationWorkflowTaskStatus[])
                    .filter(
                        (taskStatus) =>
                            STATUS_IDS_FOR_AD_HOC_TASKS.some((id) => id === taskStatus.Id) &&
                            taskStatus.Id !== workflowTask.WorkflowTaskStatusId,
                    )
                    .sort(ByWorkflowTaskStatusSortOrderAsc)
                    .map((taskStatus) => {
                        return (
                            <WorkflowTaskActionButton
                                disabled={!workflowTask.CanUserActionTask}
                                key={taskStatus.Id}
                                onClick={handleClickWorkflowActionButton(taskStatus.Id)}
                                text={taskStatus.ShortName}
                            />
                        );
                    })}
            </>
        );
    }

    if (
        !workflowTask.WorkflowTemplateTaskStatuses ||
        workflowTask.WorkflowTemplateTaskStatuses?.length === 0 ||
        workflowTask.WorkflowTemplateTaskStatuses.filter((templateTaskStatus) => templateTaskStatus.IsActionable)
            .length === 0
    ) {
        return (
            <MessageBar
                styles={{
                    content: { height: '100%' },
                    iconContainer: { marginTop: '4px', marginBottom: '4px', alignItems: 'center' },
                    root: { minHeight: 'unset', height: '100%' },
                    text: { marginTop: '4px', marginBottom: '4px', alignItems: 'center' },
                }}
                messageBarType={MessageBarType.error}
            >
                No actions found for task.
            </MessageBar>
        );
    }

    return (
        <>
            {workflowTask.WorkflowTemplateTaskStatuses?.filter(
                (templateTaskStatus) => !!templateTaskStatus.WorkflowTaskStatusId && templateTaskStatus.IsActionable,
            )
                .sort(WorkflowTemplateTaskStatusByWorkflowTaskStatusSortOrderAsc)
                .map((templateTaskStatus) => {
                    return (
                        <WorkflowTaskActionButton
                            disabled={!workflowTask.CanUserActionTask}
                            key={templateTaskStatus.Id}
                            // We can assert the type here because we are filtering out the undefined values above.
                            onClick={handleClickWorkflowActionButton(templateTaskStatus.WorkflowTaskStatusId as string)}
                            text={templateTaskStatus.WorkflowTaskStatus?.ShortName}
                        />
                    );
                })}
        </>
    );
};

export default WorkflowTaskActionsRenderer;
