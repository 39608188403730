import React from 'react';
import { WorkflowTaskDueDatePanel } from '../../molecules/WorkflowTaskDueDatePanel/WorkflowTaskDueDatePanel';
import { WorkflowTaskDueDateRenderer as BaseWorkflowTaskDueDateRenderer } from './WorkflowTaskDueDateRenderer.Cell';
import { ValuationWorkflowTask } from '../../../valuationApi';

interface Props {
    workflowTask: ValuationWorkflowTask;
}

export const WorkflowTaskDueDateRenderer = ({ workflowTask }: Props): React.ReactElement => {
    const [isPanelOpen, setPanelOpen] = React.useState<boolean>(false);

    const closePanel = (): void => {
        setPanelOpen(false);
    };

    const openPanel = (): void => {
        setPanelOpen(true);
    };

    if (!workflowTask.CanUserOverrideDueDate) {
        return <BaseWorkflowTaskDueDateRenderer onClick={openPanel} workflowTask={workflowTask} />;
    }

    return (
        <>
            <BaseWorkflowTaskDueDateRenderer onClick={openPanel} workflowTask={workflowTask} />
            <WorkflowTaskDueDatePanel
                isOpen={isPanelOpen}
                onCancel={closePanel}
                onSubmitAndClose={closePanel}
                workflowTaskId={workflowTask.Id}
            />
        </>
    );
};

export default WorkflowTaskDueDateRenderer;
