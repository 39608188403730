export enum Status {
    Active = 'Active',
    Inactive = 'Inactive',
    Scheduled = 'Scheduled',
}

export enum ViewID {
    ValuationWizardHome = 'ValuationWizardHome',
    DeleteValuationGroup = 'DeleteValuationGroup',
    DeleteReviewComment = 'DeleteReviewComment',
    ChangeWorkflow = 'ChangeWorkflow',
}

export enum ValuationDomains {
    Valuation = 'VALU_Valuation',
    VAM_SETUP = 'ValuationAssetMethodologySetup',
    VAM = 'ValuationAssetMethodologies',
    VAODS = 'ValuationAssetOtherDataScreens',
    VAOV = 'ValuationAssetOtherValues',
}

export enum ValuationListViewIds {
    WMS = 'WizardMethodologySetup',
    WVAODS = 'WizardValuationAssetOtherDataScreens',
    WAOV = 'WizardValuationAssetOtherValues',
}

export enum ValuationPicklist {
    ValuationQuarter = 'VALU_ValuationQuarter_Picklist',
    ValuationMonth = 'VALU_ValuationMonth_Picklist',
    ReportType = 'VALU_ValuationReportType_Picklist',
    Scope = 'VALU_ValuationScopeTypes_Picklist',
    Frequency = 'VALU_ValuationFrequency_Picklist',
    Source = 'VALU_ValuationSourceType_Picklist',
}

export interface AssetOrValuationProps {
    type: string;
}

export enum PortfolioTypes {
    EQUITY = 'EQUITY',
}

export enum PeriodTypes {
    MONTH = 'MONTH',
    QUARTER = 'QUARTER',
    SEMIANNUAL = 'SEMIANNUAL',
    ANNUAL = 'ANNUAL',
    ONEOFF = 'ONEOFF',
}

export enum FormViews {
    ValuationWizardSetup = 'ValuationWizardSetup',
    ValGroupSearchDropDown = '',
    AssetSearchDropDown = '_',
    MultiValuationSetupByValGroupScreen = 'AddValuationGroup',
    MultiValuationSetupByAssetScreen = 'AddValuationByAssets',
    ReportCycleTemplateView = 'ReportCycleTemplates',
    MultipleAssetsBulk = 'MultipleAssetsMultipleValuations',
    MultipleValutionGroupsBulk = 'MultipleGroupsMultipleValuations',
}

export enum MethodologyTemplate {
    Manual = '7BAC9FE1-D16E-4543-86C0-E22AF20B02A3',
    None = '7450251C-A433-4DAF-B464-F4BB7E01C222',
}

export interface ValuationAssetMethodologiesVM extends Record<string, unknown> {
    Approach: string;
    AssetId?: string;
    Primary?: boolean | null;
    MethodologyTypeId?: string;
    MethodologyScenarioTypeId?: string;
    MethodologyValueTypeId?: string;
    ValueTypeDescription?: string | null;
    InterestId?: string;
    CombinationName?: string;
    IsEnableforVCA?: boolean;
    __ADDED__?: boolean;
}

export interface ValuationAssetOtherDataScreensVM extends Record<string, unknown> {
    AssetId?: string;
    OtherDataInputScreensTypeId?: string;
    __ADDED__?: boolean;
}

export interface ValuationAssetOtherValuesVM extends Record<string, unknown> {
    AssetId?: string;
    DateOfValueEntry?: boolean | number;
    MethodologyValueTypeId?: string;
    Description?: string;
    __ADDED__?: boolean;
}

export interface ValuationAssetVM {
    AssetId?: string;
    AssetName?: string;
    PortfoliosId?: string;
    ValuationGroupId?: string | null;
    ExcludeAssetFromReporting?: boolean;
    ExcludeAssetFromReportingExplanation?: string;
    FundId?: string;
    InvestmentId?: string;
}

export interface ValuationAssetMethodologySetupVM {
    RegionalArea: string;
    VAMethodologySetupId?: string;
    AssetId?: string;
    AssetName?: string;
    PortfoliosId?: string;
    PortfolioTypeId?: string;
    ValuationId?: string;
    ValuationName?: string;
    ValuationGroupId?: string | null;
    MethodologyTemplateId?: null | string;
    CurrentVAMethodologiesId?: null | string;
    AllAsset_CopyFromPriorValuationId?: null | string;
    ValuationAssetMethodologies?: ValuationAssetMethodologiesVM[];
    ValuationAssetOtherDataScreens?: ValuationAssetOtherDataScreensVM[];
    ValuationAssetOtherValues?: ValuationAssetOtherValuesVM[];
}

export enum MethodologyTypes {
    ValuationAssetMethodologiesVM = 'ValuationAssetMethodologiesVM',
    ValuationAssetOtherDataScreensVM = 'ValuationAssetOtherDataScreensVM',
    ValuationAssetOtherValuesVM = 'ValuationAssetOtherValuesVM',
}

export interface ValuationGroupAsset {
    RegionalArea: string;
    valuationGroupAssetId: string;
    valuationGroupId: string;
    assetsId: string;
    portfoliosId: string;
    assetName: string;
    fundId: string;
    investmentId: string;
}

export interface CycleTemplateReportDetails {
    defaultSourceShortName: string;
    reportTypeShortName: string;
    scopeShortName: string;
    frequencyLongName: string;
}

export const CreatedBy = 'AEF7733D-D2F1-4A48-B589-3689743C0199';

export const month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export interface ListValuationAssetMethodologiesVM {
    assetId: string;
    primary?: boolean;
    methodologyTypeId?: string;
    methodologyScenarioTypeId?: string;
    methodologyValueTypeId?: string;
    valueTypeDescription?: string;
    interestId?: string;
    combinationName?: string;
    useforReporting?: boolean;
    useforAsCompleteValue?: boolean;
    useForStabilizedValue?: boolean;
}

export interface ListValuationAssetOtherDataScreensVM {
    assetId?: string;
    otherDataInputScreensTypeId?: string;
}

export interface ListValuationAssetOtherValuesVM {
    assetId?: string;
    dateOfValueEntry?: boolean;
    methodologyValueTypeId?: string;
    description?: string;
}

export const ValuationJsonKeys = {
    ValuationName: 'ValuationName',
    PortfoliosId: 'PortfoliosId',
    PortfolioTypeId: 'PortfolioTypeId',
    EnableValGroup: 'EnableValGroup',
    ValuationMethod: 'ValuationMethod',
    ValuationGroupId: 'ValuationGroupId',
    ValuationGroupName: 'ValuationGroupName',
    ValuationAssetsId: 'ValuationAssetsId',
    AssetsName: 'AssetsName',
    ValuationReportTypeId: 'ValuationReportTypeId',
    ValuationScopeId: 'ValuationScopeId',
    ValuationSourceTypeId: 'ValuationScopeId',
    ValuationWorkflowTemplateId: 'ValuationWorkflowTemplateId',
    ValuationPeriodTypeId: 'ValuationWorkflowTemplateId',
    ValuationMonth: 'ValuationMonth',
    ValuationQuarter: 'ValuationQuarter',
    ValuationYear: 'ValuationYear',
    ValuationDateofValue: 'ValuationDateofValue',
    ValuationEngagementDate: 'ValuationEngagementDate',
    ValuationDraftDueDate: 'ValuationDraftDueDate',
    ValuationFinalDuedate: 'ValuationFinalDuedate',
    ExceptionMade: 'ExceptionMade',
    ExceptionReason: 'ExceptionReason',
    AppraisalSelectionRationale: 'AppraisalSelectionRationale',
    ExcludeValuationFromReporting: 'ExcludeValuationFromReporting',
    ExcludeValuationFromReportingExplanation: 'ExcludeValuationFromReportingExplanation',
    ValuationStatusId: 'ValuationStatusId',
    TotalAssetsInValuations: 'TotalAssetsInValuations',
    CreatedBy: 'CreatedBy',
    ValuationAssetValuationGroupVM: 'ValuationAssetValuationGroupVM',
};

export interface AssetMethodology {
    templateName?: null | string;
    assetId?: string;
    valuationName?: string;
    valuationId?: string;
    comparisionValuationId?: string;
    methodologyTemplateId?: string;
    listValuationAssetMethodologiesVM: ListValuationAssetMethodologiesVM[];
    listValuationAssetOtherDataScreensVM: ListValuationAssetOtherDataScreensVM[];
    listValuationAssetOtherValuesVM: ListValuationAssetOtherValuesVM[];
}

export interface ValuationContactVM {
    Id: string;
    ValuationId: string;
    RoleId: string;
    CompanyId?: string | null;
    ContactId?: string | null;
    UserId?: string | null;
    ValuationWorkflowParticipantId: string;
}
export interface KeyValueVM {
    name: string;
    data: string | unknown | null | undefined;
}
export interface FieldDifferenceVM {
    FieldName: string;
    ValuationValue: string | unknown | null | undefined;
    AssetValue: string | unknown | null | undefined;
}
export interface PortfolioDecode {
    PortfolioId: string;
    Portfolio_DisplayAssetValuationDifferenceCount: boolean;
}

export interface ESInputDataDefault {
    Id: string;
    SharedFieldId: string;
}

export interface AddTask extends Record<string, unknown> {
    ValuationUniqId?: string;
    COVValuationMethod?: string;
    PortfolioId?: string | null;
    Portfolio_Id?: string | null;
    StatusId?: string;
    PortfoliosId?: string | null;
    PortfolioTypeId?: string;
    PrimaryMethodology?: string;
    EnableValGroup?: boolean;
    ValuationMethod?: string;
    ValuationType?: string;
    ValuationName?: string;
    ValuationGroupId?: string | null;
    ValuationGroupName?: string;
    ValuationAssetsId?: string | null;
    AssetId?: string | null;
    AssetsName?: string;
    ValuationReportTypeId?: string;
    ValuationScopeId?: string;
    ValuationSourceTypeId?: string;
    ValuationWorkflowTemplateId?: string;
    ValuationPeriodTypeId?: string;
    ValuationMonth?: string;
    ValuationQuarter?: string;
    ValuationYear?: number | null;
    ValuationDateofValue?: string | null;
    ValuationEngagementDate?: string | null;
    ValuationDraftDueDate?: string | null;
    ValuationFinalDuedate?: string | null;
    ExceptionMade?: boolean | null;
    ExceptionReason?: string;
    AppraisalSelectionRationale?: string;
    ExcludeValuationFromReporting?: boolean;
    ExcludeValuationFromReportingExplanation?: string;
    ValuationStatusId?: string;
    TotalAssetsInValuations?: number;
    CreatedBy?: string;
    ValuationAsset?: ValuationAssetVM[];
    ValuationAssetMethodologySetup?: ValuationAssetMethodologySetupVM[];
    ValuationRegion?: string | null;
    ValuationIsReadonly?: number;
    InvestmentIds?: string | null;
    FundIds?: string | null;
    ValuationContacts?: ValuationContactVM[];
}

export interface ReportCycleTemplatesDetails extends Record<string, unknown> {
    rcTemplateId: string;
    rcTemplateName: string;
    rcDescription: string;
    applicablePortfolioIds: string;
    cycleTemplateReportDetails: CycleTemplateReportDetails[];
    years: number[];
}

export interface Valuation {
    addTasks?: AddTask[];
    addContacts?: Record<string, unknown>;
}

export interface Step1Props {
    data: FormData | undefined;
}

export interface Step2Props {
    data: FormData | undefined;
}
export interface Step3Props {
    data: FormData | undefined;
}

export interface Step4Props {
    data: FormData | undefined;
}

export interface Step5Props {
    data: FormData | undefined;
}

export interface TemplateType {
    year: string;
    quarterValue: string[];
    monthValue: string[];
}

export interface ReportType {
    ReportTypeId: string;
    ScopeId: string;
    ReportPeriod: TemplateType[];
}

export enum GridIdentifiers {
    METHODOLOGY_SETUP = 'Methodology Setup',
    VALUATION_SETUP = 'Valuation Setup',
    VA_METHODOLOGYSETUP_GRID = 'ValuationAssetMethodologySetup Grid',
    VA_METHODOLOGIES_GRID = 'ValuationAssetMethodologiesVM Grid',
    VA_OTHERDATASCREENS_GRID = 'ValuationAssetOtherDataScreensVM Grid',
    VA_OTHERVALUES_GRID = 'ValuationAssetOtherValuesVM Grid',
}

export const Notifications = {
    CHANGE_WORKFLOW: 'One or more Valuations must be selected before selecting Change Workflow',
    ACTIVE_VALUATION:
        'The Workflow assigned to the Valuation can only be changed if the Valuation Status is "Scheduled". One or more of the selected Valuations has a Status that is not "Scheduled". Please only select Valuations where the Status is "Scheduled" and try again.  Contact your system administrator if there is an issue with an Active Valuation assigned Workflow.',
};

export const CC_Contact_Picklist = 'CC_Contact_Picklist';
export const CC_ViewForValuationContact = 'CC_ViewForValuationContact';
export const VALU_ValuationWorkflowTemplate_Picklist = 'VALU_ValuationWorkflowTemplate_Picklist';
export const VALU_AssetInformation = 'VALU_AssetInformation';

export type ValuationSubDomains =
    | ValuationAssetMethodologiesVM
    | ValuationAssetOtherDataScreensVM
    | ValuationAssetOtherValuesVM;
