/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useHistoryContext } from '@samc/react-ui-history';
import { Transition } from 'history';
import { useCurrentUser, Entitlements } from '@samc/single-spa-authentication';
import { MessageBar, MessageBarType, UnsavedChangesDialog } from '@samc/react-ui-core';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react/build/contexts/EvaluatorConfigurationContext';
import { AddValuationWizardBulk } from '../../components/organisms/AddValutionWizard/AddValuationWizardBulk';
import { decodeBase64 } from '../../util';

const MultipleValuationWizardViewSet = (): React.ReactElement => {
    const hashLocation = useLocation();
    const [showModal, toggleModal] = useState(false);
    const [wizardIsDirty, setWizardisDirty] = useState(false);
    const [transition, setTransition] = useState<Transition>();

    const { browserHistory, hashHistory } = useHistoryContext();

    const getPortfolioId = (): string | null => {
        const params = new URLSearchParams(hashLocation.search);

        return params.has('portfolioId')
            ? params.get('portfolioId')
            : params.has('PortfolioId')
              ? params.get('PortfolioId')
              : null;
    };

    const getAssetId = (): string | null => {
        const params = new URLSearchParams(hashLocation.search);
        return params.has('assetId') ? params.get('assetId') : null;
    };

    const getValuationGroupId = (): string | null => {
        const params = new URLSearchParams(hashLocation.search);
        return params.has('valuationGroupId') ? params.get('valuationGroupId') : null;
    };

    const portfolioId = getPortfolioId();
    const assetId = getAssetId();

    const URL_PARAMNAME_FILTER = 'filter';

    const [params] = useSearchParams();

    const getBase64FilterExpressionFromUrl = (): string | null => {
        return params.has(URL_PARAMNAME_FILTER) ? params.get(URL_PARAMNAME_FILTER) : null;
    };

    const encodedFilterExpression = getBase64FilterExpressionFromUrl();

    const filters = React.useMemo((): string[] => {
        if (!encodedFilterExpression || !encodedFilterExpression.length) return [];

        const decodedExpression = decodeBase64(encodedFilterExpression);
        try {
            const filterArray = JSON.parse(decodedExpression);
            if (filterArray && Array.isArray(filterArray)) {
                return filterArray;
            }
        } catch (e) {
            //
        }

        return [decodedExpression];
    }, [encodedFilterExpression]);

    useEffect(() => {
        if (!wizardIsDirty) return (): void => undefined;

        const unblock = browserHistory.block((tx: Transition) => {
            toggleModal(true);
            setTransition({ ...tx });
        });

        return (): void => {
            unblock();
        };
    }, [browserHistory, hashHistory, wizardIsDirty]);

    const closeModal = useCallback(
        (discard: boolean) => {
            if (discard && transition) {
                setWizardisDirty(false);
                const { action, location, retry } = transition;
                retry();
                if (action === 'PUSH') {
                    setTimeout(() => browserHistory.push(location));
                }
            }

            toggleModal(false);
        },
        [browserHistory, transition],
    );

    const user = useCurrentUser();

    const validateUser = React.useMemo(
        () => user.hasEntitlement(Entitlements.DataAccess.GenerateDomainAddEntitlementName('VALU_Valuation')),
        [user],
    );

    if (!user.isLoaded) return <div />;

    if (!validateUser)
        return (
            <div>
                <MessageBar
                    messageBarType={MessageBarType.error}
                    title="Error loading the Valuation Add Wizard"
                    text="You are not authorized to view this page. Please contact your administrator if you believe this is an error."
                />
            </div>
        );

    return (
        <EvaluatorConfigurationProvider value={{}}>
            <AddValuationWizardBulk
                key={`key_${getPortfolioId()}`}
                portfolioId={portfolioId}
                assetIdBulk={assetId}
                valuationGroupIdBulk={getValuationGroupId()}
                setWizardisDirty={setWizardisDirty}
                filters={filters}
            />

            <UnsavedChangesDialog show={showModal} close={closeModal} />
        </EvaluatorConfigurationProvider>
    );
};

export default MultipleValuationWizardViewSet;
