/* istanbul ignore file */
import React from 'react';
import {
    fetchApi as _fetchApi,
    useValuation as _useValuation,
    useValuationContacts as _useValuationContacts,
    useValuationWorkflowTask as _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners as _useValuationWorkflowTaskOwners,
    useValuationWorkflowTaskStatuses as _useValuationWorkflowTaskStatuses,
    ValuationApiOverrides,
    ValuationWorkflowTask,
    ValuationWorkflowTaskStatus,
    ValuationWorkflowTemplateStage,
    ValuationWorkflowTemplateTask,
    ValuationWorkflowTemplateTaskStatus,
} from '../../../valuationApi';
import { WorkflowModal as WorkflowModalTemplatePanel } from './WorkflowModal.TemplatePanel';
import { WorkflowModal as WorkflowModalAdHocTaskPanel } from './WorkflowModal.AdHocTaskPanel';
import { WorkflowModal as BaseWorkflowModal } from './WorkflowModal.Modal';

interface Props
    extends Pick<
        ValuationApiOverrides,
        | 'fetchApi'
        | 'useValuation'
        | 'useValuationContacts'
        | 'useValuationWorkflowTask'
        | 'useValuationWorkflowTaskOwners'
        | 'useValuationWorkflowTaskStatuses'
    > {
    /**
     * The id of the current WorkflowTemplateStage.
     */
    currentWorkflowTemplateStageId: string;
    /**
     * The value to display to represent the Valuations current WorkflowTemplateStatus.
     */
    currentWorkflowTemplateStatusName: string;
    /**
     * Determines whether the Modal is showing or hidden.
     */
    isOpen: boolean;
    /**
     * The Id of the Valuation to fetch.
     */
    valuationId: string;
    /**
     * The collection of ValuationWorkflowTasks to display in the Grid.
     */
    workflowTasks: ValuationWorkflowTask[];
    /**
     * A collection of ValuationWorkflowTaskStatuses which will be combined with the ValuationWorkflowTasks
     * to determine the ValuationWorkflowTaskStatus of a given ValuationWorkflowTask.
     */
    workflowTaskStatuses: ValuationWorkflowTaskStatus[];
    /**
     * A collection of ValuationWorkflowTemplateStages which will separate ValuationWorkflowTasks in the grid
     * by ValuationWorkflowTaskStatus.Name, using Full Width Rows.
     */
    workflowTemplateStages: ValuationWorkflowTemplateStage[];
    /**
     * A collection of ValuationWorkflowTemplateTasks which will comprise the contents of the WorkflowTemplatePanel.
     */
    workflowTemplateTasks: ValuationWorkflowTemplateTask[];
    /**
     * A collection of ValuationWorkflowTemplateTaskStatuses which will be combined with the ValuationWorkflowTasks
     * to determine the ValuationWorkflowTemplateTaskStatuses of a given ValuationWorkflowTask.
     */
    workflowTemplateTaskStatuses: ValuationWorkflowTemplateTaskStatus[];
    /**
     * Callback when the user selects the Close button.
     * @param event The triggering event.
     */
    onRejectionButtonClicked?: (
        event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>,
    ) => void;
    /**
     * Boolean indicator of if the WorkflowTaskGrid should display in the errored state.
     */
    workflowTaskGridIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowTaskGrid should display in the loading state.
     */
    workflowTaskGridIsLoading?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks request errored.
     */
    workflowTemplateStagesRequestIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks are still loading.
     */
    workflowTemplateStagesRequestIsLoading?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks request errored.
     */
    workflowTemplateTasksRequestIsErrored?: boolean;
    /**
     * Boolean indicator of if the WorkflowTemplateTasks are still loading.
     */
    workflowTemplateTasksRequestIsLoading?: boolean;
}

export const WorkflowModal = ({
    currentWorkflowTemplateStageId,
    currentWorkflowTemplateStatusName,
    fetchApi = _fetchApi,
    isOpen,
    onRejectionButtonClicked,
    useValuation = _useValuation,
    useValuationContacts = _useValuationContacts,
    useValuationWorkflowTask = _useValuationWorkflowTask,
    useValuationWorkflowTaskOwners = _useValuationWorkflowTaskOwners,
    useValuationWorkflowTaskStatuses = _useValuationWorkflowTaskStatuses,
    valuationId,
    workflowTaskGridIsErrored,
    workflowTaskGridIsLoading,
    workflowTasks,
    workflowTaskStatuses,
    workflowTemplateStages,
    workflowTemplateStagesRequestIsErrored,
    workflowTemplateStagesRequestIsLoading,
    workflowTemplateTasks,
    workflowTemplateTasksRequestIsErrored,
    workflowTemplateTasksRequestIsLoading,
    workflowTemplateTaskStatuses,
}: Props): React.ReactElement => {
    return (
        <WorkflowModalTemplatePanel
            workflowTemplateStages={workflowTemplateStages}
            workflowTemplateStagesRequestIsErrored={workflowTemplateStagesRequestIsErrored}
            workflowTemplateStagesRequestIsLoading={workflowTemplateStagesRequestIsLoading}
            workflowTemplateTasks={workflowTemplateTasks}
            workflowTemplateTasksRequestIsErrored={workflowTemplateTasksRequestIsErrored}
            workflowTemplateTasksRequestIsLoading={workflowTemplateTasksRequestIsLoading}
        >
            {({ openPanel: openWorkflowTemplatePanel }): React.ReactElement => (
                <WorkflowModalAdHocTaskPanel valuationId={valuationId}>
                    {({ openPanel: openAdHocTaskPanel }): React.ReactElement => (
                        <BaseWorkflowModal
                            currentWorkflowTemplateStageId={currentWorkflowTemplateStageId}
                            currentWorkflowTemplateStatusName={currentWorkflowTemplateStatusName}
                            fetchApi={fetchApi}
                            isOpen={isOpen}
                            onClickAddAdHocTask={openAdHocTaskPanel}
                            onClickClose={onRejectionButtonClicked}
                            onClickViewWorkflowTemplate={openWorkflowTemplatePanel}
                            useValuation={useValuation}
                            useValuationContacts={useValuationContacts}
                            useValuationWorkflowTask={useValuationWorkflowTask}
                            useValuationWorkflowTaskOwners={useValuationWorkflowTaskOwners}
                            useValuationWorkflowTaskStatuses={useValuationWorkflowTaskStatuses}
                            valuationId={valuationId}
                            workflowTaskGridIsErrored={workflowTaskGridIsErrored}
                            workflowTaskGridIsLoading={workflowTaskGridIsLoading}
                            workflowTasks={workflowTasks}
                            workflowTaskStatuses={workflowTaskStatuses}
                            workflowTemplateStages={workflowTemplateStages}
                            workflowTemplateTaskStatuses={workflowTemplateTaskStatuses}
                        />
                    )}
                </WorkflowModalAdHocTaskPanel>
            )}
        </WorkflowModalTemplatePanel>
    );
};

export default WorkflowModal;
